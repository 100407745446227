import React, { Component } from "react";
import { connect } from "react-redux";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";
import PaymentFormOrInfo from "../../components/billing/paymentFormOrInfo";
import {
  getCompanyPaymentDetails,
  saveCreditCard
} from "../../action-creators/billing";
import { getCompanyTest } from "../../action-creators/companies";
import {
  NEW_CARD_ADDED,
  COMPANY_PAYMENT_DETAILS_EXITED,
  WAIVER_RATES_EXITED,
  NEW_COMPANY_CARD_UPDATED
} from "../../constants";
import { and, equals, map } from "ramda";
import moment from "moment";
import AddPaymentCard from "../../components/billing/addPaymentCard";
import "../../components/payment.css";
import CustomizedSnackbars from "../../components/snackbar";
import RatesList from '../../components/billing/ratesList'
class PaymentInformation extends Component {
  state = {
    expanded: false,
    newCard: false,
    name: "Composed TextField",
    title: "the title"
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      and(!equals(prevProps.isSaved, this.props.isSaved), this.props.isSaved)
    ) {
      // console.log("params.id", this.props.companyId);
      this.props.getCompanyTest(this.props.companyId);
    }
  }

  addNewCardFunc = () => {
    this.setState({ newCard: !this.state.newCard });
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  handleExpandClick = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    const {
      classes,
      transactionInformation,
      waiverRates,
      isError,
      isSaving,
      isSaved,
      errorMsg,
      wrErrorMsg,
      wrIsError,
      wrIsLoading,
      wrIsSaved
    } = this.props;

    return transactionInformation.data ? (
      !waiverRates.isFetching && !transactionInformation.isFetching ? (
        <div>
          <Grid container spacing={24}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Grid container direction="column" spacing={16}>
                <Grid item>
                  {this.state.expanded ? (
                    <AddPaymentCard
                      addNewCardFunc={this.addNewCardFunc}
                      handleExpandClick={this.handleExpandClick}
                      newCard={this.state.newCard}
                      expanded={this.state.expanded}
                      classes={classes}
                      newCompanyCard={this.props.newCompanyCard}
                      saveCreditCard={this.props.saveCreditCard}
                    />
                  ) : (
                      <PaymentFormOrInfo
                        addNewCardFunc={this.addNewCardFunc}
                        handleExpandClick={this.handleExpandClick}
                        companyPaymentDetails={this.props.companyPaymentDetails}
                        newCard={this.state.newCard}
                        expanded={this.state.expanded}
                        classes={classes}
                      />
                    )}
                </Grid>
                <Grid item>
                  <Card className={classes.card}>
                    <CardHeader title="Waiver Rates" />
                    <RatesList classes={classes} />
                  </Card>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={6}>
              <div>
                <Card className={classes.card}>
                  <CardHeader title="Transaction History" />
                  <Card>
                    {map(
                      txs => (
                        <div key={txs.Id}>
                          <ExpansionPanel>
                            <ExpansionPanelSummary
                              classes={{ root: classes.expansionPanelSummary }}
                              expandIcon={<ExpandMoreIcon />}
                            >
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell padding="none">
                                      Billed on
                                    </TableCell>
                                    <TableCell>Billing Period</TableCell>
                                    <TableCell padding="none">Amount</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow>
                                    <TableCell
                                      classes={{ root: classes.tableCell }}
                                      padding="none"
                                    >
                                      <Typography>
                                        {moment
                                          .utc(txs.Created)
                                          .format("MM/DD/YYYY")}
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      classes={{ root: classes.tableCell }}
                                    >
                                      <Grid container>
                                        <Grid item>
                                          <Typography>
                                            {" "}
                                            {`${moment
                                              .utc(txs.From)
                                              .format("MM/DD/YYYY")} - `}{" "}
                                          </Typography>
                                        </Grid>
                                        <Grid item>
                                          <Typography>
                                            {" "}
                                            {`${moment
                                              .utc(txs.To)
                                              .format("MM/DD/YYYY")}`}{" "}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </TableCell>
                                    <TableCell
                                      classes={{ root: classes.tableCell }}
                                      padding="none"
                                    >
                                      <Typography>
                                        {`$${txs.Amount}`}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </ExpansionPanelSummary>
                            <Divider />
                            <ExpansionPanelDetails
                              classes={{
                                root: classes.expansionPanel
                              }}
                              className={classes.details}
                            >
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell padding="none">
                                      Waiver company
                                    </TableCell>
                                    <TableCell padding="none">Signed</TableCell>
                                    <TableCell>Rate</TableCell>
                                    <TableCell padding="none">Total</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {map(
                                    details => (
                                      <TableRow key={details.Id}>
                                        <TableCell
                                          classes={{ root: classes.tableCell }}
                                          padding="none"
                                        >
                                          <Typography>
                                            {details.WaiverCompanyName}
                                          </Typography>
                                        </TableCell>
                                        <TableCell
                                          classes={{ root: classes.tableCell }}
                                          padding="none"
                                        >
                                          <Typography>
                                            {details.WaiverCount}
                                          </Typography>
                                        </TableCell>
                                        <TableCell
                                          classes={{ root: classes.tableCell }}
                                        >
                                          <Typography>
                                            {details.WaiverRate}
                                          </Typography>
                                        </TableCell>
                                        <TableCell
                                          classes={{ root: classes.tableCell }}
                                          padding="none"
                                        >
                                          <Typography>
                                            {`$${details.Total}`}
                                          </Typography>
                                        </TableCell>
                                      </TableRow>
                                    ),
                                    txs.Details
                                  )}
                                </TableBody>
                              </Table>
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                        </div>
                      ),
                      transactionInformation.data
                    )}
                  </Card>
                </Card>
              </div>
            </Grid>
          </Grid>
          {isError && (
            <CustomizedSnackbars
              // message={errorMsg}
              message="Payment Information Failed to load. Please try again."
              snackType="error"
            />
          )}
          {isSaving && (
            <CustomizedSnackbars
              message="New credit card is loading..."
              snackType="info"
            />
          )}
          {isSaved && (
            <CustomizedSnackbars
              message="New credit card successfully added!"
              snackType="success"
            />
          )}
        </div>
      ) : (
          <Grid container alignItems="stretch" spacing={24}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <div>
                {this.state.expanded ? (
                  <AddPaymentCard
                    {...this.props}
                    expanded={this.state.expanded}
                  />
                ) : (
                    <PaymentFormOrInfo
                      companyPaymentDetails={this.props.companyPaymentDetails}
                      classes={classes}
                      {...this.props}
                      expanded={this.state.expanded}
                    />
                  )}
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <div>
                <Card className={classes.card}>
                  <CardHeader title="Transaction History" />
                  <Card>
                    <ExpansionPanel>
                      <ExpansionPanelSummary
                        classes={{ root: classes.expansionPanelSummary }}
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell padding="none">Billed on</TableCell>
                              <TableCell padding="none">Billing Period</TableCell>
                              <TableCell padding="none">Amount</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell
                                classes={{ root: classes.tableCell }}
                                padding="none"
                              />
                              <TableCell
                                classes={{ root: classes.tableCell }}
                                padding="none"
                              />
                              <TableCell
                                classes={{ root: classes.tableCell }}
                                padding="none"
                              />
                            </TableRow>
                          </TableBody>
                        </Table>
                      </ExpansionPanelSummary>
                    </ExpansionPanel>
                  </Card>
                </Card>
              </div>
            </Grid>
          </Grid>
        )
    ) : (
        <Grid container spacing={24}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <div>
              {this.state.expanded ? (
                <AddPaymentCard {...this.props} expanded={this.state.expanded} />
              ) : (
                  <PaymentFormOrInfo
                    companyPaymentDetails={this.props.companyPaymentDetails}
                    classes={classes}
                    {...this.props}
                  />
                )}
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <div>
              <Card className={classes.card}>
                <CardHeader title="Transaction History " />
                <Card>
                  <List>
                    <ExpansionPanel>
                      <ExpansionPanelSummary
                        classes={{ root: classes.expansionPanelSummary }}
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell padding="none">Billed on</TableCell>
                              <TableCell padding="none">Billing Period</TableCell>
                              <TableCell padding="none">Amount</TableCell>
                            </TableRow>
                          </TableHead>
                        </Table>
                      </ExpansionPanelSummary>
                    </ExpansionPanel>
                  </List>
                </Card>
              </Card>
            </div>
          </Grid>
        </Grid>
      );
  }
}

const mapStateToProps = state => ({
  companyPaymentDetails: state.companyPaymentDetails.data,
  paymentInformation: state.paymentInformation,
  addNewCard: state.companyPaymentDetails.added,
  // addNewCard: state.addNewCard,
  transactionInformation: state.transactionInformation,
  waiverRates: state.waiverRates,
  isError: state.companyPaymentDetails.isError,
  isSaving: state.companyPaymentDetails.isSaving,
  isSaved: state.companyPaymentDetails.isSaved,
  errorMsg: state.companyPaymentDetails.errorMsg,
  ///addPaymentCard\\\
  newCompany: state.companyDetails.data,
  currentCompany: state.currentCompany.data
});

const mapActionsToProps = dispatch => ({
  //PaymentInformation\\
  getCompanyTest: id => dispatch(getCompanyTest(id)),
  companyPaymentInformationExited: () => {
    dispatch({ type: COMPANY_PAYMENT_DETAILS_EXITED });
    dispatch({ type: WAIVER_RATES_EXITED });
  },
  ////addPaymentCard\\\\
  addNewCardFunc: () => dispatch({ type: NEW_CARD_ADDED }),
  saveCreditCard: data => dispatch(saveCreditCard(data)),
  getCompanyPaymentDetails: () => dispatch(getCompanyPaymentDetails()),
  newCompanyCard: value => {
    dispatch({ type: NEW_COMPANY_CARD_UPDATED, payload: value });
    dispatch({ type: NEW_CARD_ADDED });
  },
  saveCreditCard: creditCard => dispatch(saveCreditCard(creditCard))
});

const connector = connect(
  mapStateToProps,
  mapActionsToProps
);

export default connector(PaymentInformation);
