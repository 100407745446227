import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { Formik, Field, Form } from "formik";
import CustomIntlTelInput from "./phoneNumber";
import {
  equals,
  isEmpty,
  length,
  split,
  trim,
  match,
  filter,
  and,
  or,
  lt,
  map,
} from "ramda";
import Grid from "@material-ui/core/Grid";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import "react-intl-tel-input/dist/main.css";
import { registerUser } from "../../action-creators/authentication";
import CustomizedSnackbars from "../snackbar";
const Logo = require("../../images/aWaiver_Logo_w_Text.svg");
const styles = theme => ({
  appBar: {
    position: "relative"
  },
  flex: {
    flex: 1
  },
  textField: {
    display: "flex",
    margin: theme.spacing.unit
  },
  label: {
    display: "inline",
    padding: "8rem"
  },
  field: {
    padding: ".1rem"
  },
  input: {
    display: "block",
    border: ` 1px solid transparent`
  },
  pic: {
    width: "120px",
    height: "45px"
  },
  container: {
    display: "flex"
  },
  phoneFieldset: {
    textAlign: "left",
    border: ".5px solid grey",
    borderRadius: "5px",
    paddingTop: "1rem",
    paddingBottom: "1rem",
    marginRight: "1.75rem",
    marginLeft: ".5rem",
    display: "flex",
    justifyContent: "center"
  },
  central: {
    textAlign: "center"
  }
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const MyForm = ({
  values: { username, email, name, phone, password, confirmPassword },
  isValid,
  requesting,
  handleClose,
  open,
  errors,
  touched,
  handleChange,
  handleBlur,
  classes
}) => {
  return (
    <Dialog
      fullScreen
      // open={isNil(open) ? false : true}
      open={open || false}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar} color="default">
        <Toolbar>
          <Grid item>
            <Hidden xsDown>
              <Typography className={classes.flex}>
                <img src={Logo} alt="AWaiver" className={classes.pic} />
              </Typography>
            </Hidden>
          </Grid>
          <Typography variant="h6" color="primary" className={classes.flex} />
          <IconButton color="inherit" onClick={handleClose} aria-label="Close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Grid container className={classes.root}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div className={classes.central}>
            <Form>
              <DialogTitle id="form-dialog-title">Register</DialogTitle>
              <DialogContent>
                <Typography>Create a new account</Typography>
                <Typography>
                  You do not need an account if you are only signing a waiver.
                </Typography>
                <TextField
                  id="username"
                  name="username"
                  helperText={touched.username ? errors.username : ""}
                  error={touched.username && Boolean(errors.username)}
                  label="Login Name"
                  className={classes.textField}
                  value={username}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin="dense"
                  variant="outlined"
                />

                <TextField
                  id="email"
                  name="email"
                  helperText={touched.email ? errors.email : ""}
                  error={touched.email && Boolean(errors.email)}
                  margin="dense"
                  label="Email Address"
                  className={classes.textField}
                  value={email}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <TextField
                  id="name"
                  name="name"
                  helperText={touched.name ? errors.name : ""}
                  error={touched.name && Boolean(errors.name)}
                  label="Full Name"
                  className={classes.textField}
                  value={name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin="dense"
                  variant="outlined"
                />
                <Field
                  fullWidth
                  name="phone"
                  id="phone"
                  component={CustomIntlTelInput}
                  value={phone}
                  preferredCountries={[`us`, `gb`, `ca`]}
                  // css={classes.textField}
                  // containerClassName={classes.container}
                  inputClassName={classes.phoneFieldset}
                  defaultValue={phone}
                />

                {errors.phone && touched.phone && (
                  <Typography color="error">{errors.phone}</Typography>
                )}

                <TextField
                  id="password"
                  name="password"
                  helperText={touched.password ? errors.password : ""}
                  error={touched.password && Boolean(errors.password)}
                  label="Password"
                  className={classes.textField}
                  type="password"
                  value={password}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin="dense"
                />
                <TextField
                  id="confirmPassword"
                  name="confirmPassword"
                  helperText={
                    touched.confirmPassword ? errors.confirmPassword : ""
                  }
                  error={
                    touched.confirmPassword && Boolean(errors.confirmPassword)
                  }
                  label="Confirm Password"
                  className={classes.textField}
                  variant="outlined"
                  margin="dense"
                  type="password"
                  value={confirmPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={!isValid}
                  color="primary"
                >
                  Save
                </Button>
              </DialogContent>
            </Form>
          </div>
        </Grid>
      </Grid>
    </Dialog>
  );
};

const validate = ({
  email,
  phone,
  username,
  name,
  password,
  confirmPassword
}) => {
  let errors = {};
  let singleName = value => split(" ", trim(value));
  let nameLength = value => length(value) < 2;
  if (username === undefined) {
    errors.username = "Login required";
  } else if (username.trim() === "") {
    errors.username = "Login must not be blank";
  } else if (length(username) < 4) {
    errors.username = "Too Short";
  } else if (length(username) > 50) {
    errors.username = "Too Long";
  }
  if (name === undefined) {
    errors.name = "Name required";
  } else if (isEmpty(name)) {
    errors.name = "Name must include first and last name";
  } else if (length(name) < 4) {
    errors.name = "Too Short";
  } else if (length(name) > 50) {
    errors.name = "Too Long";
  } else if (isEmpty(match(/ /, trim(name)))) {
    errors.name = "Please enter first and last name";
  } else if (!isEmpty(filter(nameLength, singleName(name)))) {
    errors.name = "Too Short - must include first and last name";
  }
  if (email === undefined || isEmpty(email)) {
    errors.email = "Email required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
    errors.email = "Invalid Email Address";
  }
  if (!phone) {
    errors.phone = "Phone number required";
  } else if (!window.intlTelInputUtils.isValidNumber(phone, "")) {
    errors.phone = "Invalid phone number";
  }
  if (password === undefined) {
    errors.password = "Password required";
  } else if (isEmpty(password)) {
    errors.password = "Password required";
  } else if (
    or(
      lt(6, password),
      or(
        or(
          isEmpty(match(/([a-z])/g, password)),
          isEmpty(match(/([A-Z])/g, password))
        ),
        or(
          isEmpty(match(/([0-9])/g, password)),
          isEmpty(match(/([!@#$%^&*])/g, password))
        )
      )
    )
  ) {
    errors.password =
      "Password must consist of at least 6 characters containing at least 1 lowercase letter, 1 uppercase letter, 1 numerical digit and 1 special character.";
  }
  if (confirmPassword === undefined) {
    errors.confirmPassword = "Please confirm your password";
  } else if (
    and(
      and(!isEmpty(password), !isEmpty(confirmPassword)),
      !equals(password, confirmPassword)
    )
  ) {
    errors.confirmPassword = "Password does not match";
  }

  return errors;
};

class RegisterUser extends Component {
  state = {
    username: "",
    email: "",
    name: "",
    phone: "",
    password: "",
    confirmPassword: "",
    country: "",
    dialCode: "",
    isValidNumber: false
  };

  // handleClickOpen = () => {
  //   this.setState({ open: true });
  // };
  handleReset = () => {
    this.setState({
      username: "",
      email: "",
      name: "",
      phone: "",
      password: "",
      confirmPassword: ""
    });
    this.props.handleClose();
  };
  submitValues = ({
    username,
    email,
    name,
    phone,
    password,
    confirmPassword
  }) => {
    const regObj = {
      Email: email,
      Name: name,
      UserName: username,
      Password: password,
      PhoneNumber: phone,
      ConfirmPassword: confirmPassword
    };
    this.props.registerUser(regObj);
    this.handleReset();
  };
  render() {
    const { classes, requesting, regEmail, authentication, open } = this.props;
    const {
      name,
      email,
      phone,
      username,
      password,
      confirmPassword
    } = this.state;
    const values = { name, email, phone, username, confirmPassword, password };

    let errMsg;
    if (length(authentication.response) > 1) {
      errMsg = map(
        e => (
          <React.Fragment>
            {e}
            <br />
          </React.Fragment>
        ),
        authentication.response
      );
    } else {
      errMsg = authentication.response;
    }
    return (
      <div>
        <Formik
          render={props => (
            <MyForm
              open={open}
              handleClose={this.handleReset}
              classes={classes}
              {...props}
              requesting={requesting}
            />
          )}
          initialValues={values}
          onSubmit={this.submitValues}
          validate={validate}
        />
        {regEmail === false && (
          <CustomizedSnackbars message={errMsg} snackType="error" />
        )}
        {regEmail === true && (
          <CustomizedSnackbars
            message={authentication.response}
            snackType="success"
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  requesting: state.authentication.requesting,
  regEmail: state.authentication.regEmail,
  authentication: state.authentication
});
const mapActionToProps = dispatch => ({
  registerUser: user =>
    // e => {
    //  e.preventDefault()
    dispatch(registerUser(user))
  // }
});
const connector = connect(
  mapStateToProps,
  mapActionToProps
);
export default connector(withStyles(styles)(RegisterUser));
