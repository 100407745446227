import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import Chart from 'chart.js'
import moment from 'moment'
import { reduce, merge, append, dissoc } from 'ramda'


// export const selectWaiverUsageCount = createSelector(
//     [waiverUsage],
//     (waiverUsage) => {
//         if (waiverUsage) {
//             return reduce((accu, x) => {
//                 accu += x.count
//                 return accu
//             }, 0, waiverUsage)
//         }
//         return
//     }
// )

class UsageChart extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {

            initChartData: {
                labels: [

                ],
                datasets: [{
                    data: [],
                    backgroundColor: [

                    ],
                    hoverBackgroundColor: [

                    ]
                }]
            }
        };
    }
    setChartData = data => {
        let chartData = reduce((accu, w) => {
            accu = merge(accu, { "labels": append(w.label, accu.labels) })
            accu = merge(accu, {
                "datasets": merge(accu.datasets.data, {
                    "data": append(w.count, accu.datasets.data),
                    "backgroundColor": append(w.color, accu.datasets.backgroundColor),
                    "hoverBackgroundColor": append(w.color, accu.datasets.hoverBackgroundColor)
                })
            })
            return accu
        }, this.state.initChartData, data)

        chartData.datasets = [dissoc('0', chartData.datasets)]
        return chartData
    }
    componentDidMount() {
        const todaysDate = moment().format("YYYY-MM-DD");
        // this.props.getWaiverUsage(todaysDate, todaysDate)
        // this.props.getWaiverContacts(todaysDate, todaysDate)
        let chartCanvas = this.refs.chart
        let myChart = new Chart(chartCanvas, {
            type: 'pie',
            data: this.state.initChartData,
            options: {
                layout: {
                    padding: {
                        top: 20,
                        bottom: 0
                    }
                },
                legend: {
                    display: false,
                }
            }
        })
        this.setState({ myChart })
    }

    componentDidUpdate() {
        if (this.props.waiverUsage) {
            const myChart = this.state.myChart
            const data = this.setChartData(this.props.waiverUsage)
            Object.assign(myChart.data, data);
            myChart.update();
        }
    }
    // extra work for this ....
    componentWillUnmount() {
        this.state.myChart.destroy()
    }
    shouldComponentUpdate(nextProps) {
        if (nextProps.waiverUsage === this.props.waiverUsage) {
            return false
        }
        return true
    }
    render() {
        const { classes, waiverUsage, waiverContacts, selectWaiverUsageCount } = this.props
        const { myChart } = this.state;
        const todaysDate = moment().format("YYYY-MM-DD");

        let chartData = waiverUsage
            ? this.setChartData(waiverUsage)
            : this.state.initChartData

        return (
            <Grid item xs>
                <canvas ref='chart' data={chartData} />
            </Grid>
        )
    }
}

export default UsageChart