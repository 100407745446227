import {
  COMPANY_SIGNING_STATEMENTS_SELECTED,
  PANEL_EXPANDED,
  SIGNING_STATEMENTS_CLEARED,
  SIGNING_STATEMENTS_EDITED,
  SIGNING_STATEMENTS_RETRIEVED,
  SIGNING_STATEMENTS_FAILED,
  SIGNING_STATEMENTS_EXITED,
  RESET_SIGNING_STATEMENTS,
  SIGNING_STATEMENTS_SAVED
} from "../constants";
import { merge } from "ramda";

const initialSigningStatements = {
  statements: [],
  panels: {},
  isFetching: false,
  isSaved: false,
  isError: false,
  errorMsg: ""
};

export const signingStatements = (state = initialSigningStatements, action) => {
  switch (action.type) {
    case COMPANY_SIGNING_STATEMENTS_SELECTED:
      // console.log("COMPANY_SIGNING_STATE_SELECTEDAP", action.payload);
      return merge(state, { statements: action.payload, isFetching: false });
    case SIGNING_STATEMENTS_RETRIEVED:
      return action.payload;
    case SIGNING_STATEMENTS_EDITED:
      return merge(state, { isFetching: true });
    case SIGNING_STATEMENTS_CLEARED:
      return merge(state, {
        statements: action.payload,
        isFetching: false,
        isSaved: true,
        isError: false
      });
    case SIGNING_STATEMENTS_FAILED:
      return merge(state, {
        isError: true,
        isFetching: false,
        errorMsg: action.payload,
        isSaved: false
      });
    case SIGNING_STATEMENTS_SAVED:
      return merge(state, { isError: false, isSaved: true, isFetching: false });
    case SIGNING_STATEMENTS_EXITED:
      // console.log("SIGNING_STATEMENT_EXITED_AP", action.payload);
      return merge(state, {
        isFetching: false,
        isSaved: false,
        isError: false
      });
    case RESET_SIGNING_STATEMENTS:
      return initialSigningStatements;
    case PANEL_EXPANDED:
      return merge(state, { panels: action.payload });
    default:
      return state;
  }
};
