import React from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import MenuAppBar from "../../components/menuAppBar";
import withDrawer from "../../components/withDrawer";
import EnhancedTableToolBar from "../../components/enhancedAppBar";
import withFooter from "../../components/withFooter";
import SearchBar from "../../components/signedWaivers/swSearchBar";
import SWTabs from "../../components/signedWaivers/tabs";
import { SWS_ASSIGNED, SIGNED_WAIVERS_SEARCH_CLEAR } from "../../constants";
import { isEmpty, and } from "ramda";
import {
  changeSearchSettings,
  setSearchedSW,
  getSearchSettings,
  setRecentSW,
  setPendingSW,
  getSW
} from "../../action-creators/signedWaivers";
//import { connection } from "../../lib/connection";
import { setConnection } from "../../action-creators/signalR";
import { stat } from "fs";
const styles = theme => ({
  root: theme.root,
  affix: {
    position: "fixed",
    orientation: "horizontal",
    right: 0,
    [theme.breakpoints.down("sm")]: {
      top: "280px"
    },
    [theme.breakpoints.up("md")]: {
      top: "240px"
    },
    [theme.breakpoints.up("lg")]: {
      top: "220px"
    }
  },
  notificationBadge: {
    top: "-13px",
    right: "-12px",
    height: "7px",
    width: "7px"
  },

  mobileSnackBar: {
    color: "pink",
    [theme.breakpoints.down("sm")]: {
      flexGrow: 1,
      padding: "24px"
    }
  },
  margin: {
    margin: theme.spacing.unit * 1
  },
  padding: {
    padding: `0 ${theme.spacing.unit * 2}px`
  }
});

class SignedWaiversPage extends React.PureComponent {
  state = {
    input: "",
    searchEnabled: false,
    value: 0
  };
  componentDidMount() {
    this.props.getSearchSettings();
    if (!this.props.signalR.connection || and(this.props.signalR.connection, this.props.connection.connectionState === 0)) {
      this.props.setConnection();


    }
  }
  componentWillMount() {
    this.props.setPendingSW();

  }
  handleInput = e => {
    this.setState({ input: e.target.value });
    //  this.searchOnChange()
  };

  _handleKeyPress = e => {
    if (e.key === "Enter") {
      if (this.state.searchEnabled === true) {
        this.props.setSearchedSW(this.state.input);
        // this.handleChange(2)
        this.setState({ value: 2 });
      }
    }
  };
  onSearch = e => {
    this.props.setSearchedSW(this.state.input);
    this.setState({ value: 2 });
    //   this.handleInputClear()
  };
  searchOnChange = e => {
    if (e.target.value.length < 2) {
      this.setState({ searchEnabled: false });
    } else {
      this.setState({ searchEnabled: true });
    }
  };
  handleChange = (event, value) => {
    this.setState({ value });
    if (value === 1) {
      this.props.setRecentSW();

      this.props.dispatch({
        type: SWS_ASSIGNED,
        payload: {
          isPending: false,
          isRecent: true,
          isSearched: false
        }
      });

      if (!isEmpty(this.props.searchedSW.SignedWaivers)) {
        this.props.dispatch({ type: SIGNED_WAIVERS_SEARCH_CLEAR });
      }
    } else if (value === 0) {
      this.props.setPendingSW()
      this.props.dispatch({
        type: SWS_ASSIGNED,
        payload: {
          isPending: true,
          isRecent: false,
          isSearched: false
        }
      });
      if (!isEmpty(this.props.searchedSW.SignedWaivers)) {
        this.props.dispatch({ type: SIGNED_WAIVERS_SEARCH_CLEAR });
      }
      // if (this.props.swStepper.isSearched === true) {
      //   this.setState({ value: 2 });
      //}
    }
  };

  render() {
    const {
      classes,
      pendingSW,
      recentSW,
      searchedSW,
      swStepper,
      signedWaiver,
      dispatch,
      connection,
      ind
    } = this.props;
    const { value, input } = this.state;
    let dotColor;
    if (!connection) {
      dotColor = "error";
    } else if (!connection.connectionState) {
      dotColor = "error"
    } else
      if (Number(connection.connectionState) === 1) {
        dotColor = "primary";
      } else if (
        Number(connection.connectionState === 2) ||
        Number(connection.connectionState) === 0
      ) {
        dotColor = "secondary";
      } else {
        dotColor = "error";
      }

    return (
      <div>
        <MenuAppBar
          userYes={true}
          companyYes={true}
          history={this.props.history}
          invisible={connection && connection.connectionState === 1}
          dotColor={dotColor}
        />
        <EnhancedTableToolBar
          title="Signed Waivers"
          // button1={
          searchBar={
            <Grid container>
              {/* //   <Grid item xl={6}> */}
              <SearchBar
                searchSettings={this.props.searchSettings}
                dispatch={dispatch}
                fetching={this.props.fetching}
                changeSearchSettings={this.props.changeSearchSettings}
                onSearch={e => this.onSearch(e)}
                getSearchSettings={this.props.getSearchSettings}
                input={input}
                handleInput={this.handleInput}
                searchOnChange={e => this.searchOnChange(e)}
                handleKeyPress={e => this._handleKeyPress(e)}
              />
            </Grid>
            // </Grid>
          }
        />

        <SWTabs
          classes={classes}
          pendingSW={pendingSW}
          recentSW={recentSW}
          searchedSW={searchedSW}
          value={value}
          handleChange={this.handleChange}
          swStepper={swStepper}
          signedWaiver={signedWaiver}
          ind={ind}
        />
      </div>
    );
  }
}

const connector = connect(
  state => {
    return {
      signalR: state.signalR,
      pendingSW: state.pendingSW,
      recentSW: state.recentSW,
      searchedSW: state.searchedSW,
      swStepper: state.swStepper,
      signedWaiver: state.signedWaiver,
      searchSettings: state.searchSettings,
      // fetching: state.searchedSW.fetching,
      fetching: state.ind.fetching,
      connection: state.signalR.connection,
      ind: state.ind
    };
  },
  dispatch => {
    return {
      changeSearchSettings: () => dispatch(changeSearchSettings()),
      setSearchedSW: input => dispatch(setSearchedSW(input)),
      getSearchSettings: () => dispatch(getSearchSettings()),
      setRecentSW: () => dispatch(setRecentSW),
      setConnection: () => dispatch(setConnection),
      setPendingSW: () => dispatch(setPendingSW),
      dispatch
    };
  }
);

export default withFooter(
  withDrawer(connector(withStyles(styles)(SignedWaiversPage)))
);
