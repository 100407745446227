import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Formik } from "formik";
import * as Yup from "yup";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import MenuAppBar from "../../components/menuAppBar";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Card from "@material-ui/core/Card";
import Checkbox from "@material-ui/core/Checkbox";
import Avatar from "@material-ui/core/Avatar";
import LockIcon from "@material-ui/icons/LockOutlined";
import VisibilityOff from "@material-ui/icons/VisibilityOffTwoTone";
import Visibility from "@material-ui/icons/VisibilityTwoTone";
import {
  loginUser,
  forgotPassword,
  forgotPasswordConfirmation
} from "../../action-creators/authentication";
import withFooter from "../../components/withFooter";
import Switch from "@material-ui/core/Switch";
import ForgotPassword from "../../components/authentication/forgotPasswordDialog";
import CustomizedSnackbars from "../../components/snackbar";
import RegisterUser from "../../components/authentication/registerUser";
import { has, and } from "ramda";
import { RESET_AUTH } from "../../constants";
import NoAuthDialog from "../../components/authentication/noAuthDialog";
const styles = theme => ({
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`
  },
  container: {
    maxWidth: "200px"
  },
  textField: {
    display: "flex",
    margin: theme.spacing.unit
  },
  navPadding: {
    paddingTop: "5rem",
    paddingBottom: "2.5rem"
  },
  button: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    backgroundColor: theme.palette.primary.main
  },
  secondButton: {
    marginBottom: theme.spacing.unit,
    border: `4px solid `,
    borderRadius: "3px"
  },
  forgotPassword: {
    marginTop: "5px"
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.primary.main
  }
});
const SwitchInput = ({
  value,
  name,
  label,
  id,
  errorText,
  handleChange,
  checked
}) => {
  return (
    <FormControlLabel
      label={label}
      control={
        <Switch
          color="primary"
          onChange={handleChange}
          checked={checked}
          name={name}
          label={label}
          id={id}
          error={errorText}
        />
      }
    />
  );
};
const VisibilityCheck = ({ value, handleChange, id, checked, name }) => (
  <Checkbox
    color="primary"
    value={value.toString()}
    checked={checked}
    onChange={handleChange}
    name={name}
    id={id}
    checkedIcon={<Visibility />}
    icon={<VisibilityOff />}
  />
);
const MyForm = ({
  values: { UserName, Password, RememberMe, showPassword },
  handleForgotPassword,
  isLoading,
  openRegistration,
  errors,
  touched,
  classes,
  handleSubmit,
  handleChange,
  isValid,
  setFieldTouched
}) => {
  const change = (name, e) => {
    e.persist();
    handleChange(e);
    // console.log(errors)
    setFieldTouched(name, true, false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        id="UserName"
        name="UserName"
        helperText={touched.UserName ? errors.UserName : ""}
        error={touched.UserName && Boolean(errors.UserName)}
        label="Username or Email"
        className={classes.textField}
        variant="outlined"
        InputLabelProps={{
          shrink: true
        }}
        //    InputProps={{
        //     startAdornment: (
        //       <InputAdornment position="start">
        //         <AccountCircle />
        //       </InputAdornment>
        //     ),
        //   }}
        value={UserName}
        onChange={change.bind(null, "UserName")}
      />
      <TextField
        id="Password"
        className={classes.textField}
        variant="outlined"
        name="Password"
        type={showPassword ? "text" : "password"}
        helperText={touched.Password ? errors.Password : ""}
        error={touched.Password && Boolean(errors.Password)}
        label="Password"
        InputLabelProps={{
          shrink: true
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <VisibilityCheck
                value={showPassword}
                handleChange={change.bind(null, "showPassword")}
                id="showPassword"
                checked={showPassword}
                name="showPassword"
              />
            </InputAdornment>
          )
        }}
        value={Password}
        onChange={change.bind(null, "Password")}
      />
      <Grid container style={{ paddingTop: "3px" }}>
        <Grid item sm={6} md={6} lg={6}>
          <SwitchInput
            value={RememberMe}
            checked={RememberMe}
            handleChange={change.bind(null, "RememberMe")}
            name="RememberMe"
            label="RememberMe"
            id="RememberMe"
          />
        </Grid>
        <Grid item sm={6} md={6} lg={6}>
          <Grid container justify="flex-end">
            <Grid item>
              <Button
                color="primary"
                className={classes.forgotPassword}
                fullWidth
                onClick={handleForgotPassword}
              >
                Forgot your password?
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {isLoading ? (
        <Button
          disabled
          className={classes.button}
          fullWidth
          color="primary"
          variant="contained"
        >
          Logging in
          <CircularProgress
            size={24}
            color="primary"
            className={classes.buttonProgress}
          />
        </Button>
      ) : (
        <Button
          className={classes.button}
          fullWidth
          color="primary"
          variant="contained"
          type="submit"
        >
          Login
        </Button>
      )}
      {/* <RegisterDialog className={classes.secondButton} /> */}
      <Button
        className={classes.secondButton}
        onClick={openRegistration}
        fullWidth
        color="primary"
      >
        Register
      </Button>
    </form>
  );
};

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      UserName: "",
      Password: "",
      RememberMe: false,
      openFP: false,
      openRegistration: false
    };
  }
  // componentDidMount() {
  //     if ()
  // }
  handleFieldChange = (name, e) => {
    const { handleChange, setFieldTouched } = this.props;
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };
  handleForgotPassword = () => {
    this.setState({ openFP: true });
  };
  handleCloseFP = () => {
    this.setState({ openFP: false });
  };
  handleCloseRegistration = () => {
    this.setState({ openRegistration: false });
  };
  handleOpenRegistration = () => {
    this.setState({ openRegistration: true });
  };
  submitValues = ({ UserName, Password, RememberMe, showPassword }) => {
    const loginObj = {
      UserName: UserName,
      Password: Password,
      RememberMe: RememberMe
    };
    this.props.loginUser(loginObj);
  };

  render() {
    const {
      classes,
      isLoading,
      forgotPassword,
      forgotPasswordConfirmation,
      authentication,
      isError,
      emailSuccess,
      noAuth
    } = this.props;
    const { UserName, Password, RememberMe, showPassword } = this.state;
    const values = { UserName, Password, RememberMe, showPassword };

    return (
      <React.Fragment>
        <ForgotPassword
          open={this.state.openFP}
          dispatch={this.props.dispatch}
          handleClose={this.handleCloseFP}
          forgotPassword={forgotPassword}
          forgotPasswordConfirmation={forgotPasswordConfirmation}
          classes={classes}
          authentication={authentication}
          emailSuccess={emailSuccess}
        />
        <MenuAppBar
          home={true}
          history={this.props.history}
          open={this.state.openRegistration}
          handleOpen={this.handleOpenRegistration}
          handleClose={this.handleCloseRegistration}
        />
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.navPadding}
        >
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Card raised>
              <Grid container style={{ padding: "2em" }}>
                <Grid container justify="center">
                  <Grid item style={{ paddingBottom: ".5em" }}>
                    <Avatar className={classes.avatar}>
                      <LockIcon />
                    </Avatar>
                  </Grid>
                </Grid>
                <Grid container justify="center">
                  <Grid item>
                    <Typography variant="title">
                      Log in.
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container justify="center">
                  <Grid item xs>
                    <Formik
                      render={props => (
                        <MyForm
                          openRegistration={this.handleOpenRegistration}
                          handleForgotPassword={this.handleForgotPassword}
                          isLoading={isLoading}
                          classes={classes}
                          {...props}
                        />
                      )}
                      initialValues={values}
                      onSubmit={this.submitValues}
                      validationSchema={Yup.object().shape({
                        UserName: Yup.string("Enter a name").required(
                          "User Name or Email is required"
                        ),
                        Password: Yup.string()
                          //.matches("^(?=.*[a-z])(?=.*[A-Z])(?=.*d)[a-zA-Zd]$")
                          .required("Enter your password"),
                        RememberMe: Yup.boolean(),
                        showPassword: Yup.boolean()
                      })}
                    />
                  </Grid>
                </Grid>
                {isError && (
                  <CustomizedSnackbars
                    message="The user name or password is incorrect."
                    snackType="error"
                  />
                )}
                {and(
                  has("emailSuccess", authentication),
                  emailSuccess === true
                ) && (
                  <CustomizedSnackbars
                    message="Please check your email to reset your password."
                    snackType="success"
                    addFn={this.props.resetAuth}
                    closeFunction
                    dispatch={this.props.dispatch}
                  />
                )}
                {and(has("emailSuccess", authentication), !emailSuccess) && (
                  <CustomizedSnackbars
                    message="Forgotten Password Email Request Failed."
                    snackType="error"
                    addFn={this.props.resetAuth}
                    closeFunction
                    disaptch={this.props.dispatch}
                  />
                )}
                {noAuth && (
                  <NoAuthDialog
                    resetAuth={this.props.resetAuth}
                    clearNoAuth={this.props.clearNoAuth}
                  />
                )}
              </Grid>
            </Card>
          </Grid>
        </Grid>
        <RegisterUser
          open={this.state.openRegistration}
          handleClose={this.handleCloseRegistration}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.authentication.isLoading,
  authentication: state.authentication,
  isError: state.authentication.isError,
  emailSuccess: state.authentication.emailSuccess,
  noAuth: state.authentication.noAuth
});
const mapActionToProps = dispatch => ({
  loginUser: user => dispatch(loginUser(user)),
  // forgotPassword: email => e => {
  //     e.preventDefault()
  //     dispatch(forgotPassword(email))
  // },
  forgotPassword: email => dispatch(forgotPassword(email)),
  forgotPasswordConfirmation: () => dispatch(forgotPasswordConfirmation),
  resetAuth: () => dispatch({ type: RESET_AUTH }),
  dispatch
});
const connector = connect(
  mapStateToProps,
  mapActionToProps
);
export default withFooter(connector(withStyles(styles)(LoginPage)));
