import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { formatNumber } from "libphonenumber-js";
import CheckCircle from "@material-ui/icons/CheckCircle";
import HighlightOff from "@material-ui/icons/HighlightOff";
import moment from "moment";
import EnhancedTableToolbar from "../enhancedAppBar";
import EnhancedTableHead from "../enhancedTableHead";
import UsageChart from "./waiverUsageChart";
import { copyElementToClipboard, } from '../../lib/clipboard'
import CopyIcon from '@material-ui/icons/FileCopyOutlined'
import Hover from '../hover'
import Fab from '@material-ui/core/Fab'

const usageStyles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3
  },
  table: {
    minWidth: 1020
  },
  tableWrapper: {
    overflowX: "auto"
  },
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  fab: {
    margin: theme.spacing.unit * 1
  }
});


class EnhancedTableUsage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      order: "asc",
      orderBy: "active",
      selected: [],
      page: 0,
      rowsPerPage: 25,
      startingDate: this.props.startDate, //null,
      stoppingDate: this.props.todaysDate, //null,
      open: false
    };
    this.tableData = React.createRef()
  }


  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  onStartingDate = e => {
    this.setState({ startingDate: e.target.value });
  };
  onStoppingDate = e => {
    this.setState({ stoppingDate: e.target.value });
  };
  UpdateDates = () => {
    this.props.getWaiverContacts(
      this.state.startingDate,
      this.state.stoppingDate
    );
    this.props.getWaiverUsage(this.state.startingDate, this.state.stoppingDate);
  };
  componentDidMount() {
    this.props.getWaiverUsage(this.props.startDate, this.props.todaysDate);
    this.props.getWaiverContacts(this.props.startDate, this.props.todaysDate);
  }

  handleCopy = () => {
    const data = this.tableData
    copyElementToClipboard(document.getElementById("tableId"))
  }
  render() {
    const {
      classes,
      selectWaiverUsageCount,
      waiverContacts,
      waiverUsage,
      columnGraphData,
      columnContactData,
      startDate,
      todaysDate
    } = this.props;
    const { open, order, orderBy, selected, rowsPerPage, page } = this.state;
    // const todaysDate = moment().format("YYYY-MM-DD");

    const column = { xs: 6, sm: 6, md: 6, lg: 6 };

    const emptyRows = waiverContacts
      ? rowsPerPage -
      Math.min(rowsPerPage, waiverContacts.length - page * rowsPerPage)
      : 0;

    return (
      <React.Fragment>
        <EnhancedTableToolbar title="Usage Report" button1={<span />} />
        <Divider />
        <Divider />

        {/* Calendar selection for usage dates */}
        <Grid container style={{ padding: "1rem" }}>
          <Grid container>
            <Grid item {...column}>
              <Grid
                container
                className={classes.rangePadding}
                //spacing={16}
                alignItems="flex-end"
              >
                <Grid item>
                  <i className="material-icons">date_range</i>
                </Grid>
                <Grid item>
                  <Typography variant="subheading">From</Typography>
                </Grid>
                <Grid item>
                  <TextField
                    id="date"
                    type="date"
                    onChange={e => this.onStartingDate(e)}
                    defaultValue={startDate}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
                <Grid item>
                  <Typography variant="subheading">To</Typography>
                </Grid>
                <Grid item>
                  <TextField
                    id="date"
                    type="date"
                    onChange={e => this.onStoppingDate(e)}
                    defaultValue={todaysDate}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
                <Grid item>
                  <Button variant="contained" onClick={this.UpdateDates}>
                    Update
                  </Button>
                </Grid>
              </Grid>
              <Grid
                container
                className={classes.rangePadding}
                // spacing={16}
                alignItems="center"
              >
                {/* Pie Chart that reflects waiverUsage */}
                <UsageChart {...this.props} />
              </Grid>
            </Grid>

            {/* Waiver Usage Data Table */}
            <Grid item {...column}>
              {!waiverUsage ? (
                <div>
                  <Table>
                    <EnhancedTableHead columnData={columnGraphData} />
                  </Table>
                  <Grid container justify="center" alignItems="center">
                    <Grid item>
                      <CircularProgress
                        className={classes.progress}
                        size={200}
                      />
                    </Grid>
                  </Grid>
                </div>
              ) : (

                  <Table >
                    <EnhancedTableHead columnData={columnGraphData} />
                    <TableBody>
                      {waiverUsage.map(n => {
                        return (
                          <TableRow hover key={n.WaiverId}>
                            <TableCell padding="none">
                              <i
                                style={{ color: n.color }}
                                className="material-icons"
                              >
                                stop
                            </i>
                            </TableCell>
                            <TableCell>{n.label}</TableCell>
                            <TableCell align="right">{n.count}</TableCell>
                          </TableRow>
                        );
                      })}
                      <TableRow hover>
                        <TableCell padding="none" />
                        <TableCell>Total</TableCell>
                        <TableCell align="right">
                          {selectWaiverUsageCount}
                        </TableCell>
                      </TableRow>
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 27 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>

                )}
            </Grid>
          </Grid>

          {/* Waiver Contacts Data Table*/}
          <Grid container justify="center">
            <Grid item xs={12}>
              {!waiverContacts ? (
                <div>
                  <Table className={classes.table} aria-labelledby="tableTitle" contenteditable="true">
                    <EnhancedTableHead
                      //  className={classes.head}
                      addUsage
                      columnData={columnContactData}
                      waiverContacts={waiverContacts}
                    />
                  </Table>
                  <Grid container justify="center" alignItems="center">
                    <Grid item>
                      <CircularProgress
                        className={classes.progress}
                        size={200}
                      />
                    </Grid>
                  </Grid>
                </div>
              ) : (

                  <Grid container id={this.props.waiverContacts}>


                    <Hover message="Copy waiver contacts report"
                      component={
                        <Fab onClick={this.handleCopy} size="small" className={classes.fab} color="primary"  ><CopyIcon /></Fab>} />

                    <Table size="small" id="tableId" >
                      <EnhancedTableHead
                        color="inherit"
                        columnData={columnContactData}
                        waiverContacts={waiverContacts}
                        onRequestSort={this.handleRequestSort}
                        order={order}
                        orderBy={orderBy}
                        sortSettings={true}
                      />
                      <TableBody>
                        {waiverContacts
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((n, index) => {
                            return (
                              <TableRow
                                hover
                                key={
                                  n.PhoneNumber + index || n.ContactEmail + index
                                }
                              >
                                <TableCell>
                                  <Typography>{n.WaiverName}</Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography>{n.SignedByName}</Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography>{n.ContactEmail}</Typography>
                                </TableCell>
                                <TableCell padding="none">
                                  <Typography>
                                    {formatNumber(
                                      { country: "US", phone: `${n.ContactPhone}` },
                                      "National"
                                    )}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  {n.OptIn ? (
                                    <i className="material-icons">
                                      <CheckCircle />
                                    </i>
                                  ) : (
                                      <i className="material-icons">
                                        <HighlightOff />
                                      </i>
                                    )}
                                </TableCell>
                                <TableCell>
                                  <Typography>
                                    {moment.utc(n.Signed).format("lll")}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 49 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </Grid>
                )}
            </Grid>
          </Grid>
        </Grid>
        <TablePagination
          component="div"
          count={waiverContacts.length}
          rowsPerPageOptions={[25, 50, 125, 250]}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            "aria-label": "Previous Page"
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page"
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(usageStyles)(EnhancedTableUsage);
