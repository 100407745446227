import moment from "moment";
import { contains, map } from "ramda";

export const over50Years = date => {
  var nd = moment().add(50, "year");
  return moment(date).isAfter(nd);
};

export const adjustTime = date => {
  return moment.utc(date).local();
};

export const hasNotHappened = date => {
  return moment().isBefore(date);
};
export const hasHappened = date => {
  return moment().isAfter(date);
};

export const formatDateWTime = date => {
  return moment(date).format("ll LTS");
};
export const adjustFormatDWT = date => {
  const adjusted = adjustTime(date);
  return formatDateWTime(adjusted);
};

export const formatDate = date => {
  return moment.parseZone(date).format("ll");
};

export const includesToday = dates => {
  return contains(
    moment.parseZone().format("L"),
    map(p => moment(p.Date).format("L"), dates)
  );
};

export const today = moment().format();
export const formatToday = moment().format("ll LTS");
export const formatMoment = date => moment(date).format();
