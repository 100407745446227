import React from "react";
import TextField from "@material-ui/core/TextField";
import Button from '@material-ui/core/Button'

export function RateListItem(props) {
    const auth = Number(window.localStorage.isAuth) === 1;
    return (
        <form>
            <div style={{ display: "flex", justifyContent: "flex-start", alignContent: "center" }}>
                {auth ?

                    <Button className={props.classes.button} disabled={props.disabled} onClick={props.action} size="small" aria-label={props.new ? "Add rate" : "Delete Rate"} variant="contained" color="default" >{props.new ? "Add" : " Delete"}</Button>
                    : null}
                <TextField
                    type="number"
                    name="quantity"
                    onChange={props.onChangeCount}
                    disabled={!auth}
                    value={props.quantity}
                    label="From"
                    className={props.classes.rateInput}
                    variant="outlined"
                />
                <TextField
                    type="number"
                    name="rate"
                    disabled={!auth}
                    onChange={props.onChangeRate}
                    value={props.rate}
                    label="Rate"
                    className={props.classes.rateInput}
                    variant="outlined"
                />

            </div>
        </form>
    );
}