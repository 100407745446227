import React from "react";
import Table from "@material-ui/core/Table";
import Grid from "@material-ui/core/Grid";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import EnhancedTableHead from "../../components/enhancedTableHead";
import moment from "moment";

const paymentHeaders = [
  {
    id: "Created",
    align: "inherit",
    disablePadding: false,
    label: "Billed on"
  },
  {
    id: "CompanyName",
    align: "inherit",
    disablePadding: false,
    label: "Billed company"
  },
  {
    id: "CardTransactionId",
    align: "inherit",
    disablePadding: false,
    label: "Transaction "
  },
  { id: "Amount", align: "inherit", disablePadding: false, label: "Amount" },
  { id: "Fee", align: "inherit", disablePadding: false, label: "Fee" },
  { id: "Net", align: "inherit", disablePadding: false, label: "Net" }
];

const PaymentHistory = props => {
  const { paymentHistory } = props;

  return (
    <div>
      <Typography variant="headline">Payment History </Typography>
      {!paymentHistory.history ? (
        <div>
          <Table>
            <EnhancedTableHead
              columnData={paymentHeaders}
              sortSettings={false}
            />
          </Table>
          <Grid container justify="center" alignItems="center">
            <Grid item>
              <CircularProgress size={200} />
            </Grid>
          </Grid>
        </div>
      ) : (
        <Table>
          <EnhancedTableHead columnData={paymentHeaders} sortSettings={false} />
          <TableBody>
            {paymentHistory.history.map(n => {
              return (
                <TableRow hover key={n.CardTransactionId}>
                  <TableCell padding="none">
                    <Typography>
                      {moment.utc(n.Created).format("lll")}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{n.CompanyName}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{n.CardTransactionId}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>${n.Amount}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>${n.Fee}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>${n.Net}</Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
    </div>
  );
};

export default PaymentHistory;
