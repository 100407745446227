import {
  CURRENT_COMPANY_LOADING_STARTED,
  CURRENT_COMPANY_LOADING_FAILED,
  CURRENT_COMPANY_LOADING_SUCCEEDED,
  EDIT_COMPANY_CLEARED,
  EDIT_COMPANY_FORM_UPDATED,
  EDIT_COMPANY_SAVE_FAILED,
  EDIT_COMPANY_SAVE_SUCCEEDED,
  EDIT_COMPANY_SAVE_STARTED,
  COMPANY_LOADING_STARTED,
  COMPANY_LOADING_FAILED,
  COMPANY_LOADING_SUCCEEDED,
  CURRENT_COMPANY_SELECTION_STARTED,
  CURRENT_COMPANY_SELECTION_FAILED,
  CURRENT_COMPANY_SELECTION_SUCCEEDED,
  COMPANIES_CLEARED,
  COMPANIES_SET_FAILED,
  COMPANIES_REQUESTED,
  CURRENT_COMPANY_CLEARED,
  COMPANIES_RETRIEVED,
  NEW_COMPANY_SAVE_STARTED,
  NEW_COMPANY_SAVE_FAILED,
  NEW_COMPANY_SAVE_SUCCEEDED,
  NEW_COMPANY_FORM_UPDATED,
  CURRENT_COMPANY_SELECTED,
  COMPANY_DETAILS_REQUESTED,
  COMPANY_DETAILS_CLEARED,
  COMPANY_DETAILS_ACQUIRED,
  COMPANY_DETAILS_FAILED,
  COMPANY_DETAILS_SUBMITTED,
  COMPANY_LOCATIONS_RETRIEVED,
  CURRENT_COMPANY_SET,
  NEW_COMPANY_CARD_UPDATED,
  SET_COMPANIES,
  COMPANY_DETAILS_EDITED,
  NEW_COMPANY_CLEARED,
  NEW_COMPANY_SELECTED,
  SET_COMPANY_LOGO,
  COMPANY_DETAILS_EXITED
} from "../constants";
import { merge, mergeDeepRight } from "ramda";

const initialCurrentCompany = {
  data: {
    Id: "",
    ImageURL: "",
    Name: "",
    Current: false,
    RoleName: "",
    Description: "",
    BillingEmail: "",
    Locations: [
      { LocationId: "", Primary: true, LocationName: "", Address: "" }
    ],
    ExternallyLinkedSystem_Id: "",
    EmergencyPhone: "",
    ContactInformation: "",
    Waivers: [],
    SigningStatements: [],
    BillingData: {},
    Users: []
  },
  //isFetching: false,
  isLoading: false,
  isError: false,
  errorMsg: "",
  isFetching: false,
  isSelected: false
};

export const currentCompany = (state = {}, action) => {
  switch (action.type) {
    case CURRENT_COMPANY_SET:
      return Object.assign({}, state, action.payload);
    case CURRENT_COMPANY_SELECTED:
      return Object.assign({}, state, action.payload);
    case CURRENT_COMPANY_CLEARED:
      return {};
    case SET_COMPANY_LOGO:
      return Object.assign({}, state, { CompanyLogo: action.payload });
    default:
      return state;
  }
};

export const companies = (state = [], action) => {
  switch (action.type) {
    case COMPANIES_RETRIEVED:
      return action.payload;
    case COMPANIES_CLEARED:
      return [];
    case COMPANIES_SET_FAILED:
      return action.payload;
    case COMPANIES_REQUESTED:
      return action.payload;
    case SET_COMPANIES:
      // console.log("SET_COMPANIES_AP", action.payload);
      return state;
    default:
      return state;
  }
};

const initialCompanyDetails = {
  data: {
    Id: "",
    // ImageURL: "",
    // CompanyLogo: "",
    ImageURL: {},
    CompanyLogo: {},
    Name: "",
    Description: "",
    BillingEmail: "",
    Locations: [
      { LocationId: "", Primary: true, LocationName: "", Address: "" }
    ],
    ExternallyLinkedSystem_Id: "",
    EmergencyPhone: "",
    ContactInformation: "",
    Waivers: [],
    SigningStatements: [],
    Payment: ""
  },
  isSaving: false,
  isLoading: false,
  isError: false,
  errorMsg: "",
  isFetching: true,
  isSaved: false,
  // isSaving: false,
  // isError: false,
  isSubmitted: false
  // firstCompany: false
};

///leaving this as companyDetails right now to avoid errors, but can be DELETED
export const companyDetails = (state = initialCompanyDetails, action) => {
  switch (action.type) {
    ///////was newCompany\\\\\\\
    case NEW_COMPANY_SAVE_STARTED:
      return merge(state, { isLoading: true, isError: false, errorMsg: "" });
    case NEW_COMPANY_SAVE_FAILED:
      return mergeDeepRight(state, {
        isSaving: false,
        isError: true,
        isSubmitted: false,
        isSaving: false,
        isLoading: false,
        data: action.payload
      });
    ///successfully submitted newWaiver post\\\
    case NEW_COMPANY_SAVE_SUCCEEDED:
      return mergeDeepRight(state, {
        data: { Id: action.payload },
        isSubmitted: true,
        isFetching: false,
        isSaving: false,
        isError: false
      });

    case NEW_COMPANY_SELECTED:
      return mergeDeepRight(state, {
        data: { Id: action.payload }
      });
    case NEW_COMPANY_FORM_UPDATED:
      return mergeDeepRight(state, { data: action.payload });

    case NEW_COMPANY_CARD_UPDATED:
      return mergeDeepRight(state, { data: action.payload });

    case NEW_COMPANY_CLEARED:
      return initialCompanyDetails;
    //was editCompany
    case COMPANY_DETAILS_REQUESTED:
      return merge(state, { data: {}, isFetching: false });
    case COMPANY_DETAILS_CLEARED:
      return initialCompanyDetails;
    case COMPANY_DETAILS_ACQUIRED:
      // console.log(
      //   "COMPANY_DETAILS_ACQUIRED_MERGE",
      //   merge(state, { data: action.payload })
      // );
      return state;
    case COMPANY_LOCATIONS_RETRIEVED:
      return merge(state, { data: { Locations: action.payload } });
    case COMPANY_DETAILS_EDITED:
      return merge(state, action.payload);
    case COMPANY_DETAILS_FAILED:
      // return state;
      return merge(state, action.payload);
    case COMPANY_DETAILS_SUBMITTED:
      return action.payload;
    case COMPANY_DETAILS_EXITED:
      return merge(state, {
        isSaving: false,
        isLoading: false,
        isError: false,
        errorMsg: "",
        isFetching: false,
        isSaved: false,
        isSubmitted: false
      });

    default:
      return state;
  }
};
