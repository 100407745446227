export function selectElementContents(el) {
  const sel = window.getSelection();
  sel.removeAllRanges();
  const range = document.createRange();
  range.selectNodeContents(el)
  sel.addRange(range)
  const success = document.execCommand("copy")
  if (success) {
    console.log('Successfully copied to clipboard');

  } else console.error('Unable to copy to clipboard');



}


export function copyElementToClipboard(el) {
  selectElementContents(el);
  try {
    document.execCommand("copy");
    clearSelection();
  } catch (e) {
    alert("Could not copy to clipboard, try manual copy on selection");
  }
}

export function clearSelection() {
  if (window.getSelection) {
    if (window.getSelection().empty) {  // Chrome
      window.getSelection().empty();
    } else if (window.getSelection().removeAllRanges) {  // Firefox
      window.getSelection().removeAllRanges();
    }
  } else if (document.selection) {  // IE?
    document.selection.empty();
  }
}
