import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputBase from '@material-ui/core/InputBase'
export const TimeSelect = ({ value, hours, seconds,
    minutes, handleChange, days }) => {

    let name
    if (days) {
        name = "days"
    }
    else if (hours) {
        name = "hours"
    } else if (minutes) {
        name = "minutes"
    } else if (seconds) {
        name = "seconds"
    }
    return (

        <FormControl >
            <TextField style={{ maxWidth: 50, }} onChange={handleChange(`${name}`)} type="number"
                error={value < 0}
                id={name} value={value}
                InputLabelProps={{
                    shrink: true
                }}
                input={<InputBase name={name} alignItems="center" value={value} />}

            />
            <FormHelperText>{name}</FormHelperText>
        </FormControl>

    )
}