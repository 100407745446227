import React from "react";
import Button from "@material-ui/core/Button";
import { Formik } from "formik";
import * as Yup from "yup";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import { editWaiver } from "../../action-creators/waivers";
import withStyles from "@material-ui/core/styles/withStyles";
import FormDialog from "./formDialog";
import { withRouter } from "react-router-dom";
import { Statements, SwitchInput } from "./selectSS";
import { isEmpty, or, and, gt, length, equals } from "ramda";
import InputAdornment from "@material-ui/core/InputAdornment";
import CardVideo from './videoUrl'
import ExpOffset from '../expOffsetEntry'
import { CLEAR_TEMP } from '../../constants'
const styles = theme => ({
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 5}px ${theme.spacing.unit * 5}px ${theme
      .spacing.unit * 5}px`
  },
  container: {
    maxWidth: "200px"
  },
  textField: {
    display: "flex",
    margin: theme.spacing.unit
  },
  bootstrapRoot: {
    "label + &": {
      marginTop: theme.spacing.unit * 3
    }
  },
  bootstrapInput: {
    // borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.common.white,
    display: "flex",
    //  border: '1px solid #ced4da',
    fontSize: 16,
    width: "auto",
    // padding: '10px 12px',
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
    }
  },
  bootstrapFormLabel: {
    fontSize: 18
  },
  menu: {
    width: "75%"
  },
  bootstrapInput: {
    // borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.common.white,
    display: "flex",
    //  border: '1px solid #ced4da',
    fontSize: 16,
    width: "auto",
    // padding: '10px 12px',
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
    }
  },
  bootstrapFormLabel: {
    fontSize: 18
  },
  menu: {
    width: "75%"
  }
});



const MyForm = props => {
  const {
    classes,
    handleSubmit,
    errors,
    touched,
    handleChange,
    values: {
      Active,
      Name,
      Description,
      ParticipantSigningStatement_Id,
      GuardianSigningStatement_Id,
      ImgFileName,
      VideoURL,
      Expiration,
      ExpirationOffset
    },
    dispatch,
    isSubmitting,
    open,
    handleClose,
    setFieldTouched,
    setFieldValue,
    waiver, testing, handleTesting, tempData
  } = props;
  const change = (name, e) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };
  return (
    <FormDialog title="Edit Waiver" open={open} handleCancel={handleClose}>
      <form onSubmit={handleSubmit}>
        <SwitchInput
          value={Active}
          checked={Active}
          handleChange={change.bind(null, "Active")}
          name="Active"
          label="Active"
          id="Active"
        />

        <TextField
          id="Name"
          name="Name"
          helperText={touched.Name ? errors.Name : ""}
          error={touched.Name && Boolean(errors.Name)}
          label="Waiver Name"
          InputLabelProps={{ shrink: true }}
          // className={classes.bootstrapInput}
          className={classes.textField}
          value={Name}
          onChange={change.bind(null, "Name")}
          margin="normal"
          variant="outlined"
        />
        <TextField
          id="ParticipantPdf"
          name="ParticipantPdf"
          helperText={touched.ParticipantPdf ? errors.ParticipantPdf : ""}
          error={touched.ParticipantPdf && Boolean(errors.ParticipantPdf)}
          label="PDF of Waiver"
          InputLabelProps={{ shrink: true }}
          // className={classes.bootstrapInput}
          className={classes.textField}
          onChange={event => {
            setFieldValue("ParticipantPdf", event.currentTarget.files[0]);
            setFieldTouched("ParticipantPdf", true, true)
          }}
          margin="normal"
          variant="outlined"
          type="file"
        />
        <TextField
          id="GuardianPdf"
          name="GuardianPdf"
          helperText={touched.GuardianPdf ? errors.GuardianPdf : ""}
          error={touched.GuardianPdf && Boolean(errors.GuardianPdf)}
          label="PDF of Guardian Waiver (if different)"
          InputLabelProps={{ shrink: true }}
          className={classes.textField}
          onChange={event => {
            setFieldValue("GuardianPdf", event.currentTarget.files[0]);
            setFieldTouched("GuardianPdf", true, true)
          }}
          margin="normal"
          variant="outlined"
          type="file"
        />
        <TextField
          id="Description"
          name="Description"
          helperText={touched.Description ? errors.Description : ""}
          error={touched.Description && Boolean(errors.Description)}
          label="Description"
          InputLabelProps={{ shrink: true }}
          className={classes.textField}
          value={Description}
          onChange={change.bind(null, "Description")}
          margin="normal"
          variant="outlined"
          multiline
        />

        <Statements
          name="ParticipantSigningStatement_Id"
          handleChange={change.bind(null, "ParticipantSigningStatement_Id")}
          classes={classes}
          value={ParticipantSigningStatement_Id}
          label="Participant Signing Statement"
          placeholder="--Selection Required--"
          helperText={
            touched.ParticipantSigningStatement_Id
              ? errors.ParticipantSigningStatement_Id
              : ""
          }
          error={
            touched.ParticipantSigningStatement_Id &&
            Boolean(errors.ParticipantSigningStatement_Id)
          }
        />
        <Statements
          name="GuardianSigningStatement_Id"
          handleChange={change.bind(null, "GuardianSigningStatement_Id")}
          classes={classes}
          value={GuardianSigningStatement_Id}
          label="Guardian Signing Statement"
          placeholder="--Selection Required--"
        />

        <TextField
          id="VideoURL"
          name="VideoURL"
          helperText={touched.VideoURL ? errors.VideoURL : ""}
          error={touched.VideoURL && Boolean(errors.VideoURL)}
          label="Video URL (optional)"
          InputLabelProps={{ shrink: true }}
          className={classes.textField}
          value={VideoURL}
          onChange={change.bind(null, "VideoURL")}
          margin="normal"
          variant="outlined"
          InputProps={and(!errors.VideoURL, gt(length(VideoURL), 8)) ? {
            endAdornment: <InputAdornment position="end"> <Button value={VideoURL} variant="contained" color="primary" size="small" onClick={handleTesting}>Test Url</Button></InputAdornment>
          } : ""}


        />
        {and(and(testing, equals(testing, VideoURL)), and(!errors.VideoURL, gt(length(VideoURL), 8))) ? <CardVideo video={VideoURL} /> : <div />}
        <TextField
          id="ImgFileName"
          name="ImgFileName"
          helperText={touched.ImgFileName ? errors.ImgFileName : ""}
          error={touched.ImgFileName && Boolean(errors.ImgFileName)}
          label="Image File (optional)"
          InputLabelProps={{ shrink: true }}
          className={classes.textField}
          onChange={event => {
            setFieldValue("ImgFileName", event.currentTarget.files[0]);
            setFieldTouched("ImgFileName", true, true)
          }}
          margin="normal"
          variant="outlined"
          type="file"
        />

        <TextField
          id="Expiration"
          name="Expiration"
          helperText={touched.Expiration ? errors.Expiration : ""}
          error={touched.Expiration && Boolean(errors.Expiration)}
          label="Expiration Date (optional)"
          value={Expiration}
          onChange={change.bind(null, "Expiration")}
          // className={classes.bootstrapInput}
          className={classes.textField}
          margin="normal"
          variant="outlined"
        />

        <ExpOffset waiver={waiver} editing />
        <br />
        <Button
          type="submit"
          variant="contained"
          disabled={or(or(!isEmpty(errors), and(isEmpty(touched), tempData.edited !== true)), isSubmitting)}
        >
          Save
        </Button>
        <Button onClick={handleClose}>Cancel</Button>
      </form>
    </FormDialog>
  );
};

class NewWaiver extends React.PureComponent {
  state = { testing: false }
  handleFieldChange = (name, e) => {
    const { handleChange, setFieldTouched } = this.props;
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };
  handleTesting = (e) => {
    this.setState({ testing: e.currentTarget.value })
  }
  submitValues = ({
    Active,
    Name,
    ParticipantPdf,
    GuardianPdf,
    Description,
    ParticipantSigningStatement_Id,
    GuardianSigningStatement_Id,
    ImgFileName,
    VideoURL,
    Expiration,
    ExpirationOffset
  }) => {
    const waiverObj = {
      Active: Active,
      Name: Name,
      ParticipantPdf: ParticipantPdf,
      GuardianPdf: GuardianPdf,
      Description: Description,
      ParticipantSigningStatement_Id: ParticipantSigningStatement_Id,
      GuardianSigningStatement_Id: GuardianSigningStatement_Id,
      ImgFileName: ImgFileName,
      VideoURL: VideoURL,
      Expiration: Expiration,
      ExpirationOffset: this.props.tempData.offset
    };

    this.props.editWaiver(waiverObj);
    this.setState({ testing: false })
    this.props.handleClose();
    this.props.dispatch({ type: CLEAR_TEMP })
  };
  render() {
    const { waiver = {} } = this.props;
    return (
      <React.Fragment>
        <Paper elevation={1}>
          <Formik
            enableReinitialize={true}
            key={waiver.Id}
            render={props => (
              <MyForm
                classes={this.props.classes}
                handleTesting={this.handleTesting}
                testing={this.state.testing}
                {...props}
                handleClose={this.props.handleClose}
                open={this.props.open}
                waiver={waiver}
                tempData={this.props.tempData}
              />
            )}
            initialValues={waiver}
            onSubmit={this.submitValues}
            validationSchema={Yup.object().shape({
              Active: Yup.boolean(),
              Name: Yup.string()
                .min(2, "Too Short")
                .required("Waiver Name is required"),
              ParticipantPdf: Yup.mixed(),
              GuardianPdf: Yup.mixed(),
              Description: Yup.string()
                .min(2, "Too Short")
                .required("Please provide a description of the waiver"),
              ParticipantSigningStatement_Id: Yup.number()
                .positive()
                .integer()
                .required("Selection Required"),
              GuardianSigningStatement_Id: Yup.number()
                .positive()
                .integer()
                .required("Selection Required"),
              VideoURL: Yup.string().nullable().url(),
              ImgFileName: Yup.mixed(),
              Expiration: Yup.date(),
              ExpirationOffset: Yup.number()
            })}
          />
        </Paper>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  tempData: state.tempData
});
const mapActionToProps = dispatch => ({
  editWaiver: id => dispatch(editWaiver(id)),
  dispatch
});
const connector = connect(
  mapStateToProps,
  mapActionToProps
);
export default connector(withRouter(withStyles(styles)(NewWaiver)));
