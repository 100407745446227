import React from 'react'
import { Typography } from '@material-ui/core'
import {PropTypes} from 'prop-types'

function TabContainer(props) {
    return (
        <div>
            <Typography component="div" style={{ padding: 8 * 3 }}>
                {props.children}
            </Typography>
        </div>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

export default TabContainer