import React from "react";
import Grid from "@material-ui/core/Grid";
import { Field } from "formik";
import FormikMaterialInput from "../formikMaterialInput";
import FormikCheckbox from "../formikCheckbox";
import { validateName, luhnValidationWithCardType } from "../../lib/validation";
import ExpDateDropDown from "../formikDropDown";
import CustomizedSnackbars from "../snackbar";

const PaymentCard = props => {
  const { classes, isSubmitted } = props;
  return (
    <React.Fragment>
      <Grid container spacing={24}>
        <Grid item xs={12} md={6}>
          <Field
            className={classes.textFieldOutlined}
            validate={validateName}
            name="CardHolder"
            label="Name on Card"
            component={FormikMaterialInput}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Field
            className={classes.textFieldOutlined}
            name="CardNumber"
            label="Card Number"
            validate={luhnValidationWithCardType}
            component={FormikMaterialInput}
            type="number"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Field
            className={classes.textFieldOutlined}
            name="BillingZip"
            label="Billing Zip / Postal Code"
            component={FormikMaterialInput}
            type="number"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Field
            className={classes.textFieldOutlined}
            name="CVC"
            label="Card Verification Code"
            addText="3-4 digit security code"
            type="number"
            component={FormikMaterialInput}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Field
            name="ExpDate"
            label="Expiration Date"
            type="date"
            component={ExpDateDropDown}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Field
            className={classes.checkBox}
            name="Remember"
            label="Remember credit card details for next time"
            component={FormikCheckbox}
          />
        </Grid>
      </Grid>
      {/* <Debug /> */}
      {isSubmitted && (
        <CustomizedSnackbars
          message="New Company was successfully saved!"
          snackType="success"
        />
      )}
    </React.Fragment>
  );
};

export default PaymentCard;
