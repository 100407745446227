import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { Field } from "formik";
import FormikMaterialInput from "../formikMaterialInput";
import FormikCheckbox from "../formikCheckbox";
import { validateEmail } from "../../lib/validation";
// import { Debug } from "../../lib/Debug";

const CompanyInformation = props => {
  const { classes, formikProps } = props;
  return (
    <React.Fragment>
      <Grid container spacing={24}>
        <Grid item xs={12} sm={6}>
          <Field
            className={classes.textFieldOutlined}
            name="CompanyName"
            label="Company Name"
            component={FormikMaterialInput}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            className={classes.textFieldOutlined}
            name="BillingEmail"
            type="email"
            validate={validateEmail}
            label="Billing Email Address"
            component={FormikMaterialInput}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className={classes.fieldset}>
            <Field
              label="Company Image or Logo"
              className={classes.textFieldOutlined}
              name="CompanyLogo"
              onChange={e => {
                formikProps.setFieldTouched(`CompanyLogo`, true, true);
                formikProps.setFieldValue(`ImageUrl`, e.target.files[0].name);
                formikProps.setFieldValue(`CompanyLogo`, e.target.files[0]);
              }}
              type="file"
              component={TextField}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            className={classes.textFieldOutlined}
            name="CompanyDescription"
            label="Description of company's services"
            component={FormikMaterialInput}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            className={classes.textFieldOutlined}
            required
            name="BillingAddress"
            label="Address of Primary Location" //"Primary Location"
            component={FormikMaterialInput}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            className={classes.textFieldOutlined}
            name="City"
            label="Town / City"
            fullWidth
            component={FormikMaterialInput}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            className={classes.textFieldOutlined}
            name="State"
            label="State / Province / Region"
            component={FormikMaterialInput}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            className={classes.textFieldOutlined}
            name="ZipCode"
            label="Zip / Postal Code"
            type="number"
            component={FormikMaterialInput}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            className={classes.textFieldOutlined}
            name="Country"
            label="Country"
            component={FormikMaterialInput}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="GetContactInformation"
            className={classes.checkBox}
            component={FormikCheckbox}
            label="Collect email address and phone number from waiver signers"
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="GetEmergencyPhone"
            className={classes.checkBox}
            component={FormikCheckbox}
            label="Collect emergency phone numbers from participants"
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="GetBirthday"
            className={classes.checkBox}
            component={FormikCheckbox}
            label="Collect birthdays from waiver signers"
          />
        </Grid>
      </Grid>
      {/* <Debug /> */}
    </React.Fragment>
  );
};

export default CompanyInformation;
