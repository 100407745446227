import React, { Component } from "react";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import withStyles from "@material-ui/core/styles/withStyles";
import withDrawer from "../../components/withDrawer";
import MenuAppBar from "../../components/menuAppBar";
import {
  getCompanyPaymentDetails,
  saveCreditCard
} from "../../action-creators/billing";
import { editCompany, getCompanyTest } from "../../action-creators/companies";
import CompanyDetails from "./companyDetails";
import PaymentInformation from "./paymentInformation";
import Location from "./locations";
import SigningStatements from "./signingStatements";
import Spinner from "../../components/spinner";
import { and, equals, find, isEmpty, isNil, not, or, propEq } from "ramda";
import {
  SIGNING_STATEMENTS_EXITED,
  EDIT_LOCATION_EXITED,
  COMPANY_PAYMENT_DETAILS_EXITED,
  COMPANY_DETAILS_EXITED,
  WAIVER_RATES_EXITED,
  NEW_COMPANY_CARD_UPDATED,
  NEW_CARD_ADDED
} from "../../constants";
import { editCompanyStyles } from "./editCompanyStyles";
import AddPaymentCard from "../../components/billing/addPaymentCard";

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

class EditCompanyDetails extends Component {
  state = {
    value: 0,
    imageUrl: "",
    authImageUrl: "",
    failed: false,
    newCard: false,
    expanded: false,
    newCompany: false
  };

  componentDidMount() {
    setTimeout(this.timer, 12000);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
    if (this.props.isSaving || this.props.isSaved || this.props.isError) {
      this.props.companyDetailsExited();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    //set window to locations Tab\\
    if (this.props.locations.isClosed) {
      this.setState({ value: 2 });
      this.props.editLocationExited();
    }
    //Clears toasts for signing statements when clicked away\\
    if (and(equals(prevState.value, 3), !equals(this.state.value, 3))) {
      this.props.signingStatementExited();
    }
    //Clears toasts for companyDetails Tab\\
    else if (and(equals(prevState.value, 0), !equals(this.state.value, 0))) {
      this.props.companyDetailsExited();
    }
    //Clears toasts for paymentInformation Tab\\
    else if (and(equals(prevState.value, 1), !equals(this.state.value, 1))) {
      this.props.companyPaymentInformationExited();
    } else if (and(equals(prevState.value, 2), !equals(this.state.value, 2))) {
      this.props.editLocationExited();
    }
  }

  handleTabChange = (event, value) => {
    this.setState({ value });
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  handleExpandClick = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  addNewCardFunc = () => {
    this.setState({ newCard: !this.state.newCard });
  };

  timer = () => {
    if (
      and(
        this.props.companyPaymentDetails.isFetching,
        not(this.props.companyDetails.isSaving)
      )
    ) {
      this.setState({ failed: true });
    } else if (
      or(
        or(
          isNil(this.props.companyPaymentDetails),
          isNil(this.props.companyDetails)
        ),
        isNil(this.props.locations)
      )
    ) {
      this.setState({ failed: true });
    }
  };

  render() {
    const { value } = this.state;
    const {
      classes,
      signingStatements,
      companyDetails,
      companyPaymentDetails,
      locations,
      dispatch,
      transactionInformation
    } = this.props;

    return (
      <div>
        <MenuAppBar userYes={true} companyYes={true} />
        <AppBar style={{ paddingTop: 65 }} position="static">
          <Tabs
            value={value}
            onChange={this.handleTabChange}
            indicatorColor="primary"
          >
            <Tab label="Company Details" />
            <Tab
              label={
                !isEmpty(transactionInformation.data)
                  ? `Payment Information`
                  : `Add Payment Information`
              }
            />
            <Tab label="Locations" />
            <Tab label="Signing Statements" />
          </Tabs>
        </AppBar>

        {and(
          and(companyPaymentDetails.isFetching, !companyDetails.isSaving),
          !this.state.failed
        ) ? (
          <Spinner />
        ) : (
          <div>
            {value === 0 && (
              <TabContainer>
                <CompanyDetails
                  locations={locations.data}
                  companyPaymentDetails={this.props.companyPaymentDetails}
                  company={this.props.company}
                  companyDetails={this.props.companyDetails}
                  companyId={this.props.match.params.id}
                  primaryLocation={
                    !isNil(this.props.locations.data)
                      ? find(propEq("Primary"), this.props.locations.data)
                      : ``
                  }
                  currentUser={this.props.currentUser}
                  getCompanyTest={this.props.getCompanyTest}
                  isError={this.props.isError}
                  isSaving={this.props.isSaving}
                  isSaved={this.props.isSaved}
                  editCompany={this.props.editCompany}
                  getCompanyPaymentDetails={this.props.getCompanyPaymentDetails}
                  dispatch={dispatch}
                  history={this.props.history}
                  classes={classes}
                  failed={this.state.failed}
                  currentCompany={this.props.currentCompany}
                  newCompany={this.state.newCompany}
                  companyDetailsExited={this.props.companyDetailsExited}
                />
              </TabContainer>
            )}
            {value === 1 && (
              <TabContainer>
                {!isEmpty(transactionInformation.data) ? (
                  <PaymentInformation
                    companyId={this.props.match.params.id}
                    classes={classes}
                  />
                ) : (
                  <AddPaymentCard
                    companyId={this.props.match.params.id}
                    classes={classes}
                    handleExpandClick={this.handleExpandClick}
                    newCompanyCard={this.props.newCompanyCard}
                    saveCreditCard={this.props.saveCreditCard}
                    addNewCardFunc={this.addNewCardFunc}
                    newCard={this.state.newCard}
                    expanded={this.state.expanded}
                  />
                )}
              </TabContainer>
            )}
            {value === 2 && (
              <TabContainer>
                <Location
                  locations={locations}
                  companyId={this.props.match.params.id}
                  dispatch={this.props.dispatch}
                  dispatch={dispatch}
                  classes={classes}
                />
              </TabContainer>
            )}
            {value === 3 && (
              <TabContainer>
                <SigningStatements
                  signingStatements={signingStatements}
                  companyId={this.props.match.params.id}
                  classes={classes}
                />
              </TabContainer>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  signingStatements: state.signingStatements,
  companyPaymentDetails: state.companyPaymentDetails,
  company: state.companyPaymentDetails.data.Company,
  companyDetails: state.companyDetails,
  transactionInformation: state.transactionInformation,
  waiverRates: state.waiverRates,
  locations: state.locations,
  //companyDetails\\
  isError: state.companyDetails.isError,
  isSaving: state.companyDetails.isSaving,
  isSaved: state.companyDetails.isSaved,
  currentCompany: state.currentCompany
});

const mapActionsToProps = dispatch => ({
  getCompanyTest: id => dispatch(getCompanyTest(id)),
  getCompanyPaymentDetails: id => dispatch(getCompanyPaymentDetails(id)),
  editCompany: formData => dispatch(editCompany(formData)),
  editLocationExited: () => dispatch({ type: EDIT_LOCATION_EXITED }),
  //signingStatement\\
  signingStatementExited: () => dispatch({ type: SIGNING_STATEMENTS_EXITED }),
  companyDetailsExited: () => dispatch({ type: COMPANY_DETAILS_EXITED }),
  //paymentInformation\\
  companyPaymentInformationExited: () => {
    dispatch({ type: COMPANY_PAYMENT_DETAILS_EXITED });
    dispatch({ type: WAIVER_RATES_EXITED });
  },
  newCompanyCard: value => {
    dispatch({ type: NEW_COMPANY_CARD_UPDATED, payload: value });
    dispatch({ type: NEW_CARD_ADDED });
  },
  saveCreditCard: creditCard => dispatch(saveCreditCard(creditCard)),
  dispatch
});

const connector = connect(
  mapStateToProps,
  mapActionsToProps
);

export default withDrawer(
  connector(withStyles(editCompanyStyles)(EditCompanyDetails))
);
