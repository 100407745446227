import React, { Component } from "react";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { find, head, isNil, map } from "ramda";

class AddUserCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      RoleName: "ReviewWaivers",
      Company: `${
        isNil(this.props.company.Id)
          ? head(this.props.company)
          : head(this.props.company).Name
      }`
    };
  }

  // addAnotherRole = () => {
  //   this.props.arrayHelpers.push({});
  // };

  handleChange = event => {
    this.setState({ Company: event.target.value });

    let companyObj = find(
      c => c.Name === event.target.value,
      this.props.companies
    );

    this.props.setFieldTouched(`UserCompanies`, true, true);
    this.props.arrayHelpers.push({
      Company_Id: companyObj.Id,
      CompanyName: event.target.value,
      RoleName: this.state.RoleName
    });

    this.props.userLoadingStarted({
      UserId: this.props.values.UserId,
      RoleName: event.target.value,
      companyId: companyObj.Id
    });

    // window.location.reload();
  };

  render() {
    const { classes, company } = this.props;
    //accessLevel, index

    return (
      <Table className={classes.table}>
        <TableBody>
          <TableRow key={company.Id}>
            <TableCell align="left">
              <FormControl>
                <Select
                  label="Role Name"
                  value={this.state.Company}
                  onChange={this.handleChange}
                  input={<OutlinedInput labelWidth={0} name="Role Name" />}
                >
                  {isNil(company.Id)
                    ? company.map(
                        (comp, index) => (
                          <MenuItem key={index} value={comp}>
                            {comp}
                          </MenuItem>
                        ),
                        company
                      )
                    : company.map(
                        (comp, index) => (
                          <MenuItem key={index} value={comp.Name}>
                            {comp.Name}
                          </MenuItem>
                        ),
                        company
                      )}
                </Select>
              </FormControl>
            </TableCell>
            <TableCell align="right">
              <FormControl>
                <Select
                  disabled
                  label="Role Name"
                  value={this.state.RoleName}
                  input={<OutlinedInput name="Role Name" labelWidth={0} />}
                >
                  {map(
                    (
                      link //{link.value}
                    ) => (
                      <MenuItem key={link.label} value={link.label}>
                        {link.label}
                      </MenuItem>
                    ),
                    this.props.accessLevels
                  )}
                </Select>
              </FormControl>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }
}

export default AddUserCompany;
