import React from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import Card from "@material-ui/core/Card";
import Divider from "@material-ui/core/Divider";
import EnhancedTableToolbar from "../../components/enhancedAppBar";
import withDrawer from "../../components/withDrawer";
import MenuAppBar from "../../components/menuAppBar";
import withFooter from "../../components/withFooter";
import { getHelpVideos } from "../../action-creators/help";
import { map } from "ramda";

class HelpPage extends React.Component {
  state = {
    videoUrl:
      "https://awaiverdev.blob.core.windows.net/videos/_AWaiver_Introduction.mp4"
  };

  componentDidMount() {
    this.props.getHelpVideos();
  }

  setVideo = videoUrl => {
    this.setState({ videoUrl });
  };
  render() {
    const { helpVideos } = this.props;
    const listColumn = { xs: 3, sm: 3, md: 3, lg: 3 };
    const videoColumn = { xs: 9, sm: 9, md: 9, lg: 9 };
    return (
      <div>
        <MenuAppBar title="asdf" userYes={true} companyYes={true} />
        <EnhancedTableToolbar title="Help Videos" button1={<span />} />
        <Divider />
        <Grid container style={{ paddingTop: "1rem" }}>
          <Grid item xs>
            {helpVideos.videos ? (
              <Grid container>
                <Grid item {...listColumn}>
                  <Grid container justify="center">
                    <Grid item>
                      <Card>
                        {map(
                          x => (
                            <ListItem
                              key={x.Name}
                              button
                              onClick={e => this.setVideo(x.Url)}
                            >
                              <Typography>{x.Name}</Typography>
                            </ListItem>
                          ),
                          helpVideos.videos
                        )}
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item {...videoColumn}>
                  <Grid container justify="center" style={{ paddingRight: 20 }}>
                    <Grid item xs>
                      <video
                        className="img-responsive"
                        src={this.state.videoUrl}
                        controls
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid container>
                <Grid item {...listColumn}>
                  <Grid container justify="center">
                    <Grid item>
                      <Typography variant="headline">List of Videos</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item {...videoColumn}>
                  <Grid container justify="center">
                    <Grid item>
                      <Typography variant="headline">Url of Videos</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  helpVideos: state.helpVideos
});

const mapActionsToProps = dispatch => {
  return {
    drawerToggled: () => dispatch({ type: "DRAWER_TOGGLED" }),
    getHelpVideos: () => dispatch(getHelpVideos())
  };
};

const connector = connect(
  mapStateToProps,
  mapActionsToProps
);

export default withDrawer(withFooter(connector(HelpPage)));
