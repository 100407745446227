import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

const date = new Date();
const y = date.getFullYear();
function withFooter(Page) {
  let FooterComp = props => {
    return (
      <div>
        <Grid
          container
          style={{
            paddingBottom: "10px"
          }}
        >
          <Grid item xs>
            <Page />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs>
            <Divider />
          </Grid>
        </Grid>
        <Grid
          container
          style={{
            paddingTop: "10px",
            paddingLeft: "15px",
            paddingRight: "15px"
          }}
        >
          <Grid item sm={4} md={4} lg={4}>
            <Typography>
              <a
                target="_blank"
                href="http://localhost:61548/Content/AWaiver_Terms_of_Service.pdf"
              >
                Terms of Service
              </a>
            </Typography>
          </Grid>
          <Grid item sm={4} md={4} lg={4}>
            <Grid container justify="center">
              <Grid item>
                <Typography>AWaiver is a product of Indexic, Inc.</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={4} md={4} lg={4}>
            <Grid container justify="flex-end">
              <Grid item>
                <Typography>© {y} - Indexic, Inc.</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  };
  return FooterComp;
}

export default withFooter;
