import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import moment from "moment";
import MenuAppBar from "../../components/menuAppBar";
import withDrawer from "../../components/withDrawer";
import withFooter from "../../components/withFooter";
import {
  editCompany,
  getCompany,
  setCompanies,
  getCurrentCompany,
  selectCompany
} from "../../action-creators/companies";
import { equals, map, find, gt, update, propEq, has, isNil } from "ramda";
import {
  NEW_COMPANY_SELECTED,
  LOGOUT_REQUIRED,
  COMPANY_DETAILS_EXITED,
  DETAILS_EXITED
} from "../../constants";
import EnhancedTableToolbar from "../../components/enhancedAppBar";
import Hover from "../../components/hover";
import AddCompanyDialog from "../../components/companies/addCompanyDialog";
import EditIcon from "@material-ui/icons/Edit";
import CustomizedSnackbars from "../../components/snackbar";
import { withRouter } from "react-router-dom";
import LogoutRequired from "../../components/authentication/logoutRequiredDialog";
import { logoutUser } from "../../action-creators/authentication";
import { getCompanyPaymentDetails } from "../../action-creators/billing";
import ContactCard from "../../components/companies/contactCard";
import blue from "@material-ui/core/colors/blue";
import {
  ListItemAvatar,
  List,
  ListItem,
  ListItemText
} from "@material-ui/core";
import SearchOnType, { searchByName } from "../../components/searchOnType";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  root2: {
    width: "100%",
    backgroundColor: theme.palette.background.paper
  },
  button: {
    margin: theme.spacing.unit,
    justifyContent: "flex-end"
  },
  navPadding: {
    paddingTop: theme.navPadding.paddingTop
  },
  label: {
    margin: theme.spacing.unit,
    width: "100%"
  },
  avatar: {
    backgroundColor: blue[100],
    color: blue[600]
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  },
  rightIcon: {
    marginLeft: theme.spacing.unit
  },
  iconSmall: {
    fontSize: 20,
    marginRight: theme.spacing.unit
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit
  },
  textField: {
    display: "flex",
    margin: theme.spacing.unit
  }
});

let UserOptions = props => {
  const { classes, company, disabled } = props;
  return (
    <React.Fragment>
      {equals(props.currentCompany.id, company.Id) ? (
        <Button
          id={company.Id}
          variant="contained"
          disabled
          onClick={props.handleSet}
          className={classes.button}
        >
          Current
        </Button>
      ) : (
        <Button
          id={company.Id}
          variant="contained"
          color="default"
          onClick={props.handleSet}
          className={classes.button}
        >
          Set Current
        </Button>
      )}

      <Hover
        message="Edit Account Information"
        component={
          <Button
            key={company.Id}
            value={company.Id}
            id={company.Id}
            variant="contained"
            color="default"
            disabled={disabled}
            onClick={props.handleSetEdit}
            className={classes.button}
          >
            <EditIcon />
          </Button>
        }
      />
    </React.Fragment>
  );
};
function arePropsEqual(prevProps, nextProps) {
  if (prevProps.company.Id === nextProps.company.Id) {
    return;
  } else {
    return update(this.props.companies);
  }
}
React.memo(UserOptions, arePropsEqual);
// stateful component, which is connected to the redux store and manages it's own state
class CompanyPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      date: moment().format("YYYY-MM-DD"),
      name: "MyCompany",
      isSearching: false,
      searchedCompanies: []
    };
  }

  componentWillUnmount() {
    if (this.props.isSubmitted || this.props.isLoading || this.props.isError) {
      this.props.companyDetailsExited();
    }
  }

  handleChange = event => {
    this.setState({ value: event.target.value });
  };
  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  handleSetEdit = e => {
    const id = Number(e.currentTarget.id);
    const newRole = find(
      propEq("companyId", id),
      this.props.authentication.UserCompanies
    ).roleId;

    //removes companyDetails from previous company
    if (!equals(this.props.currentCompany.id, id)) {
      this.props.detailsExited();
    }

    if (equals(this.props.authentication.CurrentCompanyId, id)) {
      this.props.history.push(`/companyDetails/${id}`);
    } else {
      this.props.selectCompany(id).then(() => {
        if (Number(this.props.authentication.CurrentRoleId) === 1) {
          this.props.history.push(`/companyDetails/${id}`);
        } else if (
          equals(
            Number(newRole),
            Number(this.props.authentication.CurrentRoleId)
          )
        ) {
          this.props.history.push(`/companyDetails/${id}`);
        } else {
          this.props.dispatch({ type: LOGOUT_REQUIRED });
          localStorage.setItem("isAuth", Number(4));
        }
      });
    }
    return;
  };
  // component method
  handleSet = e => {
    const id = Number(e.currentTarget.id);
    const newRole = find(
      propEq("companyId", id),
      this.props.authentication.UserCompanies
    ).roleId;

    if (equals(this.props.authentication.CurrentRoleId, 1)) {
      this.props.selectCompany(id);
    } else if (
      equals(
        Number(this.props.authentication.CurrentRoleId),
        Number(Number(newRole))
      )
    ) {
      this.props.selectCompany(id);
    } else {
      this.props.selectCompany(id);
      this.props.dispatch({ type: LOGOUT_REQUIRED });
      localStorage.setItem("isAuth", Number(4));
    }

    return;
  };
  //handling searchbar
  handleInput = e => {
    const input = e.target.value;
    if (input.length > 1) {
      const cos = searchByName(input, this.props.companies);
      this.setState({ searchedCompanies: cos, isSearching: true });
    } else {
      this.setState({ isSearching: false, searchedCompanies: [] });
    }
  };

  //lifecycle method  this has to do with payment method
  componentDidMount() {
    this.props.cardAddedInit();
  }

  render() {
    const {
      classes,
      companies,
      dispatch,
      handleNext,
      handleBack,
      handleReset,
      handleClose,
      onSubmit,
      onChange,
      createCompany,
      history,
      isError,
      isLoading,
      isSubmitted,
      onClose,
      userCompanies
    } = this.props;
    const checkAuth = id => {
      if (has(userCompanies)) {
        if (!isNil(find(propEq("companyId", id), userCompanies))) {
          return gt(find(propEq("companyId", id), userCompanies).roleId, 2);
        }
      }
    };
    let cos;
    if (this.state.isSearching) {
      cos = this.state.searchedCompanies;
    } else {
      cos = companies;
    }
    return (
      <div>
        <MenuAppBar userYes={true} companyYes={true} />
        <EnhancedTableToolbar
          title="Companies"
          button1={
            <AddCompanyDialog
              handleNext={handleNext}
              handleBack={handleBack}
              handleReset={handleReset}
              handleClose={handleClose}
              onSubmit={onSubmit}
              onChange={onChange}
              classes={classes}
              createCompany={createCompany}
              history={history}
              isError={isError}
              isLoading={isLoading}
              onClose={onClose}
              dispatch={dispatch}
            />
          }
        />
        <List className={classes.root2}>
          <React.Fragment>
            {localStorage.isAuth &&
            Number(localStorage.isAuth) === 1 &&
            !companies.fetching ? (
              <SearchOnType handleInput={this.handleInput} />
            ) : (
              <div />
            )}
          </React.Fragment>
          {companies.fetching ? (
            <div />
          ) : (
            map(
              company => (
                <React.Fragment>
                  <ListItem key={`${company.Id} - ${company.Name}`}>
                    <ListItemAvatar>
                      <ContactCard
                        company={company}
                        classes={classes}
                        editCompany={this.props.editCompany}
                        selectCompany={this.props.selectCompany}
                        companyPaymentDetails={this.props.companyPaymentDetails}
                        currentCompany={this.props.currentCompany}
                        companies={companies}
                        dispatch={this.props.dispatch}
                      />
                    </ListItemAvatar>

                    <ListItemText primary={company.Name} />

                    <UserOptions
                      key={`${company.Id} - ${company.RoleName}`}
                      company={company}
                      {...this.props}
                      handleSet={this.handleSet}
                      handleSetEdit={this.handleSetEdit}
                      disabled={userCompanies && checkAuth(company.Id)}
                    />
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ),
              cos
            )
          )}
        </List>
        {isSubmitted && (
          <CustomizedSnackbars
            message="New Company Successfully Added!"
            snackType="success"
          />
        )}
        {isLoading && (
          <CustomizedSnackbars
            message="New Company is Saving... Please wait."
            snackType="info"
          />
        )}
        {isError && (
          <CustomizedSnackbars
            message="New Company Failed To Save. Please Try Again."
            snackType="error"
          />
        )}
        <LogoutRequired
          logoutUser={this.props.logoutUser}
          open={this.props.logoutRequired}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  companies: state.companies,
  currentCompany: state.currentCompany,
  currentBillingData: state.currentBillingData,
  loggedInUser: state.currentUser,
  isError: state.companyDetails.isError,
  isSubmitted: state.companyDetails.isSubmitted,
  isLoading: state.companyDetails.isLoading,
  current: state.currentCompany.data,
  userCompanies: state.authentication.UserCompanies,
  logoutRequired: state.authentication.logoutRequired,
  authentication: state.authentication,
  signalR: state.signalR,
  companyPaymentDetails: state.companyPaymentDetails
});

const mapActionsToProps = dispatch => ({
  getCurrentCompany: id => dispatch(getCurrentCompany(id)),
  setCompanies: () => dispatch(setCompanies),
  cardAddedInit: () => dispatch({ type: NEW_COMPANY_SELECTED }),
  selectCompany: id => dispatch(selectCompany(id)),
  getCompany: id => dispatch(getCompany(id)),
  logoutUser: () => dispatch(logoutUser),
  editCompany: contact => dispatch(editCompany(contact)),
  companyDetailsExited: () => dispatch({ type: COMPANY_DETAILS_EXITED }),
  detailsExited: () => dispatch({ type: DETAILS_EXITED }),
  getCompanyPaymentDetails: id => dispatch(getCompanyPaymentDetails(id)),
  dispatch
});

const connector = connect(
  mapStateToProps,
  mapActionsToProps
);

export default withDrawer(
  withFooter(connector(withRouter(withStyles(styles)(CompanyPage))))
);
