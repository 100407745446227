import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Paper from "@material-ui/core/Paper";
import DialogAppBar from "../dialogAppBar";
import CustomizedSnackbars from "../snackbar";
import { Field, Formik, Form } from "formik";
import { isNil, not, or } from "ramda";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import Hover from "../hover";
import FormikMaterialInput from "../formikMaterialInput";
import { validateEmail } from "../../lib/validation";
import Email from "@material-ui/icons/Email";

class AddUserDialog extends Component {
  state = {
    open: false,
    stateUserEmail: {
      Email: ""
    }
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    if (
      this.props.newIsAuthenticated ||
      this.props.newIsError ||
      this.props.newIsSaving
    ) {
      this.props.clearUser();
    }
    this.setState({ open: false });
  };

  handleClickSubmit = email => {
    this.props.sendInvitation(email);
  };

  handleCloseClear = () => {
    this.setState({ open: false });
    this.props.clearUser();
  };

  handleStateChange = (key, value) => {
    let stateUserEmail = this.state.stateUserEmail;
    stateUserEmail[key] = value;
    this.setState({ stateUserEmail });
  };

  render() {
    const {
      classes,
      newIsError,
      newIsSaving,
      newIsAuthenticated,
      newErrorMsg
    } = this.props;

    return (
      <React.Fragment>
        <CssBaseline />
        {
          <Hover
            message="Add User"
            component={
              <Button
                className={classes.label}
                variant="contained"
                color="primary"
                onClick={this.handleClickOpen}
              >
                <AddIcon className={classes.leftIcon} /> User
              </Button>
            }
          />
        }
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-new-user"
          fullScreen
        >
          <main className={classes.layout}>
            <Paper className={classes.paper}>
              <DialogAppBar
                title="Invite User"
                handleClose={this.handleClose}
              />
              <DialogContent>
                <DialogContentText align="center" variant="h6">
                  {not(this.props.newIsAuthenticated)
                    ? `Email address of the person you would like to approve your
                    waivers.`
                    : `Remember to change this user's role name on the
                    next page, if you would like them to be able to
                    approve/disapprove waivers!`}
                </DialogContentText>

                <Formik
                  onSubmit={(values, { resetForm }) => {
                    this.handleClickSubmit(values);
                    resetForm({ Email: "" });
                  }}
                  initialValues={this.state.stateUserEmail}
                  render={({ errors, touched, isSubmitting }) => (
                    <Form>
                      {not(this.props.newIsAuthenticated) ? (
                        <Field
                          className={classes.textField}
                          validate={validateEmail}
                          name="Email"
                          type="email"
                          icon={<Email />}
                          component={FormikMaterialInput}
                          placeholder="Email"
                        />
                      ) : (
                        <span>
                          <DialogContentText align="center" variant="subtitle2">
                            Return to Users table by clicking Cancel or the X in
                            the top right corner.
                          </DialogContentText>
                          <DialogContentText align="center" variant="subtitle2">
                            Grant access by finding their name in the list, and
                            change their level access from "None" to any other.
                          </DialogContentText>
                        </span>
                      )}
                      <Button onClick={this.handleClose}>Cancel</Button>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        disabled={or(
                          or(!isNil(errors.Email), isSubmitting),
                          not(touched.Email)
                        )}
                      >
                        Add User
                      </Button>
                    </Form>
                  )}
                />
              </DialogContent>
              {newIsError && (
                <CustomizedSnackbars message={newErrorMsg} snackType="error" />
              )}
              {newIsSaving && (
                <CustomizedSnackbars
                  message="New User is Loading..."
                  snackType="info"
                />
              )}
              {newIsAuthenticated && (
                <CustomizedSnackbars
                  message="Invitation Sent Successfully."
                  snackType="success"
                />
              )}
            </Paper>
          </main>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default AddUserDialog;
