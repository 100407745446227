//functionality
export const DRAWER_TOGGLED = "DRAWER_TOGGLED";
export const DRAWER_OPENED = "DRAWER_OPENED";
export const DRAWER_CLOSED = "DRAWER_CLOSED";
export const SEARCH_SETTINGS_RESET = "SEARCH_SETTINGS_RESET";
export const SEARCH_SETTINGS_REQUESTED = "SEARCH_SETTINGS_REQUESTED";
export const LOADING = "LOADING"
// export const SEARCH_SETTINGS_RETRIEVED = 'SEARCH_SETTINGS_RETRIEVED'
// export const SEARCH_SETTINGS_SAVE_STARTED = 'SEARCH_SETTINGS_SAVE_STARTED'
// export const SEARCH_SETTINGS_SAVE_FAILED = 'SEARCH_SETTINGS_SAVE_FAILED'
// export const SEARCH_SETTINGS_SAVE_SUCCEEDED = 'SEARCH_SETTINGS_SAVE_SUCCEEDED'
export const SEARCH_SETTINGS_FETCHED = "SEARCH_SETTINGS_FETCHED";
export const SEARCH_SETTINGS_CHANGED = "SEARCH_SETTINGS_CHANGED";
export const SEARCH_SETTINGS_FAILED = "SEARCH_SETTINGS_FAILED";
export const SET_SW_ARRAY = "SET_SW_ARRAY";
//tester
export const FILTERING = "FILTERING";
export const TAB_SELECTED = "TAB_SELECTED";
export const TAB_CLEARED = "TAB_CLEARED";
export const SUCCESS_SIGNED_WAIVER_STATUS = "SUCCESS_SIGNED_WAIVER_STATUS";
export const UPDATE_NOTES = "UPDATE_NOTES";
export const REQUEST_SIGNED_WAIVER_STATUS = "REQUEST_SIGNED_WAIVER_STATUS";
export const SUCCESS_SIGNED_WAIVER_PDF = "SUCCESS_SIGNED_WAIVER_PDF";
export const SUCCESS_WAIVER_SIGNATURE = "SUCCESS_WAIVER_SIGNATURE";
export const REQUEST_WAIVER_SIGNATURE = "REQUEST_WAIVER_SIGNATURE";
export const FAILURE_WAIVER_SIGNATURE = "FAILURE_WAIVER_SIGNATURE";
export const SUCCESS_WAIVER_VIDEO = "SUCCESS_WAIVER_VIDEO";
export const REQUEST_WAIVER_VIDEO = "REQUEST_WAIVER_VIDEO";
export const REQUEST_SET_STATUS = "REQUEST_SET_STATUS";
export const SUCCESS_SET_STATUS = "SUCCESS_SET_STATUS";
export const SUCCESS_WAIVER_PHOTO = "SUCCESS_WAIVER_PHOTO";
export const SUCCESS_NOTE_HISTORY = "SUCCESS_NOTE_HISTORY";
export const FAILURE_NOTE_HISTORY = "FAILURE_NOTE_HISTORY";
export const REQUEST_NOTE_HISTORY = "REQUEST_NOTE_HISTORY";
export const CLEAR_NOTE_HISTORY = "CLEAR_NOTE_HISTORY";
export const REQUEST_NEW_NOTE = "REQUEST_NEW_NOTE";
export const FAILURE_NEW_NOTE = "FAILURE_NEW_NOTE";
export const SUCCESS_NEW_NOTE = "SUCCESS_NEW_NOTE";
export const SET_CURRENT_NOTES = "SET_CURRENT_NOTES";
export const REQUEST_EDIT_NOTE = "REQUEST_EDIT_NOTE";
export const FAILURE_EDIT_NOTE = "FAILURE_EDIT_NOTE";
export const SUCCESS_EDIT_NOTE = "SUCCESS_EDIT_NOTE";
export const RESET_CURRENT_NOTES = "RESET_CURRENT_NOTES";
export const SET_UPDATED_NOTE = "SET_UPDATED_NOTE";
export const APPEND_NOTE_HISTORY = "APPEND_NOTE_HISTORY";
export const SELECTED_SIGNED_WAIVER_ID = "SELECTED_SIGNED_WAIVER_ID";
export const CLEAR_SELECTED_SIGNED_WAIVER_ID =
  "CLEAR_SELECTED_SIGNED_WAIVER_ID";
//export const HANDLE_CREATE_GROUP = 'HANDLE_CREATE_GROUP'
export const GROUP_DRAWER = "GROUP_DRAWER";
//export const SUCCESS_GROUP_EDIT = 'SUCCESS_GROUP_EDIT'
//export const SUCCESS_GROUP_DELETION = 'SUCCESS_GROUP_DELETION'
//export const REQUEST_GROUP_GET='REQUEST_GROUP_GET'
//export const FAILURE_GROUP_GET='FAILURE_GROUP_GET'
//export const SUCCESS_GROUP_GET = 'SUCCESS_GROUP_GET'
export const SELECTED_GROUP_TAB = "SELECTED_GROUP_TAB";
export const SUCCESS_GROUP_PARTICIPANTS_EDIT =
  "SUCCESS_GROUP_PARTICIPANTS_EDIT";
export const REQUEST_GROUP_DELETION = "REQUEST_GROUP_DELETION";
export const FAILURE_GROUP_DELETION = "FAILURE_GROUP_DELETION";
export const REQUEST_GROUP_PARTICIPANTS = "REQUEST_GROUP_PARTICIPANTS";
export const FAILURE_GROUP_PARTICIPANTS = "FAILURE_GROUP_PARTICIPANTS";
export const REQUEST_GROUP_PARTICIPANTS_GET = "REQUEST_GROUP_PARTICIPANTS_GET";
export const SUCCESS_GROUP_PARTICIPANTS = "SUCCESS_GROUP_PARTICIPANTS";
export const FAILURE_GROUP_PARTICIPANTS_GET = "FAILURE_GROUP_PARTICIPANTS_GET";
export const SUCCESS_GROUP_PARTICIPANTS_GET = "SUCCESS_GROUP_PARTICIPANTS_GET";
export const REQUEST_GROUP_PUT = "REQUEST_GROUP_PUT";
export const FAILURE_GROUP_PUT = "FAILURE_GROUP_PUT";
export const SUCCESS_GROUP_PUT = "SUCCESS_GROUP_PUT";
export const REQUEST_GROUP_POST = "REQUEST_GROUP_POST";
export const FAILURE_GROUP_POST = "FAILURE_GROUP_POST";
export const SUCCESS_GROUP_POST = "SUCCESS_GROUP_POST";
//export const SUCCESS_SEARCH_SETTINGS = 'SUCCESS_SEARCH_SETTINGS'
//export const REQUEST_SEARCH_SETTINGS = 'REQUEST_SEARCH_SETTINGS'
//export const NO_SEARCH_SETTINGS = 'NO_SEARCH_SETTINGS'
export const REQUEST_SEARCH = "REQUEST_SEARCH";
export const FINISH_SEARCH = "FINISH_SEARCH";
export const SEARCH_SIGNED_WAIVERS = "SEARCH_SIGNED_WAIVERS";
export const SEARCH_SIGNED_WAIVERS_FAILED = "SEARCH_SIGNED_WAIVERS_FAILED";
export const SUCCESS_SEARCH = "SUCCESS_SEARCH ";
export const FETCHING = "FETCHING";
//export const FAILURE_SEARCH_SETTINGS = 'FAILURE_SEARCH_SETTINGS'
//export const UPDATE_SEARCH_SETTINGS = 'UPDATE_SEARCH_SETTINGS'
export const FAILURE_SEARCH = "FAILURE_SEARCH";

//waivers
export const CURRENT_WAIVER_DEACTIVATED = "CURRENT_WAIVER_DEACTIVATED";
export const CURRENT_WAIVER_LOADING_STARTED = "CURRENT_WAIVER_LOADING_STARTED";
export const CURRENT_WAIVER_LOADING_FAILED = "CURRENT_WAIVER_LOADING_FAILED";
export const CURRENT_WAIVER_LOADING_SUCCEEDED =
  "CURRENT_WAIVER_LOADING_SUCCEEDED";
export const CURRENT_WAIVER_CLEARED = "CURRENT_WAIVER_CLEARED";

// export const WAIVER_DEACTIVATED = 'WAIVER_DEACTIVATED'
// export const WAIVER_ACTIVATED = 'WAIVER_ACTIVATED'

export const NEW_WAIVER_FORM_UPDATED = "NEW_WAIVER_FORM_UPDATED";
export const NEW_WAIVER_DEACTIVATED = "NEW_WAIVER_DEACTIVATED";
export const NEW_WAIVER_SAVE_FAILED = "NEW_WAIVER_SAVE_FAILED";
export const NEW_WAIVER_SAVE_STARTED = "NEW_WAIVER_SAVE_STARTED";
export const NEW_WAIVER_SAVE_SUCCEEDED = "NEW_WAIVER_SAVE_SUCCEEDED";

export const EDIT_WAIVER_CLEARED = "EDIT_WAIVER_CLEARED";
export const EDIT_WAIVER_FORM_UPDATED = "EDIT_WAIVER_FORM_UPDATED";
export const EDIT_WAIVER_SELECTED = "EDIT_WAIVER_SELECTED";
export const EDIT_WAIVER_SAVE_FAILED = "EDIT_WAIVER_SAVE_FAILED";
export const EDIT_WAIVER_SAVE_SUCCEEDED = "EDIT_WAIVER_SAVE_SUCCEEDED";
export const EDIT_WAIVER_SAVE_STARTED = "EDIT_WAIVER_SAVE_STARTED";
export const EDIT_WAIVER_DEACTIVATED = "EDIT_WAIVER_DEACTIVATED";
export const WAIVER_LOADING_STARTED = "WAIVER_LOADING_STARTED";
export const WAIVER_LOADING_FAILED = "WAIVER_LOADING_FAILED";
export const WAIVER_LOADING_SUCCEEDED = "WAIVER_LOADING_SUCCEEDED";

export const WAIVER_PDF_REQUESTED = "WAIVER_PDF_REQUESTED";
export const WAIVER_PDF_FAILED = "WAIVER_PDF_FAILED";
export const WAIVER_PDF_ACQUIRED = "WAIVER_PDF_ACQUIRED";

//signed waivers
export const CURRENT_SIGNED_WAIVER_LOADING_FAILED =
  "CURRENT_SIGNED_WAIVER_LOADING_FAILED";
export const CURRENT_SIGNED_WAIVER_LOADING_SUCCEEDED =
  "CURRENT_SIGNED_WAIVER_LOADING_SUCCEEDED";
export const CURRENT_SIGNED_WAIVER_CLEARED = "CURRENT_SIGNED_WAIVER_CLEARED";
export const CURRENT_SIGNED_WAIVER_LOADING_STARTED =
  "CURRENT_SIGNED_WAIVER_LOADING_STARTED";
export const SIGNED_WAIVER_SELECTED = "SIGNED_WAIVER_SELECTED";

export const SIGNED_WAIVERS_RETRIEVED = "SIGNED_WAIVERS_RETRIEVED";
export const RECENTLY_SIGNED_WAIVERS_REQUESTED =
  "RECENTLY_SIGNED_WAIVERS_REQUESTED";
export const RECENTLY_SIGNED_WAIVERS_RETRIEVED =
  "RECENTLY_SIGNED_WAIVERS_RETRIEVED";
export const PENDING_WAIVERS_RETRIEVED = "PENDING_WAIVERS_RETRIEVED";
export const RECENTLY_SIGNED_WAIVERS_EDITED = "RECENTLY_SIGNED_WAIVERS_EDITED";
export const RECENTLY_SIGNED_WAIVERS_CLEARED =
  "RECENTLY_SIGNED_WAIVERS_CLEARED";
export const PENDING_WAIVERS_EDITED = "PENDING_WAIVERS_EDITED";
export const PENDING_WAIVERS_CLEARED = "PENDING_WAIVERS_CLEARED";
export const SEARCH_REQUESTED = "SEARCH_REQUESTED";
export const SIGNED_WAIVERS_SEARCHED = "SIGNED_WAIVERS_SEARCHED";
export const SIGNED_WAIVERS_SEARCH_CLEARED = "SIGNED_WAIVERS_SEARCH_CLEARED";
export const SEARCH_FINISHED = "SEARCH_FINISHED";
export const SEARCH_SIGNED_WAIVERS_EDITED = "SEARCH_SIGNED_WAIVERS_EDITED";
export const MARKED_ACTIVE = "MARKED_ACTIVE";
export const MARK_ACTIVE_CLEARED = "MARK_ACTIVE_CLEARED";
export const ADD_ACTIVITY_REQUESTED = "ADD_ACTIVITY_REQUESTED";
export const ADD_ACTIVITY_SUCCEEDED = "ADD_ACTIVITY_SUCCEEDED";
export const ADD_ACTIVITY_FAILED = "ADD_ACTIVITY_FAILED";

export const NEW_SIGNED_WAIVER_SAVE_STARTED = "NEW_SIGNED_WAIVER_SAVE_STARTED";
export const NEW_SIGNED_WAIVER_SAVE_FAILED = "NEW_SIGNED_WAIVER_SAVE_FAILED";
export const NEW_SIGNED_WAIVER_SAVE_SUCCEEDED =
  "NEW_SIGNED_WAIVER_SAVE_SUCCEEDED";
export const NEW_SIGNED_WAIVER_FORM_UPDATED = "NEW_SIGNED_WAIVER_FORM_UPDATED";

export const EDIT_SIGNED_WAIVER_SAVE_SUCCEEDED =
  "EDIT_SIGNED_WAIVER_SAVE_SUCCEEDED";
export const EDIT_SIGNED_WAIVER_SAVE_STARTED =
  "EDIT_SIGNED_WAIVER_SAVE_STARTED";
export const EDIT_SIGNED_WAIVER_SAVE_FAILED = "EDIT_SIGNED_WAIVER_SAVE_FAILED";
export const EDIT_SIGNED_WAIVER_FORM_UPDATED =
  "EDIT_SIGNED_WAIVER_FORM_UPDATED";
export const EDIT_SIGNED_WAIVER_CLEARED = "EDIT_PARTICIPANT_CLEARED";
export const SIGNED_WAIVER_LOADING_FAILED = "SIGNED_WAIVER_LOADING_FAILED";
export const SIGNED_WAIVER_LOADING_STARTED = "SIGNED_WAIVER_LOADING_STARTED";
export const SIGNED_WAIVER_LOADING_SUCCEEDED =
  "SIGNED_WAIVER_LOADING_SUCCEEDED";

export const WAIVER_USAGE_REQUESTED = "WAIVER_USAGE_REQUESTED";
export const WAIVER_USAGE_ACQUIRED = "WAIVER_USAGE_ACQUIRED";

export const WAIVER_CONTACTS_REQUESTED = "WAIVER_CONTACTS_REQUESTED";
export const WAIVER_CONTACTS_ACQUIRED = "WAIVER_CONTACTS_ACQUIRED";
//export const SW_NOTES_UPDATED = "SW_NOTES_UPDATED"
//participants
export const CURRENT_PARTICIPANT_LOADING_STARTED =
  "CURRENT_PARTICIPANT_LOADING_STARTED";
export const CURRENT_PARTICIPANT_LOADING_FAILED =
  "CURRENT_PARTICIPANT_LOADING_FAILED";
export const CURRENT_PARTIPANT_LOADING_SUCCEEDED =
  "CURRENT_PARTIPANT_LOADING_SUCCEEDED";
export const CURRENT_PARTICIPANT_CLEARED = "CURRENT_PARTICIPANT_CLEARED";

export const PARTICIPANTS_RETRIEVED = "PARTICIPANTS_RETRIEVED";

export const NEW_PARTICIPANT_SAVE_STARTED = "NEW_PARTICIPANT_SAVE_STARTED";
export const NEW_PARTICIPANT_SAVE_FAILED = "NEW_PARTICIPANT_SAVE_FAILED";
export const NEW_PARTICIPANT_SAVE_SUCCEEDED = "NEW_PARTICIPANT_SAVE_SUCCEEDED";
export const NEW_PARTICIPANT_FORM_UPDATED = "NEW_PARTICIPANT_FORM_UPDATED";

export const EDIT_PARTICIPANT_SAVE_STARTED = "EDIT_PARTICIPANT_SAVE_STARTED";
export const EDIT_PARTICIPANT_SAVE_SUCCEEDED =
  "EDIT_PARTICIPANT_SAVE_SUCCEEDED";
export const EDIT_PARTICIPANT_SAVE_FAILED = "EDIT_PARTICIPANT_SAVE_FAILED";
export const EDIT_PARTICIPANT_FORM_UPDATED = "EDIT_PARTICIPANT_FORM_UPDATED";
export const EDIT_PARTICIPANT_CLEARED = "EDIT_PARTICIPANT_CLEARED";
export const PARTICIPANT_LOADING_FAILED = "PARTICIPANT_LOADING_FAILED";
export const PARTICIPANT_LOADING_STARTED = "PARTICIPANT_LOADING_STARTED";
export const PARTICIPANT_LOADING_SUCCEEDED = "PARTICIPANT_LOADING_SUCCEEDED";

export const PARTICIPANT_SIGNED_WAIVER_STATUS_UPDATED =
  "PARTICIPANT_SIGNED_WAIVER_STATUS_UPDATED";
export const PARTICIPANT_SIGNED_WAIVER_SUCCEEDED =
  "PARTICIPANT_SIGNED_WAIVER_SUCCEEDED";
export const PARTICIPANT_SIGNED_WAIVER_FAILED =
  "PARTICIPANT_SIGNED_WAIVER_FAILED";
export const PARTICIPANT_SIGNED_WAIVER_REQUESTED =
  "PARTICIPANT_SIGNED_WAIVER_REQUESTED";

//USERS
export const LOGIN_STARTED = "LOGIN_STARTED";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGIN_SUCCEEDED = "LOGIN_SUCCEEDED";
export const LOGIN_CLEARED = "LOGIN_CLEARED";
export const CURRENT_USER_LOADING_STARTED = "CURRENT_USER_LOADING_STARTED";
export const CURRENT_USER_LOADING_FAILED = "CURRENT_USER_LOADING_FAILED";
export const CURRENT_USER_LOADING_SUCCEEDED = "CURRENT_USER_LOADING_SUCCEEDED";
export const CURRENT_USER_CLEARED = "CURRENT_USER_CLEARED";
export const CURRENT_USER_UPDATED = "CURRENT_USER_UPDATED";
export const LOGOUT_SUCCEEDED = "LOGOUT_SUCCEEDED";
export const FORGOT_PASSWORD_REQUESTED = "FORGOT_PASSWORD_REQUESTED";
export const FORGOT_PASSWORD_SUCCEEDED = "FORGOT_PASSWORD_SUCCEEDED";
export const PASSWORD_CONFIRMATION_REQUESTED = "FORGOT_PASSWORD_SUCCEEDED";
export const PASSWORD_CONFIRMATION_SUCCEEDED = "FORGOT_PASSWORD_SUCCEEDED";
export const RESET_PASSWORD_REQUESTED = "FORGOT_PASSWORD_SUCCEEDED";
export const RESET_PASSWORD_SUCCEEDED = "FORGOT_PASSWORD_SUCCEEDED";

//added w
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const CURRENT_USER_ERROR_CLEARED = "CURRENT_USER_ERROR_CLEARED";

export const USERS_RETRIEVED = "USERS_RETRIEVED";

export const NEW_USER_SAVE_STARTED = "NEW_USER_SAVE_STARTED";
export const NEW_USER_SAVE_FAILED = "NEW_USER_SAVE_FAILED";
export const NEW_USER_SAVE_SUCCEEDED = "NEW_USER_SAVE_SUCCEEDED";
export const NEW_USER_FORM_UPDATED = "NEW_USER_FORM_UPDATED";
export const INVITATION_STARTED = "INVITATION_STARTED";
export const INVITATION_FAILED = "INVITATION_FAILED";
export const INVITATION_SENT = "INVITATION_SENT";

export const EDIT_USER_CLEARED = "EDIT_USER_CLEARED";
export const EDIT_USER_ROLENAME = "EDIT_USER_ROLENAME";
export const EDIT_USER_SELECTED = "EDIT_USER_SELECTED";
export const EDIT_USER_FORM_UPDATED = "EDIT_USER_FORM_UPDATED";
export const EDIT_USER_SAVE_FAILED = "EDIT_USER_SAVE_FAILED";
export const EDIT_USER_SAVE_SUCCEEDED = "EDIT_USER_SAVE_SUCCEEDED";
export const EDIT_USER_SAVE_STARTED = "EDIT_USER_SAVE_STARTED";
export const USER_ROLENAME_SELECTED = "USER_ROLENAME_SELECTED";
export const USER_LOADING_SUCCEEDED = "USER_LOADING_SUCCEEDED";

//COMPANIES
export const CURRENT_COMPANY_SELECTION_STARTED =
  "CURRENT_COMPANY_SELECTION_STARTED";
export const CURRENT_COMPANY_SELECTION_FAILED =
  "CURRENT_COMPANY_SELECTION_FAILED";
export const CURRENT_COMPANY_SELECTED = "CURRENT_COMPANY_SELECTED";
export const COMPANY_WAIVERS_REQUESTED = "COMPANY_WAIVERS_REQUESTED";
export const COMPANY_WAIVERS_CLEARED = "COMPANY_WAIVERS_CLEARED";
export const COMPANY_WAIVERS_RETRIEVED = "COMPANY_WAIVERS_RETRIEVED";
export const COMPANY_WAIVERS_INITIATED = "COMPANY_WAIVERS_INITIATED";

export const CURRENT_COMPANY_LOADING_STARTED =
  "CURRENT_COMPANY_LOADING_STARTED";
export const CURRENT_COMPANY_LOADING_FAILED = "CURRENT_COMPANY_LOADING_FAILED";
export const CURRENT_COMPANY_LOADING_SUCCEEDED =
  "CURRENT_COMPANY_LOADING_SUCCEEDED";
export const CURRENT_COMPANY_CLEARED = "CURRENT_COMPANY_CLEARED";

export const COMPANIES_RETRIEVED = "COMPANIES_RETRIEVED";
export const COMPANIES_SET_FAILED = "COMPANIES_SET_FAILED";
export const COMPANIES_REQUESTED = "COMPANIES_REQUESTED";
export const COMPANIES_CLEARED = "COMPANIES_CLEARED";

export const NEW_COMPANY_SAVE_STARTED = "NEW_COMPANY_SAVE_STARTED";
export const NEW_COMPANY_SAVE_FAILED = "NEW_COMPANY_SAVE_FAILED";
export const NEW_COMPANY_SAVE_SUCCEEDED = "NEW_COMPANY_SAVE_SUCCEEDED";
export const NEW_COMPANY_FORM_UPDATED = "NEW_COMPANY_FORM_UPDATED";

export const EDIT_COMPANY_CLEARED = "EDIT_COMPANY_CLEARED";
export const EDIT_COMPANY_FORM_UPDATED = "EDIT_COMPANY_FORM_UPDATED";
export const EDIT_COMPANY_SAVE_FAILED = "EDIT_COMPANY_SAVE_FAILED";
export const EDIT_COMPANY_SAVE_SUCCEEDED = "EDIT_COMPANY_SAVE_SUCCEEDED";
export const EDIT_COMPANY_SAVE_STARTED = "EDIT_COMPANY_SAVE_STARTED";
export const COMPANY_LOADING_STARTED = "COMPANY_LOADING_STARTED";
export const COMPANY_LOADING_FAILED = "COMPANY_LOADING_FAILED";
export const COMPANY_LOADING_SUCCEEDED = "COMPANY_LOADING_SUCCEEDED";

export const COMPANY_DETAILS_REQUESTED = "COMPANY_DETAILS_REQUESTED";
export const COMPANY_DETAILS_CLEARED = "COMPANY_DETAILS_CLEARED";
export const COMPANY_DETAILS_ACQUIRED = "COMPANY_DETAILS_ACQUIRED";
export const COMPANY_DETAILS_FAILED = "COMPANY_DETAILS_FAILED";
export const COMPANY_DETAILS_SUBMITTED = "COMPANY_DETAILS_SUBMITTED";
export const SET_COMPANIES = "SET_COMPANIES";

export const CURRENT_COMPANY_SET = "CURRENT_COMPANY_SET";

//GROUPS
export const CURRENT_GROUP_LOADING_STARTED = "CURRENT_GROUP_LOADING_STARTED";
export const CURRENT_GROUP_LOADING_FAILED = "CURRENT_GROUP_LOADING_FAILED";
export const CURRENT_GROUP_LOADING_SUCCEEDED =
  "CURRENT_GROUP_LOADING_SUCCEEDED";
//export const CURRENT_GROUP_CLEARED = 'CURRENT_GROUP_CLEARED'

export const GROUPS_RETRIEVED = "GROUPS_RETRIEVED";
export const GROUP_CREATED = "GROUP_CREATED";
export const GROUPS_REQUESTED = "GROUPS_REQUESTED";
export const GROUPS_FAILED = "GROUPS_FAILED";
export const GROUP_EDITED = "GROUP_EDITED";
export const GROUP_DELETED = "GROUP_DELETED";
export const NEW_GROUP_SAVE_STARTED = "NEW_GROUP_SAVE_STARTED";
export const NEW_GROUP_SAVE_FAILED = "NEW_GROUP_SAVE_FAILED";
export const NEW_GROUP_SAVE_SUCCEEDED = "NEW_GROUP_SAVE_SUCCEEDED";
export const GROUP_PARTICIPANTS_REQUESTED = "GROUP_PARTICIPANTS_REQUESTED";
export const GROUP_PARTICIPANTS_LOADING_FAILED =
  "GROUP_PARTICIPANTS_LOADING_FAILED";
export const GROUP_PARTICIPANTS_RETRIEVED = "GROUP_PARTICIPANTS_RETRIEVED";
//not using these rightnow
//export const NEW_GROUP_FORM_UPDATED = 'NEW_GROUP_FORM_UPDATED'

//export const EDIT_GROUP_CLEARED = 'EDIT_GROUP_CLEARED'
//export const EDIT_GROUP_FORM_UPDATED = 'EDIT_GROUP_FORM_UPDATED'
//export const EDIT_GROUP_SAVE_FAILED = 'EDIT_GROUP_SAVE_FAILED'
//export const EDIT_GROUP_SAVE_SUCCEEDED = 'EDIT_GROUP_SAVE_SUCCEEDED'
//export const EDIT_GROUP_SAVE_STARTED = 'EDIT_GROUP_SAVE_STARTED'
//export const GROUP_LOADING_STARTED = 'GROUP_LOADING_STARTED'
//export const GROUP_LOADING_FAILED = 'GROUP_LOADING_FAILED'
//export const GROUP_LOADING_SUCCEEDED = 'GROUP_LOADING_SUCCEEDED'

//export const CURRENT_GROUP_WAIVER_LOADING_STARTED = 'CURRENT_GROUP_WAIVER_LOADING_STARTED'
//export const CURRENT_GROUP_WAIVER_LOADING_FAILED = 'CURRENT_GROUP_WAIVER_LOADING_FAILED'
//export const CURRENT_GROUP_WAIVER_LOADING_SUCCEEDED = 'CURRENT_GROUP_WAIVER_LOADING_SUCCEEDED'
//export const CURRENT_GROUP_WAIVER_CLEARED = 'CURRENT_GROUP_WAIVER_CLEARED'

//export const GROUP_WAIVERS_RETRIEVED = 'GROUP_WAIVERS_RETRIEVED'

//export const NEW_GROUP_WAIVER_SAVE_STARTED = 'NEW_GROUP_WAIVER_SAVE_STARTED'
//export const NEW_GROUP_WAIVER_SAVE_FAILED = 'NEW_GROUP_WAIVER_SAVE_FAILED'
//export const NEW_GROUP_WAIVER_SAVE_SUCCEEDED = 'NEW_GROUP_WAIVER_SAVE_SUCCEEDED'
//export const NEW_GROUP_WAIVER_FORM_UPDATED = 'NEW_GROUP_WAIVER_FORM_UPDATED'

//export const EDIT_GROUP_WAIVER_CLEARED = 'EDIT_GROUP_WAIVER_CLEARED'
//export const EDIT_GROUP_WAIVER_FORM_UPDATED = 'EDIT_GROUP_WAIVER_FORM_UPDATED'
//export const EDIT_GROUP_WAIVER_SAVE_FAILED = 'EDIT_GROUP_WAIVER_SAVE_FAILED'
//export const EDIT_GROUP_WAIVER_SAVE_SUCCEEDED = 'EDIT_GROUP_WAIVER_SAVE_SUCCEEDED'
//export const EDIT_GROUP_WAIVER_SAVE_STARTED = 'EDIT_GROUP_WAIVER_SAVE_STARTED'
//export const GROUP_WAIVER_LOADING_STARTED = 'GROUP_WAIVER_LOADING_STARTED'
//export const GROUP_WAIVER_LOADING_FAILED = 'GROUP_WAIVER_LOADING_FAILED'
//export const GROUP_WAIVER_LOADING_SUCCEEDED = 'GROUP_WAIVER_LOADING_SUCCEEDED'

//export const CURRENT_GROUP_PARTICIPANT_LOADING_STARTED = 'CURRENT_GROUP_PARTICIPANT_LOADING_STARTED'
//export const CURRENT_GROUP_PARTICIPANT_LOADING_FAILED = 'CURRENT_GROUP_PARTICIPANT_LOADING_FAILED'
//export const CURRENT_GROUP_PARTICIPANT_LOADING_SUCCEEDED = 'CURRENT_GROUP_PARTICIPANT_LOADING_SUCCEEDED'
//export const CURRENT_GROUP_PARTICIPANT_CLEARED = 'CURRENT_GROUP_PARTICIPANT_CLEARED'

//export const GROUP_PARTICIPANTS_RETRIEVED = 'GROUP_PARTICIPANTS_RETRIEVED'

//export const NEW_GROUP_PARTICIPANT_SAVE_STARTED = 'NEW_GROUP_PARTICIPANT_SAVE_STARTED'
//export const NEW_GROUP_PARTICIPANT_SAVE_FAILED = 'NEW_GROUP_PARTICIPANT_SAVE_FAILED'
//export const NEW_GROUP_PARTICIPANT_SAVE_SUCCEEDED = 'NEW_GROUP_PARTICIPANT_SAVE_SUCCEEDED'
////export const NEW_WAIVER_FORM_UPDATED = 'NEW_WAIVER_FORM_UPDATED'

//export const EDIT_GROUP_PARTCIPANT_CLEARED = 'EDIT_GROUP_PARTCIPANT_CLEARED'
//export const EDIT_GROUP_PARTICIPANT_FORM_UPDATED = 'EDIT_GROUP_PARTICIPANT_FORM_UPDATED'
//export const EDIT_GROUP_PARTICIPANT_SAVE_FAILED = 'EDIT_GROUP_PARTICIPANT_SAVE_FAILED'
//export const EDIT_GROUP_PARTICIPANT_SAVE_SUCCEEDED = 'EDIT_GROUP_PARTICIPANT_SAVE_SUCCEEDED'
//export const EDIT_GROUP_PARTICIPANT_SAVE_STARTED = 'EDIT_GROUP_PARTICIPANT_SAVE_STARTED'
//export const GROUP_PARTICIPANT_LOADING_STARTED = 'GROUP_PARTICIPANT_LOADING_STARTED'
//export const GROUP_PARTICIPANT_LOADING_FAILED = 'GROUP_PARTICIPANT_LOADING_FAILED'
//export const GROUP_PARTICIPANT_LOADING_SUCCEEDED = 'GROUP_PARTICIPANT_LOADING_SUCCEEDED'

////export const PARTICIPANTS_RETRIEVED = 'PARTICIPANTS_RETRIEVED'

////export const NEW_PARTICIPANT_SAVE_STARTED = 'NEW_PARTICIPANT_SAVE_STARTED'
////export const NEW_PARTICIPANT_SAVE_SUCCEEDED = 'NEW_PARTICIPANT_SAVE_SUCCEEDED'
//export const NEW_PARTIPIPANT_FORM_UPDATED = 'NEW_PARTIPIPANT_FORM_UPDATED'

//export const EDIT_PARTICIPANT_SAVE_STARTED = 'EDIT_PARTICIPANT_SAVE_STARTED'
//export const EDIT_PARTICIPANT_SAVE_SUCCEEDED = 'EDIT_PARTICIPANT_SAVE_SUCCEEDED'
//export const EDIT_PARTICIPANT_SAVE_FAILED = 'EDIT_PARTICIPANT_SAVE_FAILED'
//export const EDIT_PARTICIPANT_FORM_UPDATED = 'EDIT_PARTICIPANT_FORM_UPDATED'
//export const EDIT_PARTICIPANT_CLEARED = 'EDIT_PARTICIPANT_CLEARED'
//export const PARTICIPANT_LOADING_FAILED = 'PARTICIPANT_LOADING_FAILED'
//export const PARTICIPANT_LOADING_STARTED = 'PARTICIPANT_LOADING_STARTED'
//export const PARTICIPANT_LOADING_SUCCEEDED = 'PARTICIPANT_LOADING_SUCCEEDED'

//BILLING
export const CURRENT_BILLING_LOADING_STARTED =
  "CURRENT_BILLING_LOADING_STARTED";
export const CURRENT_BILLING_LOADING_FAILED = "CURRENT_BILLING_LOADING_FAILED";
export const CURRENT_BILLING_LOADING_SUCCEEDED =
  "CURRENT_BILLING_LOADING_SUCCEEDED";
export const CURRENT_BILLING_CLEARED = "CURRENT_BILLING_CLEARED";

export const BILLING_DATA_RETRIEVED = "BILLING_DATA_RETRIEVED";

export const NEW_BILLING_DATA_SAVE_STARTED = "NEW_BILLING_DATA_SAVE_STARTED";
export const NEW_BILLING_DATA_SAVE_FAILED = "NEW_BILLING_DATA_SAVE_FAILED";
export const NEW_BILLING_DATA_UPDATED = "NEW_BILLING_DATA_UPDATED";
export const NEW_BILLING_DATA_SAVE_SUCCEEDED =
  "NEW_BILLING_DATA_SAVE_SUCCEEDED";

export const EDIT_BILLING_DATA_CLEARED = "EDIT_BILLING_DATA_CLEARED";
export const EDIT_BILLING_DATA_FORM_UPDATED = "EDIT_BILLING_DATA_FORM_UPDATED";
export const EDIT_BILLING_DATA_SAVE_FAILED = "EDIT_BILLING_DATA_SAVE_FAILED";
export const EDIT_BILLING_DATA_SAVE_SUCCEEDED =
  "EDIT_BILLING_DATA_SAVE_SUCCEEDED";
export const EDIT_BILLING_DATA_SAVE_STARTED = "EDIT_BILLING_DATA_SAVE_STARTED";
export const BILLING_DATA_LOADING_STARTED = "BILLING_DATA_LOADING_STARTED";
export const BILLING_DATA_LOADING_FAILED = "BILLING_DATA_LOADING_FAILED";
export const BILLING_DATA_LOADING_SUCCEEDED = "BILLING_DATA_LOADING_SUCCEEDED";

export const COMPANY_PAYMENT_DETAILS_RETRIEVED =
  "COMPANY_PAYMENT_DETAILS_RETRIEVED";
export const COMPANY_PAYMENT_DETAILS_FAILED = "COMPANY_PAYMENT_DETAILS_FAILED";
export const COMPANY_PAYMENT_DETAILS_REQUESTED =
  "COMPANY_PAYMENT_DETAILS_REQUESTED";
export const COMPANY_PAYMENT_DETAILS_INITIATED =
  "COMPANY_PAYMENT_DETAILS_INITIATED";

export const WAIVER_RATES_REQUESTED = "WAIVER_RATES_REQUESTED";
export const WAIVER_RATES_FAILED = "WAIVER_RATES_FAILED";
export const WAIVER_RATES_ACQUIRED = "WAIVER_RATES_ACQUIRED";

export const NEW_CARD_ADDED = "NEW_CARD_ADDED";
export const NEW_CARD_REQUESTED = "NEW_CARD_REQUESTED";

export const TRANSACTION_INFORMATION_REQUESTED =
  "TRANSACTION_INFORMATION_REQUESTED";
export const TRANSACTION_INFORMATION_FAILED = "TRANSACTION_INFORMATION_FAILED";
export const TRANSACTION_INFORMATION_RETRIEVED =
  "TRANSACTION_INFORMATION_RETRIEVED";
export const TRANSACTION_INFORMATION_CLEARED =
  "TRANSACTION_INFORMATION_CLEARED";

//ADMIN
export const PAYMENT_HISTORY_REQUESTED = "PAYMENT_HISTORY_REQUESTED";
export const PAYMENT_HISTORY_ACQUIRED = "PAYMENT_HISTORY_ACQUIRED";

export const MONTHLY_BILLING_REQUESTED = "MONTHLY_BILLING_REQUESTED";
export const MONTHLY_BILLING_ACQUIRED = "MONTHLY_BILLING_ACQUIRED";

export const API_HELP_REQUESTED = "API_HELP_REQUESTED";
export const API_HELP_ACQUIRED = "API_HELP_ACQUIRED";

//HELP VIDEOS
export const HELP_VIDEOS_REQUESTED = "REQUESTED";
export const HELP_VIDEOS_ACQUIRED = "HELP_VIDEOS_ACQUIRED";

//LOCATION
export const COMPANY_LOCATIONS_RETRIEVED = "COMPANY_LOCATIONS_RETRIEVED";
export const LOCATIONS_ACQUIRED = "LOCATIONS_ACQUIRED";

export const PRIMARY_LOCATION_EDITED = "PRIMARY_LOCATION_EDITED";
export const NEW_LOCATION_CREATED = "NEW_LOCATION_CREATED";
export const NEW_LOCATION_REQUESTED = "NEW_LOCATION_REQUESTED";
export const EDIT_LOCATION_REQUESTED = "EDIT_LOCATION_REQUESTED";
export const LOCATION_EDITED = "LOCATION_EDITED";
export const LOCATION_SELECTED = "LOCATION_SELECTED";
export const LOCATION_REQUESTED = "LOCATION_REQUESTED";
export const LOCATION_ACQUIRED = "LOCATION_ACQUIRED";
// export const CURRENT_LOCATION_SELECTED = "CURRENT_LOCATION_SELECTED";
export const LOCATION_COORDINATES_ACQUIRED = "LOCATION_COORDINATES_ACQUIRED";
export const LOCATION_COORDINATES_FAILED = "LOCATION_COORDINATES_FAILED";
export const LOCATION_COORDINATES_REQUESTED = "LOCATION_COORDINATES_REQUESTED";
export const LOCATION_DIALOG_HANDLED = "LOCATION_DIALOG_HANDLED";

//SigningStatements
export const COMPANY_SIGNING_STATEMENTS_SELECTED =
  "COMPANY_SIGNING_STATEMENTS_SELECTED";
export const PANEL_EXPANDED = "PANEL_EXPANDED";

//lym
export const SIGNED_WAIVER_CLEARED = "SIGNED_WAIVER_CLEARED";
export const SIGNED_WAIVER_SET = "SIGNED_WAIVER_SET";
export const PENDING_WAIVERS_CLEAR = "PENDING_WAIVERS_CLEAR";
export const PENDING_WAIVERS_FETCHED = "PENDING_WAIVERS_FETCHED";
export const RECENTLY_SIGNED_WAIVERS_CLEAR = "RECENTLY_SIGNED_WAIVERS_CLEAR";
export const RECENTLY_SIGNED_WAIVERS_FETCHED =
  "RECENTLY_SIGNED_WAIVERS_FETCHED";
export const SIGNED_WAIVERS_SEARCH_FETCHED = "SIGNED_WAIVERS_SEARCH_FETCHED";
export const SIGNED_WAIVERS_SEARCH_CLEAR = "SIGNED_WAIVERS_SEARCH_CLEAR";
export const SW_FETCHING = "SW_FETCHING";
export const SW_NOTES_SET = "SW_NOTES_SET";
export const SW_ACCEPTED = "SW_ACCEPTED";
export const SW_REJECTED = "SW_REJECTED";
export const SW_STATUS_UPDATED = "SW_STATUS_UPDATED";
export const SW_STATUS_FAILED = "SW_STATUS_FAILED";
export const WAIVER_ASSIGNED = "WAIVER_ASSIGNED";
export const SWS_ASSIGNED = "SWS_ASSIGNED";
export const SW_RECENT_EDITED = "SW_RECENT_EDITED";
export const SW_PENDING_EDITED = "SW_PENDING_EDITED";
export const SW_SEARCHED_EDITED = "SW_SEARCHED_EDITED";
export const ACTIVITY_DATE_ADDED = "ACTIVITY_DATE_ADDED";
export const SEARCH_SETTINGS_UPDATED = "SEARCH_SETTINGS_UPDATED";
export const PARTICIPANT_PDF = "PARTICIPANT_PDF";
export const SW_NOTE_HISTORY_SET = "SW_NOTE_HISTORY_SET";
export const SW_NOTE_ADDED = "SW_NOTE_ADDED";
export const SW_NOTE_FAILED = "SW_NOTE_FAILED";
export const NEW_NOTE_UPDATED = "NEW_NOTE_UPDATED";
export const NOTE_CLEARED = "NOTE_CLEARED";
export const NOTE_SELECTED = "NOTE_SELECTED";
export const SW_ATTACHMENTS_SET = "SW_ATTACHMENTS_SET";
export const SW_ATTACHMENTS_FAILED = "SW_ATTACHMENTS_FAILED";
export const FETCHING_ATTACHMENTS = "FETCHING_ATTACHMENTS";
//password
export const FORGOT_PASSWORD_EMAIL_SUCCEEDED =
  "FORGOT_PASSWORD_EMAIL_SUCCEEDED";

export const FORGOT_PASSWORD_EMAIL_FAILED = "FORGOT_PASSWORD_EMAIL_FAILED";
export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const NOT_AUTHORIZED = "NOT_AUTHORIZED";
export const CLEAR_NO_AUTH = "CLEAR_NO_AUTH";
export const USER_COMPANY_ROLES = "USER_COMPANY_ROLES";
export const SET_USER_ROLE = "SET_USER_ROLE";
export const SET_USER_COMPANIES = "SET_USER_COMPANIES";
export const SET_COMPANY_LOGO = "SET_COMPANY_LOGO";
export const LOGOUT_REQUIRED = "LOGOUT_REQUIRED";

//resest
export const RESET_AUTH = "RESET_AUTH";
export const RESET_PAYMENT_HISTORY = "RESET_PAYMENT_HISTORY";
export const RESET_MONTHLY_BILLING = "RESET_MONTHLY_BILLING";
export const RESET_COMPANY_PAYMENT_DETAILS = "RESET_COMPANY_PAYMENT_DETAILS";
export const RESET_WAIVER_RATES = "RESET_WAIVER_RATES";
export const RESET_LOCATIONS = "RESET_LOCATIONS";
export const RESET_SWS = "RESET_SWS";
export const RESET_SIGNING_STATEMENTS = "RESET_SIGNING_STATEMENTS";
export const CLEAR_USERS = "CLEAR_USERS";
export const CLEAR_WAIVERS = "CLEAR_WAIVERS";
export const RESET_USAGE = "RESET_USAGE";

//signalR
export const CONNECTION_SET = "CONNECTION_SET";
export const CONNECTION_CLOSED = "CONNECTION_CLOSED";
export const CONNECTION_OPEN = "CONNECTION_OPEN";
export const CONNECTION_REQUESTED = "CONNECTION_REQUESTED";
export const DISCONNECTED = "DISCONNECTED";
export const NEW_MESSAGE = "NEW_MESSAGE";
export const SIGNALR_SW_SET = "SIGNALR_SW_SET";
export const SIGNALR_SW_CLEARED = "SIGNALR_SW_CLEARED";
export const SIGNALR_COMPANY = "SIGNALR_COMPANY";
export const RECONNECTING_SIGNALR = "RECONNECTING_SIGNALR";
export const RECONNECT_SIGNALR = "RECONNECT_SIGNALR";
/////////Tim
export const NEW_COMPANY_CARD_UPDATED = "NEW_COMPANY_CARD_UPDATED";
export const EDIT_LOCATION_RELOADED = "EDIT_LOCATION_RELOADED";
export const WAIVERS_RETRIEVED = "WAIVERS_RETRIEVED";
export const WAIVERS_REQUESTED = "WAIVERS_REQUESTED";
export const WAIVERS_FAILED = "WAIVERS_FAILED";
export const WAIVERS_CLEARED = "WAIVERS_CLEARED";
export const WAIVER_VALUES_ASSIGNED = "WAIVER_VALUES_ASSIGNED";
export const CURRENT_WAIVER_SET = "CURRENT_WAIVER_SET";
export const WAIVER_ACTIVE_CHANGED = "WAIVER_ACTIVE_CHANGED";
export const WAIVER_SELECTED = "WAIVER_SELECTED";
export const EDIT_USER_LOADING = "EDIT_USER_LOADING";

export const COMPANY_DETAILS_EDITED = "COMPANY_DETAILS_EDITED";
// export const FORGOT_PASSWORD_EMAIL_SUCCEEDED = "FORGOT_PASSWORD_EMAIL_SUCCEEDED"
// export const FORGOT_PASSWORD_EMAIL_FAILED = "FORGOT_PASSWORD_EMAIL_FAILED"
//update company on authentication user
// export const UPDATE_COMPANY = "UPDATE_COMPANY"
//register user
export const REGISTER_USER_REQUESTED = "REGISTER_USER_REQUESTED";
export const REGISTER_USER_EMAIL_FAILED = "REGISTER_USER_EMAIL_FAILED";
export const REGISTER_USER_EMAIL_SUCCEEDED = "REGISTER_USER_EMAIL_SUCCEEDED";

export const WAIVER_RATES_CHANGED = "WAIVER_RATES_CHANGED";
export const WAIVER_RATES_SAVED = "WAIVER_RATES_SAVED";
export const SIGNING_STATEMENTS_EDITED = "SIGNING_STATEMENTS_EDITED";
export const SIGNING_STATEMENTS_RETRIEVED = "SIGNING_STATEMENTS_RETRIEVED";
export const SIGNING_STATEMENTS_CLEARED = "SIGNING_STATEMENTS_CLEARED";
export const SIGNING_STATEMENTS_FAILED = "SIGNING_STATEMENTS_FAILED";

export const NEW_COMPANY_CLEARED = "NEW_COMPANY_CLEARED";
export const NEW_COMPANY_SELECTED = "NEW_COMPANY_SELECTED";

export const SIGNING_STATEMENTS_EXITED = "SIGNING_STATEMENTS_EXITED";
export const COMPANY_PAYMENT_DETAILS_EXITED = "COMPANY_PAYMENT_DETAILS_EXITED";
export const WAIVER_RATES_EXITED = "WAIVER_RATES_EXITED";

export const COMPANY_DETAILS_EXITED = "COMPANY_DETAILS_EXITED";
export const EDIT_LOCATION_EXITED = "EDIT_LOCATION_EXITED";
export const EDIT_LOCATION_CLOSED = "EDIT_LOCATION_CLOSED";
export const SIGNING_STATEMENTS_SAVED = "SIGNING_STATEMENTS_SAVED";

export const DETAILS_EXITED = "DETAILS_EXITED";
export const COMPANY_DETAILS_RETRIEVED = "COMPANY_DETAILS_RETRIEVED";
export const ADMIN_PAGE_SELECTED = "ADMIN_PAGE_SELECTED";
//Constants REMOVED\\\
//     case BILLING_DATA_LOADING_SUCCEEDED:
//     // case BILLING_DATA_LOADING_FAILED:
//     case BILLING_DATA_LOADING_STARTED:
//     case EDIT_BILLING_DATA_SAVE_STARTED:
//     case EDIT_BILLING_DATA_SAVE_SUCCEEDED:
//     case EDIT_BILLING_DATA_SAVE_FAILED:
//     case EDIT_BILLING_DATA_FORM_UPDATED:
//     case EDIT_BILLING_DATA_CLEARED:

//         case COMPANY_PAYMENT_DETAILS_RETRIEVED:
//         case COMPANY_PAYMENT_DETAILS_FAILED:
//         case COMPANY_PAYMENT_DETAILS_REQUESTED:
//         case COMPANY_PAYMENT_DETAILS_INITIATED:

//         case TRANSACTION_INFORMATION_CLEARED:
//         case TRANSACTION_INFORMATION_FAILED:
//         case TRANSACTION_INFORMATION_REQUESTED:
//         case TRANSACTION_INFORMATION_CLEARED:

// EDIT_COMPANY_CLEARED,
// EDIT_COMPANY_FORM_UPDATED,
// EDIT_COMPANY_SAVE_FAILED,
// EDIT_COMPANY_SAVE_SUCCEEDED,
// EDIT_COMPANY_SAVE_STARTED,
// COMPANY_LOADING_STARTED,
// COMPANY_LOADING_FAILED,
// COMPANY_LOADING_SUCCEEDED,
// CURRENT_COMPANY_SELECTION_STARTED,
// CURRENT_COMPANY_SELECTION_FAILED,
// CURRENT_COMPANY_SELECTION_SUCCEEDED,

// CURRENT_COMPANY_LOADING_STARTED,
// CURRENT_COMPANY_LOADING_FAILED,
// CURRENT_COMPANY_LOADING_SUCCEEDED,

// SET_CURRENT_USER,
//   CURRENT_USER_ERROR_CLEARED,
//   CURRENT_USER_LOADING_STARTED,
//   CURRENT_USER_LOADING_FAILED,
//   CURRENT_USER_LOADING_SUCCEEDED,
//   CURRENT_USER_UPDATED,
//   CURRENT_USER_CLEARED,

// LOGIN_STARTED,
// LOGIN_FAILED,
// LOGIN_SUCCEEDED,
// LOGOUT_SUCCEEDED,
// LOGIN_CLEARED,

// COMPANY_SIGNING_STATEMENTS_SELECTED,

///waivers
// COMPANY_WAIVERS_CLEARED,
//     COMPANY_WAIVERS_REQUESTED,
//     COMPANY_WAIVERS_RETRIEVED,
//     EDIT_WAIVER_DEACTIVATED,
//     NEW_WAIVER_DEACTIVATED,
//     CURRENT_WAIVER_DEACTIVATED,
// WAIVER_LOADING_STARTED,
// WAIVER_LOADING_FAILED,
// COMPANY_WAIVERS_INITIATED,
// WAIVER_LOADING_SUCCEEDED,
// EDIT_WAIVER_SELECTED,
// EDIT_WAIVER_CLEARED,
// CURRENT_WAIVER_LOADING_SUCCEEDED,
// CURRENT_WAIVER_LOADING_STARTED,
// CURRENT_WAIVER_LOADING_FAILED,
export const TEMP = "TEMP";
export const CLEAR_TEMP = "CLEAR_TEMP";
