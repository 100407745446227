import {
  COMPANY_WAIVERS_RETRIEVED,
  SIGNING_STATEMENTS_RETRIEVED,
  PANEL_EXPANDED,
  COMPANY_SIGNING_STATEMENTS_SELECTED,
  SIGNING_STATEMENTS_CLEARED,
  SIGNING_STATEMENTS_EDITED,
  SIGNING_STATEMENTS_FAILED,
  SIGNING_STATEMENTS_SAVED
} from "../constants";
import { coalesce } from "../lib/fetch";
import {
  append,
  compose,
  assoc,
  dissoc,
  equals,
  head,
  isNil,
  last,
  pluck,
  prop,
  propEq,
  find,
  filter,
  findIndex,
  findLastIndex,
  merge,
  clone,
  sortBy
} from "ramda";
const url = process.env.REACT_APP_URL;

export const signingStatementAction = (statement, statementId) => async (
  dispatch,
  getState
) => {
  // quick solution that should be fine provided form validation is thorough
  dispatch({ type: SIGNING_STATEMENTS_EDITED });
  const originalStatementArray = getState().signingStatements.statements;

  const statementIndex = findIndex(propEq("Id", statementId))(
    originalStatementArray
  );
  let statementObjToModify = find(propEq("Id", statementId))(
    originalStatementArray
  );

  const newStatementObj = compose(
    dissoc("Created"),
    dissoc("CreatedBy"),
    assoc("Statement", statement),
    dissoc("UpdatedBy"),
    dissoc("Updated")
  )(statementObjToModify);

  // mutate original array at the statement index
  originalStatementArray[statementIndex] = newStatementObj;

  // create a copy of the original array so redux with know to update state
  const newStatementArray = [...originalStatementArray];

  // we PUT the new SigningStatement model
  const response = await fetch(
    `${process.env.REACT_APP_URL}/api/SigningStatement/put`,
    {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3001",
        Authorization: `Bearer ${localStorage.getItem("access_token") ||
          sessionStorage.getItem("access_token")}`
      },
      method: "PUT",
      body: JSON.stringify(newStatementObj)
    }
  );


  if (!response.ok) {
    dispatch({ type: SIGNING_STATEMENTS_FAILED });
  } else if (response.ok) {
    dispatch({
      type: SIGNING_STATEMENTS_CLEARED,
      payload: newStatementArray
    });
  }
  // just to see the edited statement, probably want to act on the PUT response
  // dispatch({
  //   type: COMPANY_WAIVERS_RETRIEVED,
  //   payload: newStatementArray
  // });
  // dispatch(getSigningStatement());
};

export const getSigningStatement = () => async (dispatch, getState) => {
  // quick solution that should be fine provided form validation is thorough
  const response = await fetch(
    `${process.env.REACT_APP_URL}/api/SigningStatement/Get`,
    {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin":
          window.location.protocol + "//" + window.location.host,
        Authorization: `Bearer ${localStorage.getItem("access_token") ||
          sessionStorage.getItem("access_token")}`
      }
    }
  )
    .then(res => res.json())
    .catch(err => console.log("err in getSigningStatements fetch", err));

  if (!response) {
    return;
  }

  // just to see the edited statement, probably want to act on the PUT response
  dispatch({ type: COMPANY_SIGNING_STATEMENTS_SELECTED, payload: response });
};

export const panelClicked = name => (dispatch, getState) => {
  const currentExtendedPanel = getState().signingStatements.panels;

  // functionalize this
  if (equals(currentExtendedPanel, name)) {
    dispatch({
      type: PANEL_EXPANDED,
      payload: ""
    });
  } else {
    dispatch({
      type: PANEL_EXPANDED,
      payload: name
    });
  }
};

export const signingStatementPost = data => async (dispatch, getState) => {
  dispatch({ type: SIGNING_STATEMENTS_EDITED });

  const response = await fetch(
    `${process.env.REACT_APP_URL}/api/SigningStatement/Create`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin":
          window.location.protocol + "//" + window.location.host,
        Authorization: `Bearer ${localStorage.getItem("access_token") ||
          sessionStorage.getItem("access_token")}`,
        "Access-Control-Allow-Credentials": true,
        Accept: "*/*"
      },
      body: JSON.stringify(data)
    }
  ).then(res => {
    if (res.status === 200) {
      res.json().then(d => {
        if (isNil(d.Statement)) {
          dispatch({ type: SIGNING_STATEMENTS_FAILED });
        } else {
          dispatch({ type: SIGNING_STATEMENTS_SAVED });
        }
      });
    }
  });
  // console.error({ response });
  // console.log({ response });
  dispatch(getSigningStatement());
};
