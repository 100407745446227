import fetch from "isomorphic-fetch";
import {
  NEW_BILLING_DATA_SAVE_FAILED,
  NEW_BILLING_DATA_SAVE_STARTED,
  NEW_BILLING_DATA_SAVE_SUCCEEDED,
  RESET_CURRENT_NOTES,
  COMPANY_LOCATIONS_RETRIEVED,
  SET_COMPANY_LOGO,
  BILLING_DATA_RETRIEVED,
  CURRENT_BILLING_LOADING_SUCCEEDED,
  CURRENT_COMPANY_LOADING_STARTED,
  WAIVER_RATES_ACQUIRED,
  WAIVER_RATES_REQUESTED,
  WAIVER_RATES_FAILED,
  COMPANY_PAYMENT_DETAILS_REQUESTED,
  COMPANY_PAYMENT_DETAILS_FAILED,
  TRANSACTION_INFORMATION_FAILED,
  TRANSACTION_INFORMATION_RETRIEVED,
  TRANSACTION_INFORMATION_REQUESTED,
  EDIT_BILLING_DATA_SAVE_STARTED,
  EDIT_BILLING_DATA_CLEARED,
  BILLING_DATA_LOADING_FAILED,
  COMPANY_PAYMENT_DETAILS_RETRIEVED,
  WAIVER_RATES_SAVED,
  WAIVER_RATES_CHANGED,
  NEW_CARD_ADDED,
  COMPANY_DETAILS_RETRIEVED
} from "../constants";
import { apiFetchClosure, coalesce } from "../lib/fetch";
import fetchDefaults from "fetch-defaults";
import { append, equals, isNil } from "ramda";

const url = process.env.REACT_APP_URL + "/api";
const authHeaders = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin":
    window.location.protocol + "//" + window.location.host,
  Authorization: `Bearer ${coalesce(
    sessionStorage.getItem("access_token"),
    localStorage.getItem("access_token")
  )}`
};
export const getBillingData = id => async (dispatch, getState) => {
  dispatch({ type: CURRENT_COMPANY_LOADING_STARTED });
  dispatch({ type: BILLING_DATA_RETRIEVED });
  const response = await apiFetchClosure()(`/api/payment`);
  if (!response) {
    console.log("Could not load billing data. Please try again.");
  }
  dispatch({ type: CURRENT_BILLING_LOADING_SUCCEEDED, payload: { id } });
};

export const saveCreditCard = creditCard => async (dispatch, getState) => {
  dispatch({
    type: EDIT_BILLING_DATA_SAVE_STARTED,
    payload: { isSaving: true }
  });

  let creditCardData = {
    CardHolder: creditCard.CardHolder,
    CardNumber: creditCard.CardNumber,
    CVC: creditCard.CVC,
    ExpirationMonth: Number(creditCard.ExpDate.Month),
    ExpirationYear: Number(creditCard.ExpDate.Year),
    ZipCode: creditCard.ZipCode,
    Remember: creditCard.Remember
  };

  // data.append("CardPayment", JSON.stringify(creditCardData));

  const response = await fetch(`${url}/payment/newcard`, {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin":
        window.location.protocol + "//" + window.location.host,
      Authorization: `Bearer ${localStorage.getItem("access_token") ||
        sessionStorage.getItem("access_token")}`
    },
    method: "POST",
    body: JSON.stringify(creditCardData)
  })
    .then(res => res.json())
    .catch(err => console.log("err in saveCreditCard: ", err));

  if (response.ExceptionMessage) {
    dispatch({ type: BILLING_DATA_LOADING_FAILED, payload: response.Message });
  } else {
    dispatch({ type: NEW_CARD_ADDED });
    dispatch({
      type: BILLING_DATA_RETRIEVED,
      payload: response
    });
    dispatch({ type: EDIT_BILLING_DATA_CLEARED, payload: { isSaved: true } });
  }
};

export const getWaiverRates = () => async (dispatch, getState) => {
  const authHeaders = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin":
      window.location.protocol + "//" + window.location.host,
    Authorization: `Bearer ${coalesce(
      sessionStorage.getItem("access_token"),
      localStorage.getItem("access_token")
    )}`
  };
  const apiFetch2 = fetchDefaults(fetch, `${url}`, {
    headers: authHeaders
  });
  dispatch({ type: WAIVER_RATES_REQUESTED });
  const response = await apiFetch2(`/api/waiverrates`)
    .then(res => res.json())
    .catch(err => console.log("err: ", err));

  if (equals(response, 404)) {
    dispatch({ type: WAIVER_RATES_FAILED });
    return;
  }

  dispatch({ type: WAIVER_RATES_ACQUIRED, payload: response });
};

export const updateWaiverRates = (rates) => async (dispatch, getState) => {

  dispatch({ type: WAIVER_RATES_CHANGED });

  const response = fetch(`${url}/waiverrates`, {
    headers: authHeaders,
    body: JSON.stringify(rates),
    method: "POST"
  })
    .then(res => res.json())
    .then(res => {
      dispatch({ type: WAIVER_RATES_SAVED });
      dispatch({
        type: WAIVER_RATES_ACQUIRED,
        payload: res
      });
    })
    .catch(err => console.log("err: ", err));


  return response
};

export const getCompanyPaymentDetails = () => async (dispatch, getState) => {
  dispatch({
    type: COMPANY_PAYMENT_DETAILS_REQUESTED
  });
  const response = await apiFetchClosure()(`/api/payment`)
    .then(res => res.json())
    .catch(err => console.log("err: ", err));
  if (equals(response, null)) {
    dispatch({
      type: COMPANY_PAYMENT_DETAILS_FAILED
    });
    return;
  }
  if (!response) {
    dispatch({
      type: COMPANY_PAYMENT_DETAILS_FAILED
    });
    return;
  }
  ///if isNil(response.Company) then there is no associated payment information
  if (isNil(response.Company)) {
    dispatch({ type: COMPANY_DETAILS_RETRIEVED, payload: response });
  }
  //otherwise company details && payment information were retrieved successfully
  dispatch({ type: COMPANY_PAYMENT_DETAILS_RETRIEVED, payload: response });
};

export const transactionDetails = () => async (dispatch, getState) => {
  dispatch({
    type: TRANSACTION_INFORMATION_REQUESTED,
    payload: { isFetching: true }
  });
  const response = await apiFetchClosure()(`/api/txs/report`)
    .then(res => res.json())
    .catch(err => console.log("err: ", err));
  if (!response) {
    dispatch({ type: TRANSACTION_INFORMATION_FAILED, payload: response });
    return;
  }
  dispatch({ type: TRANSACTION_INFORMATION_RETRIEVED, payload: response });
};
