import fetch from "isomorphic-fetch";
import {
  USERS_RETRIEVED,
  INVITATION_SENT,
  EDIT_USER_ROLENAME,
  EDIT_USER_SAVE_FAILED,
  EDIT_USER_SAVE_SUCCEEDED,
  EDIT_USER_SELECTED,
  INVITATION_STARTED,
  INVITATION_FAILED,
  EDIT_USER_LOADING,
  SET_CURRENT_USER,
  SET_USER_ROLE,
} from "../constants";
import { equals } from "ramda";
import {
  setCompanies,
  selectCompany,
} from "./companies";
import { apiFetchClosure, coalesce } from "../lib/fetch";
const url = process.env.REACT_APP_URL;

//the only time this action-creator is called is right after login or if the user is updated

export const getCurrentUser = async (dispatch, getState) => {
  // const userId = getState().currentUser.LoginName
  // dispatch({ type: CURRENT_USER_LOADING_STARTED })
  const response = await fetch(url + `/api/Account/UserId`, {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin":
        window.location.protocol + "//" + window.location.host,
      Authorization: `Bearer ${coalesce(
        sessionStorage.getItem("access_token"),
        localStorage.getItem("access_token")
      )}`
    }
  })
    .then(res => res.json())
    .catch(err => console.log("err: ", err));
  dispatch({ type: SET_CURRENT_USER, payload: { user: response } });

  // dispatch(setUserRoles)
  //lym - this is holding the route in localstorage so user isnt logged out on a refresh
  //
  // const companies =
  // if (isEmpty(getState().companies)) { dispatch(setCompanies) }

  // localStorage.setItem('isAuth', response.Roles[0].RoleId)

  let role;
  if (equals(response.CurrentRole, "ReviewWaivers")) {
    role = Number(3);
  } else if (equals(response.CurrentRole, "SysAdmin")) {
    role = Number(1);
  } else if (equals(response.CurrentRole, "CompanyAdmin")) {
    role = Number(2);
  } else {
    role = Number(4);
  }
  localStorage.setItem("isAuth", role);

  // dispatch({
  //   type: CURRENT_COMPANY_SET,
  //   payload: { id: response.CurrentCompanyId }
  // });
  const company = getState().currentCompany.id;
  const id = response.CurrentCompanyId;
  // dispatch(startConnection(id))
  //dispatch(setConnection)
  // if (!equals(company, id)) {
  //   dispatch(selectCompany(id));
  //   //  dispatch(getCompanyTest(id))
  // } //else if () {}
  dispatch(setCompanies).then(() => dispatch(selectCompany(id)));
  // const co = find(propEq("companyId", response.CurrentCompanyId), cos)

  dispatch({ type: SET_USER_ROLE, payload: { CurrentRoleId: role } });
};

export const setUsers = async (dispatch, getState) => {
  const response = await apiFetchClosure()(`/api/users/GetCompanyUsers`)
    .then(res => res.json())
    .catch(err => console.log("err: ", err));

  dispatch({ type: USERS_RETRIEVED, payload: response });
};

export const sendInvitation = email => async (dispatch, getState) => {
  dispatch({ type: INVITATION_STARTED });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${coalesce(
      sessionStorage.getItem("access_token"),
      localStorage.getItem("access_token")
    )}`
  };
  const response = await fetch(
    `${process.env.REACT_APP_URL}/users/InviteUser`,
    {
      headers: headers,
      method: "POST",
      body: JSON.stringify({ email: `${email.Email}` })
    }
  )
    .then(res => res.json())
    .catch(err => dispatch({ type: INVITATION_FAILED }));
  dispatch({ type: INVITATION_SENT, payload: response });
  // history.push('/users')
};

export const updateUserRole = user => async (dispatch, getState) => {

  const { UserId, RoleName } = user;

  dispatch({ type: EDIT_USER_ROLENAME, payload: RoleName });

  const response = await fetch(
    `${
    process.env.REACT_APP_URL
    }/Users/UpdateRole?userId=${UserId}&roleName=${RoleName}`,
    {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Access-Control-Allow-Origin": `${window.location.protocol}//${
          window.location.host
          }`,
        Authorization: `Bearer ${coalesce(
          sessionStorage.getItem("access_token"),
          localStorage.getItem("access_token")
        )}`,
        "Access-Control-Allow-Credentials": true
      }
    }
  )
    .then(res => res.json())
    .catch(err => console.log("err: ", err));
  if (equals(response.Status, -1)) {

    dispatch({ type: EDIT_USER_SAVE_FAILED, payload: response.Message });
  } else {

    dispatch({ type: EDIT_USER_SAVE_SUCCEEDED, payload: response.Message });
  }


  dispatch(setUsers);
};

export const addCompanyRoleName = user => async (dispatch, getState) => {

  const { UserId, RoleName, companyId } = user;

  dispatch({ type: EDIT_USER_ROLENAME, payload: RoleName });

  const response = await fetch(
    `${
    process.env.REACT_APP_URL
    }/Users/AddCompany?userId=${UserId}&roleName=${RoleName}&companyId=${companyId}`,
    {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Access-Control-Allow-Origin": `${window.location.protocol}//${
          window.location.host
          }`,
        Authorization: `Bearer ${coalesce(
          sessionStorage.getItem("access_token"),
          localStorage.getItem("access_token")
        )}`,
        "Access-Control-Allow-Credentials": true
      }
    }
  )
    .then(res => res.json())
    .catch(err => console.log("err: ", err));

  if (equals(response.Result.Status, -1)) {
    dispatch({ type: EDIT_USER_SAVE_FAILED, payload: response.Result.Message });
  } else {
    dispatch({
      type: EDIT_USER_SAVE_SUCCEEDED,
      payload: response.Result.Message
    });
  }
  dispatch(setUsers);
};

export const updateUserRoleNames = user => async (dispatch, getState) => {
  const { UserId, RoleName, companyId } = user;

  dispatch({ type: EDIT_USER_ROLENAME, payload: RoleName });

  const response = await fetch(
    `${
    process.env.REACT_APP_URL
    }/Users/UpdateRole?userId=${UserId}&roleName=${RoleName}&companyId=${companyId}`,
    {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Access-Control-Allow-Origin": `${window.location.protocol}//${
          window.location.host
          }`,
        Authorization: `Bearer ${coalesce(
          sessionStorage.getItem("access_token"),
          localStorage.getItem("access_token")
        )}`,
        "Access-Control-Allow-Credentials": true
      }
    }
  )
    .then(res => res.json())
    .catch(err => console.log("err: ", err));

  if (equals(response.Status, -1)) {
    dispatch({ type: EDIT_USER_SAVE_FAILED, payload: response.Message });
  } else {
    dispatch({ type: EDIT_USER_SAVE_SUCCEEDED, payload: response.Message });
  }
  dispatch(setUsers);
};

export const addUser = email => async (dispatch, getState) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${coalesce(
      sessionStorage.getItem("access_token"),
      localStorage.getItem("access_token")
    )}`
  };
  const response = await fetch(
    `${process.env.REACT_APP_URL}/users/InviteUser`,
    {
      headers: headers,
      method: "POST",
      body: JSON.stringify({ email: `${email.Email}` })
    }
  )
    .then(res => res.json())
    .catch(err => console.log("err:", err));
  dispatch({ type: INVITATION_SENT, payload: response });
};

export const selectUser = UserId => async (dispatch, getState) => {

  dispatch({ type: EDIT_USER_LOADING });
  const user = await apiFetchClosure()(`/api/User/${Number(UserId)}`)
    .then(res => res.json())
    .catch(err => console.log(err));
  dispatch({ type: EDIT_USER_SELECTED, payload: user });
};
