import * as signalR from '@microsoft/signalr'
const mode = process.env.NODE_ENV
let logLevel
if (mode === "development") {
    logLevel = signalR.LogLevel.Trace
} else {
    logLevel = signalR.LogLevel.Error
}
export const buildConnection = (userId ) => {return new signalR.HubConnectionBuilder()
    .withUrl(process.env.REACT_APP_SIGNALR_HUB + "?UserId=" + userId, {
        logMessageContent: true,
        useDefaultCredentials: true
    })
    .configureLogging(logLevel)
    .build();
}

