import React from "react";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import CardActions from "@material-ui/core/CardActions";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import SignedWaiverSearchBar from "./signedWaivers/swSearchBar";
const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
    marginTop: "4rem",
    paddingLeft: theme.spacing.unit

    // position: "fixed"
  },
  spacer: {
    flex: "1 1 100%"
  },
  actions: {
    color: theme.palette.text.secondary
  },
  location: {
    paddingRight: theme.spacing.unit,
    paddingLeft: theme.spacing.unit
  }
});

let EnhancedTableToolbar = props => {
  const { numSelected, classes, location, button1, button2, searchBar } = props;
  const width = { xs: 12, sm: 6, md: 6 };
  let className;
  if (location) {
    className = classNames(classes.location, {
      [classes.highlight]: numSelected > 0
    });
  } else {
    // className = classNames(classes.root, {
    //   [classes.highlight]: numSelected > 0
    className = classes.root
    //});
  }
  return (
    <Toolbar className={className}>
      <Grid container directetion="row" alignItems="center" justify="space-between">
        <Grid item {...width}>
          <h2 className="title">{props.title}</h2>
        </Grid>
        {searchBar ? (

          <Grid item>{searchBar}</Grid>
        ) : (
            <Grid item {...width}>
              <Grid container justify="flex-end">
                <Grid item>
                  {button1 ? (
                    <CardActions>
                      {/* <Button dense="true" raised="true" aria-label={button1}> */}
                      {button1}
                      {/* </Button> */}
                    </CardActions>
                  ) : button2 ? (
                    <CardActions>
                      <Tooltip title={button2}>
                        {/* <Button dense="true" raised="true" aria-label={button2}> */}
                        {button2}
                        {/* </Button> */}
                      </Tooltip>
                    </CardActions>
                  ) : (
                        ""
                      )}
                </Grid>
              </Grid>
            </Grid>
          )}
      </Grid>
    </Toolbar>
  );
};

export default withStyles(toolbarStyles)(EnhancedTableToolbar);
