import {
  CURRENT_BILLING_LOADING_STARTED,
  CURRENT_BILLING_LOADING_FAILED,
  CURRENT_BILLING_LOADING_SUCCEEDED,
  CURRENT_BILLING_CLEARED,
  EDIT_BILLING_DATA_CLEARED,
  EDIT_BILLING_DATA_FORM_UPDATED,
  EDIT_BILLING_DATA_SAVE_FAILED,
  EDIT_BILLING_DATA_SAVE_SUCCEEDED,
  BILLING_DATA_LOADING_STARTED,
  NEW_CARD_REQUESTED,
  BILLING_DATA_RETRIEVED,
  NEW_BILLING_DATA_SAVE_STARTED,
  NEW_BILLING_DATA_SAVE_FAILED,
  NEW_BILLING_DATA_UPDATED,
  NEW_BILLING_DATA_SAVE_SUCCEEDED,
  EDIT_BILLING_DATA_SAVE_STARTED,
  BILLING_DATA_LOADING_FAILED,
  BILLING_DATA_LOADING_SUCCEEDED,
  COMPANY_PAYMENT_DETAILS_RETRIEVED,
  COMPANY_PAYMENT_DETAILS_FAILED,
  COMPANY_PAYMENT_DETAILS_REQUESTED,
  COMPANY_PAYMENT_DETAILS_INITIATED,
  WAIVER_RATES_REQUESTED,
  WAIVER_RATES_FAILED,
  WAIVER_RATES_ACQUIRED,
  NEW_CARD_ADDED,
  TRANSACTION_INFORMATION_CLEARED,
  TRANSACTION_INFORMATION_FAILED,
  TRANSACTION_INFORMATION_REQUESTED,
  TRANSACTION_INFORMATION_RETRIEVED,
  WAIVER_RATES_SAVED,
  WAIVER_RATES_CHANGED,
  COMPANY_PAYMENT_DETAILS_EXITED,
  WAIVER_RATES_EXITED,
  RESET_COMPANY_PAYMENT_DETAILS,
  RESET_WAIVER_RATES,
  DETAILS_EXITED,
  COMPANY_DETAILS_RETRIEVED
} from "../constants";
import { merge, mergeDeepRight } from "ramda";

const initialNewBillingData = {
  data: {
    CardHolder: "",
    Name: "",
    ZipCode: "",
    CardType: "",
    LastDigits: "",
    Updated: "",
    UpdatedBy: "",
    Created: "",
    CreatedBy: "",
    Company_Id: "",
    WaiverRatesJson: ""
  },
  isSaving: false,
  isError: false,
  errorMsg: ""
};

export const newBillingData = (state = initialNewBillingData, action) => {
  switch (action.type) {
    case NEW_BILLING_DATA_SAVE_STARTED:
      return merge(state, { isSaving: true, isError: false, errorMsg: "" });
    case NEW_BILLING_DATA_SAVE_FAILED:
      return merge(state, {
        isSaving: false,
        isError: true,
        errorMsg: action.payload
      });
    case NEW_BILLING_DATA_UPDATED:
      return mergeDeepRight(state, { data: action.payload });
    case NEW_BILLING_DATA_SAVE_SUCCEEDED:
      return initialNewBillingData;
    default:
      return state;
  }
};

const companyPaymentDetailsInit = {
  data: {},
  added: false,
  isFetching: false,
  isSaving: false,
  isSaved: false,
  isLoading: false,
  isError: false,
  errorMsg: ""
};

export const companyPaymentDetails = (
  state = companyPaymentDetailsInit,
  action
) => {
  switch (action.type) {
    case COMPANY_PAYMENT_DETAILS_RETRIEVED:
      return mergeDeepRight(state, { isFetching: false, data: action.payload });
    case COMPANY_DETAILS_RETRIEVED:
      return merge(state, {
        isFetching: false,
        data: { Company: action.payload }
      });
    case COMPANY_PAYMENT_DETAILS_FAILED:
      return merge(state, { isError: true, errorMsg: action.payload });
    case COMPANY_PAYMENT_DETAILS_REQUESTED:
      return merge(state, { isFetching: true });
    case COMPANY_PAYMENT_DETAILS_INITIATED:
      return companyPaymentDetailsInit;
    case EDIT_BILLING_DATA_SAVE_STARTED:
      return merge(state, action.payload);
    case BILLING_DATA_RETRIEVED:
      return {
        ...state,
        isSaving: false,
        isSaved: true,
        isFetching: false,
        isError: false,
        isLoading: false,
        data: merge(action.payload, { Company: state.data.Company })
      };
    case BILLING_DATA_LOADING_SUCCEEDED:
      return merge(state, action.payload);
    case RESET_COMPANY_PAYMENT_DETAILS:
      return {};
    case BILLING_DATA_LOADING_FAILED:
      return merge(state, {
        isError: true,
        isSaving: false,
        isSaved: false,
        isFetching: false,
        errorMsg: action.payload
      });
    case NEW_CARD_ADDED:
      return merge(state, { added: !state.added });
    case COMPANY_PAYMENT_DETAILS_EXITED:
      return merge(state, {
        added: false,
        isFetching: false,
        isSaving: false,
        isSaved: false,
        isLoading: false,
        isError: false,
        errorMsg: ""
      });
    case DETAILS_EXITED:
      return companyPaymentDetailsInit;
    default:
      return state;
  }
};

const initialWaiverRates = {
  data: {},
  isFetching: false,
  isLoading: false,
  isSaving: false,
  isSaved: false,
  isError: false,
  errorMsg: ""
};

export const waiverRates = (state = initialWaiverRates, action) => {
  switch (action.type) {
    case WAIVER_RATES_REQUESTED:
      return merge(state, { isFetching: true });
    case WAIVER_RATES_FAILED:
      return merge(state, {
        isFetching: false,
        isError: true,
        errorMsg: "No Waiver Rates were found for this company."
      });
    case WAIVER_RATES_ACQUIRED:
      return mergeDeepRight(state, { isFetching: false, data: action.payload });
    case WAIVER_RATES_CHANGED:
      return merge(state, { isLoading: true });
    case WAIVER_RATES_SAVED:
      return merge(state, {
        data: action.payload,
        isLoading: false,
        isSaved: true
      });
    case WAIVER_RATES_EXITED:
      return merge(state, {
        isFetching: false,
        isLoading: false,
        isSaving: false,
        isSaved: false,
        isError: false,
        errorMsg: ""
      });
    case RESET_WAIVER_RATES:
      return {};
    case DETAILS_EXITED:
      return initialWaiverRates;
    default:
      return state;
  }
};

const initialTransactionInformation = {
  data: {},
  isFetching: false,
  isLoading: false,
  isSaving: false,
  isError: false,
  errorMsg: ""
};

export const transactionInformation = (
  state = initialTransactionInformation,
  action
) => {
  switch (action.type) {
    case TRANSACTION_INFORMATION_RETRIEVED:
      return mergeDeepRight(state, { isFetching: false, data: action.payload });
    case TRANSACTION_INFORMATION_FAILED:
      return merge(state, {
        isFetching: false,
        isError: true,
        errorMsg: action.payload
      });
    case TRANSACTION_INFORMATION_REQUESTED:
      return merge(state, { isFetching: true });
    case TRANSACTION_INFORMATION_CLEARED:
      return initialTransactionInformation;
    default:
      return state;
  }
};
