import React from 'react'
import { connect } from 'react-redux'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import OutlinedInput from "@material-ui/core/OutlinedInput"
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from "@material-ui/core/Switch"

const SelectStatement = ({
    name,
    value,
    handleChange,
    signingStatements,
    classes,
    label,
    placeholder,
    error,
    helperText
}) => {
    return (
        <FormControl className={classes.textField}>
            <InputLabel shrink>{label}</InputLabel>
            <Select
                displayEmpty
                value={value}
                onChange={handleChange}
                input={
                    <OutlinedInput
                        name={name}
                        id="select-multiple-placeholder"
                        labelWidth={0}
                        helpertext={helperText}
                        error={error}
                    />
                }
            >
                <ListItem disabled value="">
                    <em>{placeholder}</em>
                </ListItem>
                {signingStatements.map(ss => (
                    <ListItem key={ss.Id} value={ss.Id}>
                        <ListItemText primary={ss.Usage} secondary={ss.Statement} />
                    </ListItem>
                ))}
            </Select>
        </FormControl>
    );
};
const mapStateToProps1 = state => ({
    signingStatements: state.signingStatements.statements
});
const con = connect(mapStateToProps1);
export const Statements = con(SelectStatement);

export const SwitchInput = ({
    value,
    name,
    label,
    id,
    helperText,
    errorText,
    handleChange,
    checked
}) => {
    return (
        <FormControlLabel
            label={label}
            control={
                <Switch
                    color="primary"
                    value={value}
                    onChange={handleChange}
                    checked={checked}
                    name={name}
                    label={label}
                    id={id}
                    // helperText={helperText}
                    helpertext={helperText}
                    error={errorText}
                />
            }
        />
    );
};