import React, { Component } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import TablePagination from "@material-ui/core/TablePagination";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CheckCircle from "@material-ui/icons/VerifiedUser";
import { Link } from "react-router-dom";
import EnhancedTableToolbar from "../enhancedAppBar";
import EnhancedTableHead from "../tableHead";
import { equals, map, tail } from "ramda";
import { formatNumber } from "libphonenumber-js";
import moment from "moment";
import CustomizedSnackbars from "../snackbar";
import AddUserDialog from "./addUserDialog";
import EditIcon from "@material-ui/icons/Edit";
import Hover from "../hover";
import SearchOnType, { searchByAll } from "../searchOnType";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Hidden from "@material-ui/core/Hidden";
import PersonIcon from "@material-ui/icons/Face";
import Phone from "@material-ui/icons/Phone";
import Email from "@material-ui/icons/Email";

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

class EnhancedTableUsers extends Component {
  state = {
    open: false,
    order: "asc",
    orderBy: "UserName",
    ordered: true,
    selected: [],
    data: [],
    page: 0,
    rowsPerPage: 15,
    searchedUsers: [],
    isSearching: false,
    isActive: [],
    accessLevels: [
      { level: 1, label: "SysAdmin" },
      { level: 2, label: "CompanyAdmin" },
      { level: 3, label: "ReviewWaivers" },
      { level: 4, label: "None" }
    ]
  };
  createSortHandler = e => {
    const id = e.currentTarget.id;
    if (this.state.ordered) {
      this.setState({ ordered: false, order: "asc", orderBy: id });
    } else {
      this.setState({ ordered: true, order: "desc", orderBy: id });
    }
    // this.setState(state => ({ ordered: !state.ordered }))
  };

  handleClose = editUser => {
    this.props.updatedUser(editUser);
    this.setState({ open: false });
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleClick = (event, user) => {
    const { isActive } = this.state;
    const selectedActive = isActive.indexOf(user);
    let newActive = [];

    if (selectedActive === -1) {
      newActive = newActive.concat(isActive, user);
    } else if (selectedActive === 0) {
      newActive = newActive.concat(isActive.slice(1));
    } else if (selectedActive === isActive.length - 1) {
      newActive = newActive.concat(isActive.slice(0, -1));
    } else if (selectedActive > 0) {
      newActive = newActive.concat(
        isActive.slice(0, selectedActive),
        isActive.slice(selectedActive + 1)
      );
    }
    this.setState({ isActive: newActive });
  };

  handleClickOpen = user => {
    this.props.setUserToEdit(user);
    this.setState({ open: true, stateUserData: user });
  };

  handleChange = (event, user) => {
    this.props.editSelectedUser({
      UserId: user.UserId,
      RoleName: event.target.value
    });
  };

  isActive = id => this.state.isActive.indexOf(id) !== -1;

  handleInput = e => {
    const input = e.target.value;
    if (input.length > 1) {
      const us = searchByAll(input, this.props.users);
      this.setState({ searchedUsers: us, isSearching: true });
    } else {
      this.setState({ isSearching: false, searchedUsers: [] });
    }
  };
  componentWillMount() {
    this.props.setUsers();
    this.props.editUserCleared();
  }

  render() {
    const {
      classes,
      isError,
      isValidating,
      companies,
      users,
      columnData,
      history,
      errorMsg,
      newIsSaving,
      newUser,
      newIsError,
      newErrorMsg,
      newIsAuthenticated,
      isAuthenticated,
      authentication,
      sendInvitation,
      clearUser
    } = this.props;
    const {
      data,
      order,
      orderBy,
      selected,
      rowsPerPage,
      ordered,
      page
    } = this.state;

    let usersList;
    if (this.state.isSearching) {
      usersList = this.state.searchedUsers;
    } else {
      usersList = users;
    }
    //company Administrator
    const compAdmin = equals(2, authentication.CurrentRoleId);
    return (
      <React.Fragment>
        <Paper className={classes.tablePaper}>
          <EnhancedTableToolbar
            title="Users"
            button1={
              <AddUserDialog
                classes={classes}
                newUser={newUser}
                newIsSaving={newIsSaving}
                newIsError={newIsError}
                newIsAuthenticated={newIsAuthenticated}
                newErrorMsg={newErrorMsg}
                sendInvitation={sendInvitation}
                clearUser={clearUser}
              />
            }
            history={history}
          />
          <SearchOnType handleInput={this.handleInput} />
          <div className={classes.tableWrapper}>
            {!equals(users.length, 0) ? (
              <Grid container spacing={8}>
                <List
                  dense
                  style={{ tableLayout: "auto" }}
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  key={users.length}
                >
                  <EnhancedTableHead
                    addUser
                    users={users}
                    columnData={columnData}
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={this.handleRequestSort}
                    rowCount={data.length}
                    createSortHandler={this.createSortHandler}
                    key={users.length}
                  />

                  {stableSort(usersList, getSorting(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(user => {
                      const isActive = this.isActive(user.UserId);
                      return (
                        <Button
                          fullWidth
                          onClick={event =>
                            this.handleClick(event, user.UserId)
                          }
                          key={user.UserId}
                        >
                          <ListItem divider key={user.userId}>
                            <Grid
                              container
                              spacing={0}
                              zeroMinWidth
                              justify="space-between"
                              alignItems="center"
                            >
                              <Grid item xs>
                                <Typography>{user.Name}</Typography>
                              </Grid>
                              <Grid item xs>
                                <Hidden smDown>
                                  <Typography>{user.Email}</Typography>
                                </Hidden>
                                <Hidden mdUp>
                                  <List dense={true}>
                                    <ListItem>
                                      <ListItemIcon>
                                        <Email fontSize="small" />
                                      </ListItemIcon>
                                      <ListItemText primary={user.Email} />{" "}
                                      {user.EmailConfirmed ? (
                                        <ListItemIcon>
                                          <CheckCircle
                                            fontSize="small"
                                            color="primary"
                                          />
                                        </ListItemIcon>
                                      ) : (
                                        ""
                                      )}
                                    </ListItem>

                                    <ListItem>
                                      <ListItemIcon>
                                        <PersonIcon fontSize="small" />
                                      </ListItemIcon>
                                      <ListItemText primary={user.UserName} />
                                    </ListItem>
                                    <ListItem>
                                      <ListItemIcon>
                                        <Phone fontSize="small" />
                                      </ListItemIcon>
                                      <ListItemText
                                        primary={formatNumber(
                                          {
                                            country: "US",
                                            phone: `${user.PhoneNumber}`
                                          },
                                          "National"
                                        )}
                                      />
                                    </ListItem>
                                  </List>
                                </Hidden>
                              </Grid>
                              <Hidden smDown>
                                <Grid item xs={1}>
                                  {user.EmailConfirmed ? (
                                    <CheckCircle
                                      fontSize="small"
                                      color="primary"
                                    />
                                  ) : (
                                    ""
                                  )}
                                </Grid>
                              </Hidden>
                              <Hidden smDown>
                                <Grid item xs>
                                  <Typography padding="none">
                                    {user.UserName}
                                  </Typography>
                                </Grid>
                              </Hidden>
                              <Hidden smDown>
                                <Grid item xs>
                                  <Typography>
                                    {formatNumber(
                                      {
                                        country: "US",
                                        phone: `${user.PhoneNumber}`
                                      },
                                      "National"
                                    )}
                                  </Typography>
                                </Grid>
                              </Hidden>

                              <Grid item xs>
                                <Select
                                  className={classes.select}
                                  value={user.RoleName}
                                  name="accessLevel"
                                  onChange={e => this.handleChange(e, user)}
                                  input={
                                    <OutlinedInput
                                      labelWidth={0}
                                      name="accessLevel"
                                    />
                                  }
                                >
                                  {map(
                                    link => (
                                      <MenuItem
                                        value={link.label}
                                        key={user.UserId}
                                      >
                                        {link.label}
                                      </MenuItem>
                                    ),
                                    //checks for company Administrators so that they cannot create System Administrators and removes edit Button (line 382)
                                    !compAdmin
                                      ? this.state.accessLevels
                                      : tail(this.state.accessLevels)
                                  )}
                                </Select>
                              </Grid>
                              <Hidden smDown>
                                <Grid item xs>
                                  <Typography>
                                    {moment(user.Created).format("l")}
                                  </Typography>
                                </Grid>
                              </Hidden>

                              <Grid item xs>
                                {!compAdmin ? (
                                  <Grid
                                    container
                                    direction="column"
                                    justify="space-between"
                                  >
                                    <Grid item>
                                      <Hover
                                        message="Edit User"
                                        component={
                                          <Button
                                            aria-label="Edit User"
                                            variant="contained"
                                            color="default"
                                            size="small"
                                            user={user}
                                            component={Link}
                                            className={classes.label}
                                            to={`/users/${user.UserId}`}
                                            companies={companies}
                                          >
                                            <EditIcon color="primary" />
                                          </Button>
                                        }
                                      />
                                    </Grid>
                                  </Grid>
                                ) : null}
                              </Grid>
                            </Grid>
                          </ListItem>
                        </Button>
                      );
                    })}
                </List>
              </Grid>
            ) : (
              <Grid
                container
                justify="center"
                alignItems="center"
                style={{ height: "30em" }}
              >
                <Grid item>
                  <Typography variant="headline">No Other Users</Typography>
                </Grid>
              </Grid>
            )}
          </div>
          <Grid container>
            <TablePagination
              component="div"
              count={users.length}
              rowsPerPageOptions={[10, 15, 25, 50]}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                "aria-label": "Previous Page"
              }}
              nextIconButtonProps={{
                "aria-label": "Next Page"
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Grid>
        </Paper>

        {isError && (
          <CustomizedSnackbars
            message={errorMsg + `  Please try again.`}
            snackType="error"
          />
        )}
        {isValidating && (
          <CustomizedSnackbars
            message="Edit User is Loading..."
            snackType="info"
          />
        )}
        {isAuthenticated && (
          <CustomizedSnackbars
            message="User's Role Name successfully updated!"
            snackType="success"
          />
        )}
      </React.Fragment>
    );
  }
}

EnhancedTableUsers.propTypes = {
  classes: PropTypes.object.isRequired
};

export default EnhancedTableUsers;
