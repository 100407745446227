import fetch from 'isomorphic-fetch'
import { apiFetchClosure } from '../lib/fetch'
import { PAYMENT_HISTORY_REQUESTED, PAYMENT_HISTORY_ACQUIRED, 
    MONTHLY_BILLING_REQUESTED, MONTHLY_BILLING_ACQUIRED, 
    API_HELP_REQUESTED, API_HELP_ACQUIRED} 
    from '../constants'

const url = process.env.REACT_APP_BASE_URL

export const getPaymentHistory = () => async (dispatch, getState) => {

    dispatch({ type: PAYMENT_HISTORY_REQUESTED, payload: { isFetching: true } })
   
    const response = await apiFetchClosure()(`/api/Paymentapi/AdminReport`)
        .then(res => res.json())
        .catch(err => console.log('err: ', err));

    dispatch({ type: PAYMENT_HISTORY_ACQUIRED, payload: { history: response } })
}

export const getMonthlyPayment = () => async (dispatch, getState) => {

    dispatch({ type: MONTHLY_BILLING_REQUESTED, payload: { isFetching: true} })
   
    const response = await apiFetchClosure()(`/api/SysAdminApi/MonthlyBilling`)
        .then(res => res.json())
        .catch(err => console.log('err: ', err));
  
    dispatch({ type: MONTHLY_BILLING_ACQUIRED, payload: { billing: response } })
}

export const getApiHelp = () => async (dispatch, getState) => {

    dispatch({ type: API_HELP_REQUESTED, payload: { isFetching: true,} })
   
    const response = await apiFetchClosure()(`/help`)
        .then(res => res.text())
        .catch(err => console.log('err: ', err));
  
    dispatch({ type:  API_HELP_ACQUIRED, payload: { isFetching: true, helpPage: response } })
}

