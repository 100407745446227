import React, { Component } from "react";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import EnhancedTableHead from "../../components/enhancedTableHead";
import moment from "moment";
import Spinner from "../../components/spinner";
import { isNil } from "ramda";

const monthlyBillingHeaders = [
  {
    id: "Created",
    align: "inherit",
    disablePadding: false,
    label: "Billed on"
  },
  {
    id: "CompanyName",
    align: "inherit",
    disablePadding: false,
    label: "Billed period"
  },
  { id: "Amount", align: "inherit", disablePadding: false, label: "Amount" }
];

class MonthlyBilling extends Component {
  state = {
    loading: true
  };

  timer = () => {
    setTimeout(() => this.setState({ loading: false }), 4000);
  };

  componentDidMount() {
    this.props.getMonthlyPayment();
    this.timer();
  }

  render() {
    const { classes, monthlyBilling } = this.props;

    return (
      <div>
        <Typography variant="headline">Monthly Billing </Typography>
        {/* {isNil(monthlyBilling.billing) ? ( */}
        {isNil(monthlyBilling) ? (
          <div>
            <Table>
              <EnhancedTableHead
                columnData={monthlyBillingHeaders}
                sortSettings={false}
              />
            </Table>
            {this.state.loading ? (
              <Spinner />
            ) : (
              <center>
                <Typography variant="headline">
                  Monthly Billing is currently unavailable
                </Typography>
              </center>
            )}
          </div>
        ) : isNil(monthlyBilling.billing) ? (
          <div>
            <Table>
              <EnhancedTableHead
                columnData={monthlyBillingHeaders}
                sortSettings={false}
              />
            </Table>
            <Typography variant="headline">
              There is no Monthly Billing currently available
            </Typography>
          </div>
        ) : (
          <div>
            <Table>
              <EnhancedTableHead
                columnData={monthlyBillingHeaders}
                sortSettings={false}
              />
              <TableBody>
                {monthlyBilling.billing.map(n => {
                  return (
                    <TableRow hover key={n.CardTransactionId}>
                      <TableCell padding="none">
                        <Typography>
                          {moment.utc(n.Created).format("lll")}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{n.CompanyName}</Typography>
                      </TableCell>

                      <TableCell>
                        <Typography>${n.Amount}</Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        )}
      </div>
    );
  }
}

export default MonthlyBilling;
