import { createSelector } from 'reselect'
import { find, propEq } from 'ramda'

// selector
const currentCompanyId = (state) => state.currentCompany.id
const companies = (state) => state.companies
//const currentCompanyTrue = (state) => state.currentCompany.Current
// reselect function
export const getCurrentCompany = () => {
    return createSelector(
        [currentCompanyId, companies],

        (currentCompanyId, companies) => companies ? find(propEq('Id', currentCompanyId))(companies) : null
    )
}




const getCurrentCompany2 = createSelector(
    state => state.companiesById,
    state => state.usersById,
    state => state.waiversById,

)

//const waiversSelectors