import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import NewCompany from "../../pages/companies/new";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import Hover from "../hover";

class AddCompanyDialog extends Component {
  state = {
    open: false
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        <React.Fragment>
          {
            <Hover
              message="Add Company"
              component={
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleClickOpen}
                  className={classes.label}
                >
                  <AddIcon className={classes.leftIcon} /> Company
                </Button>
              }
            />
          }
        </React.Fragment>
        <Dialog
          fullWidth
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-new-company"
          fullScreen
        >
          <center>
            <Grid container className={classes.root}>
              <DialogContent>
                <NewCompany
                  onClose={this.handleClose}
                  // open={this.state.open}
                />
              </DialogContent>
            </Grid>
          </center>
        </Dialog>
      </div>
    );
  }
}

export default AddCompanyDialog;
