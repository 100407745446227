import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import ThumbDown from "@material-ui/icons/ThumbDown"
import ThumbUp from '@material-ui/icons/ThumbUp'
import red from '@material-ui/core/colors/red'
import green from '@material-ui/core/colors/green'
import NextIcon from '@material-ui/icons/FastForward'
import BackIcon from '@material-ui/icons/FastRewind'
const styles = theme => ({
  text: {
    paddingTop: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2
  },
  paper: {
    paddingBottom: 50
  },
  list: {
    marginBottom: theme.spacing.unit * 2
  },
  subHeader: {
    backgroundColor: theme.palette.background.paper
  },
  appBar: {
    top: "auto",
    bottom: 0
  },
  toolbar: {
    alignItems: "center",
    //   justifyContent: "space-between"
    width: "100%"
  },
  fabButton: {
    position: "absolute",
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: "0 auto"
  },
  reject: {
    color: red[500]
  },
  accept: {
    color: green[500]
  },

});

function BottomAppBar(props) {
  const {
    classes,
    handleClose,
    handleBack,
    handleForward,
    rejectBG,
    rejectDisabled,
    approveBG,
    approveDisabled,
    handleRejected,
    handleAccepted,
    id
  } = props;
  let size
  if (window.screen.width < 600) {
    size = "small"
  } else {
    size = "large"
  }
  return (
    <AppBar position="fixed" color="default" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Grid container justify="space-around">
          {/* <Hidden xsDown>
            <Button variant="contained" color="default" onClick={handleClose}>
              <i className="material-icons">cancel</i>
              &nbsp;Cancel
            </Button>
          </Hidden> */}
          <Button variant="contained" //className={classes.back}
            size={size} color="default" onClick={handleBack}>
            <BackIcon />
            &nbsp;Back
          </Button>


          <Button
            value={1}
            //className={classes.button}
            id={id}
            variant="contained"
            size={size}
            onClick={handleAccepted}
            color="default"
            style={{ backgroundColor: { approveBG } }}
            disabled={approveDisabled}

          >
            <ThumbUp className={classes.accept} />
            &nbsp;Approve
          </Button>

          <Button
            value={-1}
            id={id}
            variant="contained"
            size={size}
            onClick={handleRejected}
            // className={classes.button}
            color="default"
            style={{ backgroundColor: { rejectBG } }}
            disabled={rejectDisabled}
          >
            <ThumbDown className={classes.reject} /> &nbsp;Reject
          </Button>
          <Button size={size} variant="contained"// className={classes.next} 
            color="default" onClick={handleForward}>
            Next&nbsp;
            <NextIcon />
          </Button>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

BottomAppBar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(BottomAppBar);

