import {
  PAYMENT_HISTORY_REQUESTED,
  PAYMENT_HISTORY_ACQUIRED,
  MONTHLY_BILLING_REQUESTED,
  MONTHLY_BILLING_ACQUIRED,
  API_HELP_REQUESTED,
  API_HELP_ACQUIRED,
  RESET_PAYMENT_HISTORY,
  RESET_MONTHLY_BILLING,
  ADMIN_PAGE_SELECTED
} from "../constants";

const initialPaymentHistoryData = {
  index: 0
};

export const paymentHistory = (state = initialPaymentHistoryData, action) => {
  switch (action.type) {
    case PAYMENT_HISTORY_REQUESTED:
      return action.payload;
    case PAYMENT_HISTORY_ACQUIRED:
      return action.payload;
    case RESET_PAYMENT_HISTORY:
      return {};
    case ADMIN_PAGE_SELECTED:
      return { ...state, index: action.payload };
    default:
      return state;
  }
};

export const monthlyBilling = (state = {}, action) => {
  switch (action.type) {
    case MONTHLY_BILLING_REQUESTED:
      return action.payload;
    case MONTHLY_BILLING_ACQUIRED:
      return action.payload;
    case RESET_MONTHLY_BILLING:
      return {};
    default:
      return state;
  }
};

export const apiHelpPage = (state = {}, action) => {
  switch (action.type) {
    case API_HELP_REQUESTED:
      return action.payload;
    case API_HELP_ACQUIRED:
      return action.payload;
    default:
      return state;
  }
};
