import grey from "@material-ui/core/colors/grey";
import { ImportantDevices } from "@material-ui/icons";

export const editCompanyStyles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper
  },
  card: {
    flex: "1 1 auto",
    width: "100%"
  },
  button: {
    margin: theme.spacing.unit,
    marginTop: theme.spacing.unit * 3
    // backgroundColor: theme.palette.primary.main
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  },
  textField: {
    width: "90%",
    marginLeft: theme.spacing.unit, //"auto",
    marginRight: theme.spacing.unit, //"auto",
    flexBasis: 200,
    paddingBottom: 0,
    marginTop: 0,
    borderBottom: "1px solid #ced4da",
    borderBottomColor: grey[900],
    borderTop: "none",
    borderRight: "none",
    borderLeft: "none"
  },
  checkBox: {
    width: "auto",
    flex: "3 1 0px",
    flexDirection: "row",
    marginLeft: theme.spacing.unit * 4,
    marginTop: theme.spacing.unit,
    // marginBottom: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  },
  avatar: {
    margin: theme.spacing.unit
  },
  ////paymentInformation\\\\\
  tableCell: {
    borderBottom: `0px`
  },
  expansionPanel: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit
    }px`
  },
  expansionPanelSummary: {
    padding: `0 ${theme.spacing.unit * 6}px 0 ${theme.spacing.unit * 3}px`
  },
  details: {
    backgroundColor: "#f9f9f9"
  },
  grid: {
    marginLeft: theme.spacing.unit * 3,
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    width: "100%"
  },
  label: {
    width: "100%",
    margin: theme.spacing.unit
  },
  /////Location\\\\\
  // locationRoot: {
  //   paddingTop: "2.3rem"
  // },
  headingPadding: {
    paddingBottom: ".7rem"
  },
  //LocationCreateFormik\\

  ///signingstatements\\\\
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0
  },
  textFieldRoot: {
    padding: 0,
    "label + &": {
      marginTop: theme.spacing.unit * 3
    }
  },
  textFieldInput: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 12px",
    width: "calc(100% - 24px)",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
    }
  },
  textFieldFormLabel: {
    fontSize: 18
  },
  signButton: {
    margin: theme.spacing.unit,
    width: "150px"
  },
  mergeTitle: {
    // margin: theme.spacing.unit,
    // width: "150px",
    paddingRight: "24px"
  },
  textAreaInput: {
    rows: "4",
    cos: "100",
    marginTop: "2px",
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 12px",
    width: "100%",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
    }
  },
  ///added to translate to outlined TextFields\\\
  textFieldOutlined: {
    ///textField3
    width: "90%",
    marginLeft: theme.spacing.unit, //"auto",
    marginRight: theme.spacing.unit, //"auto",
    flexBasis: 200,
    marginTop: 0,
    marginBottom: 12,
    // paddingBottom: 12,
    borderTop: "none",
    borderRight: "none",
    borderLeft: "none"
    // textAlign: "center"
  },
  intlTelInput: {
    display: "block"
  },
  input: {
    width: "35%"
  },
  rateInput: {
    // width: "90%",
    marginLeft: 0, //theme.spacing.unit, //"auto",
    marginRight: 0, //theme.spacing.unit, //"auto",
    marginTop: theme.spacing.unit, //"auto",
    marginBottom: theme.spacing.unit, //"auto",
    flexBasis: 200
  },
  //companyDetails!!
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
    width: "90%",
    textAlign: "left"
  },
  central: {
    textAlign: "center",
    justifyContent: "center"
  },
  cardButtons: {
    marginRight: "auto",
    marginTop: "2rem"
  }
});

//////signStatement.Styles\\\\\\\\\
// const styles = theme => ({
//   root: {
//     width: "100%"
//   },
//   heading: {
//     fontSize: theme.typography.pxToRem(15),
//     flexBasis: "33.33%",
//     flexShrink: 0
//   },
//   secondaryHeading: {
//     fontSize: theme.typography.pxToRem(15),
//     color: theme.palette.text.secondary
//   },
//   textFieldRoot: {
//     padding: 0,
//     "label + &": {
//       marginTop: theme.spacing.unit * 3
//     }
//   },
//   button: {
//     margin: theme.spacing.unit,
//     width: "150px"
//   },
//   textFieldLabel: {
//     color: "rgba(0, 0, 0, 0.54)",
//     fontSize: 14,
//     marginTop: "6px",
//     marginBottom: "6px"
//   },
//   textFieldFormLabel: {
//     fontSize: 18
//   },
//   textFieldInput: {
//     borderRadius: 4,
//     backgroundColor: theme.palette.common.white,
//     border: "1px solid #ced4da",
//     fontSize: 16,
//     padding: "10px 12px",
//     width: "calc(100% - 24px)",
//     transition: theme.transitions.create(["border-color", "box-shadow"]),
//     "&:focus": {
//       borderColor: "#80bdff",
//       boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
//     }
//   },
//   textAreaInput: {
//     rows: "4",
//     cos: "100",
//     marginTop: "2px",
//     borderRadius: 4,
//     backgroundColor: theme.palette.common.white,
//     border: "1px solid #ced4da",
//     fontSize: 16,
//     padding: "10px 12px",
//     width: "100%",
//     transition: theme.transitions.create(["border-color", "box-shadow"]),
//     "&:focus": {
//       borderColor: "#80bdff",
//       boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
//     }
//   }
// });

//\\//\\
// field: {
//   width: "75%"
// },
// inputInkbar: {
//   "&:after": {
//     backgroundColor: purple[500]
//   }
// },
// formGroup: {
//   padding: theme.spacing.unit
// },
// error: {
//   // fontColor: red[500] //theme.palette.primary.main
//   color: "!important #e539535"
// }
// //   root: {
// //   display: "flex",
// //   flexWrap: "wrap",
// //   justifyContent: "space-around",
// //   overflow: "hidden",
// //   backgroundColor: theme.palette.background.paper
// // },
