import React from 'react'
import { Typography } from '@material-ui/core'
const Logo = require('../images/aWaiver_Logo_w_Text.svg')


const MainLogo = props => (
    <div >
        <Typography type="title" color="inherit" >
            <img src={Logo} alt="AWaiver" style={{ width: "10rem" }} />
        </Typography>
    </div>)

export default MainLogo