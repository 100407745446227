import React from "react"
import Grid from '@material-ui/core/Grid'
import { isEmpty } from 'ramda'

const SignedWaiverSignature = ({ signedWaiver, sw, classes }) => {

    if ((!sw.signatureUrl && sw.failed) || isEmpty(sw.signatureUrl)) {
        return (null)
    } else {
        return (
            <Grid container justify='center' >
                <Grid item>
                    <Grid container justify="center" alignItems="center">
                        <Grid item xs>
                            <img id="img" className={classes.signature} src={sw.signatureUrl} />
                        </Grid>
                    </Grid>
                </Grid></Grid>
        )
    }
}

export default SignedWaiverSignature
