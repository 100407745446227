import {
  LOCATION_REQUESTED,
  LOCATION_ACQUIRED,
  NEW_LOCATION_REQUESTED,
  NEW_LOCATION_CREATED,
  PRIMARY_LOCATION_EDITED,
  LOCATION_EDITED,
  EDIT_LOCATION_REQUESTED,
  LOCATION_COORDINATES_REQUESTED,
  LOCATION_COORDINATES_FAILED,
  LOCATION_COORDINATES_ACQUIRED,
  LOCATION_DIALOG_HANDLED,
  EDIT_LOCATION_RELOADED
} from "../constants";
import { apiFetchClosure } from "../lib/fetch";
import {
  and,
  dissoc,
  equals,
  find,
  findIndex,
  gt,
  length,
  map,
  merge,
  prop,
  propEq
} from "ramda";

export const getLocation = () => async (dispatch, getState) => {
  dispatch({ type: LOCATION_REQUESTED, payload: { isFetching: true } });
  const response = await apiFetchClosure()(`/api/location`)
    .then(res => res.json())
    .catch(err => console.log("err: ", err));
  // console.log({ response });
  dispatch({
    type: LOCATION_ACQUIRED,
    payload: response //{ isFetching: false, data: response, isAcquired: true }
  }); //{ isFetching: false, data: response} })//data: response } })
  // dispatch({ type: LOCATIONS_ACQUIRED, payload: { data: { Locations: pluck('Locations', response) }} })
};

export const createLocation = location => async (dispatch, getState) => {
  let currentLocations = getState().locations.data;
  let indexPrimary = findIndex(propEq("Primary", true), currentLocations);
  // console.log({ location, currentLocations, indexPrimary });

  let Id;

  if (gt(length(currentLocations), 0)) {
    Id = prop("Id", find(prop("Id"))(currentLocations));
  }

  dispatch({ type: NEW_LOCATION_REQUESTED, payload: { isFetching: true } });
  location = merge(location, { Id: Id });

  const response = await apiFetchClosure()(`/api/location`, {
    method: "POST",
    body: JSON.stringify(location)
  })
    .then(res => res.json())
    .catch(err => console.log("err: ", err));

  //replaces Primary on view page\\
  if (location.Primary) {
    currentLocations[indexPrimary] = merge(currentLocations[indexPrimary], {
      Primary: false
    });
  }
  currentLocations.push(response);
  const appendedLocationsArray = map(
    x => Object.assign({}, x),
    currentLocations
  );
  // console.log({ appendedLocationsArray, currentLocations, response });

  dispatch({
    type: NEW_LOCATION_CREATED,
    payload: { isFetching: false, isSaved: true, data: appendedLocationsArray }
  });
};

export const updatePrimaryLocation = data => async (dispatch, getState) => {
  dispatch({ type: PRIMARY_LOCATION_EDITED, payload: { isFetching: true } });
  const id = data.Id;
  const response = await apiFetchClosure()(`/api/location/${id}`, {
    method: "PUT",
    body: JSON.stringify(data)
  })
    .then(res => res.json())
    .catch(err => console.log("err: ", err));

  // dispatch({ type: SUCCESS_EDIT_LOCATION, payload: { fetching: false, response: updatedLocationsArray } })
};

export const updateLocation = data => async (dispatch, getState) => {
  dispatch({ type: EDIT_LOCATION_REQUESTED });

  let currentLocations = getState().locations.data; //response
  const id = data.Id;
  const locationToUpdateIndex = findIndex(propEq("Id", id), currentLocations);
  let indexPrimary = findIndex(propEq("Primary", true), currentLocations);

  const location = dissoc("Location", data);
  const response = await apiFetchClosure()(`/api/location/${id}`, {
    method: "PUT",
    body: JSON.stringify(location)
  })
    .then(res => res.json())
    .catch(err => console.log("err: ", err));

  currentLocations[locationToUpdateIndex] = response;

  if (and(data.Primary, !equals(locationToUpdateIndex, indexPrimary))) {
    currentLocations[indexPrimary] = merge(currentLocations[indexPrimary], {
      Primary: false
    });
  }

  const updatedLocationsArray = map(
    x => Object.assign({}, x),
    currentLocations
  );

  dispatch({
    type: LOCATION_EDITED,
    payload: {
      isFetching: false,
      isSaving: false,
      isSaved: true,
      data: updatedLocationsArray
    }
  });
};

export const getLocationCoordinates = addr => async (dispatch, getState) => {
  // console.log("getLocationCoordinates, addr", addr);
  dispatch({
    type: LOCATION_COORDINATES_REQUESTED,
    payload: { isFetching: true }
  });
  const response = await apiFetchClosure()(`/api/location/coordinates`, {
    method: "POST",
    body: JSON.stringify({ Address: addr })
  })
    .then(res => res.json())
    .catch(err => console.log("err: ", err));
  // console.log("getLocationCoordinates", response);

  if (response.Message) {
    dispatch({ type: LOCATION_COORDINATES_FAILED, payload: {} });
    return;
  }
  dispatch({
    type: LOCATION_COORDINATES_ACQUIRED,
    payload: { isFetching: false, data: response }
  });
};

export const handleDialog = action => async (dispatch, getState) => {
  dispatch({ type: LOCATION_DIALOG_HANDLED, payload: action });
};

export const getCurrentLocation = id => (dispatch, getState) => {
  const locations = getState().locations;
  const location = find(propEq("Id", Number(id), locations));
  dispatch({ type: EDIT_LOCATION_RELOADED, payload: location });
};
