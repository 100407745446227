import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import SearchIcon from "@material-ui/icons/SearchTwoTone";
import SSOptions from "./swSearchOptions";
import Typography from "@material-ui/core/Typography";
import Hover from "../hover";
import { styles } from "./swStyles";
const SearchInput = ({
  classes,
  handleInput,
  dispatch,
  searchSettings,
  fetching,
  getSearchSettings,
  changeSearchSettings,
  onSearch,
  searchOnChange,
  handleKeyPress,
  input
}) => {
  return (
    <div >
      <Paper className={classes.searchpaper} elevation={1}>
        <Grid container>
          {/* <Grid item xs> */}
          <SSOptions
            searchSettings={searchSettings}
            getSearchSettings={getSearchSettings}
            classes={classes}
            changeSearchSettings={changeSearchSettings}
            dispatch={dispatch}
          />
          {/* </Grid> */}

          <Grid onChange={handleInput} item md>
            <InputBase
              type="search"
              id="search input"
              placeholder="Search"
              onChange={searchOnChange}
              className={classes.input}
              onKeyPress={handleKeyPress}
              type="search"
            />
          </Grid>
          <Divider className={classes.divider} />
          <Hover
            message={
              <span>
                <Typography variant="caption">
                  To Search for a name enter the name in the search field
                </Typography>
                <Typography variant="caption">
                  To search for a date, enter the date ("mm/dd/yyyy") in the
                  search field
                </Typography>
                <Typography variant="caption">
                  To search for a name and date ("mm/dd/yyyy"), enter the name
                  followed by the date
                </Typography>
              </span>
            }
            component={
              <Grid item xs>
                {fetching ? (
                  <IconButton
                    color="primary"
                    disabled
                    className={classes.iconButton}
                    aria-
                    label="Search"
                  >
                    <SearchIcon />
                    <CircularProgress
                      size={12}
                      color="primary"
                      className={classes.buttonProgress}
                    />
                  </IconButton>
                ) : (
                    <IconButton
                      color="primary"
                      disabled={input <= 2}
                      onClick={onSearch}
                      className={classes.iconButton}
                      aria-label="Search"
                    >
                      <SearchIcon />
                    </IconButton>
                  )}
              </Grid>
            }
          />
        </Grid>
      </Paper>
    </div>
  );
};

export default withStyles(styles)(SearchInput);
