﻿import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch, Route, Router, Redirect } from "react-router-dom";
import "./App.css";
import Company from "./pages/companies/companies";
import CompanyNew from "./pages/companies/new";
import EditCompanyDetails from "./pages/companies/edit";
import history from "./history";
import AddPaymentCard from "./components/billing/addPaymentCard";
import ManageUsers from "./pages/users/users";
import Usage from "./pages/usage/usage";
import ManageWaivers from "./pages/waivers/waivers";
import AdminPage from "./pages/admin/admin";
import HelpPage from "./pages/help/help";
import EditUser from "./pages/users/edit";
import Login from "./pages/users/login";
import SignedWaivers from "./pages/signedWaivers/signedWaivers";
import withRoot from "./components/withRoot";
import ScrollToTop from "./components/ScrollToTop";
import LocationEditFormik from "./components/locations/editLocationFormik";
import { gte } from "ramda";
const App = ({ roles = [], authentication }) => {

  return (
    <Router history={history}>
      <ScrollToTop>
        <div>
          <Switch>
            <ProtectedByLogin authentication={authentication}
              exact
              path="/companies"
              component={Company}
              roles={roles}
            />
            <ProtectedByLogin roles={roles} authentication={authentication} exact path="/companies/:id/new" component={CompanyNew} />
            <ProtectedByLogin authentication={authentication} roles={roles} exact path="/companies/new" component={CompanyNew} />
            <ProtectedByRole
              protectedRoleId={2}
              roles={roles}
              exact
              path="/companies/:id/billing/new"
              component={AddPaymentCard}
            />
            <ProtectedByRole
              protectedRoleId={2}
              roles={roles}
              exact
              path="/companyDetails/:id"
              component={EditCompanyDetails}
            />
            <ProtectedByRole
              protectedRoleId={2}
              roles={roles}
              exact
              path="/companyDetails/:id/:id"
              component={LocationEditFormik}
            />
            <ProtectedByRole
              protectedRoleId={2}
              roles={roles}
              exact
              path="/waivers"
              component={ManageWaivers}
            />
            <Route exact path="/" component={Login} />
            <ProtectedByRole
              protectedRoleId={3}
              roles={roles}
              exact
              path="/signedwaivers"
              component={SignedWaivers}
            />
            <ProtectedByRole
              protectedRoleId={2}
              roles={roles}
              exact
              path="/users"
              component={ManageUsers}
            />
            <ProtectedByRole
              protectedRoleId={2}
              roles={roles}
              exact
              path="/users/:id"
              component={EditUser}
            />
            {/* <Route exact path="/users/:id" component={EditUser} /> */}
            <ProtectedByRole
              protectedRoleId={2}
              roles={roles}
              exact
              path="/usage"
              component={Usage}
            />

            {/* <Route exact path='/users/edit' component={EditUserDialog} /> */}
            {/* <Route exact path="/admin" component={AdminPage} /> */}
            <ProtectedByRole
              protectedRoleId={1}
              roles={roles}
              exact
              path="/admin"
              component={AdminPage}
            />
            <ProtectedByLogin authentication={authentication} roles={roles} exact path="/help" component={HelpPage} />
          </Switch>
        </div>
      </ScrollToTop>
    </Router>
  );
};

const mapStateToProps = state => ({
  //  roles: state.currentUser.data.Roles
  // roles: state.currentUser.Roles
  roles: state.authentication.Roles,
  authentication: state.authentication
});

const connector = connect(mapStateToProps);
const ProtectedByRole = ({
  component: Component,
  protectedRoleId,
  roles,
  authentication,
  ...rest
}) => {
  if (
    localStorage.isAuth > 0 &&
    gte(protectedRoleId, Number(localStorage.isAuth)) && (sessionStorage.fetching || roles.length > 0)
  ) {
    return <Route {...rest} component={Component} />;
  }
  return <Redirect to="/" />;
};
const ProtectedByLogin = ({
  component: Component,
  authentication,
  roles,
  ...rest
}) => {

  if (
    window.localStorage.isAuth && (window.sessionStorage.access_token || window.localStorage.access_token) && (sessionStorage.fetching || roles.length > 0)
  ) {
    return <Route {...rest} component={Component} />;
  }
  return <Redirect to="/" />;
};
export default connector(withRoot(App));
