import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";

class NotAuthorized extends Component {
  state = {
    open: true
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleContinue = () => {
    this.setState({ open: false });
    this.props.clearNoAuth();
  };
  handleClose = () => {
    this.setState({ open: false });
    this.props.resetAuth();
    window.localStorage.clear();
    window.sessionStorage.clear();
  };

  render() {
    return (
      <div>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          //  PaperComponent={PaperComponent}
          aria-labelledby="limited-access"
        >
          <DialogTitle id="limited-access">Limited Access</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Your access credentials for the current company are limited.
              Please contact an administrator if this is incorrect.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button
              component={Link}
              to="/companies"
              onClick={this.handleContinue}
              color="primary"
            >
              {/* <Button component={Link} to="/companies" onClick={this.handleContinue} color="primary"> */}
              Continue
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default NotAuthorized;
