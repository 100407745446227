import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import "../../AWaiver.css";
import {
  addSWNote,
  getSW,
  addActivityDate,
  setCurrentSW,
  getParticipantPdf,
  statusUpdated,
  editSWNote,
  getNoteHistory,
  getSWNotes,
  getAttachments
} from "../../action-creators/signedWaivers";
import { updateSignedWaivers } from "../../action-creators/signalR";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Icon from "@material-ui/core/Icon";
import { connect } from "react-redux";
import {
  map,
  prop,
  isEmpty,
  propOr,
  find,
  findIndex,
  equals,
  propEq,
  view,
  lensIndex,
  length,
  clone,
  sort,
  ascend,
  lensProp,
  pluck
} from "ramda";
import SWPanel from "./signingWaiverPanel";
import moment from "moment";
import {
  WAIVER_ASSIGNED,
  SET_SW_ARRAY,
  SIGNED_WAIVER_SET,
  PENDING_WAIVERS_FETCHED,
  RECENTLY_SIGNED_WAIVERS_FETCHED,
  SIGNED_WAIVERS_SEARCH_FETCHED
} from "../../constants";
import Divider from "@material-ui/core/Divider";
import SWPopup from "./swPopup";
import { styles } from "./swStyles";
import { Hidden } from "@material-ui/core";
import grey from "@material-ui/core/colors/grey";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import { adjustFormatDWT } from "../../lib/dateHelpers";
//import PushService from '../../signalR'

class SignedWaiversList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      checkedHere: null,
      open: false,
      waiverName: null,
      waiverIndex: null,
      waiverId: null,
      activeStep: 0,
      hereToday: []
    };
  }

  //when first signed waiver popup is selected, this loads initial state in order for all functions to work correctly
  initialClick = ev => {
    let sws;
    if (this.props.swStepper.isRecent === true) {
      sws = this.props.recentSW;
    } else if (this.props.swStepper.isSearched === true) {
      sws = this.props.searchedSW;
    } else {
      sws = this.props.pendingSW;
    }
    const wa = find(propEq("Waiver_Id", this.state.waiverId), sws);
    const swId = Number(ev.currentTarget.value);
    this.props.getAttachments(swId);
    this.props.getSWNotes(swId);
    this.handlePartialReset();
    const signed = map(s => ({ id: s.SignedWaiver_Id }), wa.SignedWaivers);
    const currentStep = findIndex(propEq("id", swId))(signed);
    this.setState({
      activeStep: currentStep
    });
    this.handleOpen();
  };

  //anything after initial
  handleStepNext = () => {
    this.handlePartialReset();
    let sws;
    if (this.props.swStepper.isRecent === true) {
      sws = this.props.recentSW;
    } else if (this.props.swStepper.isSearched === true) {
      sws = this.props.searchedSW;
    } else {
      sws = this.props.pendingSW;
    }
    const wa = find(propEq("Waiver_Id", this.state.waiverId), sws);
    if (equals(this.state.activeStep + 1, wa.SignedWaivers.length)) {
      const sw = view(lensIndex(0), wa.SignedWaivers);
      const id = sw.SignedWaiver_Id;
      this.props.getAttachments(id);
      this.props.getSWNotes(id);
      this.setState({
        activeStep: 0
      });
    } else {
      const sw = view(lensIndex(this.state.activeStep + 1), wa.SignedWaivers);
      const id = sw.SignedWaiver_Id;
      this.props.getAttachments(id);
      this.props.getSWNotes(id);
      this.setState(prevState => ({
        activeStep: prevState.activeStep + 1
      }));
    }
  };
  handleStepBack = () => {
    this.handlePartialReset();
    let sws;
    if (this.props.swStepper.isRecent === true) {
      sws = this.props.recentSW;
    } else if (this.props.swStepper.isSearched === true) {
      sws = this.props.searchedSW;
    } else {
      sws = this.props.pendingSW;
    }
    const wa = find(propEq("Waiver_Id", this.state.waiverId), sws);
    if (equals(this.state.activeStep, 0)) {
      const last = length(wa.SignedWaivers) - 1;
      const sw = view(lensIndex(last), wa.SignedWaivers);
      const id = sw.SignedWaiver_Id;
      this.props.getAttachments(id);
      this.props.getSWNotes(id);
      this.setState({
        activeStep: last
      });
    } else {
      const sw = view(lensIndex(this.state.activeStep - 1), wa.SignedWaivers);
      const id = sw.SignedWaiver_Id;
      this.props.getAttachments(id);
      this.props.getSWNotes(id);
      this.setState(prevState => ({
        activeStep: prevState.activeStep - 1
      }));
    }
  };
  //to avoid old state, on each step all state regarding 1 single signed waiver must be reset
  handlePartialReset = () => {
    this.setState({
      activeStatus: 0
    });
  };

  //when select a panel to open, it assigns the correct signedwaivers based on the current waiver
  handleChange = panel => (event, expanded) => {
    let sws;
    if (this.props.swStepper.isRecent === true) {
      sws = this.props.recentSW;
    } else if (this.props.swStepper.isSearched === true) {
      sws = this.props.searchedSW;
    } else {
      sws = this.props.pendingSW;
    }
    const activePanel = find(propEq("Name", panel), sws);
    const waiverId = activePanel.Waiver_Id;
    const waiverIndex = findIndex(propEq("Waiver_Id", waiverId), sws);
    this.setState({
      expanded: expanded ? panel : false,
      waiverName: panel,
      waiverIndex: waiverIndex,
      waiverId: waiverId
    });
  };
  getLengthProp = signedWaiverArray => {
    const getLengthProp = propOr([], "length");
    return getLengthProp(signedWaiverArray);
  };
  handleOpen = () => {
    //adds the current path to prevent unintentional logout if use browser back button
    window.history.pushState(null, null, window.location.href);
    window.addEventListener("popstate", this.handleClose);
    this.setState({ open: true });
  };
  handleClose = event => {
    this.handlePartialReset();
    window.removeEventListener("popstate", this.handleClose);
    this.setState({ open: false, activeStep: 0, hereToday: [] });
  };

  handleUpdateSWArray = newArray => {
    let sws;
    if (this.props.swStepper.isRecent) {
      sws = this.props.recentSW;
    } else if (this.props.swStepper.isSearched) {
      sws = this.props.searchedSW;
    } else {
      sws = this.props.pendingSW;
    }
    const waiver = sws[this.state.waiverIndex];
    const newsws = Object.assign({}, waiver, { SignedWaivers: newArray });
    const newWaivers = Object.assign([], sws, {
      [this.state.waiverIndex]: newsws
    });
    if (this.props.swStepper.isRecent) {
      this.props.dispatch({
        type: RECENTLY_SIGNED_WAIVERS_FETCHED,
        payload: newWaivers
      });
    } else if (this.props.swStepper.isSearched) {
      this.props.dispatch({
        type: SIGNED_WAIVERS_SEARCH_FETCHED,
        payload: newWaivers
      });
    } else {
      this.props.dispatch({
        type: PENDING_WAIVERS_FETCHED,
        payload: newWaivers
      });
    }
  };

  render() {
    const { classes, swAttachments } = this.props;
    const { expanded, waiverName, open, activeStep } = this.state;
    let signedWaivers;
    if (this.props.recentlySigned) {
      signedWaivers = this.props.recentSW;
    } else if (this.props.searchSigned) {
      signedWaivers = this.props.searchedSW;
    } else {
      signedWaivers = this.props.pendingSW;
    }
    const wa = find(propEq("Waiver_Id", this.state.waiverId), signedWaivers);
    return (
      <div className={classes.root}>
        {this.state.open && (
          <SWPopup
            waiver={this.state.waiverId}
            subtitle={`${waiverName} Waivers`}
            handleClose={this.handleClose}
            open={open}
            activeSignedWaivers={wa.SignedWaivers}
            handleStepBack={this.handleStepBack}
            handleStepNext={this.handleStepNext}
            activeStep={this.state.activeStep}
            classes={classes}
            dispatch={this.props.dispatch}
            swStepper={this.props.swStepper}
            statusUpdated={this.props.statusUpdated}
            addActivityDate={this.props.addActivityDate}
            getParticipantPdf={this.props.getParticipantPdf}
            swAttachments={swAttachments}
            getSWNotes={getSWNotes}
            currentStep={activeStep}
            getNoteHistory={this.props.getNoteHistory}
            handleUpdateSWArray={this.handleUpdateSWArray}
            activeArray={wa.SignedWaivers}
            handleNext={this.handleStepNext}
          />
        )}
        {signedWaivers.length > 0 ? (
          map(
            w => (
              <SWPanel
                classes={classes}
                key={w.Waiver_Id}
                numberOfWaivers={this.getLengthProp(w.SignedWaivers)}
                component={
                  <div className={classes.gridroot}>
                    <Grid container spacing={8}>
                      <List
                        dense
                        id={`signedWaiverList-${w.Waiver_Id}`}
                        className={classes.root}
                      >
                        {!isEmpty(w.SignedWaivers)
                          ? map(
                              sw => (
                                <Button
                                  style={{
                                    backgroundColor:
                                      sw.Status === 1
                                        ? green[200]
                                        : sw.Status === -1
                                        ? red[200]
                                        : grey[100]
                                  }}
                                  onClick={this.initialClick}
                                  fullWidth
                                  key={sw.SignedWaiver_Id}
                                  value={sw.SignedWaiver_Id}
                                  className={classes.gridroot}
                                  variant="outlined"
                                >
                                  <ListItem key={sw.SignedWaiver_Id}>
                                    <Grid
                                      container
                                      spacing={0}
                                      zeroMinWidth
                                      justify="space-between"
                                    >
                                      <Hidden>
                                        <Grid item xs>
                                          {map(
                                            p => (
                                              <div key={p.Id}>
                                                <Typography>{`${prop(
                                                  "ParticipantName",
                                                  p
                                                )}`}</Typography>
                                              </div>
                                            ),
                                            sort(
                                              ascend(prop("ParticipantName")),
                                              sw.Participants
                                            )
                                          )}
                                        </Grid>
                                      </Hidden>
                                      <Hidden xsDown>
                                        <Grid item xs>
                                          <Typography>
                                            {sw.SignedByName}
                                          </Typography>
                                        </Grid>
                                      </Hidden>
                                      <Hidden xsDown>
                                        <Grid item xs={1}>
                                          {!isEmpty(sw.CurrentNotes) ? (
                                            <Icon color="primary">
                                              <i className="material-icons">
                                                speaker_notes
                                              </i>
                                            </Icon>
                                          ) : (
                                            ""
                                          )}
                                        </Grid>
                                      </Hidden>
                                      <Hidden>
                                        <Grid item xs>
                                          <Typography align="right">
                                            {adjustFormatDWT(sw.Signed)}
                                          </Typography>
                                        </Grid>
                                      </Hidden>
                                    </Grid>
                                  </ListItem>
                                  <Divider />
                                </Button>
                              ),
                              w.SignedWaivers
                            )
                          : "There are no Signed Waivers that match your criteria at this time."}
                      </List>
                    </Grid>
                  </div>
                }
                heading={w.Name}
                panelName={w.Name}
                expanded={expanded}
                handleChange={this.handleChange(`${w.Name}`)}
              />
            ),
            signedWaivers
          )
        ) : (
          <div>
            There are no Signed Waivers that match your criteria at this time.
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  swStepper: state.swStepper,
  pendingSW: state.pendingSW,
  recentSW: state.recentSW,
  searchedSW: state.searchedSW,
  swAttachments: state.swAttachments
});
const mapActionToProps = dispatch => ({
  statusUpdated: obj => dispatch(statusUpdated(obj)),
  getSW: id => dispatch(getSW(id)),
  addActivityDate: id => dispatch(addActivityDate(id)),
  getSWNotes: id => dispatch(getSWNotes(id)),
  getAttachments: id => dispatch(getAttachments(id)),
  getParticipantPdf: id => dispatch(getParticipantPdf(id)),
  updateSignedWaivers: obj => dispatch(updateSignedWaivers(obj)),
  dispatch
});
export default connect(
  mapStateToProps,
  mapActionToProps
)(withStyles(styles)(SignedWaiversList));
