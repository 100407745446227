import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Link } from "react-router-dom";

const LogoutRequired = ({ open, logoutUser }) => {
  return (
    <div>
       
      <Dialog
        open={open || false}
        onClose={logoutUser}
        aria-labelledby="logout-required"
      >
        <DialogTitle id="logout-required">Login Required</DialogTitle>

        <DialogContent>
          <DialogContentText>
            Due to a change in credentials, please log in again.
          </DialogContentText>
        </DialogContent>

        <DialogActions>
           
          <Button component={Link} to="/" onClick={logoutUser} color="primary">
              Continue
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default LogoutRequired;
