import React from 'react';
import ReactDOM from 'react-dom';
import store from './store'
import { Provider } from 'react-redux'
import App from './App';
import { setUsers, getCurrentUser } from './action-creators/users'
import { setCompanies } from './action-creators/companies'
import registerServiceWorker from './registerServiceWorker';

if (sessionStorage.getItem('access_token') || localStorage.getItem('access_token')) {
    //this prevents user to be logged out on refresh
    sessionStorage.setItem("fetching", "fetching")
    // store.dispatch(setUsers)
    store.dispatch(getCurrentUser)
    store.dispatch(setCompanies)
}





ReactDOM.render(<Provider store={store}><App dispatch={store.dispatch} /></Provider>, document.getElementById('root'));
registerServiceWorker();