
import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import SaveIcon from "@material-ui/icons/Save";
import moment from "moment";
import CardContent from "@material-ui/core/CardContent";
import { map, sort, descend, prop, equals } from "ramda";
import Divider from "@material-ui/core/Divider";
import InfoIcon from "@material-ui/icons/ExpandMore";
import EditIcon from "@material-ui/icons/Edit";
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Fab from '@material-ui/core/Fab'
import withStyles from '@material-ui/core/styles/withStyles'
const styles = theme => ({
  fab: {
    margin: theme.spacing.unit,
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginTop: 16

  },
  right: {
    marginLeft: "auto"
  },
})
const NoteHistory = ({ noteHistory, note, history, handleCancel, classes }) => (
  <div key={note.Id}>
    <ListItem> <Fab size="small" className={classes.fab} onClick={handleCancel("history")} value={history} aria-label="note history" color="primary" > <InfoIcon />
    </Fab>  </ListItem>
  
      {/* <ListItemText primary={moment(note.Created).format("llll")}
        secondary={note.Text} /> */}
          <CardContent>
      <List dense >
        {map(
          n => (
            <ListItem key={n.Version}>
              <ListItemText primary={moment(n.Created).format("llll")}
                secondary={n.Text} />
              <ListItemSecondaryAction>
              </ListItemSecondaryAction>
            </ListItem>
          ),
          sort(descend(prop("Version")), noteHistory)
        )}
      </List>
    </CardContent>
  
    
  </div>
);

const NoteEdit = ({ classes, note, onChange, onSaveEdit, handleCancel, editing, value }) => (
  <div key={note.Id}>
    <ListItem >
      <ListItemText primary={moment(note.Created).format("llll")}
      />
      <ListItemSecondaryAction>
        <Fab size="small" className={classes.fab} aria-label="Edit" color="primary" onClick={handleCancel("editing")} value={editing}>
          <EditIcon />
        </Fab>
      </ListItemSecondaryAction>
    </ListItem>
    <CardContent>
      <form className={classes.container} autoComplete="off">
        <TextField
          className={classes.textField}
          id="filled-full-width"
          defaultValue={note.Text}
          style={{ margin: 8 }}
          fullWidth
          onChange={onChange}
          multiline
          margin="normal"
          variant="filled"
          value={value}
        // value={note.Text}
        // InputLabelProps={{
        //   shrink: true
        // }}
        />
        <Button onClick={handleCancel("editing")} size="small" variant="contained">
          Cancel
      </Button>
        <Button
          className={classes.right}
          variant="contained"
          color="primary"
          onClick={onSaveEdit}
          size="small"
        >
          <SaveIcon /> Save
      </Button>
      </form>
    </CardContent>
  </div>
);

const Note = ({ classes, note, editing, history, handleChange, handleHistory, disableEditing }) => (
  <ListItem key={note.Id}>
    <Fab size="small" className={classes.fab} id={note.Id} aria-label="note history" value="history" onClick={handleHistory(note.Id)} key={note.Id + '_' + note.Version} disabled={equals(note.Version, 1) || disableEditing}> <InfoIcon />
    </Fab>
    <ListItemText primary={moment(note.Created).format("llll")}
      secondary={note.Text} />
    <ListItemSecondaryAction>
      <Fab size="small" className={classes.fab} id={note.Id} checked={editing} value={note.Version} text={note.Text} onClick={handleChange(note.Id)} aria-label="Edit" //disabled={editing || history} 
      disabled={disableEditing}>
        <EditIcon />
      </Fab>
    </ListItemSecondaryAction>
  </ListItem>
);

const Notes = ({ note, classes, onChange, onSaveEdit, editing, history, notes,
  handleChange, handleHistory, handleCancel, value, disableEditing }) => {
  return (
    <div>
      <Divider />

      {history === note.Id ? (
        <NoteHistory note={note} noteHistory={notes} handleHistory={handleHistory} handleCancel={handleCancel} classes={classes} history={history} />
      ) : editing === note.Id ? (
        <NoteEdit
          handleCancel={handleCancel}
          onSaveEdit={onSaveEdit}
          note={note}
          classes={classes}
          onChange={onChange}
          // handleChange={handleChange}
          editing={editing}
          value={value}
        />
      ) : (
            <Note classes={classes} note={note} disableEditing={disableEditing} history={history} editing={editing} handleChange={handleChange} handleHistory={handleHistory} />
          )}

    </div>
  );

}

export default withStyles(styles)(Notes);
