import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import { fade } from '@material-ui/core/styles/colorManipulator';
import SearchIcon from '@material-ui/icons/Search';
import { filter, contains, toLower } from "ramda"
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment'
//this search bar is being used on companies page for sysadmin only and on users page for anyone that has access to the page
export const searchByName = (input, list) => {
    return filter(n => contains(toLower(input), toLower(n.Name)))(list)
}
export const searchByAll = (input, list) => {
    const searched = filter(n => contains(toLower(input), toLower(n.Name)) || contains(toLower(input), toLower(n.Email)) || contains(toLower(input), toLower(n.UserName)) || contains(toLower(input), toLower(n.RoleName)))(list)
    return searched
}
// 
const styles = theme => ({
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing.unit * 2,
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing.unit * 3,
            width: 'auto',
        },
    },
    inputRoot: {
        color: 'inherit',
    },
})
//lym example handleInput
// handleInput = e => {
//     const input = e.target.value
//     if (input.length > 1) {
//       const cos = searchByName(input, this.props.companies)
//       this.setState({ searchedCompanies: cos, isSearching: true })
//     } else {
//       this.setState({ isSearching: false, searchedCompanies: [] })
//     }
//   }
const SearchBar = ({ handleInput, classes }) => (
    <div className={classes.search} onChange={handleInput}>

        <TextField
            InputProps={{
                startAdornment: <InputAdornment position="start"><SearchIcon color="primary" /></InputAdornment>,
            }}
            type="search"
            placeholder="Search…"
            className={classes.inputRoot}
            variant="outlined"
            inputProps={{ 'aria-label': 'Search' }}
        />
    </div>
)


export default withStyles(styles)(SearchBar)