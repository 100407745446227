import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import DialogAppBar from "../../components/dialogAppBar";
import CustomizedSnackbars from "../../components/snackbar";
import {
  selectUser,
  setUsers,
  updateUserRole,
  addCompanyRoleName,
  updateUserRoleNames
} from "../../action-creators/users";
import {
  EDIT_USER_SELECTED,
  USER_ROLENAME_SELECTED,
  EDIT_USER_CLEARED
} from "../../constants";
import { Formik, Field, Form, FieldArray } from "formik";
import AddUserCompany from "../../components/users/companiesRole";
import {
  and,
  difference,
  isNil,
  equals,
  isEmpty,
  map,
  or,
  pluck,
  reject
} from "ramda";
import { Debug } from "../../lib/Debug";
import Spinner from "../../components/spinner";
import FormikMaterialInput from "../../components/formikMaterialInput";
// import grey from "@material-ui/core/colors/grey";

const styles = theme => ({
  input: {
    width: "95%",
    marginLeft: 16,
    marginTop: 16,
    marginBottom: 8
  },
  formControl: {
    margin: theme.spacing.unit,
    // minWidth: 120,
    maxWidth: 300
  },
  root: {
    flexGrow: 1,
    paddingTop: "1.5rem"
  },
  button: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    backgroundColor: theme.palette.primary.main
  },
  label: {
    textTransform: "capitalize"
  },
  secondButton: {
    marginBottom: theme.spacing.unit,
    border: `4px solid `,
    borderRadius: "3px"
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      marginTop: theme.spacing.unit * 6,
      marginBottom: theme.spacing.unit * 6,
      padding: theme.spacing.unit * 3
    }
  },
  dialogContent: {
    flex: "1 1 auto",
    paddingTop: "1rem"
    // paddingBottom: "1rem",
    // overflowY: "auto"
  },
  table: {
    width: "95%",
    marginTop: "1rem"
  }
});

class EditUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      clicked: false,
      selected: false,
      dialogAction: "",
      stateUserData: {
        Id: null,
        Name: "",
        Email: "",
        UserName: "",
        PhoneNumber: "",
        UserCompanies: [{ RoleName: "", UserId: "" }]
      },
      accessLevels: [
        { level: 1, label: "SysAdmin" },
        { level: 2, label: "CompanyAdmin" },
        { level: 3, label: "ReviewWaivers" },
        { level: 4, label: "None" }
      ]
    };
  }

  componentDidMount() {
    this.props.editUserCleared();
    this.setState({
      open: true,
      stateUserData: this.props.editUser
    });
    this.props.selectUser(this.props.match.params.id);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      or(
        and(this.props.isAuthenticated, this.state.clicked),
        and(this.props.isAuthenticated, this.state.selected)
      )
    ) {
      setTimeout(this.roleAdded, 4000);
    }
  }

  addRole = () => {
    this.setState({ clicked: true });
  };

  roleAdded = () => {
    // console.log("roleAdded!!");
    if (and(this.props.isAuthenticated, this.state.clicked)) {
      // console.log("this.state.clicked");
      this.setState({ clicked: false });
      // this.props.selectUser(this.props.match.params.id);
      // this.props.editUserCleared();
    }

    if (and(this.props.isAuthenticated, this.state.selected)) {
      this.setState({ selected: false });
    }
    this.props.selectUser(this.props.match.params.id);
  };

  handleEdit = () => {
    this.props.addCompanyRoleName(this.props.editUser.newUserCompany);
  };

  handleClickOpen = user => {
    this.props.setUserToEdit(user);
    this.setState({ open: true, stateUserData: user });
  };

  handleClose = editUser => {
    this.setState({ open: false });
  };

  handleRoleChange = (event, userId, companyId) => {
    this.setState({ selected: true });

    this.props.updateUserRoleNames({
      UserId: userId,
      RoleName: event.target.value,
      companyId: companyId
    });

    // this.props.setUsers();
  };

  render() {
    const {
      classes,
      isAuthenticated,
      isError,
      isSaving,
      isValidating,
      editUser,
      companies,
      msg,
      userCompanies
    } = this.props;
    const { clicked, selected } = this.state;

    const newCompanies =
      !isNil(companies) && !isNil(userCompanies)
        ? difference(
            pluck("Name", companies),
            pluck("CompanyName", userCompanies)
          )
        : null;

    ///provides the list of companies that the selected user does not have a RoleName allocated\\\
    const noRoleName = and(
      !isNil(companies) && !isNil(userCompanies),
      !isEmpty(userCompanies)
    )
      ? reject(
          c => equals(c.Name, pluck("CompanyName", userCompanies).join()),
          companies
        )
      : this.props.companies;

    return (
      <div>
        {!isSaving &&
        !this.props.editUser.isLoading &&
        !isNil(this.props.userCompanies) ? (
          <React.Fragment>
            <CssBaseline />
            <Dialog
              open={this.state.open}
              onClose={this.handleClose}
              aria-labelledby="form-edit-user"
              fullScreen
              //transitionComponent={Transition}
            >
              <main className={classes.layout}>
                <Paper className={classes.paper}>
                  <Grid container className={classes.root}>
                    <Grid
                      item
                      key={`${this.props.editUserData.UserId} + ${
                        this.props.editUserData.CurrentCompanyId
                      }`}
                      // key={this.props.editUserData.UserId.toString()}
                    >
                      <DialogAppBar
                        title="Edit User"
                        handleClose={this.handleClose}
                        title3={`/users`}
                      />
                      <Formik
                        initialValues={editUser.data}
                        onSubmit={(values, { resetForm }) => {
                          this.handleEdit(values);
                          // setTimeout(this.roleAdded, 5000);
                        }}
                        render={({
                          handleSubmit,
                          isSubmitting,
                          setFieldTouched,
                          touched,
                          values
                        }) => (
                          // <Form>
                          <React.Fragment>
                            <DialogContent className={classes.dialogContent}>
                              <Grid container spacing={24}>
                                <Grid
                                  item
                                  key={this.props.editUserData.UserId.toString()}
                                  xs={6}
                                >
                                  <Field
                                    name="Name"
                                    label="Full Name"
                                    component={FormikMaterialInput}
                                    value={values.Name}
                                    disabled
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <Field
                                    name="Email"
                                    label="Email Address"
                                    component={FormikMaterialInput}
                                    value={values.Email}
                                    disabled
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <Field
                                    name="UserName"
                                    label="User Name"
                                    component={FormikMaterialInput}
                                    value={values.UserName}
                                    disabled
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <Field
                                    name="PhoneNumber"
                                    label="Phone Number"
                                    component={FormikMaterialInput}
                                    value={values.PhoneNumber}
                                    disabled
                                  />
                                </Grid>
                              </Grid>

                              <FormControl fullWidth>
                                <Table className={classes.table}>
                                                {userCompanies.map(company => (
                                                    <TableBody key={company.Company_Id}>
                                      <TableRow key={company.Company_Id}>
                                        <TableCell align="left">
                                          {company.CompanyName}
                                        </TableCell>

                                        <TableCell align="right">
                                          <Select
                                            disabled={
                                              isSubmitting ||
                                              isValidating ||
                                              isSaving
                                            }
                                            value={company.RoleName}
                                            name="accessLevel"
                                            input={
                                              <OutlinedInput
                                                labelWidth={0}
                                                name="Role Name"
                                              />
                                            }
                                            onChange={e =>
                                              this.handleRoleChange(
                                                e,
                                                values.UserId,
                                                company.Company_Id
                                              )
                                            }
                                          >
                                            {map(
                                              link => (
                                                <MenuItem
                                                  key={link.level}
                                                  value={link.label}
                                                >
                                                  {link.label}
                                                </MenuItem>
                                              ),
                                              this.state.accessLevels
                                            )}
                                          </Select>
                                        </TableCell>
                                      </TableRow>
                                    </TableBody>
                                  ))}
                                </Table>

                                {clicked && (
                                  <FormControl>
                                    <FieldArray
                                      className={classes.textField}
                                      name="UserCompanies"
                                      render={arrayHelpers => (
                                        <div>
                                          <Field
                                            component={AddUserCompany}
                                            classes={classes}
                                            arrayHelpers={arrayHelpers}
                                            values={values}
                                            setFieldTouched={setFieldTouched}
                                            companies={this.props.companies}
                                            accessLevels={
                                              this.state.accessLevels
                                            }
                                            updateUserRoles={
                                              this.props.updateUserRoles
                                            }
                                            company={
                                              !isNil(noRoleName)
                                                ? newCompanies
                                                : companies
                                            }
                                            userLoadingStarted={
                                              this.props.userLoadingStarted
                                            }
                                          />
                                        </div>
                                      )}
                                    />
                                  </FormControl>
                                )}
                              </FormControl>
                            </DialogContent>

                            <DialogActions>
                              {!clicked ? (
                                <Button
                                  disabled={selected}
                                  color="default"
                                  variant="contained"
                                  onClick={this.addRole}
                                >
                                  Add Another Company Role Name
                                </Button>
                              ) : null}

                              <Button component={Link} to="/users">
                                Cancel
                              </Button>
                              <Button
                                disabled={or(
                                  and(!selected, !clicked),
                                  or(!touched.UserCompanies, isSubmitting)
                                )}
                                variant="contained"
                                type="submit"
                                color="primary"
                                onClick={handleSubmit}
                                className={classes.button}
                              >
                                Save User
                              </Button>
                            </DialogActions>
                            {/* <Debug /> */}
                          </React.Fragment>
                        )}
                      />
                    </Grid>
                  </Grid>
                  {isError && (
                    <CustomizedSnackbars
                      message="User Edit Failed to Save. Please try again."
                      snackType="error"
                    />
                  )}
                  {isValidating && (
                    <CustomizedSnackbars
                      message="Edit User is Loading..."
                      snackType="info"
                    />
                  )}
                  {isAuthenticated && (
                    <CustomizedSnackbars
                      // message="New Company RoleName successfully uploaded!"
                      message={msg}
                      snackType="success"
                    />
                  )}
                </Paper>
              </main>
            </Dialog>
          </React.Fragment>
        ) : (
          <Spinner />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  users: state.users,
  companies: state.companies,
  userCompanies: state.editUser.data.UserCompanies,
  editUser: state.editUser,
  editUserData: state.editUser.data,
  isSaving: state.editUser.isSaving,
  isError: state.editUser.isError,
  isValidating: state.editUser.isValidating,
  isAuthenticated: state.editUser.isAuthenticated,
  msg: state.editUser.msg
});

const mapActionsToProps = dispatch => ({
  // return {
  setUsers: () => dispatch(setUsers),
  selectUser: id => dispatch(selectUser(id)),
  // updatedUser: user => dispatch(updateUser(user)),
  updateUserRole: user => dispatch(updateUserRole(user)),
  addCompanyRoleName: user => dispatch(addCompanyRoleName(user)),
  updateUserRoleNames: user => dispatch(updateUserRoleNames(user)),
  setUserToEdit: editUser =>
    dispatch({ type: EDIT_USER_SELECTED, payload: editUser }),
  userLoadingStarted: edit =>
    dispatch({ type: USER_ROLENAME_SELECTED, payload: edit }),
  editUserCleared: () => dispatch({ type: EDIT_USER_CLEARED })
  // };
});

const connector = connect(
  mapStateToProps,
  mapActionsToProps
);

export default connector(withStyles(styles)(EditUser));
