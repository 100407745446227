import React from "react";
import Spinner from "../../components/spinner";

const ApiHelp = props => {
  const { apiHelpPage } = props;

  return (
    <div>
      {apiHelpPage.helpPage ? (
        <span dangerouslySetInnerHTML={{ __html: apiHelpPage.helpPage }} />
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default ApiHelp;
