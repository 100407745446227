/* eslint-disable flowtype/require-valid-file-annotation */

import { create, SheetsRegistry } from 'jss'
import {jssPreset as preset} from '@material-ui/core/styles'
//import preset from 'jss-preset-default'
import { createMuiTheme as getMuiTheme, createGenerateClassName, createMuiTheme} from '@material-ui/core';
import { blueGrey, lightGreen, red } from '@material-ui/core/colors'

const theme = createMuiTheme({
  palette: {
    primary: blueGrey,
    accent: lightGreen,
    error: {
      light: red[300],
      main: red[600],
      // main: '#c71818',
      dark: red[700],
    },
    contrastThreshold: 3,
    tonalOffset: 0.7,
    type: 'light'
  },
  typography: {
    // Use the system font.
    fontFamily:
      '-apple-system,system-ui,BlinkMacSystemFont,' +
      '"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif',
    button: {
      textTransform: 'capitalize',
    },
    headline: {
      fontSize: '1.3rem'
    },
    body1: {
    },
    rootNavPadding: {
      paddingTop: '120px'
    },
  },
  navPadding: {
    paddingTop: '60px'
  },
  root: {
    width: '100%',
    fontStyle: 'italic',
    // backgroundColor: theme.palette.background.paper,
  },

})

const themeV0 = getMuiTheme({
  palette: {
    primary: blueGrey,
    accent: lightGreen,
    error: {
      light: red[300],
      main: red[600],
      // main: '#c71818',
      dark: red[700],
    },
    contrastThreshold: 3,
    tonalOffset: 0.7,
    type: 'light'
  },
  typography: {
    // Use the system font.
    fontFamily:
      '-apple-system,system-ui,BlinkMacSystemFont,' +
      '"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif',
    button: {
      textTransform: 'capitalize',
    },
    headline: {
      fontSize: '1.3rem'
    },
    body1: {
    },
    rootNavPadding: {
      paddingTop: '120px'
    },
  },
  navPadding: {
    paddingTop: '60px'
  },
  root: {
    width: '100%',
    fontStyle: 'italic',
    // backgroundColor: theme.palette.background.paper,
  },

})

// Configure JSS
const jss = create(preset())
jss.options.createGenerateClassName = createGenerateClassName

export const sheetsManager = new Map()

export default function createContext() {
  return {
    jss,
    theme,
    themeV0,
    // This is needed in order to deduplicate the injection of CSS in the page.
    sheetsManager,
    // This is needed in order to inject the critical CSS.
    sheetsRegistry: new SheetsRegistry()
  }
}