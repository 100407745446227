import React from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import moment from 'moment';
import { TEMP } from "../constants"
import { Typography } from '@material-ui/core';
import { getTimes, cleanTimes } from '../lib/offsetSeconds'
import { TimeSelect } from './timeSelect'
import { connect } from 'react-redux'

class ExpOffset extends React.Component {
    state = {
        hours: 0,
        minutes: 0,
        seconds: 0,
        days: 0,
        eos: "00:00:00:00"
    }

    componentDidMount() {
        if (this.props.editing) {
            const seconds = this.props.waiver.ExpirationOffSet
            const data = getTimes(seconds)
            this.props.dispatch({ type: TEMP, payload: { offset: data.offset } })
            this.setState({ days: Number(data.d), hours: Number(data.h), minutes: Number(data.m), seconds: Number(data.s) })
        } else {
            const seconds = 0
            const data = getTimes(seconds)
            this.props.dispatch({ type: TEMP, payload: { offset: data.offset } })
        }
    }

    handleChange = name => e => {
        const val = e.target.value
        const { hours, minutes, seconds, days } = this.state
        let offset
        if (name === "seconds") {
            offset = `${days}:${hours}:${minutes}:${val}`
        } else if (name === "minutes") {
            offset = `${days}:${hours}:${val}:${seconds}`
        }
        else if (name === "hours") {
            offset = `${days}:${val}:${minutes}:${seconds}`
        } else if (name === "days") {
            if (val < 0) {
                offset = `${0}:${hours}:${minutes}:${seconds}`
            } else { offset = `${val}:${hours}:${minutes}:${seconds}` }
        }
        if (name === "days" && val < 0) {
            this.setState({
                [name]: 0, eos: offset
            })
        } else {
            this.setState({
                [name]: val, eos: offset
            })
        }
        const os = cleanTimes(offset)
        if (this.props.editing) {
            this.props.dispatch({ type: TEMP, payload: { offset: os, edited: true } })
        } else {
            this.props.dispatch({ type: TEMP, payload: { offset: os } })
        }
    }
    render() {
        const { classes } = this.props
        const { handleChange } = this
        const { hours, minutes, seconds, days } = this.state
        return (
            <div >
                <InputLabel ><Typography variant="caption">Expiration Offset (optional)</Typography></InputLabel>
                <Grid container direction='row' alignItems="center" xs={12} spacing={8}>
                    <Button fullWidth variant="outlined" value={this.props.value} name={this.props.name}>
                        <Grid container direction='row' alignItems="center" xs={12}>
                            <TimeSelect days classes={classes} handleChange={handleChange} value={days} />
                            :    <TimeSelect hours classes={classes} handleChange={handleChange} value={moment(hours, "H").format('H')} />
                            : <TimeSelect minutes classes={classes} handleChange={handleChange} value={moment(minutes, "m").format('m')} />
                            :   <TimeSelect seconds classes={classes} handleChange={handleChange} value={moment(seconds, 's').format("s")} />
                        </Grid>
                    </Button>
                </Grid>
            </div>
        )
    }
}
const mapStateToProps = state => ({})
const mapActionToProps = dispatch => ({
    dispatch
})

export default connect(mapStateToProps, mapActionToProps)(ExpOffset)