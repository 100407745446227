import React from 'react'
import Spinner from '../spinner'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { LinearProgress } from '@material-ui/core';

const LoadingWaivers = () => {
    return (


        <div style={{ maxWidth: '85%' }}>
            <Grid container direction="row" spacing={8}><Grid item xs={1}><div /></Grid><Grid item xs>
                <LinearProgress /></Grid>
                <Grid item xs={1}><div /></Grid>
            </Grid>
            <br />
            <Grid container direction="row" spacing={8}><Grid item xs={1}><div /></Grid><Grid item xs>
                <Typography variant="h6" color="primary">Loading...</Typography></Grid>
                <Grid item xs={1}><div /></Grid>
            </Grid>
        </div>

    )
}
export default LoadingWaivers