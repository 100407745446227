import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import { isNil } from "ramda";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

const styles = theme => ({
  ////newTrial\\\\
  field: {
    position: "relative",
    // display: "flex", ??? (makes larger textfields)
    flex: "column-reverse"
  },
  label: {
    transition: "all .2s"
  },
  input: {
    position: "absolute",
    transition: "all .2s",
    fontSize: 1.5,
    // border: 0,
    border: "1px solid #ccc"
  },
  fieldset: {
    display: "0",
    marginInlineStart: "0",
    marginInlineEnd: "0",
    paddingBlockStart: "0",
    paddingInlineStart: "0",
    paddingInlineEnd: "0",
    paddingBlockEnd: "0",
    minInlineSize: "0",
    borderStyle: "0",
    textAlign: "center"
  },
  addText: {
    marginTop: "-.75rem"
  }
});

//generic Material-UI Formik Input (default is variant=outlined & type=text)
const FormikMaterialInput = ({
  label,
  validateCC,
  placeholder,
  type,
  icon, //left-side Icon
  endIcon, //right-side Icon
  className,
  variant,
  classes,
  errComponent,
  error,
  helperText,
  addText,
  optional,
  disabled,
  field: { name, validateField, value },
  form: { setFieldValue, setFieldTouched, errors, touched },
  ...props
}) => {
  return (
    <div className={classes.fieldset}>
      <TextField
        variant={isNil(variant) ? `outlined` : variant}
        label={label}
        notched="true"
        InputLabelProps={{ shrink: true }}
        InputProps={{
          startAdornment: icon && (
            <InputAdornment position="start">{icon}</InputAdornment>
          ),
          endAdornment: endIcon && (
            <InputAdornment position="end">{endIcon}</InputAdornment>
          )
        }}
        disabled={disabled}
        placeholder={placeholder}
        helperText={touched[name] && errors[name] ? `${errors[name]}` : ""}
        error={touched[name] && Boolean(errors[name])}
        optional={optional}
        validate={validateField}
        className={className}
        name={name}
        value={value || ``}
        onChange={e => {
          setFieldTouched(name, true, true);
          setFieldValue(name, e.target.value);
        }}
        onBlur={e => {
          setFieldTouched(name, true, true);
          setFieldValue(name, e.target.value);
        }}
        type={isNil(type) ? `text` : type}
      />

      {addText && !errors[name] ? (
        <Typography
          className={classes.addText}
          align="center"
          variant="caption"
          color="textPrimary"
        >
          {addText}
        </Typography>
      ) : null}
    </div>
  );
};

export default withStyles(styles)(FormikMaterialInput);

// const useStyles = makeStyles(theme =>
//   createStyles({
//     // root: {
//     //   padding: "2px 4px",
//     //   display: "flex",
//     //   alignItems: "center",
//     //   width: 400
//     // },
//     // input: {
//     //   marginLeft: 8,
//     //   flex: 1
//     // },
//     // iconButton: {
//     //   padding: 10
//     // },
//     // divider: {
//     //   width: 1,
//     //   height: 28,
//     //   margin: 4
//     // }
//     //\\
//     field: {
//       position: "relative",
//       // display: "flex", ??? (makes larger textfields)
//       flex: "column-reverse"
//     },
//     label: {
//       transition: "all .2s"
//     },
//     input: {
//       position: "absolute",
//       transition: "all .2s",
//       fontSize: 1.5,
//       // border: 0,
//       border: "1px solid #ccc"
//     }
//   })
// );

//optional stylesheet which will show different colors based on status:
// const CssTextField = withStyles({
//   root: {
//     "& label.Mui-focused": {
//       color: "green"
//     },
//     "& .MuiInput-underline:after": {
//       borderBottomColor: "green"
//     },
//     "& .MuiOutlinedInput-root": {
//       "& fieldset": {
//         borderColor: "red"
//       },
//       "&:hover fieldset": {
//         borderColor: "yellow"
//       },
//       "&.Mui-focused fieldset": {
//         borderColor: "green"
//       }
//     }
//   }
// })(TextField);
