import React from "react";
import { withStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import PDFIcon from "@material-ui/icons/PictureAsPdf";
import { map, find, findIndex, view, propEq, lensIndex, isEmpty, isNil } from "ramda";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Hover from "../hover";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CheckIcon from "@material-ui/icons/Check";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import ListItemText from '@material-ui/core/ListItemText'
import { over50Years, hasNotHappened, hasHappened, adjustFormatDWT, formatDateWTime, formatDate, includesToday, formatToday } from '../../lib/dateHelpers'
import moment from 'moment'
import Phone from '@material-ui/icons/Phone'
import Email from '@material-ui/icons/Email'
import { formatNumber } from "libphonenumber-js";
import ListItemIcon from '@material-ui/core/ListItemIcon'
import grey from "@material-ui/core/colors/grey"
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
const styles = theme => ({
  root: {
    width: "100%",
    //   maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  badge: {
    top: "50%",
    right: -3
    // The border color match the background color.
  },
  item: {
    width: "100%"
  },
  smColumn: {
    flexBasis: "5%"
  },
  mainColumn: {
    flexBasis: "75%"
  },
  mdColumn: {
    flexBasis: "20%"
  },

  textBox: {
    ...theme.typography.button,
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing.unit
  },
  expired: {
    ...theme.typography.button,
    backgroundColor: red[200],
    padding: theme.spacing.unit
  },
  contact: {
    padding: theme.spacing.unit,
    backgroundColor: grey[200]
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700]
    }
  },
  lefty: {
    flexBasis: "25%"
  },
  righty: {
    flexBasis: "70%"
  },
  chip: {
    margin: theme.spacing.unit
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none"
  },
  dates: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing.unit,
    paddingBottom: theme.spacing.unit
  },
  pItem: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%"
  },
  pRight: {
    marginLeft: "auto"
  },
  signature: {
    // maxWidth: '200px',
    maxHeight: "2rem"
  },
});
const DateList = ({ classes, participant, handleClose, anchorEl }) => {
  let text
  // if (!participant.ActivityDates) {
  //   text = " No Dates "
  // } else 
  if (isEmpty(participant.ActivityDates)) {
    text = " No Dates "
  } else if (participant.ActivityDates.length === 1 && participant.ActivityDates[0].Date === "0001-01-01T00:00:00+00:00") {
    text = " No Dates "
  } else {
    text = "  Dates:  "
  }
  return (
    <div className={classes.dates}>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        key={participant.Id}
      >

        <Typography className={classes.pItem} variant="subtitle1">{text}</Typography>

        {!participant.ActivityDates ? "" : isEmpty(participant.ActivityDates) ? "" :
          participant.ActivityDates.length === 1 && participant.ActivityDates[0].Date === "0001-01-01T00:00:00+00:00" ? "" :
            map(
              d => (
                <MenuItem
                  // key={d.Waiver_Id}
                  key={d.Date}
                  disabled
                >
                  <Typography variant="overline">
                    {formatDate(d.Date)}
                  </Typography>
                </MenuItem>
              ),
              participant.ActivityDates
            )}


      </Menu>
    </div>
  );
};

class HereButton extends React.PureComponent {
  state = {
    anchorEl: null
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes, participant, handleAddDate } = this.props;
    const { anchorEl } = this.state;

    return (
      <div>
        <DateList
          classes={classes}
          participant={participant}
          handleClose={this.handleClose}
          anchorEl={anchorEl}
        />

        <React.Fragment>
          {includesToday(participant.ActivityDates)
            ? (
              <Button
                className={classes.buttonSuccess}
                disabled
                key={participant.Id}
                value={participant.Id}
              >
                <Typography variant="caption">
                  Here Today
                <CheckIcon size="small" />
                </Typography>
              </Button>
            ) : (
              <Button
                id={participant.Id}
                onClick={handleAddDate}
                key={participant.Id}
                value={participant.Id}
              >
                <Typography variant="caption">
                  Here Today
                <i className="material-icons md-18">check_box_outline_blank</i>
                </Typography>
              </Button>
            )}
        </React.Fragment>
        <IconButton onClick={this.handleClick}>
          <ExpandMoreIcon />
        </IconButton>
      </div>
    );
  }
}

class ParticipantList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      participants: []
    };
  }
  componentWillMount() {
    this.setState({ participants: this.props.signedWaiver.Participants });
  }
  handleAddDate = event => {
    const pId = event.currentTarget.id;
    this.props.addActivityDate(pId);
    // const participants = this.props.signedWaiver.Participants
    const p = find(propEq("Id", Number(pId)))(this.state.participants);
    const ps = map(s => ({ id: s.Id }), this.state.participants);
    //finds the index to replace in the array of participants
    const currentp = findIndex(propEq("id", Number(pId)))(ps);
    //gets the current state of the selected participants act dates
    const newpad = view(lensIndex(currentp), this.state.participants)
      .ActivityDates;
    let pad
    if (newpad.length === 1 && newpad[0].Date === "0001-01-01T00:00:00+00:00") {
      pad = []
    } else {
      pad = newpad
    }
    //adds the new date obj to the other dates
    const newPad = pad.concat({
      Date: formatToday,
      Participant_Id: Number(pId)
    });
    //replaces the old dates array with the new one
    const newPObj = Object.assign({}, p, { ActivityDates: newPad });
    //updates current participants
    const newPArray = Object.assign([], this.state.participants, {
      [currentp]: newPObj
    });
    this.setState({ participants: newPArray });
    //updates current sw
    const newSW = Object.assign({}, this.props.signedWaiver, {
      Participants: newPArray
    });
    // this.props.dispatch({ type: SIGNED_WAIVER_SET, payload: newSW })
    //replaces the old sw with the updated one
    const newSWArray = Object.assign([], this.props.swArray, {
      [this.props.activeStep]: newSW
    });
    // this.setState({ participants: newPArray, activeSW: newSW, swArray: newSWArray })
    this.props.handleUpdateSWArray(newSWArray);
    // this.props.dispatch({ type: SET_SW_ARRAY, payload: newSWArray })
  };
  handlePdf = event => {
    const pId = event.currentTarget.id;
    this.props.getParticipantPdf(pId);
    const pp = find(
      propEq("participantId", Number(pId)),
      this.props.swAttachments.participantPdfs
    );
    window.open(pp.pdfUrl, `_blank`);
  };

  render() {
    const {
      classes,
      signedWaiver,
      swAttachments
    } = this.props;
    const { participants } = this.state;
    let heading;
    if (participants && participants.length === 0) {
      heading = "Participant";
    } else {
      heading = "Participants";
    }
    let onclick
    if (!failed) {
      onclick = this.handlePdf
    }
    let background
    if (failed) {
      background = "disabled"
    }
    let message
    if (failed) {
      message = "Failed To Load PDF"
    } else {
      message = "Click To View PDF"
    }
    const failed = !swAttachments.participantPdfs && swAttachments.failed
    return (
      <div className={classes.root}>
        <Typography variant="title" gutterBottom>
          {heading}
        </Typography>
        <br />
        {map(
          p => (
            <Paper className={classes.pItem} key={p.Id}>
              <Hover
                message={message}
                component={
                  <Button fullWidth onClick={onclick} id={p.Id}>
                    <PDFIcon color={background} />
                    {p.ParticipantName}
                  </Button>
                }
              />
              {!isNil(p.Birthday) ? (<Typography variant="caption" >DOB: {moment(p.Birthday).format('ll')}</Typography>) : ''}
              <div className={classes.pRight}>
                <HereButton
                  id={p.Id}
                  participant={p}
                  classes={classes}
                  handleAddDate={this.handleAddDate}
                />
              </div>
            </Paper>
          ),
          participants
        )}
        <ListItem >
          <ListItemText primary={`Signed by: ${signedWaiver.SignedByName}`}
            secondary={`Signed on: ${adjustFormatDWT(signedWaiver.Signed)}`} />
        </ListItem>
        {!isNil(signedWaiver.ContactEmail) || !isNil(signedWaiver.ContactPhone) || (signedWaiver.EmergencyPhone && !isNil(signedWaiver.EmergencyPhone)) ?
          <Grid container direction="row" justify="space-between">

            <Grid item xs>      <Paper className={classes.contact} elevation={0}>
              <Typography >Contact Info</Typography>
              {!isNil(signedWaiver.ContactEmail) ? (
                <ListItem dense disableGutters>
                  <ListItemIcon><Email fontSize="small" color="primary" /></ListItemIcon>
                  <ListItemText primary={signedWaiver.ContactEmail} />
                </ListItem>
              ) : ''}
              {!isNil(signedWaiver.ContactPhone) ? (
                <ListItem dense disableGutters>
                  <ListItemIcon><Phone fontSize="small" color="primary" /></ListItemIcon>
                  <ListItemText primary={formatNumber(
                    {
                      country: "US",
                      phone: `${signedWaiver.ContactPhone}`
                    },
                    "National"
                  )} />
                </ListItem>
              ) : ''}
              {signedWaiver.EmergencyPhone && !isNil(signedWaiver.EmergencyPhone) ? (
                <ListItem dense disableGutters>
                  <ListItemIcon><Phone fontSize="small" color="error" /></ListItemIcon>
                  <ListItemText secondary="Emergency Contact" primary={formatNumber(
                    {
                      country: "US",
                      phone: `${signedWaiver.EmergencyPhone}`
                    },
                    "National"
                  )} />
                </ListItem>
              ) : ''}
            </Paper > </Grid>
            <Hidden smDown><Grid item xs={4}></Grid></Hidden>
            <Hidden mdDown><Grid item xs={4}></Grid></Hidden>
          </Grid>
          : ''}
        {signedWaiver.Expiration &&
          over50Years(signedWaiver.Expiration)
          ? ("") : signedWaiver.Expiration &&
            hasNotHappened(signedWaiver.Expiration)
            ? (
              <ListItem>Expires: {formatDateWTime(signedWaiver.Expiration)}
              </ListItem>
            ) : signedWaiver.Expiration &&
              hasHappened(signedWaiver.Expiration)
              ? (
                <div className={classes.expired}>
                  {`Expired on: ${formatDateWTime(signedWaiver.Expiration)}`}
                </div>
              ) : (
                <div />
              )}
      </div>
    );
  }
}
const mapStateToProps = state => ({});
const mapActionsToProps = dispatch => ({
  dispatch
});
const connector = connect(
  mapStateToProps,
  mapActionsToProps
);
export default connector(withStyles(styles)(ParticipantList));
