import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Tooltip from "@material-ui/core/Tooltip";
const EnhancedTableHead = props => {
  const { order, orderBy, columnData, disabled, className, color, size, padding, classes, } = props;

  //   const createSortHandler = property => event => {
  //     onRequestSort(event, property);
  //   };

  return (
    <React.Fragment>

      <TableHead className={className}>
        <TableRow >
          {columnData.map(column => {
            return (
              <>
                {column.sortColumn ? (
                  <TableCell
                    size={size}
                    color={color}
                    key={column.id}
                    align={column.align}
                    padding={padding ? padding : column.disablePadding ? "none" : column.checkbox ? "checkbox" : "default"}
                    sortDirection={orderBy === column.id ? order : false}
                  >
                    <Tooltip
                      title="Sort"
                      placement={column.numeric ? "bottom-end" : "bottom-start"}
                      enterDelay={300}
                      disableTouchListener={disabled}
                      disableHoverListener={disabled}
                      disableFocusListener={disabled}
                    >
                      <TableSortLabel
                        id={column.id}
                        active={orderBy === column.id}
                        direction={order}
                        onClick={props.createSortHandler}
                      // onClick={() => createSortHandler(column.id)}
                      >
                        {column.label}
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>) : (<TableCell size={size} key={column.id} align={`${column.align}`}
                    // align={column.align}
                    padding={padding ? padding : column.disablePadding ? "none" : "default"}>{column.label}</TableCell>)}
              </>
            );
          }, this)}
        </TableRow>
      </TableHead>
    </React.Fragment>
  );
};

export default EnhancedTableHead;
