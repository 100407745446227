import React, { Component } from "react";
import { connect } from "react-redux";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import LinearProgress from "@material-ui/core/LinearProgress";
import SigningStatementPanel from "../../components/signingStatementPanel";
import {
  panelClicked,
  signingStatementAction,
  signingStatementPost
} from "../../action-creators/signingStatements";
import { SIGNING_STATEMENTS_EXITED } from "../../constants";
import { concat, map, not, equals, isEmpty } from "ramda";

import "typeface-roboto";

const emptySigningStatement = [
  {
    Company: "",
    Company_Id: "",
    Created: "",
    CreatedBy: 0,
    Id: 0,
    Statement: "",
    Updated: "",
    UpdatedBy: 0,
    Usage: ""
  }
];
class SigningStatements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  render() {
    const {
      classes,
      isError,
      isFetching,
      isSaved,
      signingStatements,
      statements
    } = this.props;

    return not(equals(statements, {})) ? (
      <div>
        <Card className={classes.card}>
          <CardHeader title="Signing Statements" />
        </Card>
        <br />
        {map(
          waiver => (
            <SigningStatementPanel
              key={waiver.Id}
              statementId={waiver.Id}
              labelValue={waiver.Usage}
              heading={waiver.Usage}
              statement={waiver.Statement}
              //   expanded={this.props.expandPanel}
              expanded={this.props.expandPanel}
              panelName={waiver.Id}
              classes={classes}
              statements={statements}
              handleChange={this.props.handleChange}
              isFetching={isFetching}
              isSaved={isSaved}
              isError={isError}
              signingStatementAction={this.props.signingStatementAction}
              signingStatementPost={this.props.signingStatementPost}
            />
          ),
          concat(statements, emptySigningStatement)
        )}
      </div>
    ) : (
      <div className={classes.root}>
        <Card className={classes.card}>
          <CardHeader title="Signing Statements" />
        </Card>
        {!this.state.loading || !isEmpty(this.props.currentCompanyWaivers) ? (
          <Divider />
        ) : (
          <LinearProgress />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentCompanyWaivers: state.currentCompanyWaivers,
  signingStatements: state.signingStatements,
  statements: state.signingStatements.statements,
  expandPanel: state.signingStatements.panels,
  isSaved: state.signingStatements.isSaved,
  isFetching: state.signingStatements.isFetching,
  isError: state.signingStatements.isError
});

const mapActionsToProps = dispatch => ({
  handleChange: name => {
    dispatch(panelClicked(name));
  },
  signingStatementAction: (statement, id) => {
    dispatch(signingStatementAction(statement, id));
  },
  signingStatementPost: (statement, usage) => {
    dispatch(signingStatementPost(statement, usage));
  },
  signingStatementExited: () => dispatch({ type: SIGNING_STATEMENTS_EXITED })
});

const connector = connect(
  mapStateToProps,
  mapActionsToProps
);

export default connector(SigningStatements);
