import React from "react";
import { connect } from "react-redux";
import { reduce } from "ramda";
import { createSelector } from "reselect";
import MenuAppBar from "../../components/menuAppBar";
import withDrawer from "../../components/withDrawer";
import withFooter from "../../components/withFooter";
import EnhancedTableUsage from "../../components/usage/enhancedTableUsage";
import { getWaiverContacts, getWaiverUsage } from "../../action-creators/usage";

const columnGraphData = [
  { id: "key", align: "inherit", disablePadding: false, label: "" },
  {
    id: "waiver-name",
    align: "inherit",
    disablePadding: false,
    label: "Waiver name"
  },
  {
    id: "signed-waivers",
    align: "right",
    disablePadding: false,
    label: "Signed waivers"
  }
];

const columnContactData = [
  {
    id: "WaiverName",
    align: "inherit",
    disablePadding: false,
    label: "Waiver"
  },
  {
    id: "SignedByName",
    align: "inherit",
    disablePadding: false,
    label: "Signed by"
  },
  {
    id: "ContactEmail",
    align: "inherit",
    disablePadding: false,
    label: "Email"
  },
  { id: "ContactPhone", align: "inherit", disablePadding: false, label: "Phone" },
  { id: "OptIn", align: "inherit", disablePadding: false, label: "Opted-in" },
  { id: "Signed", align: "inherit", disablePadding: false, label: "Signed on" }
];

// selector
const waiverUsage = state => state.waiverUsage.usage;

const now = new Date();
const month =
  now.getMonth() + 1 < 10 ? `0${now.getMonth() + 1}` : now.getMonth() + 1;
const todaysDate = `${now.getFullYear()}-${month}-${now.getDate()}`;
const startDate = `${now.getFullYear()}-${month}-01`;

// reselect function -> Should probably go on its own functional page.
export const selectWaiverUsageCount = createSelector(
  [waiverUsage],
  waiverUsage => {
    if (waiverUsage) {
      return reduce(
        (accu, x) => {
          accu += x.count;
          return accu;
        },
        0,
        waiverUsage
      );
    }
    return;
  }
);

const Usage = props => {
  const {
    addUser,
    users,
    waiverUsage,
    waiverContacts,
    selectWaiverUsageCount,
    getWaiverContacts,
    getWaiverUsage
  } = props;
  return (
    <React.Fragment>
      <MenuAppBar userYes={true} companyYes={true} />
      <EnhancedTableUsage
        addUsage
        columnGraphData={columnGraphData}
        users={users}
        selectWaiverUsageCount={selectWaiverUsageCount}
        columnContactData={columnContactData}
        waiverUsage={waiverUsage}
        waiverContacts={waiverContacts}
        getWaiverContacts={getWaiverContacts}
        getWaiverUsage={getWaiverUsage}
        todaysDate={todaysDate}
        startDate={startDate}
      />
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  usage: state.waiverUsage,
  waiverUsage: state.waiverUsage.usage,
  waiverContacts: state.waiverUsage.contacts,
  selectWaiverUsageCount: selectWaiverUsageCount(state)
});

const mapActionsToProps = dispatch => {
  return {
    getWaiverUsage: (startingDate, stoppingDate) =>
      dispatch(getWaiverUsage(startingDate, stoppingDate)),
    getWaiverContacts: (startingDate, stoppingDate) =>
      dispatch(getWaiverContacts(startingDate, stoppingDate))
  };
};

const connector = connect(
  mapStateToProps,
  mapActionsToProps
);

export default withDrawer(withFooter(connector(Usage)));
