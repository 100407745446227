import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import Phone from '@material-ui/icons/Phone'
import Email from '@material-ui/icons/Email'
import Laptop from '@material-ui/icons/Laptop'
import Fab from '@material-ui/core/Fab'
import Hover from '../hover'
import { Formik, Field } from 'formik'
import CustomIntlTelInput from '../authentication/phoneNumber'
import { isNil, clone, findIndex, mergeDeepRight, propEq, equals } from 'ramda'
import TextField from '@material-ui/core/TextField'
import { Debug } from '../../lib/Debug'
import DialogContent from '@material-ui/core/DialogContent'
import EditIcon from "@material-ui/icons/Edit";
import PersonAdd from '@material-ui/icons/PersonAdd';
import { validateEmail, validateContactPhone, validateContactName } from '../../lib/validation';
import { COMPANIES_RETRIEVED } from '../../constants';
const ContactInfo = ({ company, classes, handleClose, button, buttonFn }) => (
    <DialogContent>
        <List dense key={`${company.Id} - ${company.Name}`}>
            <ListItem key={company.ContactName}>
                <ListItemAvatar>
                    <Avatar className={classes.avatar}>
                        <PersonIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={company.ContactName} />
            </ListItem>
            <ListItem key={company.ContactEmail}>
                <ListItemAvatar>
                    <Avatar className={classes.avatar}>
                        <Email />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={company.ContactEmail} />
            </ListItem>


            <ListItem key={company.ContactPhone}
            >
                <ListItemAvatar>
                    <Avatar className={classes.avatar}>
                        <Phone />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={company.ContactPhone} />
            </ListItem>
            <ListItem key={company.Website}
            >
                <ListItemAvatar>
                    <Avatar className={classes.avatar}>
                        <Laptop />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={company.Website} />
            </ListItem>
            <ListItem>
                <Button onClick={handleClose} color="primary">
                    Cancel
            </Button>

                <Button onClick={buttonFn} variant="contained" className={classes.button} color="primary" autoFocus>
                    {button}
                </Button>
            </ListItem>
        </List>
    </DialogContent>
)
const ContactInfoFields = ({ classes, handleSubmit, errors, touched, handleChange, values: { ContactName,
    ContactEmail, ContactPhone, Website }, isValid, isSubmitting, setFieldTouched, setFieldValue, handleClose, button, buttonFn, company }) => {

    const change = (name, e) => {
        e.persist();
        handleChange(e);
        // console.log(errors);
        setFieldTouched(name, true, false);
    };
    let sysAdmin
    if (localStorage.isAuth && Number(localStorage.isAuth) === 1) {
        sysAdmin = true
    } else {
        sysAdmin = false
    }
    return (
        <DialogContent >
            <form onSubmit={handleSubmit}>
                <List dense>

                    <ListItem >

                        <Avatar className={classes.avatar}>
                            <PersonIcon />
                        </Avatar>
                        <Field
                            component={TextField}

                            id="ContactName"
                            name="ContactName"
                            fullWidth
                            validate={sysAdmin || validateContactName}
                            helperText={touched.ContactName ? errors.ContactName : ""}
                            error={touched.ContactName && Boolean(errors.ContactName)}
                            label="Contact Name"
                            variant="filled" InputLabelProps={{
                                shrink: true,
                            }} value={ContactName}
                            onChange={change.bind(null, "ContactName")} margin="normal" />
                    </ListItem>

                    <ListItem >
                        <ListItemAvatar>
                            <Avatar className={classes.avatar}>
                                <Email />
                            </Avatar>
                        </ListItemAvatar>
                        <Field id="ContactEmail" component={TextField} InputLabelProps={{
                            shrink: true,
                        }} name="ContactEmail" fullWidth helperText={touched.ContactEmail ? errors.ContactEmail : ""}
                            error={touched.ContactEmail && Boolean(errors.ContactEmail)}
                            label="Contact Email" variant="filled" value={ContactEmail} onChange={change.bind(null, "ContactEmail")} margin="normal"
                            validate={sysAdmin || validateEmail} />
                    </ListItem>
                    <ListItem
                    >
                        <ListItemAvatar>
                            <Avatar className={classes.avatar}>
                                <Phone />
                            </Avatar>
                        </ListItemAvatar>
                        <Field
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputClass={classes.textField}
                            name="ContactPhone"
                            id="ContactPhone"
                            component={CustomIntlTelInput}
                            value={ContactPhone}
                            preferredCountries={[`us`, `gb`, `ca`]}
                            css={classes.textField}
                            inputClass={classes.container}
                            className={classes.textField}
                            containerClassName={classes.container}
                            defaultValue={ContactPhone}
                            validate={sysAdmin || validateContactPhone}
                        />

                        {errors.ContactPhone && touched.ContactPhone && <div>
                            <ListItemText color="secondary" secondary={errors.ContactPhone} /></div>}
                    </ListItem>
                    <ListItem
                    >
                        <ListItemAvatar>
                            <Avatar className={classes.avatar}>
                                <Laptop />
                            </Avatar>
                        </ListItemAvatar>
                        {/* <WebsiteField classes={classes} /> */}
                        <Field id="Website" component={TextField} name="Website" fullWidth helperText={touched.Website ? errors.Website : ""}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={touched.Website && Boolean(errors.Website)}
                            label="Company Website" variant="filled" value={Website} onChange={change.bind(null, "Website")} margin="normal" />

                    </ListItem>
                    <ListItem>
                        <Button onClick={handleClose} className={classes.button} color="primary">
                            Cancel
            </Button>
                        <Button type="submit" className={classes.button} variant="contained" disabled={!isValid} color="primary" autoFocus>
                            {button}
                        </Button>
                    </ListItem>

                </List>
            </form>
        </DialogContent>
    )
}

class ContactCard extends React.PureComponent {
    state = {
        open: false,
        openEdit: false,
        editing: false,
        currentId: null,
        ContactName: "",
        ContactEmail: "",
        ContactPhone: "",
        Website: ""
    }


    handleClickOpen = (ev) => {
        const { company } = this.props
        if (!equals(Number(ev.currentTarget.id), Number(this.props.currentCompany.id))) {
            this.props.selectCompany(Number(ev.currentTarget.id))
            this.setState({ open: true, currentId: Number(ev.currentTarget.id), openEdit: false, ContactEmail: company.ContactEmail, ContactName: company.ContactName, ContactPhone: company.ContactPhone, Website: company.Website });

        } else {
            this.setState({ open: true, currentId: Number(ev.currentTarget.id), openEdit: false, ContactEmail: company.ContactEmail, ContactName: company.ContactName, ContactPhone: company.ContactPhone, Website: company.Website });
        }

    }
    handleopenedit = (ev) => {
        if (!equals(Number(ev.currentTarget.id), Number(this.props.currentCompany.id))) {
            this.props.selectCompany(Number(ev.currentTarget.id))
            this.setState({ open: false, openEdit: true, editing: true, currentId: Number(ev.currentTarget.id) });
        } else {
            this.setState({ open: false, openEdit: true, editing: true, currentId: Number(ev.currentTarget.id) });
        }
    }
    handleedit = (ev) => {
        this.setState({ open: false, openEdit: true, editing: true, })
    }
    handleFieldChange = (name, e) => {
        const { handleChange, setFieldTouched } = this.props;
        e.persist();
        handleChange(e);
        setFieldTouched(name, true, false);
    };
    submitValues = ({ ContactName, ContactEmail, ContactPhone, Website }) => {
        const company = this.props.company
        const newContact = { ContactName: ContactName, ContactEmail, ContactPhone: ContactPhone, Website: Website }

        const newCompany = mergeDeepRight(this.props.companyPaymentDetails.data.Company, newContact)
        this.props.editCompany(newCompany)
        const compIndex = findIndex(propEq("Id", newCompany.Id), this.props.companies)
        const cs = clone(this.props.companies)
        const newCs = Object.assign([], cs, { [compIndex]: newCompany })
        this.props.dispatch({ type: COMPANIES_RETRIEVED, payload: newCs })
        this.handleClose()
    }
    handleClose = () => {
        this.setState({
            open: false, openEdit: false, editing: false, currentId: null, ContactName: "",
            ContactEmail: "",
            ContactPhone: "",
            Website: ""
        });
    }
    // startEdit = (e) => {
    //     this.setState({ editing: true, openEdit: true, open: false })
    // }
    render() {
        const { company = {}, classes } = this.props
        const { open, openEdit } = this.state
        const { handleClickOpen, handleClose } = this
        let title
        let button
        let buttonFn
        if (this.state.editing) {
            //  title = "Contact Information"
            title = company.Name
            button = "Save"
            buttonFn = this.submitValues
        } else {
            title = company.Name
            button = <EditIcon />
            buttonFn = this.handleedit
        }
        let message
        let color
        let onclick
        let icon
        if (isNil(company.ContactName) && isNil(company.ContactEmail)) {
            message = "Add contact information"
            color = "secondary"
            onclick = this.handleopenedit
            icon = <PersonAdd />
        } else {
            message = "View company contact information"
            color = "primary"
            onclick = handleClickOpen
            icon = <PersonIcon />
        }
        const { ContactName, ContactPhone, ContactEmail, Website } = this.state
        const values = { ContactName, ContactPhone, ContactEmail, Website }

        return (
            <div key={company.Id}>
                <Hover message={message} component={
                    <Fab color={color} onClick={onclick} id={company.Id} size="small">{icon}</Fab>} />
                <Dialog
                    open={open || openEdit}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >

                    <DialogTitle key={company.Id} id="simple-dialog-title">{title}</DialogTitle>

                    <React.Fragment>
                        {this.state.openEdit ? (

                            <Formik enableReinitialize={true}
                                key={company.Id}
                                render={props => (
                                    <ContactInfoFields handleClose={handleClose} button={button} buttonFn={buttonFn} company={company} classes={classes} {...props} />
                                )} initialValues={values} onSubmit={this.submitValues} />
                        ) : this.state.open && !this.state.openEdit ?
                                <ContactInfo company={company} classes={classes} handleClose={handleClose} button={button} buttonFn={buttonFn} /> : ""}
                    </React.Fragment>

                </Dialog>
            </div>
        );
    }
}
export default ContactCard;




