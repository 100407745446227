import React, { Component } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import { Form, Field, Formik } from "formik";
import CustomizedSnackbars from "../../components/snackbar";
import FormikCheckbox from "../../components/formikCheckbox";
import { companySchema, validateEmail } from "../../lib/validation";
import { equals, isEmpty, isNil, or, map } from "ramda";
import FormikMaterialInput from "../../components/formikMaterialInput";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
const pic = require("../../images/aWaiver_Logo_w_Text_80x28.png");

// <img src={props.company.Company.ImageUrl.substring(0,props.company.Company.ImageUrl.indexOf('?'))} />
class CompanyDetailsTest extends Component {
    state = {
        hoverPicture: false,
        open: false,
        stateEditCompany: {
            Id: null,
            Name: "",
            Email: "",
            Description: "",
            PrimaryLocation: "",
            ExternalSystem: ""
        },
        externalLinkedSystem_Id: -1,
        externalSystems: [
            { label: "none", value: -1 },
            { label: "Indexic Event Book Manager", value: 1 }
        ]
    };

    mouseEnter = () => {
        this.setState({ hoverPicture: true });
    };

    mouseLeave = () => {
        this.setState({ hoverPicture: false });
    };

    handleChange = (name, setFieldValue) => event => {
        setFieldValue(`${name}`, event.target.value);
        this.setState({ ...this.state, [name]: event.target.value });
    };

    render() {
        const {
            classes,
            company,
            editCompany,
            failed,
            isError,
            isSaved,
            isSaving,
            locations
        } = this.props;

        return or(isNil(company), failed) ?
            <div>
                <Card className={classes.card}>
                    <CardHeader
                        avatar={
                            <Avatar
                                aria-label="company Logo"
                                className={classes.avatar}
                                src={pic}
                            />
                        }
                        action={
                            <img
                                style={{ paddingTop: "20px", paddingRight: "20px" }}
                                src={pic}
                            />
                        }
                        title="Detailed Company Information Failed To Load. Please try again momentarily."
                        subheader={<Typography>{moment().format("LL")}</Typography>}
                    />
                </Card>
            </div>
            :
            <div>
                <Card className={classes.card}>
                    <CardHeader
                        avatar={
                            <Avatar
                                aria-label="company Logo"
                                src={company.ImageUrl}
                                className={classes.avatar}
                            />
                        }
                        action={
                            <img
                                style={{ paddingTop: "20px", paddingRight: "20px" }}
                                src={pic}
                            />
                        }
                        title="Detailed Company Information"
                        subheader={moment().format("LL")}
                    />

                    <CardContent>
                        <Formik
                            enableReinitialize={true}
                            initialValues={company}
                            validationSchema={companySchema}
                            onSubmit={(values, { resetForm }) => {
                                editCompany(values);
                            }}
                        >
                            {({
                                errors,
                                touched,
                                resetForm,
                                setFieldValue,
                                setFieldTouched,
                                isSubmitting,
                                values,
                                initialValues
                            }) => (
                                    <Form>
                                        <Grid container spacing={12}>
                                            <Grid item xs={12} className={classes.grid}>
                                                <Field
                                                    //className={classes.checkBox}
                                                    name="Active"
                                                    label="Active"
                                                    component={FormikCheckbox}
                                                />
                                                <Typography variant={"caption"}>Company must be active to sign new waivers.  Uncheck active if you no longer want to sign new waivers and to stop future billings.</Typography>
                                            </Grid>

                                            <Grid item xs={12} className={classes.grid}>
                                                <Field
                                                    label="Company Name:"
                                                    className={classes.textFieldOutlined}
                                                    name="Name"
                                                    type="text"
                                                    error={errors.Name}
                                                    errComponent="div"
                                                    component={FormikMaterialInput}
                                                />
                                            </Grid>

                                            <Grid item xs={12} className={classes.grid}>
                                                <div className={classes.central}>
                                                    <Field
                                                        className={classes.textFieldOutlined}
                                                        name="ImageBlobName"
                                                        onChange={e => {
                                                            setFieldTouched(`ImageUrl`, true, true);
                                                            setFieldValue(`ImageBlobName`, e.target.files[0]);
                                                            setFieldValue(`ImageUrl`, e.target.files[0].name);
                                                        }}
                                                        type="file"
                                                        component={TextField}
                                                        variant="outlined"
                                                        label="Company Image or Logo"
                                                        InputLabelProps={{ shrink: true }}
                                                    />
                                                </div>
                                            </Grid>

                                            <Grid item xs={12} className={classes.grid}>
                                                <Field
                                                    className={classes.textFieldOutlined}
                                                    name="BillingEmail"
                                                    type="email"
                                                    validate={validateEmail}
                                                    component={FormikMaterialInput}
                                                    errComponent="div"
                                                    label="Email Address For Receipts:"
                                                    error={errors.BillingEmail}
                                                />
                                            </Grid>

                                            <Grid item xs={12} className={classes.grid}>
                                                <Field
                                                    className={classes.textFieldOutlined}
                                                    name="Description"
                                                    type="text"
                                                    component={FormikMaterialInput}
                                                    label="Description of Service:"
                                                />
                                            </Grid>

                                            <Grid item xs={12} className={classes.grid}>
                                                <div className={classes.central}>
                                                    <FormControl
                                                        variant="outlined"
                                                        className={classes.formControl}
                                                    >
                                                        <InputLabel htmlFor="PrimaryLocation_Id">
                                                            Primary Location:
                            </InputLabel>
                                                        <Field
                                                            disabled
                                                            name="PrimaryLocation_Id"
                                                            component={Select}
                                                            value={values.PrimaryLocation_Id || ``}
                                                            onChange={this.handleChange(
                                                                "PrimaryLocation_Id",
                                                                setFieldValue
                                                            )}
                                                            variant="outlined"
                                                            input={
                                                                <OutlinedInput
                                                                    labelWidth={120}
                                                                    name="PrimaryLocation_Id"
                                                                />
                                                            }
                                                            inputProps={{
                                                                name: "PrimaryLocation_Id",
                                                                id: "PrimaryLocation_Id"
                                                            }}
                                                        >
                                                            {!isNil(locations)
                                                                ? map(
                                                                    location => (
                                                                        <MenuItem
                                                                            value={location.Id}
                                                                            key={location.Id}
                                                                        >
                                                                            {location.Address}
                                                                        </MenuItem>
                                                                    ),
                                                                    locations
                                                                )
                                                                : ``}
                                                        </Field>
                                                        {errors.PrimaryLocation &&
                                                            touched.PrimaryLocation ? (
                                                                <div>{errors.PrimaryLocation}</div>
                                                            ) : null}
                                                    </FormControl>
                                                </div>
                                            </Grid>

                                            <Grid item xs={12} className={classes.grid}>
                                                <div className={classes.central}>
                                                    <FormControl
                                                        variant="outlined"
                                                        className={classes.formControl}
                                                    >
                                                        <InputLabel htmlFor="ExternalLinkedSystem_Id">
                                                            External System:
                            </InputLabel>
                                                        <Field
                                                            name="ExternalLinkedSystem_Id"
                                                            component={Select}
                                                            onChange={this.handleChange(
                                                                "ExternalLinkedSystem_Id",
                                                                setFieldValue
                                                            )}
                                                            variant="outlined"
                                                            value={values.ExternalLinkedSystem_Id || -1}
                                                            input={
                                                                <OutlinedInput
                                                                    labelWidth={120}
                                                                    name="ExternalLinkedSystem_Id"
                                                                />
                                                            }
                                                        >
                                                            {map(
                                                                xs => (
                                                                    <MenuItem key={xs.value} value={xs.value}>
                                                                        {xs.label}
                                                                    </MenuItem>
                                                                ),
                                                                this.state.externalSystems
                                                            )}
                                                        </Field>
                                                        {errors.ExternalLinkedSystem &&
                                                            touched.ExternalLinkedSystem ? (
                                                                <div>{errors.ExternalLinkedSystem}</div>
                                                            ) : null}
                                                    </FormControl>
                                                </div>
                                            </Grid>

                                            <Grid container spacing={16} className={classes.central}>
                                                <Field
                                                    name="GetBirthday"
                                                    className={classes.checkBox}
                                                    component={FormikCheckbox}
                                                    label="Collect birthdays from waiver signers"
                                                />

                                                <Field
                                                    name="GetEmergencyPhone"
                                                    className={classes.checkBox}
                                                    component={FormikCheckbox}
                                                    label="Collect emergency phone numbers from participants"
                                                />

                                                <Field
                                                    name="GetContactInformation"
                                                    className={classes.checkBox}
                                                    component={FormikCheckbox}
                                                    label="Collect email address and phone number from waiver signers"
                                                />
                                            </Grid>
                                        </Grid>

                                        <div className={classes.cardButtons}>
                                            <CardActions>
                                                <Button
                                                    classes={{ label: classes.label }}
                                                    className={classes.button}
                                                    variant="contained"
                                                    onClick={() =>
                                                        resetForm({
                                                            Name: "",
                                                            Email: "",
                                                            Description: "",
                                                            PrimaryLocation: "",
                                                            ExternalSystem: "none",
                                                            GetBirthday: false,
                                                            GetContactInformation: false,
                                                            GetEmergencyPhone: false
                                                        })
                                                    }
                                                >
                                                    Clear
                        </Button>
                                                <Button
                                                    classes={{ label: classes.label }}
                                                    className={classes.button}
                                                    variant="contained"
                                                    onClick={() => resetForm(company)}
                                                >
                                                    Reset
                        </Button>
                                                <Button
                                                    disabled={or(
                                                        isSubmitting,
                                                        or(equals(initialValues, values), !isEmpty(errors))
                                                    )}
                                                    classes={{ label: classes.label }}
                                                    className={classes.button}
                                                    variant="contained"
                                                    type="submit"
                                                >
                                                    Save Company
                        </Button>
                                            </CardActions>
                                        </div>
                                        {/* <Debug /> */}
                                    </Form>
                                )}
                        </Formik>
                        {isError && (
                            <CustomizedSnackbars
                                message="New Company Failed to Save. Please try again."
                                snackType="error"
                            />
                        )}
                        {isSaving && (
                            <CustomizedSnackbars
                                message="New Company is Loading..."
                                snackType="info"
                            />
                        )}
                        {isSaved && (
                            <CustomizedSnackbars
                                message="Company Details Edited Successfully!"
                                snackType="success"
                            />
                        )}
                    </CardContent>
                </Card>
            </div>;
    }
}

export default CompanyDetailsTest;
