import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Badge from "@material-ui/core/Badge";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import SWPanel from "./signedWaiversList";
import TabContainer from "../tabContainer";
import { map, sum, length, gt } from "ramda";
import grey from '@material-ui/core/colors/grey'
import Spinner from '../spinner'
import LinearProgress from '@material-ui/core/LinearProgress'
const SimpleTabs = ({
  classes,
  searchedSW,
  pendingSW,
  recentSW,
  value,
  handleChange, ind
}) => {
  const psws = sum(map(w => length(w.SignedWaivers), pendingSW));
  const rsws = sum(map(w => length(w.SignedWaivers), recentSW));
  const ssws = sum(map(w => length(w.SignedWaivers), searchedSW));
  let totalNumberOfSearchSignedWaiverCapped = ssws >= 999 ? "999" : ssws;
  let totalNumberOfPendingSignedWaiversCapped = psws >= 999 ? "999" : psws;
  let totalNumberOfRecentlySignedWaiversCapped = rsws >= 999 ? "999" : rsws;
  let color
  let content
  if (ind.fetching) {

  } else if (ssws === 0) {
    color = "error"
    content = "!"
  } else {
    color = "primary"
    content = totalNumberOfSearchSignedWaiverCapped
  }
  let searchedMessage
  if (ind.fetching) {
    searchedMessage = "Searching..."
  } else {
    searchedMessage = "Search Results"
  }
  return (
    <div className={classes.margin}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab
          label={
            <Grid container spacing={16}>
              <Grid item>
                <Badge
                  className={classes.padding}
                  color="primary"
                  badgeContent={totalNumberOfPendingSignedWaiversCapped}
                >
                  {" "}
                  <Typography color="primary" style={{ fontWeight: "bold" }} variant="button">Pending Approval</Typography>
                </Badge>
              </Grid>
            </Grid>
          }
          className={classes.tab}
        />
        <Tab
          className={classes.tab}
          label={
            <Grid container spacing={16}>
              <Grid item>
                <Badge
                  className={classes.padding}
                  color="primary"
                  badgeContent={totalNumberOfRecentlySignedWaiversCapped}
                >
                  {" "}
                  <Typography color="primary" style={{ fontWeight: "bold" }} variant="button">Recently Signed</Typography>
                </Badge>
              </Grid>
            </Grid>
          }
        />

        {value === 2 ? (
          <Tab
            className={classes.tab}
            label={
              <Grid container spacing={16}>
                <Grid item>
                  <Badge
                    className={classes.padding}
                    color={color}
                    badgeContent={content}
                  >
                    <Typography color="primary" style={{ fontWeight: "bold" }} variant="button">{searchedMessage}</Typography>
                  </Badge>
                </Grid>
              </Grid>
            }
          />
        ) : (
            ""
          )}
      </Tabs>

      {value === 0 && (
        <TabContainer>
          <SWPanel />
        </TabContainer>
      )}
      {value === 1 && (
        <TabContainer>
          <SWPanel recentlySigned={true} />
        </TabContainer>
      )}
      {value === 2 && (
        <TabContainer>
          <React.Fragment>
            {ind.fetching ?
              <div className={classes.root}>
                <LinearProgress
                />
                <br />

                <LinearProgress />
                <br />
                <Typography variant="h6">Please wait while your search results load...</Typography>
              </div>
              : gt(ssws, 0) ? (
                <SWPanel searchSigned={true} />
              ) : (
                  <div>
                    <Typography align="center" variant="subtitle1">No Search Results Have Been Returned At This Time. </Typography>
                    <Typography align="center" variant="subtitle1">
                      Please check your search settings.
                </Typography></div>

                )}{" "}
          </React.Fragment>
        </TabContainer>
      )}
    </div>
  );
};

export default SimpleTabs;
