import {
  CURRENT_WAIVER_LOADING_STARTED,
  CURRENT_WAIVER_LOADING_FAILED,
  NEW_WAIVER_FORM_UPDATED,
  WAIVERS_REQUESTED,
  WAIVERS_FAILED,
  NEW_WAIVER_SAVE_FAILED,
  NEW_WAIVER_SAVE_STARTED,
  NEW_WAIVER_SAVE_SUCCEEDED,
  CURRENT_WAIVER_LOADING_SUCCEEDED,
  WAIVERS_RETRIEVED,
  CURRENT_WAIVER_CLEARED,
  EDIT_WAIVER_CLEARED,
  EDIT_WAIVER_FORM_UPDATED,
  EDIT_WAIVER_SELECTED,
  EDIT_WAIVER_SAVE_FAILED,
  EDIT_WAIVER_SAVE_SUCCEEDED,
  EDIT_WAIVER_SAVE_STARTED,
  WAIVER_LOADING_STARTED,
  WAIVER_LOADING_FAILED,
  COMPANY_WAIVERS_INITIATED,
  WAIVER_LOADING_SUCCEEDED,
  WAIVER_PDF_ACQUIRED,
  WAIVER_PDF_FAILED,
  WAIVER_PDF_REQUESTED,
  WAIVER_USAGE_REQUESTED,
  WAIVER_USAGE_ACQUIRED,
  WAIVER_CONTACTS_REQUESTED,
  WAIVER_CONTACTS_ACQUIRED,
  COMPANY_WAIVERS_CLEARED,
  COMPANY_WAIVERS_REQUESTED,
  COMPANY_WAIVERS_RETRIEVED,
  EDIT_WAIVER_DEACTIVATED,
  NEW_WAIVER_DEACTIVATED,
  CURRENT_WAIVER_DEACTIVATED,
  WAIVERS_CLEARED,
  WAIVER_VALUES_ASSIGNED,
  CURRENT_WAIVER_SET,
  WAIVER_ACTIVE_CHANGED,
  WAIVER_SELECTED,
  RESET_USAGE,
  CLEAR_WAIVERS, TEMP, CLEAR_TEMP
} from "../constants";
import { merge, last, split, mergeDeepRight, mergeWith, append } from "ramda";
import moment from "moment";

const initWaiver = {
  Id: "",
  Active: true,
  Name: "",
  ParticipantPdf: "",
  GuardianPdf: "",
  Description: "",
  ParticipantSigningStatement_Id: "",
  GuardianSigningStatement_Id: "",
  ImgFileName: "",
  VideoUrl: "",
  Expiration: moment("December 30, 9999").format("ll"),
  ExpirationOffset: "0",
  Company_Id: "",
  Created: "",
  CreatedBy: "",
  Revision: ""
};

export const waiver = (state = initWaiver, action) => {
  var reader = new FileReader();
  switch (action.type) {
    case CURRENT_WAIVER_SET:
      return {
        ...state,
        Name: action.payload.Name,
        Active: action.payload.Active,
        Id: action.payload.Id,
        Description: action.payload.Description,
        ParticipantSigningStatement_Id:
          action.payload.ParticipantSigningStatement_Id,
        GuardianSigningStatement_Id: action.payload.GuardianSigningStatement_Id,
        VideoURL: action.payload.VideoURL,
        Expiration: moment(action.payload.Expiration).format("ll"),
        ExpirationOffSet: action.payload.ExpirationOffset
      };
    case WAIVER_ACTIVE_CHANGED:
      return { ...state, Active: action.payload.Active };
    case CURRENT_WAIVER_CLEARED:
      return initWaiver;
    case EDIT_WAIVER_SAVE_STARTED:
      return Object.assign({}, state, action.payload);
    case EDIT_WAIVER_SAVE_FAILED:
      return Object.assign({}, state, action.payload);
    case EDIT_WAIVER_FORM_UPDATED:
      return mergeDeepRight(state, action.payload);

    case NEW_WAIVER_SAVE_STARTED:
      return merge(state, { isSaving: true, isError: false, errorMsg: "" });
    case WAIVER_VALUES_ASSIGNED:
      return Object.assign({}, state, action.payload);
    case NEW_WAIVER_SAVE_FAILED:
      return merge(state, {
        isSaving: false,
        isError: true,
        errorMsg: action.payload
      });
    case NEW_WAIVER_SAVE_SUCCEEDED:
      return action.payload;
    case NEW_WAIVER_FORM_UPDATED:
      return mergeDeepRight(state, { data: action.payload });
    case WAIVER_SELECTED:
      return Object.assign({}, state, action.payload);

    //   return Object.assign({}, state, action.payload)
    // case OPEN:
    //     return { ...state, open: true }
    default:
      return state;
  }
};

export const waivers = (state = [], action) => {
  switch (action.type) {
    case WAIVERS_REQUESTED:
      return { ...state, isFetching: action.payload };
    case WAIVERS_RETRIEVED:
   return action.payload
    // return {...state, waivers: action.payload, isFetching: false}
    case EDIT_WAIVER_SAVE_SUCCEEDED:
      return append(action.payload, state);
    case NEW_WAIVER_SAVE_SUCCEEDED:
      return append(action.payload, state);
    case WAIVERS_FAILED:
      return merge(state, {
        isError: true,
        isFetching: false,
        errorMsg: action.payload
      });
    case CLEAR_WAIVERS:
      return [];
    default:
      return state;
  }
};

export const waiverPdf = (state = [], action) => {
  switch (action.type) {
    case WAIVER_PDF_REQUESTED:
      return Object.assign({}, state, action.payload);
    case WAIVER_PDF_ACQUIRED:
      return Object.assign({}, state, action.payload);
    case WAIVER_PDF_FAILED:
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
};

const initialWaiverUsageData = {
  contacts: [], //{}
  usage: [],
  isFetching: false
};

export const waiverUsage = (state = initialWaiverUsageData, action) => {
  switch (action.type) {
    case WAIVER_USAGE_REQUESTED:
      return merge(state, { isFetching: true });
    case WAIVER_USAGE_ACQUIRED:
      return mergeDeepRight(state, {
        isFetching: false,
        usage: action.payload
      });
    case WAIVER_CONTACTS_REQUESTED:
      return merge(state, { isFetching: true });
    case WAIVER_CONTACTS_ACQUIRED:
      return mergeDeepRight(state, {
        isFetching: false,
        contacts: action.payload
      });
    case RESET_USAGE:
      return {};
    default:
      return state;
  }
};
