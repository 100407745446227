import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import { Formik } from "formik";
import * as Yup from "yup";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import { addWaiver } from "../../action-creators/waivers";
import withStyles from "@material-ui/core/styles/withStyles";
import FormDialog from "./formDialog";
import { Statements, SwitchInput } from "./selectSS";
import { isEmpty, or, and, gt, length, equals } from "ramda";
import CardVideo from './videoUrl'
import InputAdornment from '@material-ui/core/InputAdornment'
import ExpOffSet from '../expOffsetEntry'
import { CLEAR_TEMP } from '../../constants'
const styles = theme => ({
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 5}px ${theme.spacing.unit * 5}px ${theme
      .spacing.unit * 5}px`
  },
  container: {
    maxWidth: "200px"
  },
  bootstrapRoot: {
    "label + &": {
      marginTop: theme.spacing.unit * 3
    }
  },
  bootstrapInput: {
    // borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.common.white,
    display: "flex",
    //  border: '1px solid #ced4da',
    fontSize: 16,
    width: "auto",
    // padding: '10px 12px',
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
    }
  },
  bootstrapFormLabel: {
    fontSize: 18
  },
  textField: {
    display: "flex",
    margin: theme.spacing.unit
  },
  menu: {
    width: "75%"
  }
});

const MyForm = ({
  classes,
  handleSubmit,
  errors,
  touched,
  handleChange,
  values: {
    waiverName,
    active,
    description,
    videoUrl,
    participantSS,
    guardianSS,
    expirationDate,
  },
  formId,
  formName,
  isSubmitting,
  open,
  handleClose,
  setFieldValue,
  setFieldTouched,
  firstCompany, testing, handleTesting,

}) => {
  const change = (name, e) => {
    e.persist();
    handleChange(e);
    //  console.log(errors);
    setFieldTouched(name, true, false);
  };

  return (
    <FormDialog title="Add Waiver" open={open} handleCancel={handleClose}>
      <form onSubmit={handleSubmit} id={formId} name={formName}>
        <SwitchInput
          value={active}
          checked={active}
          handleChange={change.bind(null, "active")}
          name="active"
          label="Active"
          id="active"
        />

        <TextField
          id="waiverName"
          name="waiverName"
          helperText={touched.waiverName ? errors.waiverName : ""}
          error={touched.waiverName && Boolean(errors.waiverName)}
          label="Waiver Name"
          InputLabelProps={{ shrink: true }}
          className={classes.textField}
          value={waiverName}
          onChange={change.bind(null, "waiverName")}
          margin="normal"
          variant="outlined"
        />
        <TextField
          id="waiverPdf"
          name="waiverPdf"
          helperText={touched.waiverPdf ? errors.waiverPdf : ""}
          error={touched.waiverPdf && Boolean(errors.waiverPdf)}
          label="PDF of Waiver"
          InputLabelProps={{ shrink: true }}
          className={classes.textField}
          onChange={event => {
            setFieldValue("waiverPdf", event.currentTarget.files[0]);
          }}
          margin="normal"
          variant="outlined"
          type="file"
        />
        <TextField
          id="waiverGuardianPdf"
          name="waiverGuardianPdf"
          helperText={touched.waiverGuardianPdf ? errors.waiverGuardianPdf : ""}
          error={touched.waiverGuardianPdf && Boolean(errors.waiverGuardianPdf)}
          label="PDF of Guardian Waiver (if different)"
          InputLabelProps={{ shrink: true }}
          className={classes.textField}
          onChange={event => {
            setFieldValue("waiverGuardianPdf", event.currentTarget.files[0]);
          }}
          margin="normal"
          variant="outlined"
          type="file"
        />
        <TextField
          id="description"
          name="description"
          helperText={touched.description ? errors.description : ""}
          error={touched.description && Boolean(errors.description)}
          label="Description"
          InputLabelProps={{ shrink: true }}
          className={classes.textField}
          value={description}
          onChange={change.bind(null, "description")}
          margin="normal"
          variant="outlined"
          multiline
        />

        <Statements
          name="participantSS"
          handleChange={change.bind(null, "participantSS")}
          classes={classes}
          value={participantSS}
          label="Participant Signing Statement"
          placeholder="--Selection Required--"
        />
        <Statements
          name="guardianSS"
          handleChange={change.bind(null, "guardianSS")}
          classes={classes}
          value={guardianSS}
          label="Guardian Signing Statement"
          placeholder="--Selection Required--"
        />

        <TextField
          id="Video URL"
          name="videoUrl"
          helperText={touched.videoUrl ? errors.videoUrl : ""}
          error={touched.videoUrl && Boolean(errors.videoUrl)}
          label="Video URL (optional)"
          InputLabelProps={{ shrink: true }}
          className={classes.textField}
          value={videoUrl}
          onChange={change.bind(null, "videoUrl")}
          margin="normal"
          variant="outlined"
          InputProps={and(!errors.videoUrl, gt(length(videoUrl), 8)) ? {
            endAdornment: <InputAdornment position="end"> <Button value={videoUrl} variant="contained" color="primary" size="small" onClick={handleTesting}>Test Url</Button></InputAdornment>
          } : ""}
        />
        {and(and(testing, equals(testing, videoUrl)), and(!errors.videoUrl, gt(length(videoUrl), 8))) ? <CardVideo video={videoUrl} /> : <div />}
        <TextField
          id="imageFile"
          name="imageFile"
          helperText={touched.imageFile ? errors.imageFile : ""}
          error={touched.imageFile && Boolean(errors.imageFile)}
          label="Image File (optional)"
          InputLabelProps={{ shrink: true }}
          className={classes.textField}
          onChange={event => {
            setFieldValue("imageFile", event.currentTarget.files[0]);
          }}
          margin="normal"
          variant="outlined"
          type="file"
        />

        <TextField
          id="expirationDate"
          name="expirationDate"
          helperText={touched.expirationDate ? errors.expirationDate : ""}
          error={touched.expirationDate && Boolean(errors.expirationDate)}
          label="Expiration Date (optional)"
          value={expirationDate}
          onChange={change.bind(null, "expirationDate")}
          className={classes.textField}
          margin="normal"
          variant="outlined"
        />

        <ExpOffSet />
        <br />
        <Button
          type="submit"
          variant="contained"
          disabled={or(or(!isEmpty(errors), isEmpty(touched)), isSubmitting)}
        >
          Save
        </Button>
        <Button onClick={handleClose}>
          {firstCompany ? `Main Menu` : `Cancel`}
        </Button>
      </form>
    </FormDialog>
  );
};

class NewWaiver extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: true,
      waiverName: "",
      waiverPdf: null,
      waiverGuardianPdf: null,
      description: "",
      participantSS: "",
      guardianSS: "",
      imageFile: "",
      videoUrl: "",
      expirationDate: moment("December 30, 9999").format("ll"),
      expirationOffset: 0,
      formId: "newWaiverForm",
      formName: "newWaiverForm",
      testing: false,
    };
  }
  handleTesting = (e) => {
    this.setState({ testing: e.currentTarget.value })
  }
  handleFieldChange = (name, e) => {

    const { handleChange, setFieldTouched } = this.props;
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };


  submitValues = ({
    active,
    waiverName,
    waiverPdf,
    waiverGuardianPdf,
    description,
    participantSS,
    guardianSS,
    imageFile,
    videoUrl,
    expirationDate,
    expirationOffset
  }) => {
    const waiverObj = {
      Active: active,
      Name: waiverName,
      ParticipantPdf: waiverPdf,
      GuardianPdf: waiverGuardianPdf,
      Description: description,
      ParticipantSigningStatement_Id: Number(participantSS),
      GuardianSigningStatement_Id: Number(guardianSS),
      ImgFileName: imageFile,
      VideoURL: videoUrl,
      Expiration: expirationDate,
      ExpirationOffset: this.props.tempData.offset
    };


    this.props.addWaiver(waiverObj);
    this.setState({ testing: false })
    this.props.handleClose();
    this.props.dispatch({ type: CLEAR_TEMP })
  };

  render() {
    const {
      active,
      waiverName,
      waiverPdf,
      waiverGuardianPdf,
      description,
      participantSS,
      guardianSS,
      imageFile,
      videoUrl,
      expirationDate,
      expirationOffset
    } = this.state;
    const values = {
      active,
      waiverName,
      waiverPdf,
      waiverGuardianPdf,
      description,
      participantSS,
      guardianSS,
      imageFile,
      videoUrl,
      expirationDate,
      // expirationOffset
    };

    return (
      <React.Fragment>
        <Paper elevation={1}>
          <Formik
            render={props => (
              <MyForm
                waiver={this.props.waiver}
                classes={this.props.classes}
                {...props}
                handleClose={this.props.handleClose}
                formId={this.state.formId}
                formName={this.state.formName}
                open={this.props.open}
                //\\
                firstCompany={this.props.firstCompany}
                testing={this.state.testing}
                handleTesting={this.handleTesting}
              />
            )}
            initialValues={values}
            onSubmit={this.submitValues}
            validationSchema={Yup.object().shape({
              active: Yup.boolean(),
              waiverName: Yup.string()
                .min(2, "Too Short")
                .required("Waiver Name is required"),
              waiverPdf: Yup.mixed().required("Please provide PDF of waiver"),
              waiverGuardianPdf: Yup.mixed(),
              description: Yup.string()
                .min(2, "Too Short")
                .required("Please provide a description of the waiver"),
              participantSS: Yup.number()
                .positive()
                .integer()
                .required("Selection Required"),
              guardianSS: Yup.number()
                .positive()
                .integer()
                .required("Selection Required"),
              videoUrl: Yup.string().url("Invalid URL"),
              imageFile: Yup.mixed(),
              expirationDate: Yup.date(),
              // expirationOffset: Yup.number()
              expirationOffset: Yup.number()
            })}
          />
        </Paper>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  waiver: state.waiver,
  signingStatements: state.signingStatements.statements,
  tempData: state.tempData
});
const mapActionToProps = dispatch => ({
  addWaiver: id => dispatch(addWaiver(id)),
  dispatch
});
const connector = connect(
  mapStateToProps,
  mapActionToProps
);
export default connector(withStyles(styles)(NewWaiver));
