import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import CompanyInformation from "../../components/companies/companyInfo";
import PaymentCard from "../../components/billing/paymentCard";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { submitCompany } from "../../action-creators/companies";
import CustomizedSnackbars from "../../components/snackbar";
import {
  COMPANY_DETAILS_EXITED,
  NEW_COMPANY_FORM_UPDATED,
  CURRENT_WAIVER_CLEARED
} from "../../constants";
import { Formik } from "formik";
import { companyValidate, newCompanySchema } from "../../lib/validation";
import grey from "@material-ui/core/colors/grey";
import { equals, find, head, isEmpty, isNil, or, prop } from "ramda";
import NewPopup from "../../components/waivers/newForm";
import ContactInformation from "../../components/companies/contactInformation";

const styles = theme => ({
  appBar: {
    position: "relative"
  },
  checkBox: {
    width: "auto"
  },
  flagContainer: {
    right: "auto",
    width: "100%",
    left: 0
    // position: "flex"
    // display: "block"
  },

  label: {
    // textTransform: "capitalize",
    width: "100%",
    margin: theme.spacing.unit
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2
  },
  margin: {
    margin: theme.spacing.unit
  },
  paper: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3
    // position: "absolute",
  },
  stepper: {
    padding: `${theme.spacing.unit * 3}px 0 ${theme.spacing.unit * 5}px`
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end"
  },
  button: {
    marginTop: theme.spacing.unit * 5,
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit
  },
  buttonNext: {
    marginTop: theme.spacing.unit * 5,
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit
  },
  buttonBack: {
    marginTop: theme.spacing.unit * 5,
    marginRight: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit,
    marginBottom: theme.spacing.unit
  },
  buttonSubmit: {
    display: "flex",
    justifyContent: "center"
  },
  container: {
    display: "flex"
  },

  textField: {
    width: "90%",
    marginLeft: theme.spacing.unit, //"auto",
    marginRight: theme.spacing.unit, //"auto",
    flexBasis: 200,
    paddingBottom: 0,
    marginTop: 0,
    borderBottom: "1px solid #ced4da",
    borderBottomColor: grey[900],
    borderTop: "none",
    borderRight: "none",
    borderLeft: "none"
  },
  textField3: {
    width: "90%",
    marginLeft: theme.spacing.unit, //"auto",
    marginRight: theme.spacing.unit, //"auto",
    flexBasis: 200,
    paddingBottom: 12,
    marginTop: 0,
    borderBottom: "1px solid #ced4da",
    borderBottomColor: grey[900],
    borderTop: "none",
    borderRight: "none",
    borderLeft: "none"
  },
  logoLabel: {
    margin: "130px"
  },
  textFieldOutlined: {
    width: "90%",
    marginLeft: theme.spacing.unit, //"auto",
    marginRight: theme.spacing.unit, //"auto",
    flexBasis: 200,
    // paddingBottom: 12,
    marginTop: 0,
    marginBottom: 12,
    orderTop: "none",
    borderRight: "none",
    borderLeft: "none"
  },
  fieldset: {
    display: "0", //"block",
    marginInlineStart: "0", //"2px",
    marginInlineEnd: "0", //"2px",
    paddingBlockStart: "0", //"0.35em",
    paddingInlineStart: "0", //"0.75em",
    paddingInlineEnd: "0", //"0.75em",
    paddingBlockEnd: "0", //"0.625em",
    minInlineSize: "0", //"min-content",
    // borderWidth: "2px",
    borderStyle: "0", //"groove",
    // borderColor: "threedface",
    // borderImage: "initial",
    textAlign: "center"
  },
  phoneFieldset: {
    textAlign: "left",
    border: ".5px solid grey",
    borderRadius: "5px",
    paddingTop: "1rem",
    paddingBottom: "1rem",
    marginRight: "1.75rem",
    marginLeft: ".5rem",
    display: "flex",
    justifyContent: "center"
  },
  phoneInput: {
    width: "90%",
    flexBasis: 200,
    // margin: theme.spacing.unit * 3, //??
    // border: "1px solid grey",
    orderTop: "none",
    border: 0
    // marginLeft: "2rem"
  },
  phoneError: {
    marginLeft: "1rem",
    marginTop: ".25rem"
  }
});

const steps = [
  "Company Information",
  "Contact Information",
  "Payment Information"
];

function getStepContent(
  step,
  classes,
  formikProps,
  open,
  onClose,
  newWaiver,
  firstCompany
) {
  switch (step) {
    case 0:
      return (
        <CompanyInformation
          classes={classes}
          formikProps={formikProps}
          open={open}
          onClose={onClose}
          newWaiver={newWaiver}
          firstCompany={firstCompany}
        />
      );
    case 1:
      return (
        <ContactInformation
          classes={classes}
          formikProps={formikProps}
          open={open}
          onClose={onClose}
          newWaiver={newWaiver}
          firstCompany={firstCompany}
        />
      );
    case 2:
      return (
        <PaymentCard
          classes={classes}
          formikProps={formikProps}
          open={open}
          onClose={onClose}
          newWaiver={newWaiver}
          firstCompany={firstCompany}
        />
      );
    default:
      throw new Error("Unknown step");
  }
}

class NewCompany extends Component {
  state = {
    activeStep: 0,
    // open: false,
    firstCompany: false,
    newWaiver: false,
    newCompany: {
      // Company: {
      CompanyName: "",
      BillingEmail: "",
      ImageUrl: "",
      CompanyLogo: "", //"", ///"",
      CompanyDescription: "",
      ImageBlobName: {},
      PrimaryAddress: "",
      BillingAddress: "",
      // BillingAddressLine2: "",
      City: "",
      State: "",
      ZipCode: "",
      Country: "",
      GetContactInformation: false,
      GetBirthday: false,
      GetEmergencyPhone: false,
      ContactName: "",
      ContactPhone: "",
      ContactEmail: "",
      Website: "",
      // SameAsBillingAddress: true,
      // },
      // billingData: {
      CardHolder: "", //name
      CardNumber: "",
      BillingZip: "",
      ExpDate: {
        Month: "",
        Year: ""
      },
      CVC: "",
      Remember: false
      // }
    }
  };

  componentDidMount() {
    if (!isNil(this.props.match)) {
      this.setState({ firstCompany: true });
    }
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1
    }));
  };

  handleNext = () => {
    this.setState(state => ({
      activeStep: state.activeStep + 1
    }));
  };

  handleCloseFirst = () => {
    this.setState({ newWaiver: false, firstCompany: false });
    this.props.dispatch({ type: CURRENT_WAIVER_CLEARED });
    this.props.dispatch({ type: COMPANY_DETAILS_EXITED });
    this.props.history.push("/signedWaivers");
  };

  testSubmit = newCompany => {
    this.props.newCompanyForm(newCompany);
    this.props.submitCompany(newCompany);
    if (this.state.firstCompany) {
      this.setState(state => ({ activeStep: state.activeStep + 1 }));
      this.setState({ newWaiver: true });
    } else {
      this.props.onClose();
    }
  };

  render() {
    const {
      classes,
      isError,
      isLoading,
      isSubmitted,
      onClose,
      open,
      waiverSubmitted,
      waiverSaving,
      waiverFailed,
      wErrorMsg
    } = this.props;
    const { activeStep, firstCompany, newWaiver } = this.state;

    const currentCompanyName = prop(
      "Name",
      find(
        c => equals(c.Id, this.props.currentCompany.id),
        this.props.companies
      )
    );

    const currentWaiverName = prop("Name", head(this.props.waivers));

    return (
      <React.Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="display3" align="center">
              New Company
            </Typography>
            <Typography variant="display1" align="center">
              {activeStep === 0
                ? `Address`
                : activeStep === 1
                  ? `Contact Information`
                  : `Billing`}
            </Typography>
            <div>
              <Stepper activeStep={activeStep} className={classes.stepper}>
                {steps.map(label => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>

              <div>
                <Formik
                  initialValues={this.state.newCompany}
                  validationSchema={newCompanySchema}
                  onSubmit={(values, { resetForm }) => {
                    this.testSubmit(values);
                    resetForm(values);
                  }}
                  render={formikProps => (
                    <React.Fragment>
                      {activeStep === steps.length ? (
                        <React.Fragment>
                          <NewPopup
                            title="New Waiver"
                            open={newWaiver}
                            handleClose={this.handleCloseFirst}
                            firstCompany={firstCompany}
                          />
                          <Typography
                            align="center"
                            variant="display2"
                            gutterBottom
                          >
                            {`Thank you for adding ${currentWaiverName} to 
                              ${currentCompanyName}
                            !`}
                          </Typography>
                          <Button
                            color="primary"
                            variant="contained"
                            className={classes.button}
                            onClick={this.handleCloseFirst}
                          >
                            Main Menu
                          </Button>
                        </React.Fragment>
                      ) : (
                          <React.Fragment>
                            {getStepContent(
                              activeStep,
                              classes,
                              formikProps,
                              open,
                              onClose,
                              newWaiver,
                              firstCompany
                            )}
                            <div className={classes.buttons}>
                              {activeStep !== 0 && (
                                <Button
                                  onClick={this.handleBack}
                                  className={classes.buttonBack}
                                >
                                  Back
                              </Button>
                              )}
                              {activeStep !== steps.length - 1 && (
                                <React.Fragment>
                                  <Button
                                    className={classes.buttonBack}
                                    onClick={onClose}
                                    disabled={firstCompany}
                                  >
                                    Cancel
                                </Button>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() =>
                                      this.handleNext(formikProps.values)
                                    }
                                    className={classes.buttonNext}
                                  >
                                    Next
                                </Button>
                                </React.Fragment>
                              )}
                              {activeStep === steps.length - 1 && (
                                <React.Fragment>
                                  <Button
                                    disabled={or(
                                      or(
                                        !isEmpty(formikProps.errors),
                                        formikProps.isSubmitting
                                      ),
                                      isEmpty(formikProps.touched)
                                    )}
                                    className={classes.button}
                                    onClick={() =>
                                      formikProps.handleSubmit(formikProps.values)
                                    }
                                    variant="contained"
                                    type="submit"
                                  >
                                    Save
                                </Button>
                                </React.Fragment>
                              )}
                            </div>
                          </React.Fragment>
                        )}
                    </React.Fragment>
                  )}
                />
              </div>
            </div>
          </Paper>
        </main>

        {isLoading && (
          <CustomizedSnackbars
            message="New Company is Saving... Please wait."
            snackType="info"
          />
        )}
        {isError && (
          <CustomizedSnackbars
            message="New Company Failed To Save. Please Try Again."
            snackType="error"
          />
        )}
        {isSubmitted && (
          <CustomizedSnackbars
            message="New Company Successfully Added!"
            snackType="success"
          />
        )}
        {waiverSubmitted && (
          <CustomizedSnackbars
            message="New Waiver Successfully Added!"
            snackType="success"
          />
        )}
        {waiverSaving && (
          <CustomizedSnackbars
            message="New Waiver is Saving... Please wait."
            snackType="info"
          />
        )}
        {waiverFailed && (
          <CustomizedSnackbars message={wErrorMsg} snackType="error" />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  companies: state.companies,
  company: state.companyDetails.data,
  isLoading: state.companyDetails.isLoading,
  isError: state.companyDetails.isError,
  isSubmitted: state.companyDetails.isSubmitted,
  currentCompany: state.currentCompany,
  waiverSubmitted: state.waiver.waiverSubmitted,
  waiverSaving: state.waiver.isSaving,
  waiverFailed: state.waiver.waiverFailed,
  wErrorMsg: state.waiver.errorMsg,
  waivers: state.waivers,
  companyData: state.companyPaymentDetails.data.Company
});

const mapActionsToProps = dispatch => ({
  newCompanyForm: value => {
    dispatch({ type: NEW_COMPANY_FORM_UPDATED, payload: value });
  },
  submitCompany: company => dispatch(submitCompany(company)),
  dispatch
});

const connector = connect(
  mapStateToProps,
  mapActionsToProps
);

export default connector(withStyles(styles)(NewCompany));
