import React from "react";
import { withRouter } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { DRAWER_TOGGLED } from "../constants";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import Hidden from "@material-ui/core/Hidden";
import Badge from "@material-ui/core/Badge";
import Grid from "@material-ui/core/Grid";
import MenuIcon from "@material-ui/icons/Menu";
import { getCurrentCompany } from "../lib/selectors/company";
import RegisterUser from "./authentication/registerUser";
const Logo = require("../images/aWaiver_Logo_w_Text.svg");

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  flex: {
    flex: 1
  },
  firstButton: {
    marginLeft: -12,
    marginRight: 12
  },
  lastButton: {
    marginLeft: 12,
    marginRight: -12
  },
  button: {
    margin: theme.spacing.unit
  },
  pic: {
    width: "120px",
    height: "45px"
  },
  label: {
    textTransform: "capitalize"
  },
  margin: {
    margin: theme.spacing.unit * 2
  }
});

const MenuAppBar = props => {
  const {
    classes,
    currentCompany,
    toggleDrawer,
  } = props;
  const companyName = currentCompany ? currentCompany.Name : "Fetching Company";
  //const currentCompany = find(propEq("Id", props.authentication.CurrentCompanyId), props.companies)

  return (
    <div id="menu-container" className={classes.root}>
      <AppBar color="default" position="fixed">
        <Toolbar style={{ height: "64px" }}>
          <React.Fragment>
            {!props.home && !props.register ? (
              <Hidden>
                <IconButton
                  className={classes.firstButton}
                  color="primary"
                  aria-label="Menu"
                  onClick={toggleDrawer}
                >
                  <MenuIcon />
                </IconButton>
              </Hidden>
            ) : (
                <Grid item>
                  <Hidden xsDown>
                    <Typography className={classes.flex}>
                      <img src={Logo} alt="AWaiver" className={classes.pic} />
                    </Typography>
                  </Hidden>
                </Grid>
              )}
          </React.Fragment>
          <Grid container justify="space-between" alignItems="center">
            <React.Fragment>
              {props.companyYes ? (
                <Grid item>
                  <Hidden xsDown>
                    <Link to="/companies" className="router-link">
                      <Button

                        className={classes.button}
                      >
                        <Typography
                          variant="subheading"
                          color="textPrimary"
                          className={classes.flex}
                        >
                          {companyName}
                        </Typography>
                      </Button></Link>
                  </Hidden>
                </Grid>
              ) : (
                  <div />
                )}
            </React.Fragment>
            <React.Fragment>
              {!props.home && !props.register ? (
                <Grid item>
                  <Hidden >
                    <Typography className={classes.flex}>
                      <img src={Logo} alt="AWaiver" className={classes.pic} />
                    </Typography>
                  </Hidden>
                </Grid>
              ) : props.register ? (
                <Typography variant="title">Register</Typography>
              ) : null}
            </React.Fragment>
            <Grid item>
              <Grid container justify="flex-end">
                <Hidden>
                  <div>
                    <React.Fragment>
                      {props.home ? (
                        <Hidden xsDown>
                          <Button onClick={props.handleOpen}>Register</Button>
                        </Hidden>
                      ) : // <RegisterDialog menuBar={true} />
                        props.userYes ? (
                          //  ) : (props.authentication.UserName) ? (
                          <Hidden xsDown>
                            <Badge
                              className={classes.margin}
                              color={props.dotColor}
                              variant="dot"
                              invisible={props.invisible}
                            >
                              <Link to="/signedwaivers" className="router-link">
                                <Button
                                  color="primary"
                                  className={classes.button}
                                >
                                  {`Welcome ${props.authentication.UserName}`}
                                </Button>
                              </Link>
                            </Badge>
                          </Hidden>
                        ) : (
                            <div />
                          )}
                    </React.Fragment>
                  </div>
                </Hidden>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <RegisterUser
        open={props.open || false}
        handleClose={props.handleClose}
      />
    </div>
  );
};

const mapStateToProps = () => {
  const getCompany = getCurrentCompany();
  return state => {
    return {
      //  userName: state.currentUser.data.UserName,
      //  userName: state.currentUser.UserName,
      companies: state.companies,
      authentication: state.authentication,
      // companyName: getCompany(state),
      currentCompany: getCompany(state)
      // connected: state.signalR

      //  currentCompany: find(c => c.Current)(state.companies),
    };
  };
};
const mapActionToProps = dispatch => ({
  toggleDrawer: () => dispatch({ type: DRAWER_TOGGLED })
});
const connector = connect(
  mapStateToProps,
  mapActionToProps
);
export default connector(withRouter(withStyles(styles)(MenuAppBar)));
