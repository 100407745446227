import {
  SET_CURRENT_USER,
  CURRENT_USER_ERROR_CLEARED,
  CURRENT_USER_LOADING_STARTED,
  CURRENT_USER_LOADING_FAILED,
  CURRENT_USER_LOADING_SUCCEEDED,
  CURRENT_USER_UPDATED,
  USER_LOADING_SUCCEEDED,
  LOGIN_STARTED,
  LOGIN_FAILED,
  LOGIN_SUCCEEDED,
  LOGOUT_SUCCEEDED,
  LOGIN_CLEARED,
  COMPANY_SIGNING_STATEMENTS_SELECTED,
  USERS_RETRIEVED,
  NEW_USER_SAVE_STARTED,
  NEW_USER_SAVE_FAILED,
  NEW_USER_SAVE_SUCCEEDED,
  NEW_USER_FORM_UPDATED,
  EDIT_USER_CLEARED,
  EDIT_USER_FORM_UPDATED,
  EDIT_USER_SAVE_FAILED,
  EDIT_USER_SAVE_SUCCEEDED,
  EDIT_USER_SAVE_STARTED,
  USER_ROLENAME_SELECTED,
  EDIT_USER_SELECTED,
  INVITATION_SENT,
  EDIT_USER_ROLENAME,
  INVITATION_STARTED,
  INVITATION_FAILED,
  EDIT_USER_LOADING,
  CLEAR_USERS,
  CURRENT_USER_CLEARED
} from "../constants";
import { merge, mergeDeepRight } from "ramda";

const initialCurrentUser = {
  data: {
    //  AccessFailedCount: 0,
    //  Claims: [],
    Created: "",
    CurrentCompanyId: "",
    Email: "",
    EmailConfirmed: true,
    Id: 0,
    LockoutEnabled: true,
    LockEndDateUtc: null,
    Logins: [],
    Name: "",
    PasswordHash: "",
    PhoneNumber: "",
    PhoneNumberConfirmed: false,
    Roles: [],
    SearchSettings: null,
    SecurityStamp: "",
    TwoFactorEnabled: false,
    UserName: "",
    UserId: "",
    UserName: "", //UserName or Email
    Password: "",
    Name: "",
    PhoneNumber: "",
    Email: "",
    Role: "",
    Current: false,
    UserCompanies: [{ Company_Id: "", RoleName: "", CompanyName: "" }]
  },
  isChecking: false,
  isAuthenticated: false,
  isLoading: false,
  isError: false,
  errorMsg: ""
};

export const users = (state = [], action) => {
  switch (action.type) {
    case USERS_RETRIEVED:
      return action.payload;
    case CLEAR_USERS:
      return [];
    default:
      return state;
  }
};

const initialNewUser = {
  data: {
    UserName: "", //UserName or Email
    Password: "",
    Name: "",
    PhoneNumber: "",
    Email: "",
    CompanyId: "",
    Role: "",
    Current: false,
    Companies: []
  },
  isAuthenticated: false,
  isSaving: false,
  isError: false,
  errorMsg: ""
};

export const newUser = (state = initialNewUser, action) => {
  switch (action.type) {
    case NEW_USER_SAVE_STARTED:
      return merge(state, { isSaving: true, isError: false, errorMsg: "" });
    case NEW_USER_SAVE_FAILED:
      return merge(state, {
        isSaving: false,
        isError: true,
        errorMsg: action.payload
      });
    case NEW_USER_SAVE_SUCCEEDED:
      return initialNewUser;
    case NEW_USER_FORM_UPDATED:
      return merge(state, { data: action.payload });
    case INVITATION_STARTED:
      return merge(state, { isSaving: true, isError: false, errorMsg: "" });
    case INVITATION_FAILED:
      return merge(state, {
        isSaving: false,
        isError: true,
        errorMsg: action.payload
      });
    case INVITATION_SENT:
      return merge(state, {
        isError: false,
        isAuthenticated: true,
        data: { Email: action.payload }
      });
    case CURRENT_USER_CLEARED:
      return merge(state, initialNewUser);
    default:
      return state;
  }
};

const initialEditUser = {
  data: {
    UserId: "",
    UserName: "", //UserName or Email
    Password: "",
    Name: "",
    PhoneNumber: "",
    Email: "",
    CompanyId: "",
    //RoleName: '',
    Current: false,
    UserCompanies: [{ Name: "", Id: "", RoleName: "" }],
    Created: ""
  },
  RoleName: "",
  newUserCompany: {},
  isAuthenticated: false,
  isSaving: false,
  isLoading: false,
  isValidating: false,
  isError: false,
  errorMsg: "",
  msg: ""
};

export const editUser = (state = initialEditUser, action) => {
  switch (action.type) {
    case EDIT_USER_CLEARED:
      return merge(state, {
        RoleName: "",
        newUserCompany: {},
        isAuthenticated: false,
        isSaving: false,
        isLoading: false,
        isValidating: false,
        isError: false,
        errorMsg: "",
        msg: ""
      });
    // return initialEditUser;
    case EDIT_USER_ROLENAME:
      // console.log("EDIT_USER_ROLENAME_ap", action.payload);
      // console.log("EDIT_USER_ROLENAME_state..", state);
      return merge(state, { RoleName: action.payload, isValidating: true });
    case EDIT_USER_SELECTED:
      return merge(state, {
        data: action.payload,
        isLoading: false,
        isAuthenticated: false,
        isSaving: false,
        isValidating: false,
        isError: false,
        errorMsg: ""
      });
    //   action.payload; //merge(state, { data: action.payload });
    case EDIT_USER_FORM_UPDATED:
      return mergeDeepRight(state, { data: action.payload });
    case EDIT_USER_SAVE_FAILED:
      return merge(state, {
        isError: true,
        errorMsg: action.payload,
        isLoading: false,
        isSaving: false,
        isValidating: false,
        isAuthenticated: false
      });
    case EDIT_USER_SAVE_SUCCEEDED:
      return merge(state, {
        isError: false,
        errorMsg: "",
        isLoading: false,
        isSaving: false,
        isValidating: false,
        isAuthenticated: true,
        msg: action.payload
      });
    case EDIT_USER_SAVE_STARTED:
      return merge(state, {
        isError: false,
        errorMsg: "",
        isLoading: false,
        isSaving: true
      });
    case USER_ROLENAME_SELECTED:
      return merge(state, {
        newUserCompany: action.payload
      });
    case EDIT_USER_CLEARED:
      // return mergeDeepRight(state, { data: action.payload });
      return {
        ...state,
        isAuthenticated: false,
        isSaving: false,
        isLoading: false,
        isValidating: false,
        isError: false,
        errorMsg: ""
      };
    case EDIT_USER_LOADING:
      return merge(state, { isLoading: true });
    default:
      return state;
  }
};

//user_loading_started ===> USER_ROLENAME_SELECTED
