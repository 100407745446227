import React from "react";
import Drawer from "@material-ui/core/Drawer";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Card from "@material-ui/core/Card";
import WaiverIcon from "@material-ui/icons/AssignmentIndTwoTone";
import MainLogo from "./logo";
import SignedWaiverIcon from "@material-ui/icons/AssignmentTurnedInTwoTone";
import withStyles from "@material-ui/core/styles/withStyles";
import { ADMIN_PAGE_SELECTED, DRAWER_TOGGLED } from "../constants";
import CompanyIcon from "@material-ui/icons/BusinessTwoTone";
import UsersIcon from "@material-ui/icons/VerifiedUserTwoTone";
import UsageIcon from "@material-ui/icons/DataUsageTwoTone";
import HelpIcon from "@material-ui/icons/HelpOutlineTwoTone";
import LogoutIcon from "@material-ui/icons/ExitToAppTwoTone";
import { getCurrentCompany } from "../lib/selectors/company";
import { logoutUser } from "../action-creators/authentication";
import { equals, lt } from "ramda";
import { setPendingSW } from "../action-creators/signedWaivers";
import SplitAdminButton from "./splitAdminButton";
//import authentication from '@kdpw/msal-b2c-react'
const styles = theme => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  active: {
    backgroundColor: theme.palette.primary
  },
  menuItem: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "&:primary, &:icon": {
        color: theme.palette.common.white
      }
    }
  },
  splitButton: {
    // position: "inherit",
    paddingTop: 0, //"0",
    paddingBottom: 0, //"0",
    xPlacement: "bottom"
  },
  splitGrow: {
    transformOrigin:
      "&:placement" === "bottom" ? "center top" : "center bottom",
    width: "100%",
    display: "flex"
  },
  splitPopper: {
    position: "relative",
    placement: "bottom"
  },
  splitMenu: {
    visibility: "100%"
  }
});

const MenuList = props => {
  // const currentCompanyName = currentCompany ? currentCompany.Name : 'Fetching Company'
  const role = Number(window.localStorage.isAuth);
  return (
    <div className={props.classes.root}>
      <Card style={{ padding: 10, background: "darkGrey" }}>
        <center>
          <MainLogo />
        </center>
      </Card>
      <Divider />
      <ListItem
        button
        component={NavLink}
        to="/signedWaivers"
        disabled={lt(3, role)}
        onClick={props.toggleDrawer}
        className={props.classes.active}
      >
        <SignedWaiverIcon color="primary" />
        <ListItemText primary="Signed Waivers" />
      </ListItem>
      <ListItem
        button
        component={NavLink}
        className={props.classes.active}
        to="/companies"
        disabled={lt(4, role)}
        onClick={props.toggleDrawer}
      >
        <CompanyIcon color="primary" />

        <ListItemText primary="Company" />
      </ListItem>

      {/* </Link> */}
      {/* <Link to="/waivers" className="router-link"> */}
      <ListItem
        button
        component={NavLink}
        to="/waivers"
        className={props.classes.active}
        disabled={lt(2, role)}
        onClick={props.toggleDrawer}
      >
        <WaiverIcon color="primary" />
        <ListItemText primary="Manage Waivers" />
      </ListItem>
      {/* </Link>
            <Link to="/users" className="router-link"> */}
      <ListItem
        disabled={lt(2, role)}
        component={NavLink}
        to="/users"
        className={props.classes.active}
        button
        onClick={props.toggleDrawer}
      >
        <UsersIcon color="primary" />
        <ListItemText primary="Users" />
      </ListItem>
      {/* </Link>
            <Link to="/usage" className="router-link"> */}
      <ListItem
        button
        component={NavLink}
        to="/usage"
        disabled={lt(2, role)}
        className={props.classes.active}
        onClick={props.toggleDrawer}
      >
        <UsageIcon color="primary" />
        <ListItemText primary="Usage Reports" />
      </ListItem>
      {/* </Link> */}

      <Divider />

      {/* <Link to="/help" className="router-link"> */}
      <ListItem
        button
        component={NavLink}
        to="/help"
        className={props.classes.active}
        onClick={props.toggleDrawer}
      >
        <HelpIcon color="primary" />
        <ListItemText primary="Help Videos" />
      </ListItem>

      {equals(1, role) ? (
        <ListItem
          button
          {...props}
          component={SplitAdminButton}
          role={role}
          toggleDrawer={props.toggleDrawer}
        />
      ) : null}

      <Divider />
      {/* <Link to="/" className="router-link"> */}
      <ListItem
        button
        component={NavLink}
        to="/"
        className={props.classes.active}
        onClick={props.logoutUser}
      //  onClick={authentication.signOut()}
      >
        <LogoutIcon color="primary" />
        <ListItemText primary="Logout" />
      </ListItem>
      {/* </Link> */}
      <Divider />
    </div>
  );
};
const MenuListItems = withStyles(styles)(MenuList);
const withDrawer = function (PageComponent) {
  const WrappedDrawerComponent = props => {
    return (
      <div>
        <PageComponent {...props} />
        <Drawer
          open={props.open}
          onClose={props.toggleDrawer}
          //   logoutUser={props.logoutUser}npage

          //   logoutUser={props.logoutUser}
          anchor="left"
        >
          <div tabIndex={0} role="button">
            <MenuListItems {...props} />
          </div>
        </Drawer>
      </div>
    );
  };
  const getCompany = getCurrentCompany();
  const mapStateToProps = state => ({
    open: state.drawer.open,
    currentCompany: getCompany(state)
  });

  const mapActionToProps = dispatch => ({
    toggleDrawer: () => dispatch({ type: DRAWER_TOGGLED }),
    logoutUser: () => dispatch(logoutUser),
    setPendingSW: () => dispatch(setPendingSW),
    selectAdminPage: index =>
      dispatch({ type: ADMIN_PAGE_SELECTED, payload: index }),
    dispatch
  });
  const connector = connect(
    mapStateToProps,
    mapActionToProps
  );

  return connector(WrappedDrawerComponent);
};

export default withDrawer;
