import { apiFetchClosure } from '../lib/fetch'
import { HELP_VIDEOS_REQUESTED, HELP_VIDEOS_ACQUIRED } from '../constants'

export const getHelpVideos = () => async (dispatch, getState) => {

    dispatch({ type: HELP_VIDEOS_REQUESTED, payload: { isFetching: true } })

    const response = await apiFetchClosure()(`/api/apitutorial/videos`)
        .then(res => res.json())
        .catch(err => console.log('err: ', err));

    dispatch({ type: HELP_VIDEOS_ACQUIRED, payload: { isFetching: true, videos: response } })
}









