import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import { difference, identity, slice, split, times } from "ramda";

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 1200
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: theme.spacing.unit / 4
  },
  label: {
    marginBottom: ".75rem"
  },
  noLabel: {
    marginTop: theme.spacing.unit * 3
  },
  menu: {
    marginLeft: ".5rem"
  },
  inputLabel: {
    border: "1px solid #ced4da",
    borderRadius: ".25rem",
    padding: ".5rem",
    marginTop: ".25rem",
    height: "2rem",
    backgroundColor: "#e0e0e0"
  },
  select: {
    cursor: "pointer",
    height: "2.5rem",
    marginTop: ".25rem"
  }
});

const getYears = (startYear, endYear) => {
  let yearArray = [];
  let currentYear = moment(startYear);
  let stopYear = moment(endYear);
  while (currentYear <= stopYear) {
    yearArray.push(moment(currentYear).format("YYYY"));
    currentYear = moment(currentYear).add(1, "years");
  }
  return yearArray;
};

const now = new Date();
const startYear = now.getFullYear();
const endYear = startYear + 10;
const years = difference(times(identity, endYear), times(identity, startYear));

const months = [
  "1 - January",
  "2 - February",
  "3 - March",
  "4 - April",
  "5 - May",
  "6 - June",
  "7 - July",
  "8 - August",
  "9 - September",
  "10 - October",
  "11 - November",
  "12 - December"
];

class ExpDateDropDown extends Component {
  state = {
    month: "",
    year: ""
  };

  handleChangeMonth = event => {
    this.setState({ month: event.target.value });
    this.props.form.setFieldTouched(this.props.field.name, true, true);
    this.props.form.setFieldValue(this.props.field.name, {
      Month: moment(slice(0, 1, split(" ", event.target.value)).join()).format(
        "MM"
      ),
      Year: `${this.state.year}`
    });
  };

  handleChangeYear = event => {
    this.setState({ year: event.target.value });
    this.props.form.setFieldTouched(this.props.field.name, true, true);
    this.props.form.setFieldValue(this.props.field.name, {
      Month: moment(slice(0, 1, split(" ", this.state.month)).join()).format(
        "MM"
      ),
      Year: `${event.target.value}`
    });
  };

  //   handleChangeMultiple = event => {
  //     const { options } = event.target;
  //     const value = [];
  //     for (let i = 0, l = options.length; i < l; i += 1) {
  //       if (options[i].selected) {
  //         value.push(options[i].value);
  //       }
  //     }
  //     this.setState({
  //       name: value
  //     });
  //   };

  render() {
    const { classes, label } = this.props;

    return (
      <React.Fragment>
        <Typography variant="subtitle1">{label}</Typography>
        <Grid container justify="center">
          <Grid item>
            <InputLabel
              htmlFor="select"
              variant="outlined"
              className={classes.inputLabel}
            >
              Month
            </InputLabel>
            <Select
              className={classes.select}
              value={this.state.month}
              // value={this.state.month || field.value.Month}
              onChange={this.handleChangeMonth}
              input={<OutlinedInput labelWidth={0} name="month" />}
            >
              {months.map(month => (
                <MenuItem className={classes.menu} key={month} value={month}>
                  {month}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item>
            <InputLabel
              htmlFor="select"
              variant="filled"
              className={classes.inputLabel}
            >
              Year
            </InputLabel>
            <Select
              className={classes.select}
              value={this.state.year}
              // value={this.state.year || field.value.Year}
              onChange={this.handleChangeYear}
              input={<OutlinedInput labelWidth={0} name="year" />}
            >
              {years.map(year => (
                <MenuItem className={classes.menu} key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ExpDateDropDown);
