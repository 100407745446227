import React, { Component } from 'react';
import PropTypes from 'prop-types'
import {
    withStyles, Typography, Grid, Button, Badge, IconButton,

} from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
//import SignedWaiversTable from './signedWaiverTable'
import WaiverIcon from '@material-ui/icons/AssignmentIndTwoTone';
import { equals } from 'ramda'
import SaveIcon from '@material-ui/icons/Save'
import grey from '@material-ui/core/colors/grey'
const styles = theme => ({
    root: {
        //  width: '100%'
        flex: 1
    },

    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,

    },

    leftIcon: {
        marginRight: theme.spacing.unit,
    },
    details: {
        alignItems: 'center',
        flexBasis: 1
    },
    column: {
        flexBasis: '33.33%',
    },

    iconSmall: {
        fontSize: 20,
    },

});


const SignedPanel = ({ labelValue, waiver, heading, classes, panelName, component, numberOfWaivers, grouping,
    handleClearGroupCheckBoxes, handleSelectAllGroupCheckBoxes, handleOpenGroupDialog, expanded, handleChange }) => {


    return (
        <div className={classes.root}>
            <ExpansionPanel style={{ backgroundColor: grey[300], borderBottom: `1px solid ${grey[500]}`, }} square expanded={expanded === panelName} onChange={handleChange}>
                <ExpansionPanelSummary expandIcon={equals(numberOfWaivers, 0)
                    ? <ExpandMoreIcon />
                    : equals(expanded, panelName)
                        ?
                        <IconButton color="primary">
                            <Badge id="container_2" //badgeContent={numberOfWaivers}
                            >
                                <WaiverIcon />
                            </Badge>
                        </IconButton>
                        : <IconButton>
                            <Badge badgeContent={numberOfWaivers} color="primary">
                                <WaiverIcon />
                            </Badge>
                        </IconButton>}>
                    <Typography color="primary" style={{ fontWeight: "bold" }} className={classes.heading}>{heading}</Typography>
                </ExpansionPanelSummary >
                {
                    grouping && equals(expanded, panelName)
                        ? <Grid container style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                            <Grid item>
                                <Button color="primary" onClick={() => handleSelectAllGroupCheckBoxes(waiver)}>
                                    <i className="material-icons">
                                        select_all
                                    </i>
                                    Select all</Button>
                            </Grid>
                            <Grid item>
                                <Button color="primary" onClick={handleClearGroupCheckBoxes}>
                                    <i className="material-icons">
                                        clear_all
                                    </i>
                                    Clear all</Button>
                            </Grid>
                            <Grid item>
                                <Button color="primary" onClick={handleOpenGroupDialog} variant="contained" size="small" className={classes.button}>
                                    <SaveIcon className={(classes.leftIcon, classes.iconSmall)} />
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                        : <div></div>
                }
                <ExpansionPanelDetails className={classes.details} >
                    {component}
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
    )

}

SignedPanel.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default SignedPanel;

