import React from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import MenuAppBar from "../../components/menuAppBar";
import withDrawer from "../../components/withDrawer";
import withFooter from "../../components/withFooter";
import EnhancedTableWaivers from "../../components/waivers/enhancedTableWaivers";
import {
  getWaiverPdf,
  setCurrentWaiver,
  setWaivers
} from "../../action-creators/waivers";
import { getSigningStatement } from "../../action-creators/signingStatements";

const waiverStyles = theme => ({
  root: {
    // width: "100%",
    flexGrow: 1,
    marginTop: theme.spacing.unit * 3
  },
  label: {
    width: "100%",
    margin: theme.spacing.unit
  },
  table: {
    //width: "100%"
    minWidth: 750
  },
  tableWrapper: {
    overflowX: "scroll"
    // width: "100%"
  },
  button: {
    margin: theme.spacing.unit
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  },
  rightIcon: {
    marginLeft: theme.spacing.unit
  },
  iconSmall: {
    fontSize: 20,
    marginRight: theme.spacing.unit
  },
  tablePaper: {
    // marginTop: theme.spacing.unit * 3,
    width: "100%",
    // overflowX: "auto",
    marginBottom: theme.spacing.unit * 1
  },
  cell: {
    // whiteSpace: 'inherit',
    //overflow: 'hidden',
    //   textOverflow: 'ellipsis',
    maxWidth: '200px',
    //overflowWrap: ""
  },
  narrowCell: {
    maxWidth: '50px',
  },
  tableCell: {
    whiteSpace: 'inherit',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '200px',
    overflowWrap: "break-word"
  },
});

const columnDataWaiver = [
  { id: "Active", tiny: true, size: "small", checkbox: true, label: "Active", sortColumn: true },
  {
    id: "Name",//padding: "none",
    disablePadding: false, label: "Waiver", align: "left"
  },
  { id: "ImageUrl", align: "center", padding: "none", hiddenMd: true, label: "" },
  {
    id: "VideoURL",
    align: "left",
    disablePadding: false,
    label: "Video URL",
    // padding: "dense"
    hiddenMd: true
  },
  {
    id: "Description",
    align: "center",
    disablePadding: false,
    //padding: "none",
    label: "Description"
  },
  {
    id: "Revision", align: "right", padding: "none",
    label: "Revision", hiddenMd: true
  },

  {
    id: "Expiration",
    align: "right",
    disablePadding: false,
    label: "Expiration",
    hiddenXs: true
  },

  {
    id: "buttons", align: "right", //disablePadding: false,
    label: ""
  }
];

const ManageWaivers = props => {
  const {
    classes,
    company,
    currentWaiver,
    dispatch,
    getSigningStatement,
    getWaiverPdf,
    setCurrentWaiver,
    setWaivers,
    signingStatements,
    waiverPdf,
    waivers,
    ind
  } = props;

  return (
    <React.Fragment>
      <MenuAppBar userYes={true} companyYes={true} />

      <EnhancedTableWaivers
        classes={classes}
        company={company}
        columnData={columnDataWaiver}
        currentWaiver={currentWaiver}
        dispatch={dispatch}
        getWaiverPdf={getWaiverPdf}
        getSigningStatement={getSigningStatement}
        setCurrentWaiver={setCurrentWaiver}
        setWaivers={setWaivers}
        signingStatements={signingStatements}
        waiverPdf={waiverPdf}
        waivers={waivers}
ind={ind}
      />
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  company: state.currentCompany.data,
  currentWaiver: state.waiver,
  waiverPdf: state.waiverPdf,
  waivers: state.waivers,
  signingStatements: state.signingStatements.statements,
  ind: state.ind
});

const mapActionsToProps = dispatch => {
  return {
    getWaiverPdf: (id, isGuardian) => dispatch(getWaiverPdf(id, isGuardian)),
    setWaivers: () => dispatch(setWaivers),
    getSigningStatement: () => dispatch(getSigningStatement()),
    setCurrentWaiver: id => dispatch(setCurrentWaiver(id)),
    dispatch
  };
};

const connector = connect(
  mapStateToProps,
  mapActionsToProps
);

export default withDrawer(
  withFooter(connector(withStyles(waiverStyles)(ManageWaivers)))
);
