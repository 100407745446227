import React from "react";
import { Link } from "react-router-dom";
import AdminIcon from "@material-ui/icons/VpnLockTwoTone";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grid from "@material-ui/core/Grid";
import Grow from "@material-ui/core/Grow";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { Settings, Receipt, AttachMoney } from "@material-ui/icons";
import { lt } from "ramda";

const options = [
  { name: "Payment History", icon: Receipt },
  { name: "Run Monthly Billing", icon: AttachMoney },
  { name: "API", icon: Settings }
];

export default function SplitAdminButton(props) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(); //React.useState(0);
  const { classes, className, role, selectAdminPage, toggleDrawer } = props;

  function handleMenuItemClick(event, index) {
    if (index === 1) {
      if (
        !window.confirm(
          "Are you sure you would like to run monthly billing for all companies?"
        )
      ) {
        return null; //toggleDrawer();
      }
    }
    setSelectedIndex(index);
    setOpen(false);
    selectAdminPage(index);
    toggleDrawer();
  }

  function handleToggle() {
    setOpen(prevOpen => !prevOpen);
  }

  function handleClose(event) {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  }
  return (
    <Grid container style={{}}>
      <Grid item xs={12}>
        <ListItem
          button
          color="primary"
          style={{ paddingTop: 0, paddingBottom: 0 }}
          //   ref={anchorRef}
          aria-label="split button"
          className={className.splitButton}
        >
          <AdminIcon color="primary" />
          <ListItemText>Admin</ListItemText>
          <IconButton
            color="primary"
            size="large"
            variant="outlined"
            aria-owns={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </IconButton>
        </ListItem>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          disablePortal
          transition
          placement="bottom" //"bottom-start"
          style={{ position: "relative" }}
        >
          {({ TransitionProps, placement }) => (
            <Grow {...TransitionProps} className={className.splitGrow}>
              <Paper id="menu-list-grow" elevation={24}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList>
                    {options.map((option, index) => (
                      <MenuItem
                        {...props}
                        key={option}
                        index={index}
                        disabled={lt(1, Number(role))}
                        selected={index === selectedIndex}
                        onClick={event => handleMenuItemClick(event, index)}
                        className={className.splitMenu}
                        color="primary"
                        component={Link}
                        to="/admin"
                      >
                        <IconButton color="primary">
                          <option.icon />
                        </IconButton>
                        <span>{option.name}</span>
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  );
}
