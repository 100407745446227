import {
    SIGNED_WAIVER_CLEARED, SIGNED_WAIVER_SET, PENDING_WAIVERS_CLEAR, PENDING_WAIVERS_FETCHED,
    RECENTLY_SIGNED_WAIVERS_CLEAR, RECENTLY_SIGNED_WAIVERS_FETCHED, SIGNED_WAIVERS_SEARCH_FETCHED, SIGNED_WAIVERS_SEARCH_CLEAR, SW_FETCHING,
    SW_NOTES_SET, SW_STATUS_UPDATED, SW_STATUS_FAILED, WAIVER_ASSIGNED, SWS_ASSIGNED,
    SEARCH_SETTINGS_CHANGED, SEARCH_SETTINGS_FETCHED, SEARCH_SETTINGS_REQUESTED, SEARCH_SETTINGS_RESET,
    SW_RECENT_EDITED, SEARCH_SETTINGS_UPDATED,
    SW_PENDING_EDITED, SW_SEARCHED_EDITED, SEARCH_SETTINGS_FAILED,
    PARTICIPANT_PDF, SW_NOTE_HISTORY_SET, SW_NOTE_ADDED, SW_NOTE_FAILED, NEW_NOTE_UPDATED, NOTE_CLEARED, NOTE_SELECTED, SW_ATTACHMENTS_SET, RESET_SWS, UPDATE_NOTES, SW_ATTACHMENTS_FAILED, FETCHING_ATTACHMENTS,
} from '../constants'
import { merge, } from 'ramda';


const initialStepper = {
    activeWaiverId: 0,
    isPending: true,
    isRecent: false,
    isSearched: false,
    swArray: []
}
export const swStepper = (state = initialStepper, action) => {
    switch (action.type) {
        case WAIVER_ASSIGNED:
            return { ...state, activeWaiverId: action.payload.activeWaiverId, }
        case SWS_ASSIGNED:
            return { ...state, isPending: action.payload.isPending, isRecent: action.payload.isRecent, isSearched: action.payload.isSearched }
        case RESET_SWS:
            return {}
        // case SET_SW_ARRAY:
        //     return Object.assign({}, state, { swArray: action.payload })
        default:
            return state
    }
}
export const pendingSW = (state = [], action) => {
    switch (action.type) {
        case PENDING_WAIVERS_FETCHED:
            return action.payload
        case SW_PENDING_EDITED:
            return action.payload
        case PENDING_WAIVERS_CLEAR:
            return []
        default:
            return state
    }
}

export const recentSW = (state = [], action) => {
    switch (action.type) {
        case RECENTLY_SIGNED_WAIVERS_FETCHED:
            return action.payload
        case SW_RECENT_EDITED:
            return action.payload
        case RECENTLY_SIGNED_WAIVERS_CLEAR:
            return []
        default:
            return state
    }
}



export const searchSettings = (state = {}, action) => {
    switch (action.type) {
        case SEARCH_SETTINGS_REQUESTED:
            return { ...state, fetching: action.payload.fetching }
        case SEARCH_SETTINGS_FETCHED:
            return { ...state, fetching: action.payload.fetching, ss: action.payload.ss, established: action.payload.established }
        case SEARCH_SETTINGS_RESET:
            return {}
        case SEARCH_SETTINGS_UPDATED:
            return action.payload
        case SEARCH_SETTINGS_CHANGED:
            return action.payload
        case SEARCH_SETTINGS_FAILED:
            return { ...state, errMsg: action.payload.errMsg }
        default:
            return state
    }
}



export const searchedSW = (state = [], action) => {
    switch (action.type) {
        case SIGNED_WAIVERS_SEARCH_FETCHED:
            return action.payload
        case SW_SEARCHED_EDITED:
            return action.payload
        case SIGNED_WAIVERS_SEARCH_CLEAR:
            return []
        default:
            return state
    }
}



const initialSignedWaiver = {
    SignedWaiver_Id: '',
    Waiver_Id: '',
    Participants: [
        {
            Id: "",
            //SignedWaiver_Id: ""
            ActivityDates: [],
            ParticipantName: "",
            Birthday: null,
            ReferenceKey: null,
            PdfFileName: ""
        }
    ],
    SignedByName: '', //same as ParticipantName property of the legal name of the person who is legally authorized to sign the doc on behald of the person to whom the doc pertains
    ContactEmail: '',
    ContactPhone: '',
    EmergencyPhone: '',
    OptIn: false,
    Signed: '', //DateTime
    Expiration: "",
    Status: 0,
    AttachmentFiles: 0, //bitwise field that idenitified the attachment Types
    ReferenceKey: '',
    WaiverRevision: "",
    Current_Waiver_Revision: "",
    Name: null,
    CurrentNotes: [],
    OtherFiles: null,
    Video: "",
    Signature: "",
    Picture: "",
    fetching: false,
    isError: false,
    errMsg: "",
    color: null,
    note: {},
    message: ""

}

export const note = (state = {}, action) => {
    switch (action.type) {
        case SW_NOTE_HISTORY_SET:
            return { ...state, noteHistory: action.payload.noteHistory }
        case NEW_NOTE_UPDATED:
            return { ...state, Text: action.payload }
        case NOTE_SELECTED:
            return { ...state, Id: action.payload }
        case NOTE_CLEARED:
            return {}
        case PARTICIPANT_PDF:
            return { ...state, pdf: action.payload.pdf }
        default:
            return state
    }
}
export const signedWaiver = (state = initialSignedWaiver, action) => {
    switch (action.type) {
        case SIGNED_WAIVER_SET:
            return action.payload
        case UPDATE_NOTES:
            return { ...state, CurrentNotes: action.payload.CurrentNotes, Notes: action.payload.Notes }
        case SW_FETCHING:
            return { ...state, fetching: action.payload.fetching }

        case SW_NOTES_SET:
            return { ...state, Notes: action.payload.Notes }
        case SW_NOTE_FAILED:
            return Object.assign({}, state, { isError: true, errMsg: action.payload })
        case SW_NOTE_ADDED:
            return Object.assign({}, state, { Notes: action.payload })
        //        case SW_ACCEPTED:
        //     return Object.assign({}, state, { Status: 1 })
        // case SW_REJECTED:
        //     return Object.assign({}, state, { Status: -1 })
        case SW_STATUS_UPDATED:
            return Object.assign({}, state, { message: action.payload })
        case SW_STATUS_FAILED:
            return merge(state, { isError: true, errMsg: action.payload })
        // case ACTIVITY_DATE_ADDED:
        //     return action.payload
        case SIGNED_WAIVER_CLEARED:
            return initialSignedWaiver

        default:
            return state
    }
}
export const swAttachments = (state = {}, action) => {
    switch (action.type) {
        case SW_ATTACHMENTS_SET:
            return {
                ...state, signatureUrl: action.payload.signatureUrl, pictureUrl: action.payload.pictureUrl, videoUrl: action.payload.videoUrl,
                participantPdfs: action.payload.participantPdfs, fetching: false
            }
        case FETCHING_ATTACHMENTS:
            return { ...state, failed: false, fetching: true }
        case SW_ATTACHMENTS_FAILED:
            return { ...state, failed: true, fetching: false }
        default:
            return state
    }
}