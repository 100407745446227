import React, { Component } from "react";
import PropTypes from "prop-types";
import Hidden from "@material-ui/core/Hidden";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CheckBox from "@material-ui/core/Checkbox";
import EnhancedTableToolbar from "../enhancedAppBar";
import EnhancedTableHead from "../tableHead";
import { CURRENT_WAIVER_CLEARED } from "../../constants";
import { equals, isEmpty } from "ramda";
import moment from "moment";
import NewPopup from "./newForm";
import EditPopup from "./editForm";
import { descend, sort, prop, ascend, isNil, endsWith, gt } from "ramda";
import Hover from "../hover";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import EditIcon from "@material-ui/icons/Edit";
import PDFIcon from "@material-ui/icons/PictureAsPdfTwoTone";
import WebIcon from "@material-ui/icons/DesktopMacTwoTone";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import List from "@material-ui/core/List";
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { getTimes } from '../../lib/offsetSeconds'
import { over50Years } from "../../lib/dateHelpers";
import LoadingWaivers from "./loadingWaivers";
const url = process.env.REACT_APP_URL
class EnhancedTableWaivers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      openNew: false,
      openEdit: false,
      order: "desc",
      ordered: true,
      orderBy: "Active",
      selected: [],
      data: [],
      dialogAction: "",
      page: 0,
      rowsPerPage: 10,
      checkedValues: [],
      isActive: [],
      checked: false
    };
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   if (this.props.waiverPdf.objUrl !== nextProps.waiverPdf.objUrl) {
  //     window.open(nextProps.waiverPdf.objUrl);
  //     return false;
  //   }
  //   return true;
  // }
  createSortHandler = () => {
    if (this.state.ordered) {
      this.setState({ ordered: false, order: "asc" });
    } else {
      this.setState({ ordered: true, order: "desc" });
    }
    // this.setState(state => ({ ordered: !state.ordered }))
  };

  handleClick = (event, id) => {
    const { isActive } = this.state;
    const selectedActive = isActive.indexOf(id);
    let newActive = [];

    if (selectedActive === -1) {
      newActive = newActive.concat(isActive, id);
    } else if (selectedActive === 0) {
      newActive = newActive.concat(isActive.slice(1));
    } else if (selectedActive === isActive.length - 1) {
      newActive = newActive.concat(isActive.slice(0, -1));
    } else if (selectedActive > 0) {
      newActive = newActive.concat(
        isActive.slice(0, selectedActive),
        isActive.slice(selectedActive + 1)
      );
    }
    this.setState({ isActive: newActive });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleOpenNew = () => {
    this.setState({ openNew: true });
  };

  handleCloseEdit = () => {
    this.setState({ openEdit: false });
    this.props.dispatch({ type: CURRENT_WAIVER_CLEARED });
  };
  handleSetState = () => {
    this.setState({ openEdit: true });
  };
  handleOpenEdit = event => {
    const id = Number(event.currentTarget.id);
    this.props.setCurrentWaiver(id);
    this.handleSetState();
  };

  handleCloseNew = () => {
    this.setState({ openNew: false });
  };

  isActive = Id => this.state.isActive.indexOf(Id) !== -1;

  componentWillMount() {
    this.props.getSigningStatement();
  }

  render() {
    const {
      classes,
      columnData,
      company,
      waivers,
      getWaiverPdf,
      signingStatements,
      ind
    } = this.props;
    const {
      order,
      orderBy,
      selected,
      rowsPerPage,
      page,
      isActive,
      ordered
    } = this.state;
    console.log("waivers", ind)
    let pPdfHover
    let gPdfHover
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      pPdfHover = "Download Participant Waiver"
      gPdfHover = "Download Guardian Waiver"
    } else {
      pPdfHover = "View Participant Waiver"
      gPdfHover = "View Guardian Waiver"
    }
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, waivers.length - page * rowsPerPage);
    let custSort;
    if (ordered) {
      custSort = sort(descend(prop("Active")), waivers);
    } else {
      custSort = sort(ascend(prop("Active")), waivers);
    }
    // console.log("url", url)
    let urlHead
    if (endsWith('/', url)) {
      urlHead = `${url}Sign`
    } else {
      urlHead = `${url}/Sign`
    }
    const formatOffSet = (w) => {
      if (gt(w.ExpirationOffset, 0)) {
        const data = getTimes(w.ExpirationOffset)
        return data.view
      } else {
        return w.ExpirationOffset
      }
    }
    return (
      <React.Fragment>
        <EditPopup
          key={this.props.currentWaiver.Id}
          open={this.state.openEdit}
          handleClose={this.handleCloseEdit}
          waiver={this.props.currentWaiver}
          title="Edit Waiver"
          editWaiver={this.props.editWaiver}
        />

        <div className={classes.root}>
          <Paper className={classes.tablePaper}>
            <EnhancedTableToolbar
              title="Waivers"
              button1={
                <Hover
                  message="Add Waiver"
                  component={
                    <Button
                      className={classes.label}
                      variant="contained"
                      color="primary"
                      onClick={this.handleOpenNew}
                    >
                      <AddIcon className={classes.leftIcon} /> Waiver
                  </Button>
                  }
                />
              }
            />
            <React.Fragment>
              {ind.loading 
               ? (<LoadingWaivers />) : (
                <div className={classes.tableWrapper}>

                  {!waivers.isFetching && !equals(waivers.length, 0) ? (

                    <List style={{ tableLayout: "auto", }}
                      className={classes.table}
                      aria-labelledby="tableTitle"
                      id="tableTitle" size="small"
                    >

                      <EnhancedTableHead
                        //  addWaiver
                        custButton={true}
                        content={
                          <Button onClick={this.handleOpenNew}>
                            <AddIcon />
                            &nbsp; Waiver
                        </Button>
                        }
                        columnData={columnData}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={this.handleRequestSort}
                        createSortHandler={this.createSortHandler}
                        rowCount={waivers.length}

                      />
                      {/* <TableBody> */}
                      {custSort
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((waiver, index) => {
                          const isActive = this.isActive(waiver.Id);
                          return (
                            <Button fullWidth onClick={event =>
                              this.handleClick(event, waiver.Id)
                            } key={waiver.Id} role="switch"
                              aria-checked={isActive}
                              tabIndex={-1}
                              key={index}
                              selected={isActive}>

                              <ListItem divider key={waiver.Id}>
                                <Grid container spacing={0} zeroMinWidth justify="space-between" alignItems="center">


                                  <Grid item xs={1}>     <CheckBox
                                    value={waiver.Active ? `true` : `false`}
                                    checked={waiver.Active}
                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                    waiver={waiver}
                                    key={waiver.Id}
                                    disabled
                                  /></Grid>
                                  <Grid item xs>
                                    <Typography >{waiver.Name}</Typography>
                                    {!isEmpty(waiver.ImageUrl) ? <Hidden lgUp>  <img
                                      src={`${waiver.ImageUrl}`}
                                      alt={waiver.ImageUrl}
                                      width="150"
                                      height="100"
                                    /> </Hidden> : ""}
                                  </Grid>


                                  <Hidden mdDown>
                                    <Grid item xs>
                                      <img
                                        src={`${waiver.ImageUrl}`}
                                        alt={waiver.ImageUrl}
                                        width="150"
                                        height="100"
                                      />
                                    </Grid>
                                  </Hidden>
                                  <Hidden mdDown><Grid xs> <a
                                    style={{ textDecoration: "none" }}
                                    href={waiver.VideoURL}
                                    title={waiver.VideoURL}
                                    target="_blank"

                                  >{waiver.VideoURL}

                                  </a></Grid></Hidden>

                                  <Grid item xs >
                                    <List dense>

                                      <ListItem dense>
                                        <ListItemText primary={waiver.Description} />
                                      </ListItem>
                                      <ListItem dense alignItems="flex-start">
                                        <Typography variant="caption"><b>Link:</b></Typography>
                                        <ListItemText primary={
                                          <a
                                            style={{ textDecoration: "none" }}
                                            href={`${urlHead}/${waiver.Id}`}
                                            title={`${urlHead}/${waiver.Id}`}
                                            target="_blank"

                                          >{`${url}/Sign/${waiver.Id}`}</a>} />
                                      </ListItem>

                                      <Hidden lgUp>
                                        {!isNil(waiver.VideoURL) ? <ListItem dense alignItems="flex-start">

                                          <Typography variant="caption"><b>Video:</b></Typography>
                                          <ListItemText primary={<a
                                            style={{ textDecoration: "none" }}
                                            href={waiver.VideoURL}
                                            title={waiver.VideoURL}
                                            target="_blank"

                                          >{waiver.VideoURL}
                                          </a>} /></ListItem> : ""}
                                        <ListItem dense alignItems="flex-start">
                                          <Typography variant="caption">Revision:</Typography>
                                          <ListItemText primary={waiver.Revision} />
                                        </ListItem>
                                      </Hidden>
                                      <Hidden smUp ><ListItem dense alignItems="flex-start"><Typography variant="caption">Expiration Date:</Typography> <ListItemText secondary={over50Years(waiver.Expiration) ? ("") : moment(waiver.Expiration).format(
                                        "MM/DD/YYYY hh:mm A"
                                      )} /></ListItem>
                                        <ListItem alignItems="flex-start" dense><Typography variant="caption">Expiration Offset: </Typography>
                                          <ListItemText primary={formatOffSet(waiver)} />
                                        </ListItem></Hidden>

                                    </List>
                                  </Grid>
                                  <Hidden mdDown>
                                    <Grid item xs={1} align="center">
                                      <Typography>
                                        {waiver.Revision} </Typography></Grid></Hidden>

                                  <Hidden xsDown><Grid item xs>


                                    <ListItem dense alignItems="flex-start"><Typography variant="caption">Date: </Typography> <ListItemText secondary={over50Years(waiver.Expiration) ? "" : moment(waiver.Expiration).format(
                                      "MM/DD/YYYY hh:mm A"
                                    )} /></ListItem>
                                    <ListItem alignItems="flex-start" dense><Typography variant="caption">Offset: </Typography>
                                      <ListItemText primary={formatOffSet(waiver)} />
                                    </ListItem>
                                  </Grid></Hidden>
                                  <Grid item xs>
                                    <Grid
                                      container
                                      direction="column"
                                      justify="space-between"
                                    >
                                      <Grid item>
                                        <Hover
                                          message="Edit Waiver"
                                          component={
                                            <Button
                                              size="small"
                                              id={waiver.Id}
                                              aria-label="Edit Waiver"
                                              variant="contained"
                                              color="default"
                                              waiver={waiver}
                                              className={classes.label}
                                              company={company}
                                              onClick={this.handleOpenEdit}
                                            >
                                              <EditIcon />
                                            </Button>
                                          }
                                        />
                                        <Hover
                                          message={pPdfHover}
                                          component={
                                            <Button
                                              size="small"
                                              aria-label={pPdfHover}
                                              className={classes.label}
                                              variant="contained"
                                              onClick={e =>
                                                getWaiverPdf(waiver.Id, false)
                                              }
                                            >
                                              <PDFIcon
                                                className={classes.iconSmall}
                                              />
                                              Participant
                                        </Button>
                                          }
                                        />
                                        <Hover
                                          message={gPdfHover}
                                          component={
                                            <Button
                                              size="small"
                                              aria-label={gPdfHover}
                                              className={classes.label}
                                              variant="contained"
                                              color="default"

                                              onClick={e =>
                                                getWaiverPdf(waiver.Id, true)

                                              }
                                            >
                                              <PDFIcon
                                                className={classes.iconSmall}
                                              />
                                              Guardian
                                        </Button>
                                          }
                                        />
                                      </Grid>
                                    </Grid>
                                  </Grid>

                                </Grid></ListItem>
                            </Button>
                          );
                        })}


                    </List>

                  ) : (

                      <Grid
                        container
                        justify="center"
                        alignItems="center"
                        style={{ height: "30em" }}
                      >
                        <Grid item>
                          <Typography variant="headline">
                            No waivers registered
                      </Typography>
                        </Grid>
                      </Grid>
                      // </React.Fragment>
                    )}
                  {/* <div> */}
                </div>
              )}
            </React.Fragment>
            <TablePagination
              component="div"
              count={waivers.length}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                "aria-label": "Previous Page"
              }}
              nextIconButtonProps={{
                "aria-label": "Next Page"
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />    </Paper>

        </div>
        {/* </Paper> */}
        <NewPopup
          open={this.state.openNew}
          handleClose={this.handleCloseNew}
          title="New Waiver"
        />
      </React.Fragment>
    );
  }
}

EnhancedTableWaivers.propTypes = {
  classes: PropTypes.object.isRequired
};

export default EnhancedTableWaivers;
