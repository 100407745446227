import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  currentCompany,
  companies,
  // newCompany,
  // editCompany,
  companyDetails
} from "./reducers/companies";

import { users, newUser, editUser } from "./reducers/users";
//import { drawer } from './reducers/funtionality'
//import { currentWaiver, waivers, editWaiver, newWaiver, waiverPdf, waiverUsage, waiverContacts, currentCompanyWaivers } from './reducers/waivers'
import { helpVideos } from "./reducers/help";

import { paymentHistory, monthlyBilling, apiHelpPage } from "./reducers/admin";

import { waivers, waiverPdf, waiverUsage, waiver } from "./reducers/waivers";

import {
  // addNewCard,
  // currentBillingData,
  // billingData,
  newBillingData,
  // editBillingData,
  companyPaymentDetails,
  waiverRates,
  transactionInformation
} from "./reducers/billing";

import { locations } from "./reducers/location";
import { signingStatements } from "./reducers/signingStatements";
import {
  signedWaiver,
  recentSW,
  pendingSW,
  searchedSW,
  swStepper,
  searchSettings,
  note,
  swAttachments
} from "./reducers/signedWaivers";
import { drawer, ind, tempData } from "./reducers/funtionality";
import { authentication } from "./reducers/authentication";
import { signalR } from './reducers/signalR'
const middlewares = [];

if (process.env.NODE_ENV === `development`) {
  // const { logger } = require(`redux-logger`);

  middlewares.push();
}



const enhancer = composeWithDevTools(
  applyMiddleware(thunk, ...middlewares)
  // other store enhancers if any
);
const store = createStore(
  combineReducers({
    apiHelpPage,
    companies,
    companyDetails,
    companyPaymentDetails,
    currentCompany,
    editUser,
    helpVideos,
    locations,
    monthlyBilling,
    newBillingData,
    newUser,
    paymentHistory,
    signingStatements,
    transactionInformation,
    users,
    waiverRates,
    drawer,
    ind,
    tempData,
    signedWaiver,
    recentSW,
    pendingSW,
    searchedSW,
    swStepper,
    searchSettings,
    note,
    waiver,
    waivers,
    waiverPdf,
    waiverUsage,
    swAttachments,
    authentication,
    signalR
  }),
  enhancer
);

export default store;
