import fetch from 'isomorphic-fetch'
import {
    SIGNED_WAIVER_SET,
    PENDING_WAIVERS_FETCHED, RECENTLY_SIGNED_WAIVERS_FETCHED, SW_FETCHING,
    SW_NOTES_SET, SW_STATUS_FAILED, SWS_ASSIGNED, SIGNED_WAIVERS_SEARCH_FETCHED,
    SEARCH_SETTINGS_REQUESTED, SEARCH_SETTINGS_FETCHED, SEARCH_SETTINGS_CHANGED, ACTIVITY_DATE_ADDED, SEARCH_SETTINGS_FAILED,
    PARTICIPANT_PDF, SW_NOTE_HISTORY_SET, SW_NOTE_FAILED,
    SIGNED_WAIVER_CLEARED, SW_STATUS_UPDATED,
    SW_ATTACHMENTS_SET, UPDATE_NOTES, SW_NOTE_ADDED, SW_ATTACHMENTS_FAILED, FETCHING_ATTACHMENTS, FETCHING
} from '../constants'
import { has, find, propEq, gt, split, last, dropLast, join, length, not, contains, merge, append, findIndex, map } from 'ramda'
import {
    coalesce,
} from '../lib/fetch'
import { formatToday, formatMoment, today } from '../lib/dateHelpers'
const url = process.env.REACT_APP_URL
const baseHeaders = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': window.location.protocol + "//" + window.location.host,
    'Authorization': `Bearer ${coalesce(sessionStorage.getItem('access_token'), localStorage.getItem('access_token'))}`
}
const localHeaders = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${coalesce(sessionStorage.getItem('access_token'), localStorage.getItem('access_token'))}`
}

export const getParticipantPdf = participant => (dispatch, getState) => {
    const pdfs = getState().swAttachments.participantPdfs
    const p = find(propEq('participantId', Number(participant)), pdfs)
    dispatch({ type: PARTICIPANT_PDF, payload: { pdf: p.pdfUrl } })
    // window.open(p.pdfUrl)
}


export const addActivityDate = (id) => async (dispatch, getState) => {

    const response = await fetch(url + '/api/signedWaivers/addActivityDate', {
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': window.location.protocol + "//" + window.location.host,
            'Authorization': `Bearer ${coalesce(sessionStorage.getItem('access_token'), localStorage.getItem('access_token'))}`
        },
        method: "POST",
        body: JSON.stringify({ Participant_Id: id, Date: formatToday })

    }).then(res => res.json()).catch(err => console.log("err: ", err))

    dispatch({ type: ACTIVITY_DATE_ADDED, payload: response })
}


export const getSearchSettings = () => async (dispatch, getState) => {
    dispatch({ type: SEARCH_SETTINGS_REQUESTED, payload: { fetching: true } })
    const ss = await fetch(url + `/api/SignedWaivers/SearchSettings`, {
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': window.location.protocol + "//" + window.location.host,
            'Authorization': `Bearer ${coalesce(sessionStorage.getItem('access_token'), localStorage.getItem('access_token'))}`
        }
    }).then(res => res.json())
        .catch(err => console.log("err: ", err))
    dispatch({ type: SEARCH_SETTINGS_FETCHED, payload: { fetching: false, ss: ss, established: true } })
}

export const changeSearchSettings = newSS => async (dispatch, getState) => {

    const ss = getState().searchSettings.ss
    const ssNew = {
        dateIsBirthday: ss.dateIsBirthday,
        asSigner: ss.asSigner,
        asParticipant: ss.asParticipant,
        searchExpired: ss.searchExpired,
        searchNotExpired: ss.searchNotExpired,
        includeRejected: ss.includeRejected,
    }
    const response = await fetch(url + `/api/SignedWaivers/SearchSettings`, {
        headers: {
            "Content-Type": "application/json",
            'Access-Control-Allow-Origin': window.location.protocol + "//" + window.location.host,
            'Authorization': `Bearer ${coalesce(sessionStorage.getItem('access_token'), localStorage.getItem('access_token'))}`
        },
        method: "POST",
        body: JSON.stringify(ssNew)
    })
        .then(res => res.json())
        .catch(err => console.log("err: ", err))

    if (response) {
        // console.log("response", response)
        dispatch({
            type: SEARCH_SETTINGS_CHANGED, payload: response
        })
    } else {
        dispatch({ type: SEARCH_SETTINGS_FAILED, payload: "failed to save settings" })
    }
}


export const setRecentSW = async (dispatch, getState) => {
    const authHeaders = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': window.location.protocol + "//" + window.location.host,
        'Authorization': `Bearer ${coalesce(sessionStorage.getItem('access_token'), localStorage.getItem('access_token'))}`
    }

    const response = await fetch(url + `/api/SignedWaivers/SignedRecently?includeRejected=true`, {
        headers: authHeaders
    })
        .then(res => res.json())
        .catch(err => console.log('err: ', err));
    if (!response) {
        return
    }
    if (has('ExceptionMessage')(response)) {
        return
    }


    dispatch({ type: RECENTLY_SIGNED_WAIVERS_FETCHED, payload: response })
}

export const setPendingSW = async (dispatch, getState) => {
    const authHeaders = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': window.location.protocol + "//" + window.location.host,
        'Authorization': `Bearer ${coalesce(sessionStorage.getItem('access_token'), localStorage.getItem('access_token'))}`
    }
    const response = await fetch(url + `/api/SignedWaivers/PendingApproval`, {
        headers: authHeaders
    })

        .then(res => res.json())
        .catch(err => console.log('err: ', err));

    if (!response) {
        return
    }
    if (has('ExceptionMessage')(response)) {
        return
    }

    dispatch({ type: PENDING_WAIVERS_FETCHED, payload: response })
}

export const initiateSWStepper = (dispatch, getState) => {
    if (getState().swStepper.activeWaiverId === 0) {
        dispatch({ type: SWS_ASSIGNED, payload: { isPending: true, isRecent: false, isSearched: false } })
    }
}

export const setSearchedSW = input => async (dispatch, getState) => {
    dispatch({
        type: FETCHING
        , payload: true
    })
    const pieces = split(' ', input)
    const searchLength = length(pieces)
    const lastPiece = last(pieces)
    const firstPieces = dropLast(1, pieces)
    const textPiece = join(' ', firstPieces)
    let inputDate
    let inputText
    if (searchLength === 1 && contains('/', input)) {
        inputDate = formatMoment(input)
        inputText = ""
    } else if (searchLength === 1 && not(contains('/', input))) {
        inputDate = null
        inputText = input
    } else if (gt(searchLength, 1) && contains('/', lastPiece)) {
        inputDate = formatMoment(lastPiece)
        inputText = textPiece
    } else {
        inputDate = null
        inputText = input
    }
    const authHeaders = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': window.location.protocol + "//" + window.location.host,
        'Authorization': `Bearer ${coalesce(sessionStorage.getItem('access_token'), localStorage.getItem('access_token'))}`
    }
    const ss = getState().searchSettings.ss
    const dateIsBirthday = ss.dateIsBirthday
    const asSigner = ss.asSigner
    const asParticipant = ss.asParticipant
    const searchExpired = ss.searchExpired
    const searchNotExpired = ss.searchNotExpired
    const includeRejected = ss.includeRejected

    const response = await fetch(url + `/api/SignedWaivers/Search?name=${inputText}&date=${inputDate}&dateIsBirthday=${dateIsBirthday}&asSigner=${asSigner}&asParticipant=${asParticipant}&searchExpired=${searchExpired}&searchNotExpired=${searchNotExpired}&includeRejected=${includeRejected}`, {
        headers: authHeaders,
        mode: "cors",
        cache: "no-cache",
    })
        .then(res => res.json())
        .catch(err => console.log('err: ', err));

    if (!response) { return }
    if (has('ExceptionMessage')(response)) {
        return
    }
    dispatch({ type: FETCHING, payload: false })
    dispatch({ type: SIGNED_WAIVERS_SEARCH_FETCHED, payload: response })
    dispatch({ type: SWS_ASSIGNED, payload: { waivers: response, isPending: false, isRecent: false, isSearched: true } })


}
// without doing a complete call for the signedWaiver
export const setCurrentSW = id => (dispatch, getState) => {
    dispatch({ type: SIGNED_WAIVER_CLEARED })
    dispatch({ type: SW_FETCHING, payload: { fetching: true } })
    const swStepper = getState().swStepper
    let sws
    if (swStepper.isRecent === true) {
        sws = getState().recentSW
    } else if (swStepper.isSearched === true) {
        sws = getState().searchedSW
    } else {
        sws = getState().pendingSW
    }
    const swArray = find(propEq('Waiver_Id', swStepper.activeWaiverId), sws).SignedWaivers
    const sw = find(propEq('SignedWaiver_Id', id), swArray)
    dispatch({ type: SIGNED_WAIVER_SET, payload: sw })

    // dispatch(getAttachments(id))
    // dispatch(getSWNotes(id))
}
export const getAttachments = id => async (dispatch, getState) => {
    dispatch({ type: FETCHING_ATTACHMENTS })
    const attachments = await fetch(`/api/SignedWaiver/Attachments/${id}`, {
        headers: localHeaders
    })
        .then(res => res.json())
        // .catch(err => console.log("err: ", err))
        .catch(err => dispatch({ type: SW_ATTACHMENTS_FAILED }))
    dispatch({ type: SW_ATTACHMENTS_SET, payload: attachments })
    // console.log("attachments", attachments)
}

export const getSW = id => async (dispatch, getState) => {
    dispatch({ type: SIGNED_WAIVER_CLEARED })
    dispatch({ type: SW_FETCHING, payload: { fetching: true } })


    const sw = await fetch(url + `/api/SignedWaivers/Waiver?signedWaiverId=${id}`, {
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': window.location.protocol + "//" + window.location.host,
            'Authorization': `Bearer ${coalesce(sessionStorage.getItem('access_token'), localStorage.getItem('access_token'))}`
        }
    })
        .then(res => res.json())
        .catch(err => console.log("err: ", err))

    dispatch({ type: SIGNED_WAIVER_SET, payload: sw })
    dispatch(getAttachments(id))
    dispatch(getSWNotes(id))
}


export const getSWNotes = id => async (dispatch, getState) => {
    const authHeaders = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': window.location.protocol + "//" + window.location.host,
        'Authorization': `Bearer ${coalesce(sessionStorage.getItem('access_token'), localStorage.getItem('access_token'))}`
    }
    const response = await fetch(url + `/api/signedWaivers/GetNoteHistory?signedWaiverId=${id}`, {
        headers: authHeaders
    })

        .then(res => res.json())
        .catch(err => console.log('err: ', err));

    dispatch({ type: SW_NOTES_SET, payload: { Notes: response } })

}

export const getNoteHistory = (id) => async (dispatch, getState) => {
    const sw = getState().signedWaiver.SignedWaiver_Id
    const note = await fetch(url + `/api/signedWaivers/GetNoteHistory?signedWaiverId=${sw}&noteId=${id}`, {
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': window.location.protocol + "//" + window.location.host,
            'Authorization': `Bearer ${coalesce(sessionStorage.getItem('access_token'), localStorage.getItem('access_token'))}`
        }
    })
        .then(res => res.json())
        .catch(err => console.log('err: ', err));
    dispatch({ type: SW_NOTE_HISTORY_SET, payload: { noteHistory: note } })
}
export const updateSWNotes = (note) => (dispatch, getState) => {
    const swNotes = getState().signedWaiver.CurrentNotes
    const notesHistory = getState().signedWaiver.Notes
    if (note.version) {
        const editednote = { Text: note.note, Version: note.Version + 1, Created: today, Id: note.Id }
        const n = find(propEq("Id", note.Id), swNotes)
        const i = findIndex(propEq("Id", note.Id), swNotes)
        const nn = merge(n, editednote)
        const newCurrentNotes = Object.assign([], swNotes, { [i]: nn })
        const newNotes = append(nn, notesHistory)
        dispatch({ type: UPDATE_NOTES, payload: { CurrentNotes: newCurrentNotes, Notes: newNotes } })
        dispatch(editSWNote(note))
    } else {
        //  dispatch({type: UPDATE_NOTES, payload: {CurrentNotes: n, Notes: n}})
    }

}
//if this succeeds it will get a response thru signalr and update the status/color and signed waivers

export const statusUpdated = sw => async (dispatch, getState) => {

    const id = Number(sw.SignedWaiver_Id)
    const status = Number(sw.Status)
    const userId = Number(getState().authentication.Id)
    const response = await fetch(`/api/SignedWaiver/Status/id=${id}&userId=${userId}&newStatus=${status}`, {
        headers: localHeaders,
        method: "PUT",
    })
        .then(res => res.json())
        .catch(err => dispatch({ type: SW_STATUS_FAILED, payload: err }))
    dispatch({ type: SW_STATUS_UPDATED, payload: response })

}

export const addSWNote = n => (dispatch, getState) => {
    const id = n.swId
    const note = n.note
    const notes = getState().signedWaiver.Notes
    fetch(`/api/SignedWaiver/NewNote/${id}`, {
        headers: localHeaders,
        method: "POST",
        body: JSON.stringify({ Id: -1, Text: note, CreatedBy: getState().authentication.Id })
    })
        .then(res => {
            if (res.status === 200) {
                //  res.json().then(d => dispatch({ type: SW_NOTE_ADDED, payload: append(d, notes) }))
                res.json().then(d => dispatch({ type: SW_NOTES_SET, payload: { Notes: map(n=> Object.assign({}, {Version: n.version, Text: n.text, Id: n.id, Created: n.created, CreatedBy: n.createdBy}))(d) } }))
            } else {
                res.json().then(err => dispatch({ type: SW_NOTE_FAILED, payload: err }))
            }
        })
}

export const editSWNote = n => (dispatch, getState) => {

    const id = n.swId
    const note = n.note
    const nId = n.nId
    const notes = getState().signedWaiver.Notes
    //  const version=n.
    fetch(`/api/SignedWaiver/NewNote/${id}`, {
        headers: localHeaders,
        method: "POST",
        body: JSON.stringify({ Id: nId, Text: note, CreatedBy: getState().authentication.Id })
    }).then(res => {
        if (res.status === 200) {
            // res.json().then(d => dispatch({ type: SW_NOTE_ADDED, payload: append(d, notes) }))
            res.json().then(d => dispatch({ type: SW_NOTES_SET, payload: { Notes: map(n=> Object.assign({}, {Version: n.version, Text: n.text, Id: n.id, Created: n.created, CreatedBy: n.createdBy}))(d) } }))
        } else {
            res.json().then(err => dispatch({ type: SW_NOTE_FAILED, payload: err }))
        }
    })

}

