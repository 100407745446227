import React from 'react';
import Menu from '@material-ui/core/Menu';
import MoreVertIcon from '@material-ui/icons/Menu'
import Hover from '../hover'
import { T, F, keys, pickBy, } from 'ramda'
import Divider from '@material-ui/core/Divider';
import { List, ListSubheader, ListItem, ListItemText, ListItemSecondaryAction, Switch, IconButton, Grid, Button } from '@material-ui/core'
import { SEARCH_SETTINGS_UPDATED, } from '../../constants';
import { styles } from './swStyles'
import withStyles from '@material-ui/core/styles/withStyles'

class SimpleMenu extends React.PureComponent {
    state = {
        anchorEl: null,
    };

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    render() {
        const { anchorEl } = this.state;
        const { classes, searchSettings, changeSearchSettings, dispatch } = this.props

        return (
            <div >
                <Hover message="Filter Search Options" component={
                    <IconButton color="primary" size="small" className={classes.iconButton} aria-owns={anchorEl ? 'simple-menu' : undefined}
                        aria-haspopup="true"
                        onClick={this.handleClick} aria-label="More">
                        <MoreVertIcon />
                    </IconButton>} />

                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={this.handleClose}
                >

                    <SwitchListSecondary dispatch={dispatch} classes={classes} searchSettings={searchSettings} handleCancel={this.handleClose} changeSearchSettings={changeSearchSettings} />
                </Menu>
            </div>
        );
    }
}

export default withStyles(styles)(SimpleMenu)




class SwitchListSecondary extends React.PureComponent {
    state = {
        checked: [],
    };
    componentWillMount() {
        const isTrue = (value, key) => T(value) === value
        const isChecked = pickBy(isTrue, this.props.searchSettings.ss)
        const keysChecked = keys(isChecked)
        this.setState({ checked: keysChecked })
    }


    handleToggle = value => () => {
        const { checked, } = this.state;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
            if (value === "searchNotExpired") {
                this.props.dispatch({ type: SEARCH_SETTINGS_UPDATED, payload: { ...this.props.searchSettings, ss: { ...this.props.searchSettings.ss, searchNotExpired: true } } })
            }
            if (value === "searchExpired") {
                this.props.dispatch({ type: SEARCH_SETTINGS_UPDATED, payload: { ...this.props.searchSettings, ss: { ...this.props.searchSettings.ss, searchExpired: true } } })
            }
            if (value === "asSigner") {
                this.props.dispatch({ type: SEARCH_SETTINGS_UPDATED, payload: { ...this.props.searchSettings, ss: { ...this.props.searchSettings.ss, asSigner: true } } })
            }
            if (value === "asParticipant") {
                this.props.dispatch({ type: SEARCH_SETTINGS_UPDATED, payload: { ...this.props.searchSettings, ss: { ...this.props.searchSettings.ss, asParticipant: true } } })
            }
            if (value === "includeRejected") {
                this.props.dispatch({ type: SEARCH_SETTINGS_UPDATED, payload: { ...this.props.searchSettings, ss: { ...this.props.searchSettings.ss, includeRejected: true } } })
            }
            if (value === "dateIsBirthday") {
                this.props.dispatch({ type: SEARCH_SETTINGS_UPDATED, payload: { ...this.props.searchSettings, ss: { ...this.props.searchSettings.ss, dateIsBirthday: true } } })
            }
        } else {
            newChecked.splice(currentIndex, 1);
            if (value === "searchNotExpired") {
                this.props.dispatch({ type: SEARCH_SETTINGS_UPDATED, payload: { ...this.props.searchSettings, ss: { ...this.props.searchSettings.ss, searchNotExpired: false } } })
            }
            if (value === "searchExpired") {
                this.props.dispatch({ type: SEARCH_SETTINGS_UPDATED, payload: { ...this.props.searchSettings, ss: { ...this.props.searchSettings.ss, searchExpired: false } } })
            }
            if (value === "asSigner") {
                this.props.dispatch({ type: SEARCH_SETTINGS_UPDATED, payload: { ...this.props.searchSettings, ss: { ...this.props.searchSettings.ss, asSigner: false } } })
            }
            if (value === "asParticipant") {
                this.props.dispatch({ type: SEARCH_SETTINGS_UPDATED, payload: { ...this.props.searchSettings, ss: { ...this.props.searchSettings.ss, asParticipant: false } } })
            }
            if (value === "includeRejected") {
                this.props.dispatch({ type: SEARCH_SETTINGS_UPDATED, payload: { ...this.props.searchSettings, ss: { ...this.props.searchSettings.ss, includeRejected: false } } })
            }
            if (value === "dateIsBirthday") {
                this.props.dispatch({ type: SEARCH_SETTINGS_UPDATED, payload: { ...this.props.searchSettings, ss: { ...this.props.searchSettings.ss, dateIsBirthday: false } } })
            }
        }

        this.setState({
            checked: newChecked,// unChecked: newUnChecked
        });
    };
    handleSaveSettings = () => {
        this.props.changeSearchSettings()
        this.props.handleCancel()
    }

    render() {
        const { classes, handleCancel, handleSaveSettings } = this.props;
        return (
            <List dense subheader={<ListSubheader>Settings</ListSubheader>} className={classes.options}>
                <ListItem>

                    <ListItemText primary="Search waivers that are not expired" />
                    <ListItemSecondaryAction>
                        <Switch color="primary"
                            onChange={this.handleToggle('searchNotExpired')}
                            checked={this.state.checked.indexOf('searchNotExpired') !== -1}
                        />
                    </ListItemSecondaryAction>
                </ListItem>
                <ListItem>

                    <ListItemText primary="Search expired waivers" />
                    <ListItemSecondaryAction>
                        <Switch color="primary"
                            onChange={this.handleToggle('searchExpired')}
                            checked={this.state.checked.indexOf('searchExpired') !== -1}
                        />
                    </ListItemSecondaryAction>
                </ListItem>
                <Divider />
                <ListItem>

                    <ListItemText primary="Search by signer" />
                    <ListItemSecondaryAction>
                        <Switch color="primary"
                            onChange={this.handleToggle('asSigner')}
                            checked={this.state.checked.indexOf('asSigner') !== -1}
                        />
                    </ListItemSecondaryAction>
                </ListItem>
                <ListItem>

                    <ListItemText primary="Search by participant" />
                    <ListItemSecondaryAction>
                        <Switch color="primary"
                            onChange={this.handleToggle('asParticipant')}
                            checked={this.state.checked.indexOf('asParticipant') !== -1}
                        />
                    </ListItemSecondaryAction>
                </ListItem>
                <Divider />
                <ListItem>

                    <ListItemText primary="Include rejected waivers" />
                    <ListItemSecondaryAction>
                        <Switch color="primary"
                            onChange={this.handleToggle('includeRejected')}
                            checked={this.state.checked.indexOf('includeRejected') !== -1}
                        />
                    </ListItemSecondaryAction>
                </ListItem>
                <Divider />
                <ListItem>

                    <ListItemText primary="Date in search is birthday" secondary="Only search birthday not activity or signed date" />
                    <ListItemSecondaryAction>
                        <Switch color="primary"
                            onChange={this.handleToggle('dateIsBirthday')}
                            checked={this.state.checked.indexOf('dateIsBirthday') !== -1}
                        />
                    </ListItemSecondaryAction>
                </ListItem>
                <ListItem >
                    <Grid container spacing={16}>
                        <Grid item xs={3} /><Grid item xs={3} />
                        <Grid item xs={3}>
                            <Button onClick={handleCancel}>Cancel</Button>
                        </Grid>
                        <Grid item xs={3}><Button variant="contained" onClick={this.handleSaveSettings}>Save</Button> </Grid>
                    </Grid>
                </ListItem>
            </List>
        );
    }
}


