import fetch from "isomorphic-fetch";
import {
  COMPANIES_SET_FAILED,
  COMPANIES_REQUESTED,
  COMPANIES_RETRIEVED,
  NEW_COMPANY_SAVE_STARTED,
  NEW_COMPANY_SAVE_FAILED,
  NEW_COMPANY_SAVE_SUCCEEDED,
  CURRENT_COMPANY_SELECTED,
  CLEAR_SELECTED_SIGNED_WAIVER_ID,
  COMPANY_DETAILS_ACQUIRED,
  COMPANY_DETAILS_REQUESTED,
  COMPANY_DETAILS_FAILED,
  SET_COMPANIES,
  COMPANY_WAIVERS_REQUESTED,
  COMPANY_PAYMENT_DETAILS_INITIATED,
  UPDATE_COMPANY,
  COMPANY_DETAILS_EDITED,
  COMPANY_DETAILS_SUBMITTED,
  SET_USER_COMPANIES,
  EDIT_COMPANY_SAVE_SUCCEEDED,
  EDIT_COMPANY_SAVE_FAILED
} from "../constants";
import { apiFetchClosure, coalesce } from "../lib/fetch";
import {
  clone,
  findIndex,
  merge,
  prop,
  propEq,
  equals,
  find,
  map
} from "ramda";

import {
  getWaiverRates,
  getCompanyPaymentDetails,
  transactionDetails
} from "./billing";
import { getLocation } from "./location";
import { getSigningStatement } from "./signingStatements";
import { setWaivers } from "./waivers";

const url = process.env.REACT_APP_URL;
const localHeaders = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${coalesce(
    sessionStorage.getItem("access_token"),
    localStorage.getItem("access_token")
  )}`
};

export const getCompany = id => async (dispatch, getState) => {
  dispatch({
    type: COMPANY_DETAILS_REQUESTED,
    payload: { data: {}, isFetching: false }
  });

  const response = await apiFetchClosure()(`/api/Companies/${id}`)
    //cam only has going to api/company??
    .then(res => res.json())
    .catch(err => console.log("err", err));
  if (!response) {
    return;
  }
  dispatch({
    type: COMPANY_DETAILS_ACQUIRED,
    payload: { data: response, isFetching: false }
  });
};

export const getCurrentCompany = companyId => async (dispatch, getState) => {
  dispatch({ type: CLEAR_SELECTED_SIGNED_WAIVER_ID });
  dispatch({ type: COMPANY_PAYMENT_DETAILS_INITIATED });
  dispatch({ type: COMPANY_WAIVERS_REQUESTED });

  dispatch(getCompanyPaymentDetails());
  dispatch(getCompany());
  dispatch(getWaiverRates());
  dispatch(transactionDetails());
};

export const selectCompany = id => async (dispatch, getState) => {
  const company = getState().currentCompany;
  const response = await fetch(
    `${url}/Company/SetCurrent/${id}?redirectUrl=${false}`,
    {
      headers: {
        "Content-Type": "application/json",
        // "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": `${window.location.protocol}//${
          window.location.host
        },`,
        // window.location.protocol + "//" + window.location.host,
        Authorization: `Bearer ${coalesce(
          sessionStorage.getItem("access_token"),
          localStorage.getItem("access_token")
        )}`
      },
      method: "POST"
    }
  )
    .then(res => res.json())
    .catch(err => console.error("Error: ", err));

  dispatch({
    type: CURRENT_COMPANY_SELECTED,
    payload: { id: response.CurrentCompanyId }
  });
  dispatch({ type: UPDATE_COMPANY, payload: response.CurrentCompanyId });

  dispatch(setWaivers);

  dispatch(getCompanyTest(id));
};

//changed for entities
export const setCompanies = async (dispatch, getState) => {
  dispatch({ type: COMPANIES_REQUESTED, payload: { fetching: true } });
  const response = await apiFetchClosure()(`/api/company`)
    .then(res => res.json())
    .catch(err => console.log("err: ", err));
  if (response === 400) {
    dispatch({
      type: COMPANIES_SET_FAILED,
      payload: { fetching: false, type: "get", searchSettingsEstablished: true }
    });
    return;
  }

  dispatch({ type: COMPANIES_RETRIEVED, payload: response });

  const cos = map(r => {
    if (r.RoleName === "ReviewWaivers") {
      r = { companyId: r.Id, roleId: Number(3) };
    } else if (r.RoleName === "SysAdmin") {
      r = { companyId: r.Id, roleId: Number(1) };
    } else if (r.RoleName === "CompanyAdmin") {
      r = { companyId: r.Id, roleId: Number(2) };
    } else {
      r = { companyId: r.Id, roleId: Number(4) };
    }
    return r;
  })(response);

  dispatch({ type: SET_USER_COMPANIES, payload: cos });
  //removes fetching that may have been placed on refresh
  if (sessionStorage.fetching) {
    sessionStorage.removeItem("fetching");
  }
};
export const getCompanyTest = id => async (dispatch, getState) => {
  dispatch(getCompanyPaymentDetails(id));
  dispatch(getSigningStatement(id));
  dispatch(getWaiverRates(id));
  dispatch(transactionDetails(id));
  dispatch(getLocation());
};

export const editCompany = company => async (dispatch, getState) => {
  dispatch({ type: COMPANY_DETAILS_EDITED, payload: { isSaving: true } });

  let data = new FormData();

    let companyData = {
    Active: company.Active,
    Name: company.Name,
    BillingEmail: company.BillingEmail,
    Description: company.Description,
    SameAsBillingAddress: company.SameAsBillingAddress,
    GetContactInformation: company.GetContactInformation,
    PrimaryLocation_Id: company.PrimaryLocation_Id,
    BillToCompany_Id: company.BillToCompany_Id,
    ExternalLinkedSystem_Id: company.ExternalLinkedSystem_Id,
    companyLogo: company.ImageBlobName,
    ImageUrl: company.ImageUrl,
    GetEmergencyPhone: company.GetEmergencyPhone,
    GetBirthday: company.GetBirthday,
    Updated: company.Updated,
    UpdatedBy: company.UpdatedBy,
    Id: company.Id,
    Created: company.Created,
    CreatedBy: company.CreatedBy,
    ContactName: company.ContactName,
    ContactPhone: company.ContactPhone,
    ContactEmail: company.ContactEmail,
    Website: company.Website
  };

  data.append("companyData", JSON.stringify(companyData));
  data.append("companyLogo", company.ImageBlobName);

  let response = "";
  var request = new XMLHttpRequest();
  request.open("PUT", `${url}/api/company`);
  request.responseType = "json";
  request.setRequestHeader(
    "Access-Control-Allow-Origin",
    `${window.location.protocol}//${window.location.host}`
  );
  request.setRequestHeader(
    "Authorization",
    "Bearer " +
      `${coalesce(
        localStorage.getItem("access_token"),
        sessionStorage.getItem("access_token")
      )}`
  );

  request.body = data;

  request.onload = function(oEvent) {
    if (request.status === 200) {
      response = "Uploaded companydetails!";
      const companyPaymentDetails = getState().companyPaymentDetails;
      companyPaymentDetails["Company"] = request.response;
      const newCompanyPaymentDetails = clone(companyPaymentDetails);
      dispatch({
        type: COMPANY_DETAILS_ACQUIRED,
        payload: newCompanyPaymentDetails
      });
      // creating an updatedCompanies array so that the company name on the menuAppBar reflects the changes
      // menuAppBar uses a memoization function in /selector/company to compute the 'currentCompany' value
      // createSelector will not work with a state update function that mutates an existing object instead of creating a new one each time
      // thus, it is necassary to apply the Object.assign method to each object in the array
      let companies = getState().companies;
      let companyIndex = findIndex(propEq("Id", companyPaymentDetails.Id))(
        companies
      );
      const updatedCompany = merge(companies[companyIndex], {
        Name: companyPaymentDetails.Name
      });
      companies[companyIndex] = updatedCompany;
      const updatedCompanies = companies.map(c => Object.assign({}, c));
      dispatch({ type: SET_COMPANIES, payload: updatedCompanies });
      dispatch({
        type: COMPANY_DETAILS_SUBMITTED,
        payload: { isSaved: true }
      });
      ///////\\\\\\\
      dispatch(getCompanyPaymentDetails(company.Id));
    } else {
      response =
        "Error " +
        request.status +
        " occurred when trying to upload your file.<br />";
      dispatch({
        type: COMPANY_DETAILS_FAILED,
        payload: { isError: true }
      });
      return;
    }
  };
  request.send(data);
};

export const submitCompany = companyFormData => async (dispatch, getState) => {
  let data = new FormData();

  data.append("Address", companyFormData.BillingAddress);
  data.append("BillingEmail", companyFormData.BillingEmail);
  data.append("Description", companyFormData.CompanyDescription);
  data.append("Name", companyFormData.CompanyName);
  data.append("Country", companyFormData.Country);
  data.append("CompanyLogo", companyFormData.CompanyLogo);
  data.append("ImageUrl", companyFormData.ImageUrl);
  data.append("City", companyFormData.City);
  data.append("State", companyFormData.State);
  data.append("ZipCode", companyFormData.ZipCode);
  data.append("GetBirthday", companyFormData.GetBirthday);
  data.append("GetEmergencyPhone", companyFormData.GetEmergencyPhone);
  data.append("GetContactInformation", companyFormData.GetContactInformation);
  data.append("ExpirationMonth", companyFormData.ExpDate.Month);
  data.append("ExpirationYear", companyFormData.ExpDate.Year);
  data.append("CardHolder", companyFormData.CardHolder);
  data.append("CardNumber", companyFormData.CardNumber);
  data.append("CVC", companyFormData.CVC);
  data.append("ImageUrl", companyFormData.CompanyLogo.name);
  data.append("BillToThisCo", companyFormData.Remember);
  data.append("ContactName", companyFormData.ContactName);
  data.append("ContactPhone", companyFormData.ContactPhone);
  data.append("ContactEmail", companyFormData.ContactEmail);
  data.append("Website", companyFormData.Website);
  // data.append("File", companyFormData.CompanyLogo);
  // data.append("SameAsBillingAddress", companyFormData.SameAsBillingAddress);
  // data.append("Remember", companyFormData.Remember);

  dispatch({
    type: NEW_COMPANY_SAVE_STARTED,
    payload: companyFormData
  });
  let response = "";
  var request = new XMLHttpRequest();
  request.open("POST", `${url}/api/company`);
  request.responseType = "json";
  request.setRequestHeader(
    "Access-Control-Allow-Origin",
    `${window.location.protocol}//${window.location.host}`
  );
  request.setRequestHeader(
    "Authorization",
    "Bearer " +
      `${coalesce(
        localStorage.getItem("access_token"),
        sessionStorage.getItem("access_token")
      )}`
  );
  request.body = data;

  request.onload = function(oEvent) {
    if (request.status === 200) {
      response = "Uploaded!";
      var companyId = request.response;
      dispatch({
        type: NEW_COMPANY_SAVE_SUCCEEDED,
        payload: companyId
      });
      dispatch({
        type: CURRENT_COMPANY_SELECTED,
        payload: { id: companyId }
      });
      dispatch(getCompany(companyId)); //.then(() => dispatch(setCurrentCompanyDetails()))
      dispatch(setCompanies);
    } else {
      response =
        "Error occurred when trying to create a new company.<br />" +
        request.status;
      dispatch({
        type: NEW_COMPANY_SAVE_FAILED,
        payload: companyFormData
      });
      return;
    }
  };
  request.send(data);
};

export const setCurrentCompany = id => async (dispatch, getState) => {
  // dispatch({ type: CLEAR_SELECTED_SIGNED_WAIVER_ID })
  // dispatch({ type: INIT_COMPANY_PAYMENT_DETAILS })
  //dispatch({ type: COMPANY_WAIVERS_REQUESTED, payload: id })

  const response = await apiFetchClosure()(`/api/Company/SetCurrent/${id}`);
  if (!response) {
    return;
  }
  dispatch({ type: CURRENT_COMPANY_SELECTED, payload: { id } });
};

// export const getCompanyDetails = id => async (dispatch, getState) => {
//   const response = await fetch(`${url}/api/Companies/${id}`, {
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${localStorage.getItem("access_token")}`
//     }
//   })
//     .then(res => res.json())
//     .catch(err => console.log("err: ", err));

//   if (!response) {
//     return;
//   }
//   dispatch({ type: COMPANY_DETAILS_ACQUIRED, payload: response });
// };
