import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { Field, Form, Formik } from "formik";
import DialogAppBar from "../dialogAppBar";
import FormControl from "@material-ui/core/FormControl";
import FormikCheckbox from "../formikCheckbox";
import * as Yup from "yup";
import { isEmpty, or } from "ramda";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import Hover from "../hover";
import FormikMaterialInput from "../formikMaterialInput";

const newLocationDataSchema = Yup.object().shape({
  Name: Yup.string()
    .min(2, "Too short")
    .max(100, "Too Long")
    .required("Required"),
  Address: Yup.string()
    .min(4, "Too Short")
    .max(150, "Too Long")
    // .max()
    .required("Address is required")
  //Location
  // Latitude: Yup.number()
  // .min(0, "Please complete zipcode")
  // .max(99999, "Must be a Zip / Postal Code")
  // .positive()
  // .integer(),
  // Longitude: Yup.string()
  // .min(3)
  // .max(4)
  // .required("Required"),
  // Primary: Yup.boolean()
});

class LocationCreateFormik extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      newLocationData: {
        Id: null,
        Name: "",
        Address: "",
        Location: {
          Latitude: "",
          Longitude: ""
        },
        Primary: false
      }
    };
  }

  refreshCoordinates = address => {
    this.props.getLocationCoordinates(address);
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  createLocation = address => {
    this.props.getLocationCoordinates(address);
  };

  onCreateSubmit = addr => {
    this.props.createLocation(addr);
    this.handleClose();
  };

  render() {
    const { classes, fullScreen } = this.props;

    // const splitDialog = { xs: 6, sm: 6, md: 6, lg: 6 };

    return (
      <React.Fragment>
        {
          <Hover
            message="Add Location"
            component={
              <Button
                className={classes.label}
                variant="contained"
                color="primary"
                onClick={this.handleClickOpen}
              >
                <AddIcon className={classes.leftIcon} /> Location
              </Button>
            }
          />
        }
        <Dialog
          fullWidth
          fullScreen={fullScreen}
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogAppBar title="Add Location" handleClose={this.handleClose} />
          <FormControl fullWidth>
            <Formik
              initialValues={this.state.newLocationData}
              validationSchema={newLocationDataSchema}
              onSubmit={values => this.onCreateSubmit(values)}
              render={({ dirty, errors, handleSubmit, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <DialogContent>
                    <Grid item xs={12}>
                      <Field
                        className={classes.textFieldOutlined}
                        name="Name"
                        component={FormikMaterialInput}
                        label="Location Name"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        className={classes.textFieldOutlined}
                        name="Address"
                        component={FormikMaterialInput}
                        label="Address"
                      />
                    </Grid>

                    <Grid container spacing={16} justify="center">
                      <Grid item>
                        <Field
                          className={classes.textFieldOutlined}
                          // helperText="Lat/Lon calculated automatically"
                          addText="Lat/Lon calculated automatically"
                          name="Latitude"
                          type="number"
                          component={FormikMaterialInput}
                          label="Latitude"
                        />
                      </Grid>

                      <Grid item>
                        <Field
                          className={classes.textFieldOutlined}
                          name="Longitude"
                          type="number"
                          component={FormikMaterialInput}
                          label="Longitude"
                        />
                      </Grid>
                      <Grid container justify="center">
                        <Field
                          // disabled={true}
                          name="Primary"
                          component={FormikCheckbox}
                          label="Primary"
                        />
                      </Grid>
                    </Grid>

                    {/* <Debug /> */}
                  </DialogContent>

                  <DialogActions>
                    <Button
                      onClick={this.handleClose}
                      variant="contained" //onClick={() => handleDialog('')}
                      color="primary"
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={!dirty || or(isSubmitting, !isEmpty(errors))}
                      onClick={handleSubmit}
                      color="default"
                      variant="contained"
                      type="submit"
                    >
                      Add Location
                    </Button>
                  </DialogActions>
                </Form>
              )}
            />
          </FormControl>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default LocationCreateFormik;

// const mapStateToProps = state => {
//   return {
//     locations: state.locations
//   };
// };

// const mapActionsToProps = dispatch => {
//   return {
//     createLocation: location => dispatch(createLocation(location)),
//     getLocationCoordinates: addr => dispatch(getLocationCoordinates(addr))
//   };
// };

// const connector = connect(
//   mapStateToProps,
//   mapActionsToProps
// );

// export default connector(withStyles(styles)(LocationCreateFormik));

//////oldMapButton\\\\
{
  /* <Grid item>
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  spacing={16}
                > */
}
{
  /* <Grid item>
                    {this.state.address !== this.props.newLocation ? ( //this.props.initialValues.Address
                      <Button
                        variant="contained"
                        onClick={() =>
                          this.refreshCoordinates(this.state.address)
                        }
                        color="primary"
                      >
                        Map
                      </Button>
                    ) : (
                      <Button variant="contained" color="primary">
                        Map
                      </Button>
                    )}
                  </Grid> */
}
{
  /* </Grid>
              </Grid> */
}
{
  /* </Grid> */
}

// const onCreateSubmit = formData => {
//   if (!formData.Address) {
//     throw new SubmissionError({ _error: "Address required" });
//     return;
//   }
//   if (!formData.Name) {
//     throw new SubmissionError({ _error: "Name required" });
//     return;
//   }
//   // save a cross origin post if nothing is changed and the user 'saves'
//   // if (equals(formData, store.getState().locationToEdit)) {
//   //     store.dispatch(handleDialog())
//   //     return
//   // }

//   store.dispatch(createLocation(formData));
//   store.dispatch(handleDialog(""));
// };
