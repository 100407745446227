import React from "react";
import ListItem from "@material-ui/core/ListItem";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import Typography from "@material-ui/core/Typography";
import { cpus } from "os";
const EnhancedTableHead = props => {
  const { order, orderBy, columnData, disabled, className, color, size, padding } = props;



  return (
      <React.Fragment>
          <ListItem key={props.numSelected} divider>

        <Grid container spacing={0} zeroMinWidth justify="space-between" alignItems="center">
          {columnData.map(column => {
            return (
              <>
                {column.sortColumn ? (
                  <Hidden smDown={column.smDown}>
                    <Grid item xs
                      //size={size}
                      color={color}
                      key={column.id}
                      align={column.align}
                      padding={padding ? padding : column.disablePadding ? "none" : column.checkbox ? "checkbox" : "default"}
                      //sortDirection={orderBy === column.id ? order : false}
                    >
                      <Tooltip
                        title="Sort"
                        placement={column.numeric ? "bottom-end" : "bottom-start"}
                        enterDelay={300}
                        disableTouchListener={disabled}
                        disableHoverListener={disabled}
                        disableFocusListener={disabled}
                      >
                        <TableSortLabel
                          id={column.id}
                          active={orderBy === column.id}
                          direction={order}
                          onClick={props.createSortHandler}
                        >
                          <Typography> {column.label}</Typography>
                        </TableSortLabel>
                      </Tooltip>
                    </Grid> </Hidden>)
                  : column.tiny ? (<Grid item xs={1} key={column.id} align={`${column.align}`}
                    padding={padding ? padding : column.disablePadding ? "none" : "default"}><Typography>{column.label}</Typography></Grid>
                  ) :
                    column.hidden ? <Hidden smDown><Grid item xs key={column.id} align={`${column.align}`}
                      padding={padding ? padding : column.disablePadding ? "none" : "default"}><Typography>{column.label}</Typography></Grid></Hidden>
                      :
                      column.hiddenMd ? <Hidden mdDown><Grid item xs key={column.id} align={`${column.align}`}
                        padding={padding ? padding : column.disablePadding ? "none" : "default"}><Typography>{column.label}</Typography></Grid></Hidden>
                        :
                        column.hiddenXs ? <Hidden xsDown><Grid item xs key={column.id} align={`${column.align}`}
                          padding={padding ? padding : column.disablePadding ? "none" : "default"}><Typography>{column.label}</Typography></Grid></Hidden> : column.trade ? <Hidden mdUp><Grid item xs key={column.id} align={`${column.align}`}
                            padding={padding ? padding : column.disablePadding ? "none" : "default"}><Typography>{column.label}</Typography></Grid></Hidden> : (<Grid item xs key={column.id} align={`${column.align}`}
                              padding={padding ? padding : column.disablePadding ? "none" : "default"}><Typography>{column.label}</Typography></Grid>)}
              </>
            );
          }, this)}
          <Grid item xs><div></div></Grid>
        </Grid>
      </ListItem>
    </React.Fragment>
  );
};

export default EnhancedTableHead;
