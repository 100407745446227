import fetch from "isomorphic-fetch";
import {
  COMPANIES_CLEARED,
  LOGIN_FAILED,
  LOGIN_SUCCEEDED,
  LOGIN_STARTED,
  LOGOUT_SUCCEEDED,
  FORGOT_PASSWORD_REQUESTED,
  FORGOT_PASSWORD_EMAIL_SUCCEEDED,
  FORGOT_PASSWORD_EMAIL_FAILED,
  PASSWORD_CONFIRMATION_REQUESTED,
  PASSWORD_CONFIRMATION_SUCCEEDED,
  RESET_PASSWORD_REQUESTED,
  RESET_PASSWORD_SUCCEEDED,
  REGISTER_USER_REQUESTED,
  REGISTER_USER_EMAIL_FAILED,
  REGISTER_USER_EMAIL_SUCCEEDED,
  RESET_AUTH,
  NOT_AUTHORIZED,
  SET_USER_ROLE,
  RESET_PAYMENT_HISTORY,
  TRANSACTION_INFORMATION_CLEARED,
  CURRENT_COMPANY_CLEARED,
  DRAWER_CLOSED,
  RESET_MONTHLY_BILLING,
  RESET_COMPANY_PAYMENT_DETAILS,
  RESET_WAIVER_RATES,
  COMPANY_DETAILS_CLEARED,
  RESET_LOCATIONS,
  RESET_SWS,
  PENDING_WAIVERS_CLEAR,
  RECENTLY_SIGNED_WAIVERS_CLEAR,
  SEARCH_SETTINGS_RESET,
  SIGNED_WAIVERS_SEARCH_CLEAR,
  NOTE_CLEARED,
  SIGNED_WAIVER_CLEARED,
  RESET_SIGNING_STATEMENTS,
  CLEAR_USERS,
  CURRENT_WAIVER_CLEARED,
  CLEAR_WAIVERS,
  RESET_USAGE,
  RECONNECT_SIGNALR
} from "../constants";
import {
  equals,
  length,
  split,
  last,
  has,
  map,
  and,
  find,
  propEq
} from "ramda";
import { apiFetchClosure, coalesce } from "../lib/fetch";
import history from "../history";
import { getCurrentUser } from "./users";
import { authentication } from "../reducers/authentication";
import { setCompanies } from "./companies";
import { disconnect } from './signalR'
const url = process.env.REACT_APP_URL;
export const registerUser = form => (dispatch, getState) => {
  dispatch({ type: RESET_AUTH });
  dispatch({ type: REGISTER_USER_REQUESTED });
  let data = new FormData();
  data.append("Email", form.Email);
  data.append("Name", form.Name);
  data.append("UserName", form.UserName);
  data.append("Password", form.Password);
  data.append("ConfirmPassword", form.ConfirmPassword);
  data.append("PhoneNumber", form.PhoneNumber);
  fetch(`${process.env.REACT_APP_URL}/Account/Register`, {
    method: "POST",
    body: data,
    mode: "cors",
    cache: "no-cache",
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": `${window.location.protocol}//${
        window.location.host
        }`,

      "Access-Control-Allow-Credentials": true
    }
  }).then(res => {
    if (res.status === 200) {
      res.json().then(d => {
        if (and(has("Succeeded", d), !d.Succeeded)) {
          dispatch({ type: REGISTER_USER_EMAIL_FAILED, payload: d.Errors });
        } else {
          dispatch({
            type: REGISTER_USER_EMAIL_SUCCEEDED,
            payload:
              "An email have been sent.  Please confirm you email address."
          });
        }
      });
    } else {
      res.json().then(err => console.error("error: ", err));
    }
  });
  dispatch({
    type: REGISTER_USER_EMAIL_SUCCEEDED,
    payload: "An email have been sent.  Please confirm you email address."
  });
};

export const forgotPassword = email => async (dispatch, getState) => {
  dispatch({ type: RESET_AUTH });
  dispatch({ type: FORGOT_PASSWORD_REQUESTED });
  const response = await fetch(url + `/Account/ForgotPassword`, {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin":
        window.location.protocol + "//" + window.location.host,
      Authorization: `Bearer ${coalesce(
        sessionStorage.getItem("access_token"),
        localStorage.getItem("access_token")
      )}`
    },
    method: "POST",
    body: JSON.stringify(email)
  })
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: FORGOT_PASSWORD_EMAIL_SUCCEEDED,
          payload: "Please check your email to reset your password."
        });
      } else if (res.status === 404) {
        dispatch({
          type: FORGOT_PASSWORD_EMAIL_FAILED,
          payload:
            "Email provided was not found.  Please check spelling and try again"
        });
      }
    })
    .catch(err => console.error("Error: ", err));
};

export const forgotPasswordConfirmation = () => async (dispatch, getState) => {
  dispatch({ type: PASSWORD_CONFIRMATION_REQUESTED });
  const response = await apiFetchClosure()(
    `${process.env.REACT_APP_URL}/Account/ForgotPasswordConfirmation`
  )
    .then(res => res.json())
    .catch(err => console.log("err: ", err));
  dispatch({ type: PASSWORD_CONFIRMATION_SUCCEEDED, payload: response });
};
export const resetPassword = data => async (dispatch, getState) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${coalesce(
      sessionStorage.getItem("access_token"),
      localStorage.getItem("access_token")
    )}`
  };
  dispatch({ type: RESET_PASSWORD_REQUESTED });
  const response = await fetch(
    `${process.env.REACT_APP_URL}/Account/ResetPassword`,
    {
      headers: headers,
      method: "POST",
      body: JSON.stringify(data)
    }
  )
    .then(res => res.json())
    .catch(err => console.log("err: ", err));

  dispatch({ type: RESET_PASSWORD_SUCCEEDED, payload: response });
};

export const loginUser = user => async (dispatch, getState) => {
  // dispatch({ type: RECONNECT_SIGNALR, payload: true })

  dispatch({ type: RESET_AUTH });
  window.localStorage.clear();
  window.sessionStorage.clear();
  dispatch({
    type: LOGIN_STARTED,
    payload: {
      isLoading: true,
      isAuthenticated: false,
      isError: false
    }
  });
  const response = await fetch(`${url}/token`, {
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin":
        window.location.protocol + "//" + window.location.host
    },
    method: "POST",
    body: `username=${user.UserName}&password=${
      user.Password
      }&grant_type=password`
  })
    //     .then(res => res.json())
    .then(response => {
      if (equals(response.status, 200)) {
        const tokenBody = response.json();
        tokenBody.then(body => {
          if (user.RememberMe) {
            localStorage.setItem("access_token", body.access_token);
            sessionStorage.setItem("fetching", "fetching")
            window.addEventListener('beforeunload', dispatch(logoutUser))
          } else {
            sessionStorage.setItem("access_token", body.access_token);
            sessionStorage.setItem("fetching", "fetching")
          }
          dispatch(receiveLogin(user));
        });
      } else {
        const tokenBody = response.json();
        tokenBody.then(user => {
          dispatch(loginError(user.error_description));
        });
      }
    });
};


const receiveLogin = user => async (dispatch, getState) => {
  //add event listener to do a logout even if user uses brower close button
  // window.addEventListener("close", dispatch(logoutUser))


  dispatch({
    type: LOGIN_SUCCEEDED,
    payload: {
      isLoading: false,
      isAuthenticated: true,
      isError: false
    }
  });
  //  dispatch(getCurrentUser)
  dispatch(getCurrentUser).then(() => {
    if (getState().authentication.currentCompanyId === null) {
      history.push("/companies/new");
      sessionStorage.removeItem("fetching")
    } else if (
      and(
        Number(window.localStorage.isAuth) === 4,
        getState().authentication.currentCompanyId !== null
      )
    ) {
      dispatch({ type: NOT_AUTHORIZED });
      sessionStorage.removeItem("fetching")
    } else {
      dispatch(setCompanies);
      history.push("/signedwaivers");
      sessionStorage.removeItem("fetching")
    }

  });
};

function loginError(message) {
  return {
    type: LOGIN_FAILED,
    payload: {
      isLoading: false,
      isAuthenticated: false,
      isError: true,
      message
    }
  };
}

export const logoutUser = (dispatch, getState) => {
  dispatch({ type: RESET_MONTHLY_BILLING });
  dispatch({ type: RESET_AUTH });
  dispatch({ type: RESET_PAYMENT_HISTORY });
  dispatch({ type: RESET_COMPANY_PAYMENT_DETAILS });
  dispatch({ type: RESET_WAIVER_RATES });
  dispatch({ type: TRANSACTION_INFORMATION_CLEARED });
  dispatch({ type: CURRENT_COMPANY_CLEARED });
  dispatch({ type: COMPANIES_CLEARED });
  dispatch({ type: COMPANY_DETAILS_CLEARED });
  dispatch({ type: DRAWER_CLOSED });
  dispatch({ type: RESET_LOCATIONS });
  dispatch({ type: RESET_SWS });
  dispatch({ type: PENDING_WAIVERS_CLEAR });
  dispatch({ type: RECENTLY_SIGNED_WAIVERS_CLEAR });
  dispatch({ type: SEARCH_SETTINGS_RESET });
  dispatch({ type: SIGNED_WAIVERS_SEARCH_CLEAR });
  dispatch({ type: NOTE_CLEARED });
  dispatch({ type: SIGNED_WAIVER_CLEARED });
  dispatch({ type: RESET_SIGNING_STATEMENTS });
  dispatch({ type: CLEAR_USERS });
  dispatch({ type: CURRENT_WAIVER_CLEARED });
  dispatch({ type: CLEAR_WAIVERS });
  dispatch({ type: RESET_USAGE });
  dispatch({ type: CLEAR_WAIVERS });
  //stops connection to signalR  -lym
  dispatch(disconnect(getState().signalR.connection))
  window.localStorage.clear();
  window.sessionStorage.clear();

};


