import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Field } from "formik";
import FormikMaterialInput from "../formikMaterialInput";
import {
  validateEmail,
  validateContactPhone,
  validateName
} from "../../lib/validation";
import CustomIntlTelInput from "../../components/authentication/phoneNumber";

export const ContactNameField = ({ classes }) => (
  <Field
    className={classes.textFieldOutlined}
    name="ContactName"
    label="Contact Name"
    component={FormikMaterialInput}
    // validate={validateContactName}
    validate={validateName}
    margin="dense"
  />
);
export const ContactEmailField = ({ classes }) => (
  <Field
    className={classes.textFieldOutlined}
    name="ContactEmail"
    type="email"
    validate={validateEmail}
    label="Contact Email Address"
    component={FormikMaterialInput}
    margin="dense"
  />
);
export const ContactPhoneField = ({ classes, defaultPhone, formikProps }) => (
  <span>
    <div className={classes.phoneFieldset}>
      <Field
        fullWidth
        name="ContactPhone"
        id="ContactPhone"
        component={CustomIntlTelInput}
        preferredCountries={[`US`, `GB`, `CA`]}
        defaultValue={defaultPhone}
        validate={validateContactPhone}
        inputClassName={classes.phoneInput}
      />
    </div>
    {formikProps.errors["ContactPhone"] &&
    formikProps.touched["ContactPhone"] ? (
      <Typography
        align="left"
        color="error"
        className={classes.phoneError}
        variant="caption"
      >
        {formikProps.errors["ContactPhone"]}
      </Typography>
    ) : null}
  </span>
);
export const WebsiteField = ({ classes }) => (
  <Field
    className={classes.textFieldOutlined}
    name="Website"
    type="email"
    label="Company Web Address"
    component={FormikMaterialInput}
  />
);
const CompanyInformation = props => {
  const { classes, formikProps, defaultPhone } = props;
  return (
    <React.Fragment>
      <Grid container spacing={8} alignItems="flex-start">
        <Grid item xs={12} sm={6}>
          <ContactNameField classes={classes} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ContactEmailField classes={classes} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ContactPhoneField
            classes={classes}
            defaultPhone={defaultPhone}
            formikProps={formikProps}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <WebsiteField classes={classes} />
        </Grid>
      </Grid>
      {/* <Debug /> */}
    </React.Fragment>
  );
};

export default CompanyInformation;
