import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'

const styles = {
    card: {
        maxWidth: 345,
    },
    media: {
        height: 140,
    },
}
const CardVideo = ({ classes, video }) => (
    <Card className={classes.card}>
        <CardMedia className={classes.media}
            image={video}
            component="iframe"
            title="Test Video Url" />
    </Card>
)
export default withStyles(styles)(CardVideo)