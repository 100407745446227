import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Formik } from "formik";
import * as Yup from "yup";
import CustomizedSnackbars from "../snackbar";
import { RESET_AUTH } from "../../constants";
import { and, has } from "ramda";
const MyForm = ({
  values: { Email },
  handleClose,
  errors,
  touched,
  handleSubmit,
  handleChange,
  setFieldTouched
}) => {
  const change = (name, e) => {
    e.persist();
    handleChange(e);
    // console.log(errors);
    setFieldTouched(name, true, false);
  };
  return (
    <form onSubmit={handleSubmit}>
      <TextField
        id="Email"
        name="Email"
        helperText={touched.Email ? errors.Email : ""}
        errors={touched.Email && Boolean(errors.Email)}
        autoFocus
        margin="dense"
        label="Email Address"
        type="email"
        fullWidth
        value={Email}
        onChange={change.bind(null, "Email")}
      />
      <Button onClick={handleClose} color="primary">
        Cancel
      </Button>
      <Button type="submit" variant="contained" color="primary">
        Send Email
      </Button>
    </form>
  );
};
class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Email: ""
      //  openConfirmation: false
    };
  }
  handleFieldChange = (name, e) => {
    const { handleChange, setFieldTouched } = this.props;
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };
  submitValues = ({ Email }) => {

    const fp = {
      Email: Email
    };

    this.props.forgotPassword({ Email: Email });
    this.props.forgotPasswordConfirmation();
    this.props.handleClose();

    //   if (this.props.emailSuccess) {
    //     return (
    //     <CustomizedSnackbars message="Please check your email to reset your password." snackType="success" closeFunction dispatch={this.props.dispatch} />)
    //   } else if (!this.props.emailSuccess) {
    //     return (
    //     <CustomizedSnackbars message="Forgotten Password Email Request Failed." snackType="error" closeFunction disaptch={this.props.dispatch} />
    //     )
    // }

    //  this.setState({ openConfirmation: true })
  };
  // closeConfirmation = () => {
  //   this.setState({ openConfirmation: false })
  // }

  render() {
    const {
      open,
      handleClose,
      classes,
      emailSuccess,
      authentication
    } = this.props;
    const { Email } = this.state;
    const values = { Email };
    return (
      <div>
        {/* <React.Fragment>
        {and(has('emailSuccess', authentication), emailSuccess === true) && (
                                    <CustomizedSnackbars message="Please check your email to reset your password." snackType="success" closeFunction dispatch={this.props.dispatch} />
                                )}
                                {and(has("emailSuccess", authentication), !emailSuccess) && (
                                    <CustomizedSnackbars message="Forgotten Password Email Request Failed." snackType="error" closeFunction disaptch={this.props.dispatch} />
                                )}
        </React.Fragment> */}
        <center>
          {/* <EmailConfirmation open={this.state.openConfirmation}
          handleClose={this.closeConfirmation} authentication={authentication} /> */}
          <Dialog
            maxWidth="md"
            // fullWidth="true"
            fullWidth={true}
            open={open}
            onClose={handleClose}
            aria-labelledby="forgot-passowrd"
          >
            <DialogTitle id="form-dialog-title">
              Forgot your password?
            </DialogTitle>
            <DialogContent>
              <DialogContentText>Enter your email below</DialogContentText>
              <Formik
                render={props => (
                  <MyForm
                    handleClose={this.props.handleClose}
                    classes={classes}
                    {...props}
                  />
                )}
                initialValues={values}
                onSubmit={this.submitValues}
                validationSchema={Yup.object().shape({
                  Email: Yup.string()
                    .email("Invalid email")
                    .required("Required")
                })}
              />
            </DialogContent>
          </Dialog>
        </center>
      </div>
    );
  }
}

export default ForgotPassword;
