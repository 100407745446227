//card, actions, expand, expandOpen, container, textField, right, hadingBox, fab
import red from "@material-ui/core/colors/red";

export const styles = theme => ({
  actions: {
    display: "flex"
  },
  appBar: {
    position: "relative",
    width: "100%"
  },
  buttonProgress: {
    padding: 10
  },
  card: {
    maxWidth: "98%",
    margin: "auto"
  },
  // notesCard: {
  //   maxWidth: 400
  // },
  column: {
    flexBasis: "33.33%"
  },
  columnLeft: {
    flexBasis: "33.33%",
    paddingLeft: "2rem"
  },
  columnRight: {
    flexBasis: "33.33%",
    textAlign: "right"
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  details: {
    alignItems: "center"
  },
  dialogPaper: {
    overflowY: "initial"
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  fab: {
    margin: theme.spacing.unit
  },
  flex: {
    flex: 1
  },
  formControl: {
    margin: theme.spacing.unit,
  },
  gridpaper: {
    // padding: theme.spacing.unit * 2,
    flexGrow: 1,
    // textAlign: 'center',
    color: theme.palette.text.secondary
  },
  gridroot: {
    flexGrow: 1
  },
  bigScreen: {
    width: '1000px',
    maxWidth: "100%",
    height: "1000px",
    maxHeight: "1000px",

  },
  header: {
    display: "flex",
    alignItems: "center",
    height: 50,
    paddingLeft: theme.spacing.unit * 4,
    backgroundColor: theme.palette.background.default
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0
  },
  headingBox: {
    ...theme.typography.button,
    backgroundColor: red[200],
    padding: theme.spacing.unit
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
  },
  iconButton: {
    padding: 10
  },
  image: {
    width: 128,
    height: 128
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%"
  },
  //using on the searchbar
  input: {
    marginLeft: 8,
    flex: 1
  },
  lefty: {
    flexBasis: "50%"
  },
  mobileStepper: {
    color: 'red'
  },
  options: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
    // padding: '2px 4px',
    //  display: 'flex',
    // alignItems: 'center',
    // width: 400,

    //  width: '100%'
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none"
  },
  right: {
    marginLeft: "auto"
  },
  righty: {
    flexBasis: "50%"
  },
  root: {
    // width: "100%"
    flex: 1
  },
  panelRoot: {
    width: "100%"
  },
  searchpaper: {
    // padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    minWidth: 200,
    //width: 400,
    // padding: theme.spacing.unit * 0.5,
    //textAlign: "center",
    color: theme.palette.text.secondary
  },
  searchroot: {
    flexGrow: 1
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  signature: {
    // maxHeight: '4rem',
    maxWidth: '10rem',
    maxHeight: "4rem"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
  }
});

