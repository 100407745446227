import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogAppBar from '../dialogAppBar';

const FormDialog = ({ children, open, type, title, handleCancel, disabled }) => {

    return (
        <div>

            <Dialog
                fullScreen
                open={open}
                onClose={handleCancel}
                aria-labelledby="form-dialog-title"
            >
                <DialogAppBar title={title} handleClose={handleCancel} />
                <DialogContent>
                    {children}
                </DialogContent>

            </Dialog>
        </div>
    );

}

export default FormDialog