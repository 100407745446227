import React, { Component } from "react";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import withStyles from "@material-ui/core/styles/withStyles";
import withDrawer from "../../components/withDrawer";
import withFooter from "../../components/withFooter";
import MenuAppBar from "../../components/menuAppBar";
import PaymentHistory from "./paymentHistory";
import MonthlyBilling from "./monthlyBilling";
import ApiHelp from "./apiHelp";
import {
  getPaymentHistory,
  getMonthlyPayment,
  getApiHelp
} from "../../action-creators/admin";

const styles = theme => ({});

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

class AdminPage extends Component {
  state = {
    value: 0
  };

  componentDidMount() {
    this.setState({ value: this.props.paymentHistory.index });
    this.props.getPaymentHistory();
    this.props.getApiHelp();
  }

  handleTabChange = (event, value) => {
    if (value === 1) {
      if (
        !window.confirm(
          "Are you sure you would like to run monthly billing for all companies?"
        )
      ) {
        return this.setState({ value: this.state.value });
      }
    }
    return this.setState({ value });
  };

  render() {
    const { value } = this.state;
    const { classes } = this.props;

    return (
      <div>
        <MenuAppBar userYes={true} companyYes={true} />

        <AppBar style={{ paddingTop: 65 }} position="static">
          <Tabs
            value={value}
            onChange={this.handleTabChange}
            indicatorColor="primary"
          >
            <Tab label="Payment History" />
            <Tab label="Run Monthly Billing" />
            <Tab label="API" />
          </Tabs>
        </AppBar>
        {value === 0 && (
          <TabContainer>
            <PaymentHistory
              paymentHistory={this.props.paymentHistory}
              classes={classes}
            />
          </TabContainer>
        )}
        {value === 1 && (
          <TabContainer>
            <MonthlyBilling
              monthlyBilling={this.props.monthlyBilling.billing}
              classes={classes}
              getMonthlyPayment={this.props.getMonthlyPayment}
            />
          </TabContainer>
        )}
        {value === 2 && (
          <TabContainer>
            <ApiHelp apiHelpPage={this.props.apiHelpPage} classes={classes} />
          </TabContainer>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  paymentHistory: state.paymentHistory,
  monthlyBilling: state.monthlyBilling,
  apiHelpPage: state.apiHelpPage
});

const mapActionsToProps = dispatch => ({
  getPaymentHistory: id => dispatch(getPaymentHistory(id)),
  getMonthlyPayment: id => dispatch(getMonthlyPayment(id)),
  getApiHelp: id => dispatch(getApiHelp(id))
});

const connector = connect(
  mapStateToProps,
  mapActionsToProps
);

export default withDrawer(withFooter(connector(withStyles(styles)(AdminPage))));
