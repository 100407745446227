import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import green from "@material-ui/core/colors/green";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { equals, isNil } from "ramda";

const styles = {
  root: {
    color: green[600],
    "&$checked": {
      color: green[500]
    }
  },
  checked: {}
};

class FormikCheckbox extends Component {
  state = {
    checked: true //this.props.field.value
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      !equals(this.props.field.value, this.state.checked) &&
      !isNil(this.props.field.value)
    ) {
      this.setState({ checked: this.props.field.value });
      this.props.form.setFieldValue(
        `${this.props.field.name}`,
        this.props.field.value
      );
    }
  }

  handleChange = name => {
    this.props.form.setFieldTouched(name, true, true);
    this.setState({ checked: !this.state.checked });
    this.props.form.setFieldValue(name, !this.state.checked);
  };

  render() {
    const {
      className,
      label,
      field: { name, value },
      disabled
    } = this.props;

    return (
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              className={className}
              disabled={disabled}
              name={name}
              checked={value}
              value={`${value}`}
              onChange={() => this.handleChange(name)}
              color="primary"
            />
          }
          label={label}
        />
      </FormGroup>
    );
  }
}

export default withStyles(styles)(FormikCheckbox);
