import { CONNECTION_SET, CONNECTION_CLOSED, CONNECTION_OPEN, CONNECTION_REQUESTED, DISCONNECTED, NEW_MESSAGE, SIGNALR_SW_CLEARED, SIGNALR_SW_SET, SIGNALR_COMPANY, RECONNECTING_SIGNALR, RECONNECT_SIGNALR } from '../constants'
import { append } from 'ramda'
const initialState = {
    statusUpdate: [],
    noteUpdates: [],
    newSignedWaivers: [],
    connection: null,
    companyId: null,
    reconnectSignalR: false,
    errMsg: "",
    messages: [],
    sw: {},
    connectionId: null,
    requesting: false,
    reconnecting: true
}
export const signalR = (state = initialState, action) => {
    switch (action.type) {

        case CONNECTION_SET:
            return Object.assign({}, state, { connection: action.payload })
        case CONNECTION_OPEN:
            return Object.assign({}, state, { open: true, requesting: false, errMsg: "" })
        case CONNECTION_CLOSED:
            return Object.assign({}, state, { open: false, requesting: false, errMsg: action.payload })
        case CONNECTION_REQUESTED:
            return Object.assign({}, state, { open: false, requesting: true, reconnectSignalR: true })
        case DISCONNECTED:
            return {}
        case NEW_MESSAGE:
            return Object.assign({}, state, { messages: append(action.payload, state.messages) })
        case SIGNALR_SW_SET:
            return Object.assign({}, state, { sw: action.payload })
        case SIGNALR_SW_CLEARED:
            return Object.assign({}, state, { sw: {} })
        case RECONNECT_SIGNALR:
            return Object.assign({}, state, { reconnectSignalR: action.payload })
        case SIGNALR_COMPANY:
            return Object.assign({}, state, { companyId: action.payload })
        case RECONNECTING_SIGNALR:
            return Object.assign({}, state, { open: false, reconnecting: true })
        default:
            return state
    }
}