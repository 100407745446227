import React, { useState } from "react";
import { RateListItem } from "./rateListItem";
import { updateWaiverRates } from "../../action-creators/billing";
import CustomizedSnackbars from "../snackbar";
import Button from '@material-ui/core/Button'

import { connect } from 'react-redux'
import { append, indexOf, isEmpty } from "ramda";
function RatesList(props) {
    const [newRate, setNewRate] = useState({ Rate: "", Count: "" })
    const [saveActive, setSaveActive] = useState(false)

    const {
        data,
        isLoading,
        isError,
        errorMsg,
        isSaved
    } = props.waiverRatesState;
    const waiverRates = data
    const [rates, setRates] = useState(waiverRates);

    const handleChange = (data) => {
        const newRates = Object.assign([], rates, { [data.ind]: data.newRate })
        setRates(newRates)
        setSaveActive(true)
    }
    const handleAddRate = (rate) => {
        const newRates = append(rate, rates)
        setRates(newRates)
        setNewRate({ Count: "", Rate: "" })
        setSaveActive(true)
    }
    const handleDeleteRate = (ind) => {
        const newRates = rates.filter(r => indexOf(r, rates) !== ind)
        setRates(newRates)
        setSaveActive(true)
    }
    const handleCancel = () => {
        setNewRate({ Count: "", Rate: "" })
        setRates(waiverRates)
        setSaveActive(false)
    }
    const handleSave = () => {
        props.updateWaiverRates(rates)
        setSaveActive(false)

    }
    return (
        <div style={{ padding: 20 }}>
            <div>
                {rates && rates.length >= 0
                    ? rates.map((rate, index) => (
                        <RateListItem
                            classes={props.classes}
                            key={index}
                            id={index}
                            quantity={rate.Count}
                            rate={rate.Rate}
                            onChangeCount={(event) => handleChange({ ind: index, newRate: { Count: Number(event.target.value), Rate: rate.Rate } })}
                            onChangeRate={(event) => handleChange({ ind: index, newRate: { Count: rate.Count, Rate: Number(event.target.value) } })}
                            action={() => handleDeleteRate(index)}
                        />
                    ))
                    : null}
                <RateListItem disabled={isEmpty(newRate.Rate) || isEmpty(newRate.Count)} classes={props.classes} new={true} quantity={newRate.Count} rate={newRate.Rate} onChangeCount={(event) => setNewRate({ Count: Number(event.target.value), Rate: Number(newRate.Rate) })} onChangeRate={(event) => setNewRate({ Rate: Number(event.target.value), Count: Number(newRate.Count) })}
                    action={() => handleAddRate({ Count: newRate.Count, Rate: newRate.Rate })}
                />
                {saveActive ? (<div><Button variant="contained" color="primary" onClick={() => handleSave()} className={props.classes.button}>Save</Button> <Button variant="contained" className={props.classes.button} onClick={() => { handleCancel() }}>Cancel</Button></div>) : null}
            </div>
            {isLoading && (
                <CustomizedSnackbars
                    message="Waiver Rates Saving..."
                    snackType="info"
                />
            )}
            {isSaved && (
                <CustomizedSnackbars
                    message="Waiver Rates Changed Successfully!"
                    snackType="success"
                />
            )}
            {isError && <CustomizedSnackbars message={errorMsg} snackType="error" />}
        </div>
    );
}
const mapStateToProp = state => ({
    waiverRatesState: state.waiverRates
})
const mapActionToProps = dispatch => ({
    updateWaiverRates: rates => dispatch(updateWaiverRates(rates))
})
const connector = connect(mapStateToProp, mapActionToProps)
export default connector(RatesList);