import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { isEmpty, or } from "ramda";
import EnhancedTableHead from "../../components/enhancedTableHead";
import EnhancedTableToolbar from "../../components/enhancedAppBar";
import {
  getLocation,
  updateLocation,
  updatePrimaryLocation,
  handleDialog,
  createLocation
} from "../../action-creators/location";
import {
  EDIT_LOCATION_REQUESTED,
  LOCATION_DIALOG_HANDLED
} from "../../constants";
import Spinner from "../../components/spinner";
import LocationCreateFormik from "../../components/locations/locationCreateFormik";
import CustomizedSnackbars from "../../components/snackbar";
import EditIcon from "@material-ui/icons/Edit";
import Hover from "../../components/hover";

const locationTableHeaders = [
  {
    id: "locationName",
    align: "inherit",
    disablePadding: false,
    label: "Location Name"
  },
  { id: "address", align: "inherit", disablePadding: false, label: "Address" },
  { id: "primary", align: "inherit", disablePadding: false, label: "Primary" },
  {
    id: "location",
    align: "inherit",
    disablePadding: false,
    label: "Location"
  },
  { id: "editbutton", align: "right", disablePadding: false, label: "" }
];

class Location extends Component {
  state = {
    open: false,
    openEdit: false,
    editLocation: {}
  };

  handleClickOpen = location => {
    //this.props.setLocationToEdit(location)
    this.setState({ open: true, editLocation: location });
  };

  handleLocationEdit = (location, classes) => {
    this.props.setLocationToEdit(location);
  };

  // handleLocationEdit2 = location => {
  //   console.log("handleLocationEdit2", location);
  //   this.setState({ openEdit: true, editLocation: location });
  // };

  handleClose = () => {
    this.props.dispatch({ type: LOCATION_DIALOG_HANDLED, payload: "" });
  };

  render() {
    const {
      locations,
      classes,
      handleDialog,
      open,
      companyId,
      dispatch,
      isSaved,
      isSaving,
      isFetching,
      isError
    } = this.props;

    return (
      <React.Fragment>
        <EnhancedTableToolbar
          location
          title="Locations"
          button1={
            <LocationCreateFormik
              open={open}
              dispatch={dispatch}
              handleDialog={handleDialog}
              handleClose={this.handleClose}
              locations={this.props.locations}
              createLocation={this.props.createLocation}
              classes={classes}
            />
          }
        />

        {or(isEmpty(locations), locations.isFetching) ? (
          <div>
            <Table>
              <EnhancedTableHead columnData={locationTableHeaders} />
            </Table>
            <Spinner />
          </div>
        ) : (
          <Table>
            <EnhancedTableHead columnData={locationTableHeaders} />
            <TableBody>
              {locations.data.map(location => {
                return (
                  <TableRow hover key={location.Id}>
                    <TableCell>{location.Name}</TableCell>
                    <TableCell>{location.Address}</TableCell>
                    <TableCell>
                      <Checkbox
                        checked={location.Primary}
                        // onChange={e => this.handlePrimaryChange(location.Id)}
                        color="primary"
                        value="primaryAddress"
                        disabled
                      />
                    </TableCell>
                    <TableCell>
                      <Grid container spacing={8}>
                        <Grid item>
                          <Tooltip id="Latitude-icon" title="Latitude">
                            <div>{`${location.Location.Latitude.toFixed(
                              4
                            )}`}</div>
                          </Tooltip>
                        </Grid>
                        <Grid item>
                          <Typography> , </Typography>
                        </Grid>
                        <Grid item>
                          <Tooltip id="Longitude-icon" title="Longitude">
                            <div>{`${location.Location.Longitude.toFixed(
                              4
                            )}`}</div>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <Hover
                        message="Edit Location"
                        component={
                          <Button
                            aria-label="Edit Location"
                            variant="contained"
                            color="default"
                            location={location}
                            component={Link}
                            className={classes.label}
                            to={`/companyDetails/${companyId}/${location.Id}`}
                            onClick={() => {
                              this.handleLocationEdit(location);
                            }}
                            // onClick={this.handleLocationEdit2(location)}
                          >
                            <EditIcon />
                          </Button>
                        }
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
        {isSaved && (
          <CustomizedSnackbars
            message="New Location successfully uploaded!"
            snackType="success"
          />
        )}
        {isFetching && (
          <CustomizedSnackbars
            message="New Location successfully uploaded!"
            snackType="info"
          />
        )}
        {isError && (
          <CustomizedSnackbars
            message="New Location failed to load! Please try again"
            snackType="error"
          />
        )}
        {isSaving && (
          <CustomizedSnackbars message="Location is saving!" snackType="info" />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  locations: state.locations,
  newLocation: state.locations.newLocation,
  //locationDialog: state.locationDialog
  isError: state.locations.isError,
  isFetching: state.locations.isFetching,
  isSaved: state.locations.isSaved,
  isSaving: state.locations.isSaving
});

const mapActionsToProps = dispatch => ({
  createLocation: location => dispatch(createLocation(location)),
  getLocation: () => dispatch(getLocation),
  updatePrimaryLocation: data => dispatch(updatePrimaryLocation(data)),
  setLocationToEdit: location => {
    dispatch({ type: EDIT_LOCATION_REQUESTED, payload: location });
  },
  updateLocation: data => dispatch(updateLocation(data)),
  handleDialog: action => dispatch(handleDialog(action))
});

const connector = connect(
  mapStateToProps,
  mapActionsToProps
);

// export default withDrawer(connector(withStyles(styles)(Location)));
// export default withDrawer(connector(Location));
export default connector(Location);

///old update Primary Location\\\
// handlePrimaryChange = id => {
//   // Update the UI
//   let {
//     locations: { response }
//   } = this.props;
//   let locIdx = findIndex(propEq("Id", id))(response);
//   const indexedMap = addIndex(map);
//   const updatedLocations = indexedMap((val, idx) => {
//     return idx === locIdx
//       ? merge(val, { Primary: true })
//       : merge(val, { Primary: false });
//   }, response);
//   //const updatedLocations = map(l => Object.assign({}, l), response)
//   this.props.dispatch({
//     type: LOCATION_SELECTED,
//     payload: { fetching: false, response: updatedLocations }
//   });

//   // to update the database
//   let updatedPrimaryLocation = merge(response[locIdx], { Primary: true });
//   this.props.updatePrimaryLocation(updatedPrimaryLocation);
// };
