import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddCircle from "@material-ui/icons/AddCircle";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CustomizedSnackbars from "./snackbar";
import { equals, isEmpty, or } from "ramda";
import "typeface-roboto";

class SigningStatementPanel extends Component {
  state = {
    statementEdited: "",
    selectStart: 0,
    usage: "",
    usageOptions: ["Participant", "Guardian", "Participant and Guardian"]
  };

  componentDidMount() {
    this.initStatementEdit(this.props.statement);
  }

  _onMouseMove = e => {
    this.setState({ selectStart: e.target.selectionStart });
  };

  participantFieldMerge = () => {
    this.setState({
      statementEdited: `${this.state.statementEdited.slice(
        0,
        this.state.selectStart
      )} {{-ParticipantName-}} ${this.state.statementEdited.slice(
        this.state.selectStart + 1
      )}`
    });
  };
  guardianFieldMerge = () => {
    this.setState({
      statementEdited: `${this.state.statementEdited.slice(
        0,
        this.state.selectStart
      )} {{-SignedByName-}} ${this.state.statementEdited.slice(
        this.state.selectStart + 1
      )}`
    });
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  resetStatement = originalStatement => {
    this.setState({ statementEdited: originalStatement });
  };

  handleStatementEdit = e => {
    this.setState({ statementEdited: e.target.value });
  };

  initStatementEdit = statementEdited => {
    this.setState({ statementEdited });
  };
  signingStatementPost = () => {
    const ssObj = {
      Statement: this.state.statementEdited,
      Usage: this.state.usage
    };
    this.props.signingStatementPost(ssObj);
  };
  render() {
    const {
      heading,
      expanded,
      statement,
      labelValue,
      classes,
      panelName,
      statementId,
      signingStatementAction,
      handleChange,
      isError,
      isFetching,
      isSaved
    } = this.props;

    return (
      <ExpansionPanel
        key={panelName}
        expanded={expanded === panelName}
        onChange={() => {
          handleChange(panelName);
        }}
      >
        <ExpansionPanelSummary
          expandIcon={!isEmpty(heading) ? <ExpandMoreIcon /> : <AddCircle />}
        >
          <Typography className={classes.heading}>
            {!isEmpty(heading) ? heading : `New Signing Statement`}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container>
            <Grid item sm={8} md={8} lg={8}>
              <FormControl className={classes.formControl}>
                {!isEmpty(heading) ? (
                  <TextField
                    disabled
                    id="Usage"
                    defaultValue={labelValue}
                    label="Usage"
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        root: classes.textFieldRoot,
                        input: classes.textFieldInput
                      }
                    }}
                    InputLabelProps={{
                      shrink: true,
                      className: classes.textFieldFormLabel
                    }}
                  />
                ) : (
                    <TextField
                      value={this.state.usage}
                      id={this.state.usage}
                      select
                      label="Usage"
                      InputProps={{
                        disableUnderline: true,
                        classes: {
                          root: classes.textFieldRoot,
                          input: classes.textFieldInput
                        }
                      }}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.textFieldFormLabel
                      }}
                      onChange={this.handleChange("usage")}
                      helperText="Please select usage for this signing statement"
                      SelectProps={{ MenuProps: { className: classes.menu } }}
                    >
                      {this.state.usageOptions.map((usage, index) => (
                        <MenuItem key={index} value={usage}>
                          {usage}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                <div onClick={this._onMouseMove.bind(this)}>
                  <label htmlFor="textarea" className={classes.textFieldLabel}>
                    Statement
                  </label>
                  <textarea
                    id="statementarea"
                    value={this.state.statementEdited}
                    spellCheck="false"
                    onChange={e => {
                      this.handleStatementEdit(e);
                    }}
                    style={{ fontFamily: "Roboto" }}
                    className={classes.textAreaInput}
                    rows="10"
                    cols="50"
                  />
                </div>
                <span className="button">
                  {!isEmpty(heading) ? (
                    <Button
                      disabled={or(
                        isFetching,
                        equals(this.state.statementEdited, statement)
                      )}
                      className={classes.signButton}
                      onClick={() =>
                        signingStatementAction(
                          this.state.statementEdited,
                          statementId
                        )
                      }
                      variant="contained"
                    >
                      Save
                    </Button>
                  ) : (
                      <Button
                        disabled={or(
                          isFetching,
                          or(
                            isEmpty(this.state.statementEdited),
                            isEmpty(this.state.usage)
                          )
                        )}
                        className={classes.signButton}
                        onClick={this.signingStatementPost}
                        variant="contained"
                      >
                        Save
                    </Button>
                    )}
                  <Button
                    className={classes.signButton}
                    onClick={() => this.resetStatement(statement)}
                    variant="contained"
                  >
                    Reset
                  </Button>
                </span>
              </FormControl>
            </Grid>
            <Grid item sm={4} md={4} lg={4}>
              <Grid container justify="flex-end">
                <Typography variant="headline" className={classes.mergeTitle}>
                  Merge Fields
                </Typography>
              </Grid>
              <Grid container justify="flex-end">
                <Button
                  className={classes.signButton}
                  onClick={this.participantFieldMerge}
                  variant="contained"
                >
                  Participant Name
                </Button>
              </Grid>
              <Grid container justify="flex-end">
                <Button
                  className={classes.signButton}
                  onClick={this.guardianFieldMerge}
                  variant="contained"
                >
                  Guardian Name
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </ExpansionPanelDetails>

        {isSaved && (
          <CustomizedSnackbars
            message="New Signing Statement successfully uploaded!"
            snackType="success"
          />
        )}
        {isFetching && (
          <CustomizedSnackbars
            message="New Signing Statement is loading..."
            snackType="info"
          />
        )}
        {isError && (
          <CustomizedSnackbars
            message="New Signing Statement has failed to load. Please try again."
            snackType="error"
          />
        )}
      </ExpansionPanel>
    );
  }
}

export default SigningStatementPanel;
