import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import green from '@material-ui/core/colors/green'
import red from '@material-ui/core/colors/red'
const toolbarStyles = theme => ({
  appBar: {
    position: "relative",
    marginBottom: '1.0'
  },
  appBarRed: {
    position: 'relative',
    marginBottom: '1.0',
    backgroundColor: red[300]
  },
  appBarGreen: {
    position: 'relative',
    marginBottom: '1.0',
    backgroundColor: green[300]
  },
  flex: {
    flex: 1
    //justifyContent: 'center'
  }
});

const DialogAppBar = ({
  title,
  handleClose,
  classes,
  subtitle,
  title2,
  title3,
  red,
  green
}) => {
  //this is what changes the color in the approved or rejected signedWaivers.  Please dont remove
  let appClass
  if (red) {
    appClass = classes.appBarRed
  } else if (green) {
    appClass = classes.appBarGreen
  } else {
    appClass = classes.appBar
  }
  return (
    <AppBar className={appClass} style={{ marginBottom: "1.0rem" }}>
      <Toolbar>
        <Typography variant="title" color="inherit" className={classes.flex}>
          {title}
        </Typography>
        {title2 ? (
          <Typography variant="subheading" color="inherit">
            {subtitle}
          </Typography>
        ) : null}
        {title3 ? (
          <IconButton
            color="inherit"
            component={Link}
            to={title3}
            aria-label="Close"
          >
            <CloseIcon />
          </IconButton>
        ) : (
            <IconButton color="inherit" onClick={handleClose} aria-label="Close">
              <CloseIcon />
            </IconButton>
          )}
        {/* // <IconButton color="inherit" onClick={handleClose} aria-label="Close">
                //     <CloseIcon />
                // </IconButton> */}
      </Toolbar>
    </AppBar>
  );
};

export default withStyles(toolbarStyles)(DialogAppBar);
