import fetch from "isomorphic-fetch";
import {
  WAIVERS_REQUESTED,
  WAIVERS_RETRIEVED,
  NEW_WAIVER_SAVE_STARTED,
  NEW_WAIVER_SAVE_SUCCEEDED,
  EDIT_WAIVER_SAVE_STARTED,
  EDIT_WAIVER_SAVE_FAILED,
  EDIT_WAIVER_SAVE_SUCCEEDED,
  WAIVER_PDF_ACQUIRED,
  WAIVER_PDF_REQUESTED,
  CURRENT_WAIVER_SET,
  LOADING,
  WAIVERS_FAILED
} from "../constants";
import { coalesce, } from "../lib/fetch";
import fetchDefaults from "fetch-defaults";
import { propEq, find, isEmpty, split, join } from "ramda";
import { setPendingSW, } from "./signedWaivers";
import moment from "moment";
export const setWaivers = async (dispatch, getState) => {
  dispatch({ type: WAIVERS_REQUESTED, payload: true })

    const waivers = await fetch(`${process.env.REACT_APP_URL}/api/waivers`, {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': window.location.protocol + "//" + window.location.host,
        'Authorization': `Bearer ${coalesce(sessionStorage.getItem('access_token'), localStorage.getItem('access_token'))}`
    }
    })
      .then(res => res.json())
      .catch(err => dispatch({type: WAIVERS_FAILED, payload: err}));

  
 
    dispatch({ type: WAIVERS_RETRIEVED, payload: waivers });
    dispatch({type: LOADING, payload: false})
    dispatch(setPendingSW);

  };


export const setCurrentWaiver = id => (dispatch, getState) => {
  const waivers = getState().waivers;
  const w = find(propEq("Id", Number(id)), waivers);
  let waiver
  if (w.VideoURL === "null") {
    waiver = Object.assign({}, w, { VideoURL: "" })
  } else {
    waiver = w
  }
  dispatch({ type: CURRENT_WAIVER_SET, payload: waiver });
};


//this is being used to post new waiver
export const addWaiver = data => (dispatch, getState) => {
  dispatch({ type: NEW_WAIVER_SAVE_STARTED });
 // dispatch({ type: WAIVERS_REQUESTED, payload: true })
 dispatch({type: LOADING, payload: true})
  var formData = new FormData();
  formData.append("Active", data.Active);
  formData.append("Name", data.Name);
  formData.append("ParticipantPdfFile", data.ParticipantPdf);
  formData.append("GuardianPdfFile", data.GuardianPdf);
  formData.append("Description", data.Description);
  formData.append(
    "ParticipantSigningStatement_Id",
    data.ParticipantSigningStatement_Id
  );
  formData.append(
    "GuardianSigningStatement_Id",
    data.GuardianSigningStatement_Id
  );
  formData.append("ImageFile", data.ImgFileName);
  if (!isEmpty(data.VideoURL)) {
    formData.append("VideoURL", data.VideoURL);
  }
  formData.append("Expiration", moment(data.Expiration).format());
  formData.append("LocalExpiration", moment(data.Expiration).format());
  formData.append(
    "ExpirationOffsetTS",
    data.ExpirationOffset
  );
  fetch(`${process.env.REACT_APP_URL}/Waiver/Create`, {
    method: "POST",
    body: formData,
    mode: "cors",
    cache: "no-cache",
    headers: {
      Authorization: `Bearer ${coalesce(
        sessionStorage.getItem("access_token"),
        localStorage.getItem("access_token")
      )}`,
      "Access-Control-Allow-Origin": `${window.location.protocol}//${
        window.location.host
        }`,
      Accept: "application/json",
      "Access-Control-Allow-Credentials": true
    }
  }).then(res => {
    if (res.status === 200) {
      dispatch({
        type: NEW_WAIVER_SAVE_SUCCEEDED,
        payload: { fetching: false, response: "Success" }
      });



      dispatch(setWaivers);

    } else {

      dispatch({
        type: EDIT_WAIVER_SAVE_FAILED,
        payload: {
          fetching: false,
          message:
            "Error " +
            res.statis +
            " occurred when trying to upload your file."
        }
      })

      dispatch(setWaivers);

    }
  })

};


export const editWaiver = data => async (dispatch, getState) => {

  const w = getState().waiver
  dispatch({ type: EDIT_WAIVER_SAVE_STARTED, payload: { fetching: true } });
  //dispatch({ type: WAIVERS_REQUESTED, payload: true })
  dispatch({type: LOADING, payload: true})
  const wId = w.Id;

  var formData = new FormData();
  formData.append("Id", wId);
  formData.append("Active", data.Active);
  formData.append("Description", data.Description);
  formData.append("Name", data.Name);
  formData.append("ParticipantPdfFile", data.ParticipantPdf);
  formData.append("GuardianPdfFile", data.GuardianPdf);
  formData.append(
    "ParticipantSigningStatement_Id",
    data.ParticipantSigningStatement_Id
  );
  formData.append(
    "GuardianSigningStatement_Id",
    data.GuardianSigningStatement_Id
  );
  formData.append("ImageFile", data.ImgFileName);
  if (!isEmpty(data.VideoURL) && data.VideoURL !== "null") {
    formData.append("VideoURL", data.VideoURL);
  }
  formData.append("Expiration", moment(data.Expiration).format());
  formData.append("LocalExpiration", moment(data.Expiration).format());
  formData.append(
    "ExpirationOffsetTS", data.ExpirationOffset
    // moment(data.ExpirationOffset, "D:HH:mm:ss").format("D:HH:mm:ss")
  );
  formData.append("Updated", moment().format());
  fetch(`${process.env.REACT_APP_URL}/Waiver/Edit/${wId}`, {
    method: "POST",
    body: formData,
    mode: "cors",
    cache: "no-cache",
    headers: {
      Authorization: `Bearer ${coalesce(
        sessionStorage.getItem("access_token"),
        localStorage.getItem("access_token")
      )}`,
      "Access-Control-Allow-Origin": `${window.location.protocol}//${
        window.location.host
        }`,
      Accept: "application/json",
      "Access-Control-Allow-Credentials": true
    }
  }).then(res => {
    if (res.status === 200) {
      dispatch({
        type: EDIT_WAIVER_SAVE_SUCCEEDED,
        payload: { fetching: false, response: "Success" }
      });
      dispatch(setWaivers);
    } else {
      dispatch({
        type: EDIT_WAIVER_SAVE_FAILED,
        payload: {
          fetching: false,
          message:
            "Error " +
            res.status +
            " occurred when trying to upload your file."
        }
      })
      dispatch(setWaivers);
    }
  })

};

export const getWaiverPdf = (id, isGuardian) => async (dispatch, getState) => {

  const authHeaders = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin":
      window.location.protocol + "//" + window.location.host,
    Authorization: `Bearer ${coalesce(
      sessionStorage.getItem("access_token"),
      localStorage.getItem("access_token")
    )}`
  };
  const apiFetch2 = fetchDefaults(fetch, `${process.env.REACT_APP_URL}`, {
    headers: authHeaders
  });
  dispatch({ type: WAIVER_PDF_REQUESTED, payload: { fetching: true } });
  const response = await apiFetch2(`/waiver/index/${id}?guardian=${isGuardian}`)
    .then(res => res.blob())
    .catch(err => console.log("err: ", err));
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    const waivers = getState().waivers
    const w = find(propEq("Id", id), waivers)
    let type
    if (isGuardian) {
      type = "Guardian_PDF"
    } else {
      type = "Participant_PDF"
    }
    const n = split(' ', w.Name)
    const nn = join('_', n)
    const fileName = `${nn}_${type}.pdf`
    const file = new Blob([response], { type: "application/pdf" })
    window.navigator.msSaveBlob(file, fileName);
    dispatch({ type: WAIVER_PDF_ACQUIRED, payload: { fetching: false, file } })
  }
  else {
    //ar objectUrl = URL.createObjectURL(response);
    const file = new Blob([response], { type: "application/pdf" })
    const objUrl = window.URL.createObjectURL(file)
    window.open(objUrl, `_blank`);
    dispatch({ type: WAIVER_PDF_ACQUIRED, payload: { fetching: false, objUrl } })
  }
};

