import fetchDefaults from "fetch-defaults";
import fetch from "isomorphic-fetch";

// for behavior similiar to that of the C# null coalescing operator
export function coalesce() {
  var len = arguments.length;
  for (var i = 0; i < len; i++) {
    if (arguments[i] !== null && arguments[i] !== undefined) {
      return arguments[i];
    }
  }
  return null;
}

export const apiFetchClosure = () => {
  const authHeaders = {
    "Content-Type": "application/json",
    // "Content-Type": "multipart/form-data",
    "Access-Control-Allow-Origin": `${window.location.protocol}//${
      window.location.host
    },`,
    // window.location.protocol + "//" + window.location.host,
    Authorization: `Bearer ${coalesce(
      sessionStorage.getItem("access_token"),
      localStorage.getItem("access_token")
    )}`
  };
  return fetchDefaults(fetch, `${process.env.REACT_APP_URL}`, {
    headers: authHeaders
  });
};

// let response = "";
// // const response = await fetch(`http://localhost:61548/Company/Edit`, {

// var request = new XMLHttpRequest();
// request.open("POST", `${process.env.REACT_APP_URL}/api/company`)
// request.responseType = "json";
//   request.setRequestHeader(
//     "Access-Control-Allow-Origin",
//     `${window.location.protocol}//${window.location.host}`
//   );
//   request.setRequestHeader(
//     "Authorization",
//     "Bearer " +
//       `${coalesce(
//         localStorage.getItem("access_token"),
//         sessionStorage.getItem("access_token")
//       )}`
//   );

export const waiverPdfFetchClosure = () => {
  const authHeaders = {
    "Content-Type": "blob",
    "Access-Control-Allow-Origin":
      window.location.protocol + "//" + window.location.host,
    Authorization: `Bearer ${coalesce(
      sessionStorage.getItem("access_token"),
      localStorage.getItem("access_token")
    )}`
  };
  return fetchDefaults(fetch, `${process.env.REACT_APP_URL}`, {
    headers: authHeaders
  });
};
