import { modulo, divide, subtract, gt, split, equals, head, multiply, sum } from 'ramda'

const sid = 86400
const sih = 3600
const sim = 60
//allows input of day and portion
export const cleanTimes = os => {
    const data = split(':', os)
    if (equals(modulo(multiply(head(data), 24), 24), 0)) {
        return os
    } else {
        const d = multiply(head(data), sid)
        const h = multiply(data[1], sih)
        const m = multiply(data[2], sim)
        const total = sum([d, h, m, data[3]])
        const newOffset = getTimes(total)
        return newOffset.offset
    }
}
//takes in seconds that gets from expiration offset and breaks it down
//moment should be able to do this but wanted to change it into actual day instead of number of days
export const getTimes = s => {
    //sad is seconds after days is take out
    const sad = modulo(s, sid)
    const days = divide(subtract(s, sad), sid)
    const sah = modulo(sad, sih)
    const hours = divide(subtract(sad, sah), sih)
    const remainingSeconds = modulo(sah, sim)
    const minutes = divide(subtract(sah, remainingSeconds), sim)
    const offset = `${days}:${hours}:${minutes}:${remainingSeconds}`
    let vh
    let vm
    let ds
    let hs
    let ms
    if (gt(days, 1)) {
        ds = " Days "
    } else {
        ds = " Day "
    }
    if (gt(hours, 1)) {
        hs = " Hours "
    } else {
        hs = " Hour "
    }
    if (gt(minutes, 1)) {
        ms = " Minutes "
    } else {
        ms = " Minute "
    }
    if (hours === 0) {
        vh = ""
    } else {

        vh = hours + hs
    }
    if (minutes === 0) {
        vm = ""
    }
    else {
        vm = minutes + ms
    }
    const viewOffset = days + ds + vh + vm
    return { d: days, h: hours, m: minutes, s: remainingSeconds, offset: offset, view: viewOffset }
}