import React from "react";
import IntlTelInput from "react-intl-tel-input";
import { last } from "ramda";
import "react-intl-tel-input/dist/main.css";

const CustomIntlTelInput = ({
  value,
  css,
  classes,
  inputClassName,
  containerClassName,
  className, //id, defaultValue,
  preferredCountries,
  form: { setFieldValue, setFieldTouched, errors, touched },
  field: { name, validateField }
  //   ...props
}) => {
  return (
    <div>
      <IntlTelInput
        validate={validateField}
        containerClassName={containerClassName}
        inputClassName={inputClassName}
        fieldName={name}
        defaultValue={value}
        autoPlaceholder={false}
        placeholder="Phone Number"
        onPhoneNumberChange={(isValid, value, countryCode) => {
          if (isNaN(last(value))) {
            setFieldTouched(name, true, true);
          }
          if (isValid) {
            setFieldTouched(name, true, true);
            value = window.intlTelInputUtils.formatNumber(
              value,
              countryCode.iso2
            );
            setFieldValue(name, value);
          }
          setFieldValue(name, value);
        }}
        onPhoneNumberBlur={() => {
          setFieldTouched(name, true, true);
        }}
        preferredCountries={preferredCountries}
        // preferredCountries={[`${preferredCountries}`]}
        css={["intl-tel-input", `${css}`]}
        // css={[`${css}`]}
        //css={["intl-tel-input", 'form-control']}
        format={true}
        formatOnInit={true}
        formatOnDisplay
      />
    </div>
  );
};
export default CustomIntlTelInput;
