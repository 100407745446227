import React from "react"
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import CardHeader from "@material-ui/core/CardHeader";
import Card from '@material-ui/core/Card'
import ErrorIcon from '@material-ui/icons/Error'
import { and, or, isNil, isEmpty } from 'ramda'
const SignedWaiverMedia = ({ signedWaiver }) => {

    return (
        <Grid container justify='center' >
            <Grid item xs >
                {
                    signedWaiver.videoUrl

                        ? <Grid container justify='center' >
                            <Grid item><video src={signedWaiver.videoUrl} className="img-sw-photo" controls></video>
                            </Grid>
                        </Grid>
                        :
                        <Grid container justify='center' >
                            <Grid item>
                                <img src={signedWaiver.pictureUrl} className="img-sw-photo" />
                            </Grid>
                        </Grid>
                }
            </Grid>
        </Grid>
    )

}

const MediaCard = ({ signedWaiver }) => {

    return (
        signedWaiver.fetching ? <Grid container justify='center'>
            <Grid item>
                <CircularProgress
                    size={100}
                />
            </Grid>
        </Grid> :
            and(or(isNil(signedWaiver.pictureUrl), isEmpty(signedWaiver.pictureUrl)), or(isNil(signedWaiver.videoUrl), isEmpty(signedWaiver.videoUrl))) ?
                <Grid container justify='center'>
                    <Grid item>
                        <Card elevation={0}>
                            <CardHeader
                                avatar={<ErrorIcon color="primary" />} title="No Media Was Found For This Signed Waiver" />
                        </Card>
                    </Grid>
                </Grid>
                : signedWaiver.videoUrl || signedWaiver.pictureUrl
                    ? <Grid container justify='center'>
                        <Grid item xs>
                            <SignedWaiverMedia
                                signedWaiver={signedWaiver}
                            />
                        </Grid>
                    </Grid>
                    :
                    // signedWaiver.failed ? 
                    <Grid container justify='center'>
                        <Grid item>
                            <Card elevation={0}>
                                <CardHeader
                                    avatar={<ErrorIcon color="error" />} title="Failed to load media" />
                            </Card>
                        </Grid>
                    </Grid>

    )

}

export default MediaCard
