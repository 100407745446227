import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import PartList from "./participantList";
import "../../AWaiver.css";
import MediaCard from "./mediaCard";
import DialogAppBar from "../dialogAppBar";
import Dialog from "@material-ui/core/Dialog";
import MobileStepper from "@material-ui/core/MobileStepper";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { styles } from "./swStyles";
import SWSignature from "./swSignature";
import NotesCard from "./swNotes";
import SwActionBar from "./swActionBar";
import { SIGNED_WAIVER_SET, SET_SW_ARRAY } from '../../constants'
import { clone, find, propEq } from 'ramda'
import { throws } from "assert";
import { swAttachments } from "../../reducers/signedWaivers";
import green from '@material-ui/core/colors/green'
import red from '@material-ui/core/colors/red'
import Typography from '@material-ui/core/Typography'
const TextMobileStepper = ({
  classes,
  theme,
  activeSignedWaivers,
  activeStep,
  handleNext,
  handleBack,
  children
}) => {
  const maxSteps = activeSignedWaivers.length;
  return (
    <div className={classes.root}>
      <MobileStepper
        steps={maxSteps}
        variant="progress"
        position="static"
        activeStep={activeStep}
        className={classes.mobileStepper}
        nextButton={
          <Typography color="primary" variant="button">
            {`${activeStep + 1} / ${maxSteps}`}
          </Typography>

        }
        backButton={<div />
          // <Typography>
          //   {`${activeStep + 1} / ${maxSteps}`}
          // </Typography>
        }
      />
      <br />{children}
    </div>
  );
};

TextMobileStepper.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const SWSteps = withStyles(styles, { withTheme: true })(TextMobileStepper);

const SimpleModal = ({
  red,
  green,
  classes,
  onEntering,
  onExiting,
  children,
  open,
  handleClose,
  heading,
  fullScreen,
  subtitle
}) => {
  // let classname
  // if (window.visualViewport.width < 800) {
  //   classname = classes.gridpaper
  // } else {
  //   classname = classes.bigScreen
  // }
  return (
    <div>
      <Dialog
        aria-labelledby="form-dialog-video"
        onEntering={onEntering}
        onExiting={onExiting}
        open={open}
        fullScreen
        // fullWidth={!window.visualViewport.width < 800}
        // maxWidth={!window.visualViewport.width < 800 ? "xl" : ""}
        //  fullScreen={window.visualViewport.width < 800}
        className={classes.gridpaper}
        onClose={handleClose}
      >
        <DialogAppBar
          red={red}
          green={green}
          title={heading}
          title2
          subtitle={subtitle}
          handleClose={handleClose}
        />

        <DialogContent>{children}</DialogContent>
      </Dialog>
    </div>
  );
};
class SWPopup extends React.PureComponent {
  // constructor(props) {
  // super(props)
  // this.state = {
  //   signedWaiver: {}
  // }
  // }

  //updating status
  handleStatus = ev => {
    const status = Number(ev.currentTarget.value)
    const id = Number(ev.currentTarget.id)
    const sws = clone(this.props.activeArray)
    const sw = find(propEq("SignedWaiver_Id", id), sws)
    let color
    if (status === 1) {
      color = green[200]
    } else if (status === -1) {
      color = red[200]
    }
    const newSW = Object.assign(sw, { Status: status, color: color })
    this.props.statusUpdated(newSW)
    const newSwArray = Object.assign([], sws, { [this.props.activeStep]: newSW })
    //this.props.dispatch({ type: SET_SW_ARRAY, payload: newSwArray })
    this.props.handleUpdateSWArray(newSwArray)
    this.props.handleNext()
  }
  render() {
    const {
      waiver,
      subtitle,
      classes,
      handleClose,
      open,
      activeSignedWaivers,
      handleStepBack,
      handleStepNext,
      note,
      activeStep,
      addActivityDate,
      dispatch,
      swStepper,
      getParticipantPdf, swAttachments, activeArray
    } = this.props
    let heading
    // if (this.props.swStepper.swArray[this.props.currentStep].Status === -1) {
    if (activeArray[this.props.currentStep].Status === -1) {
      heading = "Rejected"
    } else if (activeArray[this.props.currentStep].Status === 1) {
      heading = "Approved"
    } else {
      // heading = "Approve Waiver"
      heading = "Pending"
    }
    let rejectBG
    if (activeArray[this.props.currentStep].Status === -1) {
      rejectBG = "secondary"
    }
    let approveBG
    if (activeArray[this.props.currentStep].Status === 1) {
      approveBG = "primary"
    }
    let size
    if (window.screen.width < 600) {
      size = 12
    } else {
      size = 6
    }
    return (
      <SimpleModal
        waiver={waiver}
        subtitle={subtitle}
        classes={classes}
        heading={heading}
        handleClose={handleClose}
        open={open}
        red={activeArray[this.props.currentStep].Status === -1} green={activeArray[this.props.currentStep].Status === 1}
      >
        <SWSteps
          activeSignedWaivers={activeArray}
          handleBack={handleStepBack}
          handleNext={handleStepNext}
          activeStep={activeStep}
        >
          <div className={classes.root} key={activeArray[this.props.currentStep].SignedWaiver_Id}>
            <Grid container spacing={8}>
              <Grid item xs={size}>
                {/* <div className={classes.lefty}> */}
                <PartList
                  signedWaiver={activeArray[this.props.currentStep]}
                  activeStep={activeStep}
                  swArray={activeArray}
                  //dispatch={dispatch}
                  swAttachments={swAttachments}
                  addActivityDate={addActivityDate}
                  getParticipantPdf={getParticipantPdf}
                  note={note}
                  handleUpdateSWArray={this.props.handleUpdateSWArray}

                />
                {/* </div> */}
              </Grid>
              <Grid item xs={size}>
                {/* <div className={classes.righty}> */}
                <MediaCard signedWaiver={swAttachments} />
                <SWSignature signedWaiver={activeArray[this.props.currentStep]} sw={swAttachments} classes={classes} />
                {/* </div> */}
              </Grid>
              <Grid item xs={12}>

                <NotesCard
                  //  classes={classes}
                  signedWaiver={activeArray[this.props.currentStep]}
                  getSWNotes={this.props.getSWNotes}
                  // dispatch={dispatch}
                  // swStepper={swStepper}
                  activeArray={activeArray}
                  handleUpdateSWArray={this.props.handleUpdateSWArray}

                /> </Grid> </Grid>
            <br />
            <br />
          </div>
        </SWSteps>

        <SwActionBar
          id={activeArray[this.props.currentStep].SignedWaiver_Id}
          // signedWaiver={activeSW}
          signedWaiver={activeArray[this.props.currentStep]}
          handleClose={handleClose}
          handleForward={handleStepNext}
          handleBack={handleStepBack}
          rejectBG={rejectBG}
          approveBG={approveBG}
          rejectDisabled={activeArray[this.props.currentStep].Status === -1}
          approveDisabled={activeArray[this.props.currentStep].Status === 1}
          handleRejected={this.handleStatus}
          handleAccepted={this.handleStatus}
        />
      </SimpleModal>
    );
  };
}

export default SWPopup;
