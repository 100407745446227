import React from 'react'
import { Grid, CircularProgress } from '@material-ui/core'

const Spinner = props => {
  return (
    <Grid style={{ height: window.innerHeight }} container alignItems="center" justify='center'>
      <Grid item>
        <CircularProgress
          size={props.csize ? props.csize : window.innerHeight / 5} thickness={props.thickness}
        />
      </Grid>
    </Grid>
  )
}

export default Spinner