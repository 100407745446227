import {
    LOGIN_STARTED, LOGIN_FAILED, LOGIN_SUCCEEDED, LOGOUT_SUCCEEDED, FORGOT_PASSWORD_REQUESTED,
    FORGOT_PASSWORD_EMAIL_SUCCEEDED,
    FORGOT_PASSWORD_EMAIL_FAILED,
    PASSWORD_CONFIRMATION_REQUESTED,
    PASSWORD_CONFIRMATION_SUCCEEDED,
    RESET_PASSWORD_REQUESTED,
    RESET_PASSWORD_SUCCEEDED, SET_CURRENT_USER, UPDATE_COMPANY, REGISTER_USER_EMAIL_FAILED, REGISTER_USER_EMAIL_SUCCEEDED, REGISTER_USER_REQUESTED, RESET_AUTH, NOT_AUTHORIZED, CLEAR_NO_AUTH, USER_COMPANY_ROLES, SET_USER_ROLE, SET_USER_COMPANIES, LOGOUT_REQUIRED
} from "../constants"
export const authentication = (state = {}, action) => {
    switch (action.type) {
        case LOGIN_STARTED:
            return Object.assign({}, state, action.payload)
        case LOGIN_FAILED:
            return Object.assign({}, state, action.payload)
        case SET_CURRENT_USER:
            return Object.assign({}, state, action.payload.user)
        case UPDATE_COMPANY:
            return Object.assign({}, state, { CurrentCompanyId: action.payload })
        case LOGIN_SUCCEEDED:
            return Object.assign({}, state, action.payload)
        case FORGOT_PASSWORD_REQUESTED:
            return Object.assign({}, state, { requesting: true })
        case FORGOT_PASSWORD_EMAIL_FAILED:
            return Object.assign({}, state, { response: action.payload, emailSuccess: false, requesting: false })
        case FORGOT_PASSWORD_EMAIL_SUCCEEDED:
            return Object.assign({}, state, action.payload, { response: action.payload, emailSuccess: true, requesting: false })
        case REGISTER_USER_REQUESTED:
            return Object.assign({}, state, { requesting: true, })
        case REGISTER_USER_EMAIL_FAILED:
            return Object.assign({}, state, { response: action.payload, regEmail: false, requesting: false })
        case REGISTER_USER_EMAIL_SUCCEEDED:
            return Object.assign({}, state, action.payload, { response: action.payload, regEmail: true, requesting: false })
        case LOGOUT_SUCCEEDED:
            window.localStorage.clear()
            window.sessionStorage.clear()
            return {}
        case RESET_AUTH:
            return {}
        case NOT_AUTHORIZED:
            return Object.assign({}, state, { noAuth: true })
        case CLEAR_NO_AUTH:
            return Object.assign({}, state, { noAuth: false })
        case USER_COMPANY_ROLES:
            return Object.assign({}, state, { UserCompanies: action.payload })
        case SET_USER_ROLE:
            // return Object.assign({}, state, { CurrentRoleId: action.payload })
            return Object.assign({}, state, action.payload)
        case SET_USER_COMPANIES:
            return Object.assign({}, state, { UserCompanies: action.payload })
        case LOGOUT_REQUIRED:
            return Object.assign({}, state, { logoutRequired: true })
        default:
            return state
    }
}