import * as Yup from "yup";
import {
  equals,
  filter,
  gt,
  gte,
  isEmpty,
  length,
  lt,
  match,
  not,
  or,
  split,
  trim
} from "ramda";
import moment from "moment";

///Yup validation Schemas\\\
export const newCompanySchema = Yup.object().shape({
  CompanyName: Yup.string()
    .min(2, "Too short")
    .max(60, "Too Long")
    .required("Required"),
  BillingEmail: Yup.string()
    .email("Invalid email")
    .required("Required"),
  ImageURL: Yup.string().url("Invalid URL format"),
  CompanyDescription: Yup.string()
    .min(5, "Too Short")
    .max(200, "Too Long"),
  ZipCode: Yup.number()
    .min(0, "Please complete zipcode")
    .max(99999, "Must be a Zip / Postal Code")
    .positive()
    .integer()
    .required("Required"),
  Country: Yup.string().required("Required"),
  City: Yup.string().required("Required"),
  State: Yup.string().required("Required"),
  ZipCode: Yup.string().required("Required"),
  GetContactInformation: Yup.boolean(),
  GetBirthday: Yup.boolean(),
  // SameAsBillingAddress: Yup.boolean(),
  CardHolder: Yup.string()
    .min(2, "Too Short")
    .max(80, "Too Long")
    .required("Required"),
  CardNumber: Yup.string()
    .min(12, "Too Short")
    .max(20, "Too Long")
    .required("Required"),
  BillingZip: Yup.number()
    .min(0, "Please complete zipcode")
    .max(99999, "Must be a Zip / Postal Code")
    .positive("May not be negative")
    .integer("Must be a number")
    .required("Required"),
  CVC: Yup.number()
    .min(0)
    .max(9999)
    .required("Required"),
  // ExpDate: Yup.string()
  ExpDate: Yup.object().shape({
    Month: Yup.string().required("Required"),
    Year: Yup.string().required("Required")
  }),
  Remember: Yup.boolean()
});

export const companySchema = Yup.object().shape({
  Name: Yup.string()
    .min(2, "Too short")
    .max(75, "Too Long")
    .required("Required"),
  BillingEmail: Yup.string()
    .email("Invalid email")
    .required("Required"),
  Description: Yup.string()
    .min(5, "Too Short")
    .max(250, "Too Long")
});

export const newBillingDataSchema = Yup.object().shape({
  CardHolder: Yup.string()
    .min(2, "Too Short")
    .max(80, "Too Long")
    .required("Required"),
  CardNumber: Yup.string()
    .min(12, "Too Short")
    .max(20, "Too Long")
    .required("Required"),
  ZipCode: Yup.number("Must be a number")
    .min(0, "Please complete zipcode")
    .max(99999, "Must be a Zip / Postal Code")
    .positive()
    .integer()
    .required("Required"),
  CVC: Yup.string()
    .min(3, "Too Short")
    .max(4, "Too Long")
    .required("Required"),
  ExpDate: Yup.object().shape({
    Month: Yup.string().required("Required"),
    Year: Yup.string().required("Required")
  }),
  Remember: Yup.boolean()
});

export const locationDataSchema = Yup.object().shape({
  Name: Yup.string()
    .min(2, "Too short")
    .max(100, "Too Long")
    .required("Required"),
  Address: Yup.string()
    .min(4, "Too Short")
    .max(150, "Too Long")
    // .max()
    .required("Address is required")
  //Location
  // Latitude: Yup.number()
  // .min(0, "Please complete zipcode")
  // .max(99999, "Must be a Zip / Postal Code")
  // .positive()
  // .integer(),
  // Longitude: Yup.string()
  // .min(3)
  // .max(4)
  // .required("Required"),
  // Primary: Yup.boolean()
});

///////Logical Validation\\\\\\\
export const companyValidate = values => {
  let errors = {};
  //const ExpirationMonthNumericalValue = moment().month(values.ExpirationMonth).format("M");
  const ExpirationMonthNumericalValue = moment()
    .month(`${values.ExpirationMonth}`)
    .format("M");
  if (!values.CompanyName) {
    errors.CompanyName = "Company name is required";
  }
  if (!values.BillingEmail) {
    errors.BillingEmail = "Email address required";
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.billingEmail)
  ) {
    errors.BillingEmail = "Invalid email address";
  }
  if (!values.CardHolder) {
    errors.CardHolder = "Card holder name required";
  }
  if (!values.CardNumber) {
    errors.CardNumber = "Card number required";
  } else if (isNaN(Number(values.CardNumber))) {
    errors.CardNumber = "Credit card number must be a number";
  }
  if (!values.BillingAddressZip) {
    errors.BillingAddressZip = "Billing address zip required";
  } else if (isNaN(Number(values.BillingAddressZip))) {
    errors.BillingAddressZip = "Bill address zip must be a number";
  } else if (values.BillingAddressZip.length !== 5) {
    errors.BillingAddressZip = "Zip Code must be 5 digits";
  }
  if (!values.CVC) {
    errors.CVC = "CVC required";
  } else if (!isNaN(Number(values.CVC))) {
    errors.CVC = "CVC must be a number";
  } else if (values.CVC.length !== 3) {
    errors.CVC = "Must be 3 characters";
  }
  if (!values.MonthExpiration) {
    errors.MonthExpiration = "Expiration month required";
  }
  if (!values.YearExpiration) {
    errors.YearExpiration = "Expiration year required";
  }
  return errors;
};
//lym using companycontact
export const validateContactName = value => {
  let errorMessage;

  if (isEmpty(value)) {
    errorMessage = "Name is required";
  } else if (lt(length(value), 3)) {
    errorMessage = "Too Short";
  } else if (gt(length(value), 50)) {
    errorMessage = "Too Long";
  }
  return errorMessage;
};
//lym using for contact phone
export const validateContactPhone = value => {
  let errorMessage;
  if (!value) {
    errorMessage = "Phone number required";
  } else if (!window.intlTelInputUtils.isValidNumber(value, "")) {
    errorMessage = "Invalid phone number";
  }
  return errorMessage;
};
// export const validateName = value => {
//   //validate for only middle Initial
//   let errorMessage;
//   let singleName = value => split(" ", trim(value));
//   let nameLength = value => length(value) < 2;

//   if (isEmpty(value)) {
//     errorMessage = "Name is required";
//     // } else if (length(value) < 5) {
//   } else if (lt(length(value), 5)) {
//     errorMessage = "Too Short";
//     // } else if (length(value) > 50) {
//   } else if (gt(length(value), 50)) {
//     errorMessage = "Too Long";
//   } else if (isEmpty(match(/ /, trim(value)))) {
//     errorMessage = "Enter Full (First and Last) Name Here";
//     // } else if (!isEmpty(filter(nameLength, singleName(value)))) {
//     //   errorMessage = "Too Short";
//   }
//   return errorMessage;
// };

//now checks for at least 2 names that are at least 2 letters each
export const validateName = value => {
  let errorMessage;
  let singleName = value => split(" ", trim(value));
  let gtNameLength = value => gte(length(value), 2); //length(value)
  // let nameLength = value => length(value) < 2;

  if (isEmpty(value)) {
    errorMessage = "Name is required";
  } else if (length(value) < 5) {
    errorMessage = "Too Short";
  } else if (length(value) > 50) {
    errorMessage = "Too Long";
  } else if (isEmpty(match(/ /, trim(value)))) {
    errorMessage = "Enter Full (First and Last) Name Here";
    // } else if (!isEmpty(filter(nameLength, singleName(value)))) {
    //   errorMessage = currentLocale.tooShort;
  } else if (gte(1, length(filter(gtNameLength, singleName(value))))) {
    errorMessage = "Too Short";
  }

  return errorMessage;
};

export const validateEmail = value => {
  let errorMessage;
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    errorMessage = "Invalid Email Address";
  }
  return errorMessage;
};

export const validatePhoneNumbers = values => {
  let errorMessage;
  if (equals(values.phoneNumber, values.icePhoneNumber)) {
    errorMessage = `Invalid. These phone numbers cannot be the same`;
  }
  return errorMessage;
};

// export const luhnValidation = value => {
//   //remove non-digit characters
//   var value = value.replace(/\D/g, "");
//   var sum = 0;
//   var shouldDouble = false;
//   // loop through values starting at the rightmost side
//   for (var i = value.length - 1; i >= 0; i--) {
//     var digit = parseInt(value.charAt(i));

//     if (shouldDouble) {
//       if ((digit *= 2) > 9) digit -= 9;
//     }

//     sum += digit;
//     shouldDouble = !shouldDouble;
//   }
//   return sum % 10 == 0;
// };

export const luhnValidationWithCardType = value => {
  //remove non-digit characters
  var value = value.replace(/\D/g, "");
  var sum = 0;
  var shouldDouble = false;
  // loop through values starting at the rightmost side
  for (var i = value.length - 1; i >= 0; i--) {
    var digit = parseInt(value.charAt(i));

    if (shouldDouble) {
      if ((digit *= 2) > 9) digit -= 9;
    }
    sum += digit;
    shouldDouble = !shouldDouble;
  }

  var valid = sum % 10 == 0;
  var accepted = false;

  Object.keys(acceptedCreditCards).forEach(function(key) {
    var regex = acceptedCreditCards[key];
    if (regex.test(value)) {
      accepted = true;
    }
  });
  // return accepted && valid;
  if (or(not(accepted), not(valid))) {
    return `Invalid`;
  }
  // return or(not(accepted), not(valid));
};

////shows !valid vs !accepted\\\
// if (or(not(accepted), not(valid))) {
//   console.log("***!valid, !accepted***", accepted, valid);
//   return `Incomplete`;
// } else if (not(accepted)) {
//   console.log("*!accepted*");
//   return `Invalid Credit Card Type`;
// } else if (not(valid)) {
//   console.log("*!valid*");
//   return `Invalid Credit Card Number`;
// }

export function valid_credit_card(value) {
  // accept only digits, dashes or spaces
  if (/[^0-9-\s]+/.test(value)) return false;

  // The Luhn Algorithm. It's so pretty.
  var nCheck = 0,
    nDigit = 0,
    bEven = false;
  value = value.replace(/\D/g, "");

  for (var n = value.length - 1; n >= 0; n--) {
    var cDigit = value.charAt(n),
      nDigit = parseInt(cDigit, 10);

    if (bEven) {
      if ((nDigit *= 2) > 9) nDigit -= 9;
    }

    nCheck += nDigit;
    bEven = !bEven;
  }

  return nCheck % 10 == 0;
}

///////////used to validate creditCard matches the cvv length\\\\\\\\
// const validateCVV = (creditCard, cvv) => {
//   ///regEx validation for accepted credit cards
//   var acceptedCreditCards = {
//     visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
//     mastercard: /^5[1-5][0-9]{14}$|^2(?:2(?:2[1-9]|[3-9][0-9])|[3-6][0-9][0-9]|7(?:[01][0-9]|20))[0-9]{12}$/,
//     amex: /^3[47][0-9]{13}$/,
//     discover: /^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/,
//     diners_club: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
//     jcb: /^(?:2131|1800|35[0-9]{3})[0-9]{11}$/
//   };

//   //remove all non digit characters
//   var creditCard = creditCard.replace(/\D/g, '');
//   var cvv = cvv.replace(/\D/g, '')
// //AmEx = 4 digits
// if ((acceptedCreditCards.amex).test(creditCard)) {
//   if ((/^\d{4}$/).test(cvv))
//     return true;
// } else if ((/^\d{3}$/).test(cvv)) {
// //checking for 3 digits everywhere else
//   return true;
// }
// return false;
// }

var acceptedCreditCards = {
  ///regEx validation for accepted credit cards
  visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
  mastercard: /^5[1-5][0-9]{14}$|^2(?:2(?:2[1-9]|[3-9][0-9])|[3-6][0-9][0-9]|7(?:[01][0-9]|20))[0-9]{12}$/,
  amex: /^3[47][0-9]{13}$/,
  discover: /^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/,
  diners_club: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
  jcb: /^(?:2131|1800|35[0-9]{3})[0-9]{11}$/
};

export function checkSupported(value) {
  // remove all non digit characters
  var value = value.replace(/\D/g, "");
  var accepted = false;

  // loop through the keys (visa, mastercard, amex, etc.)
  Object.keys(acceptedCreditCards).forEach(function(key) {
    var regex = acceptedCreditCards[key];
    if (regex.test(value)) {
      accepted = true;
    }
  });

  return accepted;
}
