import React from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import MenuAppBar from "../../components/menuAppBar";
import withDrawer from "../../components/withDrawer";
import withFooter from "../../components/withFooter";
import EnhancedTableUsers from "../../components/users/enhancedTableUsers";
import {
  selectUser,
  sendInvitation,
  setUsers,
  updateUserRole
  // updateUser
} from "../../action-creators/users";
import {
  CURRENT_USER_CLEARED,
  EDIT_USER_CLEARED,
  EDIT_USER_SELECTED
} from "../../constants";

const userStyles = theme => ({
  ///enhancedTableUsers\\\
  actions: {
    color: theme.palette.text.secondary
  },
  root: {
    flexGrow: 1,
    // width: "100%",
    marginTop: theme.spacing.unit * 3
  },
  table: {
    // minWidth: 1020
    // minWidth: 750
    flex: 1
  },
  tableWrapper: {
    overflowX: "scroll"
  },
  spacer: {
    flex: "1 1 100%"
  },
  ///addUserDialog\\\
  button: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    backgroundColor: theme.palette.primary.main
  },
  label: {
    //  textTransform: "capitalize"
    width: "100%",
    margin: theme.spacing.unit
  },
  secondButton: {
    marginBottom: theme.spacing.unit,
    border: `4px solid `,
    borderRadius: "3px"
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  tablePaper: {
    // marginTop: theme.spacing.unit * 3,
    width: "100%",
    // overflowX: "auto",
    marginBottom: theme.spacing.unit * 1
  },
  paper: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      marginTop: theme.spacing.unit * 6,
      marginBottom: theme.spacing.unit * 6,
      padding: theme.spacing.unit * 3
    }
  },
  textField: {
    width: "90%",
    marginLeft: theme.spacing.unit, //"auto",
    marginRight: theme.spacing.unit, //"auto",
    flexBasis: 200,
    paddingBottom: 0,
    borderTop: "None",
    borderRight: "None",
    borderLeft: "None",
    marginTop: 10
    // marginTop: 0,
    // borderBottomColor: grey[900],
    // borderBottom: "1px solid #ced4da",
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  },
  select: {
    fontSize: 12,
    width: 140,
    height: 30
  }
});

const columnDataUser = [
  {
    id: "Name",
    align: "inherit",
    //disablePadding: false,
    padding: "none",
    label: "Name",
    sortColumn: true
  },
  {
    id: "Email",
    //align: "inherit",
    padding: "none",
    label: "Email",
    sortColumn: true,

    smDown: true
  },
  {
    padding: "none",
    label: "User Info",
    trade: true
  },
  {
    id: "EmailConfirmed",
    align: "center",
    disablePadding: true,
    //label: "Confirmed"
    label: "",
    tiny: true
  },
  {
    id: "UserName",
    align: "inherit",
    // disablePadding: false,
    padding: "none",
    label: "Login Name",
    sortColumn: true,
    smDown: true
  },
  {
    id: "PhoneNumber",
    align: "inherit",
    // padding: "dense",
    label: "Phone number",
    sortColumn: true,
    smDown: true
  },
  {
    id: "RoleName",
    align: "inherit",
    padding: "none",
    label: "Access",
    hidden: true
  },

  {
    id: "Created",
    hidden: true,
    align: "inherit",
    padding: "none",
    label: "Created"
  }
];

const ManageUsers = props => {
  const {
    classes,
    dispatch,
    history,
    key,
    users,
    //enhancedTableUsers\\
    companies,
    isError,
    isValidating,
    isAuthenticated,
    errorMsg,
    setUsers,
    editSelectedUser,
    editUserCleared,
    //addUser\\
    newUser,
    newIsSaving,
    newIsError,
    newIsAuthenticated,
    newErrorMsg,
    sendInvitation,
    clearUser,
    authentication
  } = props;

  return (
    <React.Fragment>
      <MenuAppBar userYes={true} companyYes={true} />
      <div className={classes.root}>
        <EnhancedTableUsers
          //all\\
          addUser
          classes={classes}
          dispatch={dispatch}
          history={history}
          //enhancedTableUsers\\
          key={key}
          columnData={columnDataUser}
          users={users}
          companies={companies}
          isError={isError}
          isValidating={isValidating}
          isAuthenticated={isAuthenticated}
          errorMsg={errorMsg}
          setUsers={setUsers}
          editSelectedUser={editSelectedUser}
          editUserCleared={editUserCleared}
          //AddUser\\
          newUser={newUser}
          newIsSaving={newIsSaving}
          newIsError={newIsError}
          newIsAuthenticated={newIsAuthenticated}
          newErrorMsg={newErrorMsg}
          sendInvitation={sendInvitation}
          clearUser={clearUser}
          authentication={authentication}
        />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  //enhancedTableUsers\\
  users: state.users,
  editUser: state.editUser.data,
  companies: state.companies,
  isError: state.editUser.isError,
  isValidating: state.editUser.isValidating,
  isAuthenticated: state.editUser.isAuthenticated,
  errorMsg: state.editUser.errorMsg,
  ///addUserDialog\\\
  newUser: state.newUser.data,
  newIsSaving: state.newUser.isSaving,
  newIsError: state.newUser.isError,
  newIsAuthenticated: state.newUser.isAuthenticated,
  newErrorMsg: state.newUser.errorMsg,
  authentication: state.authentication
});

const mapActionsToProps = dispatch => ({
  setUsers: () => dispatch(setUsers),
  editSelectedUser: user => dispatch(updateUserRole(user)),
  setUserToEdit: user => dispatch({ type: EDIT_USER_SELECTED, payload: user }),
  selectUser: user => dispatch(selectUser(user)),
  editUserCleared: () => dispatch({ type: EDIT_USER_CLEARED }),
  ///addUserDialog\\\
  sendInvitation: email => dispatch(sendInvitation(email)),
  clearUser: () => dispatch({ type: CURRENT_USER_CLEARED }),
  dispatch
});

const connector = connect(
  mapStateToProps,
  mapActionsToProps
);

export default withDrawer(
  withFooter(connector(withStyles(userStyles)(ManageUsers)))
);
