import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import red from "@material-ui/core/colors/red";
import AddIcon from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
import SaveIcon from "@material-ui/icons/Save";
import { isEmpty, clone, gt, length, map, sort, descend, prop, filter, propEq, find, findIndex, difference, append, or } from "ramda";
import Button from "@material-ui/core/Button";
import Note from "./note";
import List from '@material-ui/core/List'
import { styles } from './swStyles'
import moment from 'moment'
import { connect } from 'react-redux'
import { updateSWNotes, getNoteHistory, addSWNote, editSWNote } from '../../action-creators/signedWaivers'

class NotesCard extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      addExpanded: false,
      note: "",
      currentNotes: [],
      notes: [],
      text: "",
      history: false,
      editing: false,
      noteVersion: 1,
      noteId: 0,
      disableEditing: false,
      type: "",
      nId: 0
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.sw.Notes !== this.props.sw.Notes) {
      if (!isEmpty(this.state.type)) {
        // const newNote = difference(this.props.sw.Notes, this.state.notes)
        const newNote = difference(this.props.sw.Notes, prevProps.sw.Notes)[0]
        let newCurrentNotes
        if (this.state.type === "new") {
          newCurrentNotes = append(newNote, this.props.signedWaiver.CurrentNotes)
        } else if (this.state.type === "edit") {
          const i = findIndex(propEq("Id", newNote.Id), this.props.signedWaiver.CurrentNotes)
          newCurrentNotes = Object.assign([], this.props.signedWaiver.CurrentNotes, { [i]: newNote })
        }
        const sws = clone(this.props.activeArray)
        const sw = find(propEq("SignedWaiver_Id", this.props.signedWaiver.SignedWaiver_Id), sws)
        const newSW = Object.assign(sw, { CurrentNotes: newCurrentNotes })
        const newSwArray = Object.assign([], sws, { [this.props.activeStep]: newSW })
        this.setState({ type: "" })
        this.props.handleUpdateSWArray(newSwArray)
      }
    }
  }
  componentWillMount() {
    this.setState({ notes: this.props.sw.Notes, currentNotes: this.props.signedWaiver.CurrentNotes })
  }
  handleEditing = name => event => {
    const version = Number(event.currentTarget.value)
    const text = event.currentTarget.text
    this.setState({ editing: name, noteId: name, noteVersion: version, text: text, disableEditing: true, addExpanded: false, history: false })
  };
  //gets all note versions
  handleHistory = name => event => {
    this.setState({ history: name });
    const id = Number(event.currentTarget.Id);
    const notes = filter(propEq("Id", name), this.props.sw.Notes);
    console.log(notes)
    this.setState({ notes: notes, disableEditing: false });
  };
  //cancels note edit and note history
  handleCancel = name => (event) => {
    this.setState({ [name]: false, note: "", noteId: null, disableEditing: false });
  };
  //edit note change
  onChange = note => event => {
    this.setState({ note: event.target.value })
  };
  handleSaveEdit = () => {
    const editObj = { swId: Number(this.props.signedWaiver.SignedWaiver_Id), version: this.state.noteVersion, note: this.state.note, nId: Number(this.state.noteId) }
    this.props.editSWNote(editObj)

    this.setState({ editing: false, note: "", noteId: null, type: "edit", disableEditing: false, editing: false, history: false })
  }
  handleExpandAdd = () => {

    this.setState(state => ({ addExpanded: !state.addExpanded, history: false }));
  };
  handleChange = value => event => {
    this.setState({ note: event.target.value })
  }
  onSaveNew = n => {

    const noteObj = { note: this.state.note, Created: moment().format(), Id: length(this.props.signedWaiver.CurrentNotes) + 1, swId: Number(this.props.signedWaiver.SignedWaiver_Id) }
    this.props.addSWNote(noteObj);

    this.setState({ addExpanded: false, note: "", disableEditing: false, type: "new", editing: false, history: false });
  };
  handleCancelAdd = () => {
    this.setState({ addExpanded: false, disableEditing: false, })
  }
  render() {
    const { classes, signedWaiver, getNoteHistory, sw } = this.props
    const { note, currentNotes, addExpanded, text, history, editing, } = this.state
    const { onChange, handleCancel, handleHistory, handleEditing, handleSaveEdit, handleCancelAdd, handleExpandAdd, onSaveNew, handleChange } = this
    return (
      <React.Fragment>
        <Card className={classes.card}>
          <CardHeader
            avatar={
              <Avatar aria-label="Notes" >
                <i className="material-icons md-18">speaker_notes</i>
              </Avatar>
            }
            action={
              <Button
                variant="contained"
                disabled={addExpanded}
                onClick={handleExpandAdd}
                aria-expanded={addExpanded}
                aria-label="Add Note"
                size="small"
              >
                <AddIcon /> Add Note
                  </Button>
            }
            title="Notes"
          />
          <Collapse in={addExpanded} timeout="auto" unmountOnExit>
            <CardContent>
              <form className={classes.container} autoComplete="off">
                <TextField
                  className={classes.textField}
                  id="new-note"
                  style={{ margin: 8 }}
                  fullWidth
                  onChange={e => {
                    const val = e.target.value
                    if (val !== "") {
                      this.setState({ note: val })
                    }
                  }}
                  multiline
                  margin="normal"
                  variant="filled"

                />
                <Button onClick={handleCancelAdd} size="small" variant="contained">
                  Cancel
          </Button>
                <Button
                  className={classes.right}
                  variant="contained"
                  color="primary"
                  onClick={onSaveNew}
                  size="small"
                >
                  <SaveIcon /> Save
                    </Button>
              </form>
            </CardContent>
          </Collapse>
          {!isEmpty(signedWaiver.CurrentNotes) ? (
            <List dense>
              {gt(length(signedWaiver.CurrentNotes), 0) ? (
                <React.Fragment>
                  {map(
                    n => (
                      <Note
                        key={n.Id}
                        note={n}
                        onSaveEdit={handleSaveEdit}
                        id={n.Id}
                        value={text}
                        onChange={onChange("text")}
                        getNoteHistory={getNoteHistory}
                        signedWaiver={signedWaiver}
                        history={history}
                        editing={editing}
                        handleChange={handleEditing}
                        handleHistory={handleHistory}
                        handleCancel={handleCancel}
                        notes={this.state.notes}
                        //   notes={sw.Notes}
                        disableEditing={this.state.disableEditing}
                      />
                    ),
                    sort(descend(prop(new Date("Created"))), signedWaiver.CurrentNotes)

                  )}
                </React.Fragment>
              ) : (
                  <div />
                )}
            </List>
          ) : (
              <div />
            )}
        </Card>
      </React.Fragment>
    );
    //  }


  }
}
const mapStateToProps = state => ({
  sw: state.signedWaiver
})
const mapActionToProps = dispatch => ({
  addSWNote: (obj) => dispatch(addSWNote(obj)),
  editSWNote: (obj) => dispatch(editSWNote(obj)),
  updateSWNotes: (obj) => dispatch(updateSWNotes(obj)),
  getNoteHistory: id => dispatch(getNoteHistory(id)),
  dispatch
})
const connection = connect(mapStateToProps, mapActionToProps)

export default connection(withStyles(styles)(NotesCard));
