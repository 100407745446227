import { HELP_VIDEOS_REQUESTED, HELP_VIDEOS_ACQUIRED } from '../constants'

export const helpVideos = (state = {}, action) => {
    switch (action.type) {
        case HELP_VIDEOS_REQUESTED:
        return action.payload
        case HELP_VIDEOS_ACQUIRED:
        return action.payload
      default:
        return state
    }
  }









