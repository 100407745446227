import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import { Field, Form, Formik } from "formik";
import FormikCheckbox from "../formikCheckbox";
import {
  luhnValidationWithCardType,
  newBillingDataSchema,
  validateName
} from "../../lib/validation";
import { isEmpty, or } from "ramda";
import ExpDateDropDown from "../formikDropDown";
import FormikMaterialInput from "../formikMaterialInput";

class AddPaymentCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      getBillingData: [],
      newBillingData: [],
      open: false,
      billingData: {
        CardHolder: "", //name
        CardNumber: "",
        ZipCode: "",
        ExpDate: {
          Month: "",
          Year: ""
        },
        CVC: "",
        Remember: true
      }
    };
  }

  handleSubmitCard = newCard => {
    this.props.newCompanyCard(newCard);
    this.props.saveCreditCard(newCard);
    this.props.handleExpandClick();
    ///window.location.reload();
  };

  render() {
    const { classes, handleExpandClick } = this.props;
    return (
      <React.Fragment>
        <Card className={classes.card}>
          <CardHeader title="Billing Information" />
          <CardContent>
            <Formik
              initialValues={this.state.billingData}
              enableReinitialize={true}
              validationSchema={newBillingDataSchema}
              onSubmit={(values, { resetForm }) =>
                this.handleSubmitCard(values)
              }
              render={formikProps => (
                <Form>
                  <center>
                    <Grid container spacing={24}>
                      <Grid item xs={12} md={6}>
                        <Field
                          validate={validateName}
                          name="CardHolder"
                          label="Name on card"
                          component={FormikMaterialInput}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          name="CardNumber"
                          label="Card Number"
                          validate={luhnValidationWithCardType}
                          type="number"
                          component={FormikMaterialInput}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <Field
                          required
                          name="ZipCode"
                          label="Billing Zip / Postal Code"
                          component={FormikMaterialInput}
                          type="number"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          required
                          name="CVC"
                          label="CVC (code on back)"
                          type="number"
                          component={FormikMaterialInput}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          required
                          className={classes.textField}
                          name="ExpDate"
                          label="Expiration Date"
                          component={ExpDateDropDown}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          className={classes.checkBox}
                          name="Remember"
                          label="Remember credit card details for next time"
                          component={FormikCheckbox}
                        />
                      </Grid>
                    </Grid>

                    <Button
                      onClick={handleExpandClick}
                      className={classes.button}
                      variant="contained"
                      type="button"
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={or(
                        or(
                          !isEmpty(formikProps.errors),
                          formikProps.isSubmitting
                        ),
                        isEmpty(formikProps.touched)
                      )}
                      onClick={formikProps.handleSubmit}
                      className={classes.button}
                      variant="contained"
                      type="submit"
                    >
                      Save New Credit Card
                    </Button>
                    {/* <Debug /> */}
                  </center>
                </Form>
              )}
            />
          </CardContent>
        </Card>
      </React.Fragment>
    );
  }
}

export default AddPaymentCard;
