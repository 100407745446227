import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import { Field, Formik, Form } from "formik";
import FormikCheckbox from "../formikCheckbox";
import DialogAppBar from "../dialogAppBar";
import { getLocation, updateLocation } from "../../action-creators/location";
import {
  LOCATION_SELECTED,
  EDIT_LOCATION_EXITED,
  EDIT_LOCATION_CLOSED
} from "../../constants";
import { getCompanyPaymentDetails } from "../../action-creators/billing";
import CustomizedSnackbars from "../snackbar";
import { and, equals, find, isEmpty, isNil, or, split } from "ramda";
import { locationDataSchema } from "../../lib/validation";
import { Link } from "react-router-dom";
//newer
import FormikMaterialInput from "../formikMaterialInput";

const styles = theme => ({
  root: {
    paddingTop: "2.3rem"
  },
  headingPadding: {
    paddingBottom: ".7rem"
  },
  checkBox: {
    width: "auto"
  },
  central: {
    textAlign: "center",
    justifyContent: "center"
  },
  textField: {
    width: "90%",
    flexBasis: 200,
    marginTop: 0,
    marginBottom: 12,
    paddingBottom: 12
  }
});

class LocationEditFormik extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      stateLocationData: {
        Id: null,
        Name: "",
        Address: "",
        Location: {
          Latitude: "",
          Longitude: ""
        },
        Primary: true //false //"" //false
      }
    };
  }

  componentDidMount() {
    let companyId = split("/", this.props.match.url)[2];
    let locationId = this.props.match.params.id;
    this.props.getLocation();
    this.setState({
      companyId: companyId,
      locationId: locationId,
      stateLocationData: this.props.locations.editLocation
    });
  }

  componentDidUpdate() {
    if (
      and(
        isNil(this.props.locations.editLocation),
        !isNil(this.props.locations.data)
      )
    ) {
      let editLocation = find(
        l => l.Id === Number(this.props.match.params.id),
        this.props.locations.data
      );
      this.props.setLocationToEdit(editLocation);
      this.setState({ stateLocationData: editLocation });
    }
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  refreshCoordinates = address => {
    this.props.getLocationCoor(address);
  };

  onEditSubmit = address => {
    this.props.updateLocation(address);
    this.props.history.push(`/companyDetails/${this.props.companyDetails.Id}`);
    this.props.editLocationClosed();
    // window.location.reload();
  };

  handleCancel = () => {
    this.props.history.push(`/companyDetails/${this.props.companyDetails.Id}`);
    this.props.editLocationClosed();
  };

  render() {
    const { classes, isError, isSaved, isSaving } = this.props;

    return (
      <React.Fragment>
        <DialogAppBar title="Edit Location" handleClose={this.handleCancel} />

        <Formik
          enableReinitialize={true}
          validationSchema={locationDataSchema}
          // initialValues={this.props.locations.editLocation}
          initialValues={this.state.stateLocationData}
          onSubmit={this.onEditSubmit}
          render={formikProps => (
            <Form onSubmit={formikProps.handleSubmit}>
              <DialogContent>
                <Grid item xs={12}>
                  <Field
                    className={classes.textField}
                    name="Name"
                    component={FormikMaterialInput}
                    label="Company Name"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="Address"
                    component={FormikMaterialInput}
                    label="Address"
                    className={classes.textField}
                  />
                </Grid>

                <Grid container spacing={16} className={classes.central}>
                  <Grid item>
                    <Field
                      className={classes.checkBox}
                      name="Primary"
                      component={FormikCheckbox}
                      label="Primary"
                    />
                  </Grid>
                  <Grid item>
                    <Field
                      id="component-location-latitude"
                      name="Location.Latitude" //will be location: { latitude: "", longitude: "" } so
                      className={classes.textField}
                      type="number"
                      placeholder="Latitude"
                      component={FormikMaterialInput}
                      label="Latitude"
                    />
                  </Grid>
                  <Grid item>
                    <Field
                      id="component-location-longitude"
                      className={classes.textField}
                      name="Location.Longitude"
                      type="number"
                      placeholder="Longitude"
                      component={FormikMaterialInput}
                      label="Longitude"
                    />
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions>
                <Button
                  onClick={this.handleCancel}
                  color="default"
                  variant="contained"
                >
                  Cancel
                </Button>
                <Button
                  disabled={or(
                    formikProps.isSubmitting,
                    or(
                      equals(formikProps.initialValues, formikProps.values),
                      !isEmpty(formikProps.errors)
                    )
                  )}
                  onClick={formikProps.handleSubmit}
                  color="default"
                  variant="contained"
                >
                  Update Location
                </Button>
              </DialogActions>
            </Form>
          )}
        />
        {isError && (
          <CustomizedSnackbars
            message="New Location Failed to Save. Please try again."
            snackType="error"
          />
        )}
        {isSaving && (
          <CustomizedSnackbars
            message="New Location is Loading..."
            snackType="info"
          />
        )}
        {isSaved && (
          <CustomizedSnackbars
            message="Location successfully edited!"
            snackType="success"
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  companyPaymentDetails: state.companyPaymentDetails,
  companyDetails: state.companyPaymentDetails.data.Company,
  locations: state.locations,
  companies: state.companies,
  isFetching: state.locations.isFetching,
  isError: state.locations.isError,
  isSaved: state.locations.isSaved,
  isSaving: state.locations.isSaving,
  isAcquired: state.locations.isAcquired
});

const mapActionsToProps = dispatch => ({
  getLocation: () => dispatch(getLocation()),
  getCompanyPaymentDetails: id => dispatch(getCompanyPaymentDetails(id)),
  setLocationToEdit: location =>
    dispatch({ type: LOCATION_SELECTED, payload: location }),
  updateLocation: addr => dispatch(updateLocation(addr)),
  editLocationExited: () => dispatch({ type: EDIT_LOCATION_EXITED }),
  editLocationClosed: () => dispatch({ type: EDIT_LOCATION_CLOSED })
});

const connector = connect(
  mapStateToProps,
  mapActionsToProps
);

export default connector(withStyles(styles)(LocationEditFormik));

/////was Map Button\\\\\
{
  /* <Grid item>
                    <Grid
                      container
                      justify="center"
                      alignItems="center"
                      spacing={16}
                    >
                      <Grid item>
                        {this.state.address !== this.props.newLocation ? ( //this.props.initialValues.Address
                          <Button
                            variant="contained"
                            onClick={() =>
                              this.refreshCoordinates(this.state.address)
                            }
                            color="primary"
                          >
                            Map
                          </Button>
                        ) : (
                          <Button variant="contained" color="primary">
                            Map
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Grid> */
}
