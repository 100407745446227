import {
  PRIMARY_LOCATION_EDITED,
  NEW_LOCATION_CREATED,
  NEW_LOCATION_REQUESTED,
  LOCATION_EDITED,
  LOCATION_ACQUIRED,
  LOCATION_UPDATED,
  LOCATION_REQUESTED,
  LOCATION_COORDINATES_ACQUIRED,
  LOCATION_COORDINATES_FAILED,
  LOCATION_COORDINATES_REQUESTED,
  LOCATION_DIALOG_HANDLED,
  EDIT_LOCATION_REQUESTED,
  EDIT_LOCATION_RELOADED,
  LOCATION_SELECTED,
  EDIT_LOCATION_EXITED,
  RESET_LOCATIONS,
  EDIT_LOCATION_CLOSED
} from "../constants";
import { find, isNil, merge } from "ramda";

const initialLocationData = {
  data: [
    {
      Address: "",
      Id: "",
      Location: {
        Latitude: "",
        Longitude: ""
      },
      Name: ""
    }
  ],
  isFetching: false,
  isPrimary: false,
  isError: false,
  isSaving: false,
  isSaved: false,
  isAcquired: false,
  isClosed: false,
  locationDialog: "",
  editLocation: {
    Address: "",
    Id: "",
    Location: {
      Latitude: "",
      Longitude: ""
    },
    Name: ""
  },
  newLocation: {
    Address: "",
    Id: "",
    Location: {
      Latitude: "",
      Longitude: ""
    },
    Name: ""
  }
};

export const locations = (state = initialLocationData, action) => {
  switch (action.type) {
    case NEW_LOCATION_REQUESTED:
      return action.payload;
    case NEW_LOCATION_CREATED:
      return action.payload;
    case LOCATION_REQUESTED:
      return action.payload;
    case LOCATION_ACQUIRED:
      return merge(state, {
        isFetching: false,
        data: action.payload,
        isAcquired: true
      });
    case LOCATION_EDITED:
      return action.payload;
    case LOCATION_SELECTED:
      return merge(state, {
        editLocation: action.payload
        // isFetching: true
      });
    case EDIT_LOCATION_REQUESTED:
      // console.log("EDIT_LOCATION_REQUESTED_AP", action.payload);
      return merge(state, { isSaving: true });
    case PRIMARY_LOCATION_EDITED:
      return action.payload;
    case LOCATION_COORDINATES_ACQUIRED:
      return action.payload;
    case LOCATION_COORDINATES_FAILED:
      return action.payload;
    case LOCATION_COORDINATES_REQUESTED:
      return action.payload;
    case LOCATION_COORDINATES_ACQUIRED:
      return action.payload;
    case LOCATION_DIALOG_HANDLED:
      return merge(state, { locationDialog: action.payload });
    case EDIT_LOCATION_RELOADED:
      return merge(state, { isFetching: false, isSaving: false });
    case EDIT_LOCATION_CLOSED:
      return merge(state, { isClosed: true });
    case EDIT_LOCATION_EXITED:
      return merge(state, {
        isFetching: false,
        isPrimary: false,
        isError: false,
        isSaving: false,
        isSaved: false,
        isAcquired: false,
        isClosed: false
      });
    case RESET_LOCATIONS:
      return {};
    default:
      return state;
  }
};
