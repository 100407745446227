import { apiFetchClosure } from "../lib/fetch";
import {
  WAIVER_USAGE_REQUESTED,
  WAIVER_USAGE_ACQUIRED,
  WAIVER_CONTACTS_ACQUIRED,
  WAIVER_CONTACTS_REQUESTED
} from "../constants";
import moment from 'moment'
export const getWaiverUsage = (startingDate, stoppingDate) => async (
  dispatch,
  getState
) => {
  const n = moment(stoppingDate).add(1, 'day').format("YYYY-MM-DD")
  dispatch({ type: WAIVER_USAGE_REQUESTED });
  const response = await apiFetchClosure()(
    `/api/SignedWaivers/Usage?fromDateTime=${startingDate}&toDateTime=${n}`
  )
    .then(res => res.json())
    .catch(err => console.log("err: ", err));
  dispatch({ type: WAIVER_USAGE_ACQUIRED, payload: response });
};

export const getWaiverContacts = (startingDate, stoppingDate) => async (
  dispatch,
  getState
) => {
  const n = moment(stoppingDate).add(1, 'day').format("YYYY-MM-DD")
  dispatch({ type: WAIVER_CONTACTS_REQUESTED });
  const response = await apiFetchClosure()(
    `/api/SignedWaivers/contacts?fromDateTime=${startingDate}&toDateTime=${n}`
  )
    .then(res => res.json())
    .catch(err => console.log("err: ", err));
  dispatch({ type: WAIVER_CONTACTS_ACQUIRED, payload: response });
};
