import {
    DRAWER_TOGGLED, DRAWER_OPENED, DRAWER_CLOSED, FETCHING, TEMP, CLEAR_TEMP, LOADING

} from '../constants'





export const drawer = (state = { open: false }, action) => {
    switch (action.type) {
        case DRAWER_TOGGLED:
            return { open: !state.open }
        case DRAWER_OPENED:
            return { open: true }
        case DRAWER_CLOSED:
            return { open: false }

        default:
            return state
    }
}

export const ind = (state = {loading: true}, action) => {
    switch (action.type) {
        case FETCHING:
            return Object.assign({}, state, { fetching: action.payload })
        case LOADING:
            return Object.assign({}, state, { loading: action.payload })
        default:
            return state
    }
}

export const tempData = (state = { edited: false }, action) => {
    switch (action.type) {
        case TEMP:
            return Object.assign({}, state, { offset: action.payload.offset, edited: action.payload.edited })
        case CLEAR_TEMP:
            return { edited: false }
        default:
            return state
    }
}