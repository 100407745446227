import React from "react";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import AddPaymentCard from "./addPaymentCard";
import { isEmpty, prop } from "ramda";
import Hover from "../hover";
import AddIcon from "@material-ui/icons/AddCircleOutline";

const PaymentFormOrInfo = props => {
  const { companyPaymentDetails, classes } = props;

  return !props.companyPaymentDetails.added ? (
    isEmpty(props.companyPaymentDetails.LastDigits) ? (
      <Card className={classes.card}>
        <CardHeader title="Billing Information" />
        <CardContent>
          <AddPaymentCard
            addNewCardDispatch={props.addNewCardDispatch}
            {...props}
          />
        </CardContent>
      </Card>
    ) : (
      <Card className={classes.card}>
        <CardHeader
          title="Payment Information"
          subheader={`Current balance $${prop(
            "Balance",
            companyPaymentDetails
          )}`}
        />
        <CardContent>
          <Typography component="h3">
            <b>Card holder:</b>
            {` ${prop("CardHolder", companyPaymentDetails)}`}
          </Typography>
          <Typography type="body2">
            <b>Card type:</b>
            {`  ${prop("CardType", companyPaymentDetails)}`}
          </Typography>
          <Typography component="p">
            <b>Card ending in:</b>
            {` ${prop("LastDigits", companyPaymentDetails)}`}
          </Typography>
          <Typography component="h3">
            <b>Billing zip code:</b>
            {` ${prop("ZipCode", companyPaymentDetails)}`}
          </Typography>
          <Typography component="h3">
            <b>Last Updated:</b>
            {` ${moment(prop("Updated", companyPaymentDetails)).format(
              "MM/DD/YYYY hh:mm A"
            )}`}
          </Typography>
        </CardContent>
        <CardActions>
          <Hover
            message="Add New Card"
            component={
              <Button
                // disabled={}
                className={classes.button}
                color="primary"
                variant="contained"
                onClick={() => {
                  props.handleExpandClick();
                }}
              >
                <AddIcon className={classes.leftIcon} /> Card
              </Button>
            }
          />
        </CardActions>
      </Card>
    )
  ) : null;
};

export default PaymentFormOrInfo;
